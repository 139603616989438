import { apiEnums } from '@nodal/api';
import { medicalReviewsUids } from '@nodal/core/consts/medicalReviewsUids';
import { screenTypes } from '@nodal/core/consts/screenTypes';
import { t } from '@nodal/i18n';
import { compact } from 'lodash';
import every from 'lodash/every';
import flatMap from 'lodash/flatMap';
import some from 'lodash/some';

import { getCurrentScreeningPayment } from 'utils/candidateRequest';

import {
  billingStatusToBagdeStatus,
  taskStatusToBadgeStatus,
  getMedicalReviewStatus,
  screenStatusToBadgeStatus,
} from '@b2b/utils/candidate';

import type { CandidateScreen } from './ScreeningDetails.interface';
import type { ApiModel } from '@nodal/api';
import type { ScreenType } from '@nodal/core/consts/screenTypes';

export const transformScreensBasedOnPreviousStatus = (
  screens: ApiModel.ScreenNested[],
): ApiModel.ScreenNested[] =>
  screens.map((screen, idx) => {
    const previousScreen = screens[idx - 1];

    // NOTE: We check whether the previous screen has been completed
    // and update the status to 'processing' for the next unstarted screen the user is in
    // (instead of showing 'pending' which we get from BE)
    if (
      (previousScreen?.status === apiEnums.ScreenStatusEnum.App ||
        previousScreen?.status === apiEnums.ScreenStatusEnum.Half) &&
      screen.status === apiEnums.ScreenStatusEnum.Pend
    ) {
      return { ...screen, status: apiEnums.ScreenStatusEnum.Proc };
    }

    return screen;
  });

export const getStatusBasedOnMultipleScreens = (
  screens: ApiModel.ScreenNested[],
): ApiModel.ScreenStatusEnum => {
  if (!screens.length) {
    return apiEnums.ScreenStatusEnum.Pend;
  }

  if (some(screens, { status: apiEnums.ScreenStatusEnum.Rej })) {
    return apiEnums.ScreenStatusEnum.Rej;
  } else if (every(screens, { status: apiEnums.ScreenStatusEnum.App })) {
    return apiEnums.ScreenStatusEnum.App;
  } else if (
    some(screens, { status: apiEnums.ScreenStatusEnum.Proc }) ||
    some(screens, { status: apiEnums.ScreenStatusEnum.App })
  ) {
    return apiEnums.ScreenStatusEnum.Proc;
  }

  return apiEnums.ScreenStatusEnum.Pend;
};

export const getFinalReviewStatus = (
  profile: ApiModel.DonorProfile | ApiModel.ParentsProfile,
): ApiModel.ScreenStatusEnum => {
  if (profile.final_review_completed) {
    return apiEnums.ScreenStatusEnum.App;
  }

  const staffReview = {
    send_to_matching: profile.send_to_matching,
    all_stages_complete: profile.all_stages_complete,
    cleared_for_medical_review:
      'cleared_for_medical_review' in profile
        ? profile.cleared_for_medical_review
        : false,
  };

  if (
    some(staffReview, (value) => !!value) ||
    profile.match_profile_review_status !==
      apiEnums.MatchProfileReviewStatusEnum.Pending
  ) {
    return apiEnums.ScreenStatusEnum.Proc;
  }

  return apiEnums.ScreenStatusEnum.Pend;
};

export const buildBasicCandidateScreens = (
  screens: ApiModel.ScreenNested[],
  medicalReview?: ApiModel.ReviewNested,
): Array<CandidateScreen> => {
  // NOTE: We do not show all candidate screens in screening details.
  // -> organization candidate, navigator, parents or donor profile is displayed in the candidate profile,
  // -> hipaaConsentForm is combined into one and displayed together with medicalRecordReview
  const excludedScreenTypes = [
    screenTypes.parentsProfile,
    screenTypes.donorProfile,
    screenTypes.navigatorProfile,
    screenTypes.organizationCandidateProfile,
    screenTypes.hipaaConsentForm,
  ];

  const displayScreeningScreens = screens.filter(
    (screen) =>
      !excludedScreenTypes.find((screenType) => screenType === screen.type),
  );

  const screensWithMeta = flatMap(
    displayScreeningScreens,
    ({ type: screenType, title }, idx) => {
      // NOTE: There is a requirement for screening screens to check the previous screen status and
      // update the status for the next unstarted screen in which the user is in
      const transformedScreeningScreens =
        transformScreensBasedOnPreviousStatus(screens);

      const { status, completion_date } =
        transformedScreeningScreens.find(
          (screen) => screenType === screen.type,
        ) || {};

      if (screenType === screenTypes.medicalRecordReview) {
        return [
          {
            title: t('Medical Forms'),
            // NOTE: The Medical Forms section consists of the Hipaa Consent and Medical Record screens.
            // We need to calculate the status based on these two screens.
            // We filter the screens to extract information about the Hipaa Consent form, which is not displayed.
            status: screenStatusToBadgeStatus.get(
              getStatusBasedOnMultipleScreens(
                transformedScreeningScreens.filter(
                  ({ type }) =>
                    type === screenTypes.hipaaConsentForm ||
                    type === screenTypes.medicalRecordReview,
                ),
              ),
            ),
            type: screenTypes.medicalForms,
            completionDate: completion_date,
            order: idx + 1,
          },
          {
            title: t('Medical Review'),
            type: screenTypes.medicalReview,
            status: screenStatusToBadgeStatus.get(
              getMedicalReviewStatus(medicalReview),
            ),
            // NOTE: The completion date of the medial review section is determined by the reviewDecision step.
            completionDate: medicalReview?.steps?.find(
              (step) =>
                step.uid === medicalReviewsUids.orgCandidateReviewDecision ||
                step.uid === medicalReviewsUids.donorReviewDecision,
            )?.completion_date,
            // NOTE: The Medical Review section needs to be displayed at the end of screening screens
            order: displayScreeningScreens.length + 1,
          },
        ];
      }

      return {
        title: title || undefined,
        status: status ? screenStatusToBadgeStatus.get(status) : undefined,
        // NOTE: added type cast because type for screen_type in Api Schema (string)
        // is less specific than type defined by us
        type: screenType as ScreenType,
        completionDate: completion_date,
        order: idx + 1,
      };
    },
  );

  return screensWithMeta;
};

export const buildCandidateScreensByRole = ({
  role,
  screens,
  medicalReview,
  finalReview,
  contract,
  payment,
  ipDashboardEnabled,
}: {
  role: ApiModel.UserRoleEnum;
  screens: ApiModel.ScreenNested[];
  medicalReview?: ApiModel.ReviewNested;
  finalReview?: {
    status: ApiModel.ScreenStatusEnum;
    completion_date?: string | null;
  };
  contract?: {
    status?: ApiModel.TaskStatusEnum;
    completion_date?: string | null;
  };
  payment?: {
    status?: ApiModel.BillingStatusEnum;
    completion_date?: string | null;
  };
  ipDashboardEnabled: boolean;
}): Array<CandidateScreen> => {
  const basicCandidateScreens = buildBasicCandidateScreens(
    screens,
    medicalReview,
  );

  if (
    role === apiEnums.UserRoleEnum.Par ||
    role === apiEnums.UserRoleEnum.Dnr ||
    role === apiEnums.UserRoleEnum.Nap
  ) {
    return [
      ...basicCandidateScreens,
      ...compact([
        contract &&
          role === apiEnums.UserRoleEnum.Par &&
          ipDashboardEnabled && {
            title: t('Contract'),
            type: screenTypes.contract,
            status:
              contract.status && taskStatusToBadgeStatus.get(contract.status),
            completionDate: contract.completion_date,
            order: basicCandidateScreens.length + 1,
          },
        {
          title: t('DocuSign Documents'),
          type: screenTypes.docuSignDocuments,
          status: undefined,
          completionDate: null,
          order: basicCandidateScreens.length + 2,
        },
        finalReview && {
          title: t('Final Review'),
          type: screenTypes.finalReview,
          status: screenStatusToBadgeStatus.get(finalReview.status),
          completionDate: finalReview.completion_date,
          order: basicCandidateScreens.length + 3,
        },
        payment &&
          (role === apiEnums.UserRoleEnum.Par ||
            role === apiEnums.UserRoleEnum.Nap) && {
            title: t('Billing'),
            type: screenTypes.matchingPayment,
            status: payment?.status
              ? billingStatusToBagdeStatus.get(payment.status)
              : undefined,
            completionDate: payment?.completion_date,
            order: basicCandidateScreens.length + 4,
          },
        role !== apiEnums.UserRoleEnum.Nap && {
          title: t('Matching'),
          type:
            role === apiEnums.UserRoleEnum.Par
              ? screenTypes.parentsMatching
              : screenTypes.surrogateMatching,
          order: basicCandidateScreens.length + 5,
        },
      ]),
    ];
  }

  return basicCandidateScreens;
};

export const getPaymentCompletionDateBy = ({
  invitation,
  subscription,
  matchingPayment,
}: {
  invitation?: ApiModel.InvitationNested;
  subscription?: ApiModel.Subscription;
  matchingPayment?: ApiModel.NodalMatchingPayment;
}) => {
  if (invitation) {
    return getCurrentScreeningPayment(invitation.screening_payments)
      ?.completion_date;
  } else if (subscription?.stripe_payment_method?.created) {
    // NOTE: The payment method returns time in seconds,
    // so we're converting it to milliseconds
    return new Date(
      subscription?.stripe_payment_method?.created * 1000,
    ).toISOString();
  } else if (matchingPayment?.completion_date) {
    return matchingPayment?.completion_date;
  }
};
