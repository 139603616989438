/* tslint:disable */
/* eslint-disable */
/**
 * Nodal health
 * Nodal health API
 *
 * The version of the OpenAPI document: 1.0.0 (api)
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from './base';

/**
 *
 * @export
 * @interface Address
 */
export interface Address {
  /**
   *
   * @type {string}
   * @memberof Address
   */
  city?: string | null;
  /**
   *
   * @type {AddressState}
   * @memberof Address
   */
  state?: AddressState | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  line1?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  line2?: string | null;
  /**
   *
   * @type {string}
   * @memberof Address
   */
  zipcode?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof Address
   */
  location: Array<number> | null;
}
/**
 * @type AddressState
 * @export
 */
export type AddressState = BlankEnum | NullEnum | StateEnum;

/**
 *
 * @export
 * @enum {string}
 */

export const AfterDeliveryRelationshipEnum = {
  NoContact: 'no_contact',
  RegularContact: 'regular_contact',
  OccasionalUpdates: 'occasional_updates',
  TimeWillTell: 'time_will_tell',
  NotSureYet: 'not_sure_yet',
} as const;

export type AfterDeliveryRelationshipEnum =
  typeof AfterDeliveryRelationshipEnum[keyof typeof AfterDeliveryRelationshipEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const AgencyUsageEnum = {
  Agency: 'agency',
  Independent: 'independent',
  CaseManagementLite: 'case_management_lite',
  Undecided: 'undecided',
} as const;

export type AgencyUsageEnum =
  typeof AgencyUsageEnum[keyof typeof AgencyUsageEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const AlternativeMethodsSmokingHistoryEnum = {
  Vaped: 'vaped',
  Ecig: 'ecig',
  Both: 'both',
  No: 'no',
} as const;

export type AlternativeMethodsSmokingHistoryEnum =
  typeof AlternativeMethodsSmokingHistoryEnum[keyof typeof AlternativeMethodsSmokingHistoryEnum];

/**
 *
 * @export
 * @interface AvailableScreen
 */
export interface AvailableScreen {
  /**
   *
   * @type {string}
   * @memberof AvailableScreen
   */
  uid: string;
  /**
   *
   * @type {string}
   * @memberof AvailableScreen
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof AvailableScreen
   */
  screen_type: string;
}
/**
 *
 * @export
 * @interface B2BUser
 */
export interface B2BUser {
  /**
   *
   * @type {number}
   * @memberof B2BUser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof B2BUser
   */
  date_joined?: string;
  /**
   *
   * @type {string}
   * @memberof B2BUser
   */
  email?: string;
  /**
   *
   * @type {UserRoleEnum}
   * @memberof B2BUser
   */
  role?: UserRoleEnum;
  /**
   *
   * @type {string}
   * @memberof B2BUser
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof B2BUser
   */
  middle_name: string;
  /**
   *
   * @type {string}
   * @memberof B2BUser
   */
  last_name: string;
  /**
   *
   * @type {Array<UserProfilePhoto>}
   * @memberof B2BUser
   */
  profile_photos: Array<UserProfilePhoto>;
  /**
   *
   * @type {Review}
   * @memberof B2BUser
   */
  medical_review: Review;
  /**
   *
   * @type {Array<ScreenDetailsNested>}
   * @memberof B2BUser
   */
  screens: Array<ScreenDetailsNested>;
  /**
   *
   * @type {Array<InvitationNested>}
   * @memberof B2BUser
   */
  invitations: Array<InvitationNested>;
  /**
   *
   * @type {Array<OrganizationNested>}
   * @memberof B2BUser
   */
  organizations: Array<OrganizationNested>;
  /**
   *
   * @type {UserStatusEnum}
   * @memberof B2BUser
   */
  status?: UserStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof B2BUser
   */
  show_matching_badge: boolean;
  /**
   *
   * @type {InvitationStatusEnum}
   * @memberof B2BUser
   */
  invitation_status: InvitationStatusEnum | null;
  /**
   *
   * @type {ProfileObject}
   * @memberof B2BUser
   */
  profile: ProfileObject;
  /**
   *
   * @type {SubscriptionObject}
   * @memberof B2BUser
   */
  subscription: SubscriptionObject;
  /**
   *
   * @type {MatchingPaymentObject}
   * @memberof B2BUser
   */
  services_payment: MatchingPaymentObject;
  /**
   *
   * @type {BillingStatusEnum}
   * @memberof B2BUser
   */
  billing_status: BillingStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof B2BUser
   */
  application_flagged_for_review: boolean;
  /**
   *
   * @type {boolean}
   * @memberof B2BUser
   */
  match_profile_flagged_for_review: boolean;
  /**
   *
   * @type {string}
   * @memberof B2BUser
   */
  last_login?: string | null;
}
/**
 *
 * @export
 * @interface B2BUserDisqualify
 */
export interface B2BUserDisqualify {
  /**
   *
   * @type {B2BUserDisqualifyStatusEnum}
   * @memberof B2BUserDisqualify
   */
  status?: B2BUserDisqualifyStatusEnum;
  /**
   *
   * @type {B2BUserDisqualifyDisqualificationReason}
   * @memberof B2BUserDisqualify
   */
  disqualification_reason?: B2BUserDisqualifyDisqualificationReason | null;
  /**
   *
   * @type {string}
   * @memberof B2BUserDisqualify
   */
  disqualification_reason_optional_details?: string | null;
}
/**
 * @type B2BUserDisqualifyDisqualificationReason
 * @export
 */
export type B2BUserDisqualifyDisqualificationReason =
  | BlankEnum
  | DisqualificationReasonEnum
  | NullEnum;

/**
 *
 * @export
 * @enum {string}
 */

export const B2BUserDisqualifyStatusEnum = {
  Dis: 'dis',
} as const;

export type B2BUserDisqualifyStatusEnum =
  typeof B2BUserDisqualifyStatusEnum[keyof typeof B2BUserDisqualifyStatusEnum];

/**
 * Serializer used to avoid fetching content objects in users\' screens, and to skip some costly method fields.
 * @export
 * @interface B2BUserList
 */
export interface B2BUserList {
  /**
   *
   * @type {number}
   * @memberof B2BUserList
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof B2BUserList
   */
  date_joined?: string;
  /**
   *
   * @type {string}
   * @memberof B2BUserList
   */
  email?: string;
  /**
   *
   * @type {UserRoleEnum}
   * @memberof B2BUserList
   */
  role?: UserRoleEnum;
  /**
   *
   * @type {string}
   * @memberof B2BUserList
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof B2BUserList
   */
  middle_name: string;
  /**
   *
   * @type {string}
   * @memberof B2BUserList
   */
  last_name: string;
  /**
   *
   * @type {ReviewNested}
   * @memberof B2BUserList
   */
  medical_review: ReviewNested;
  /**
   *
   * @type {Array<ScreenNested>}
   * @memberof B2BUserList
   */
  screens: Array<ScreenNested>;
  /**
   *
   * @type {CandidateStepsEnum}
   * @memberof B2BUserList
   */
  candidate_steps: CandidateStepsEnum;
  /**
   *
   * @type {UserStatusEnum}
   * @memberof B2BUserList
   */
  status?: UserStatusEnum;
  /**
   *
   * @type {BillingStatusEnum}
   * @memberof B2BUserList
   */
  billing_status: BillingStatusEnum;
  /**
   *
   * @type {MatchingPaymentObject}
   * @memberof B2BUserList
   */
  services_payment: MatchingPaymentObject;
  /**
   *
   * @type {boolean}
   * @memberof B2BUserList
   */
  application_flagged_for_review: boolean;
  /**
   *
   * @type {boolean}
   * @memberof B2BUserList
   */
  match_profile_flagged_for_review: boolean;
  /**
   *
   * @type {MedicalReviewStatusEnum}
   * @memberof B2BUserList
   */
  medical_review_status: MedicalReviewStatusEnum;
}
/**
 *
 * @export
 * @interface B2BUserNonDetailedList
 */
export interface B2BUserNonDetailedList {
  /**
   *
   * @type {number}
   * @memberof B2BUserNonDetailedList
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof B2BUserNonDetailedList
   */
  date_joined?: string;
  /**
   *
   * @type {string}
   * @memberof B2BUserNonDetailedList
   */
  email?: string;
  /**
   *
   * @type {UserRoleEnum}
   * @memberof B2BUserNonDetailedList
   */
  role?: UserRoleEnum;
  /**
   *
   * @type {string}
   * @memberof B2BUserNonDetailedList
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof B2BUserNonDetailedList
   */
  middle_name: string;
  /**
   *
   * @type {string}
   * @memberof B2BUserNonDetailedList
   */
  last_name: string;
  /**
   *
   * @type {ReviewNested}
   * @memberof B2BUserNonDetailedList
   */
  medical_review: ReviewNested;
  /**
   *
   * @type {UserStatusEnum}
   * @memberof B2BUserNonDetailedList
   */
  status?: UserStatusEnum;
  /**
   *
   * @type {Array<OrganizationNested>}
   * @memberof B2BUserNonDetailedList
   */
  organizations: Array<OrganizationNested>;
  /**
   *
   * @type {string}
   * @memberof B2BUserNonDetailedList
   */
  title: string;
}
/**
 *
 * @export
 * @interface B2bCandidatePayment
 */
export interface B2bCandidatePayment {
  /**
   *
   * @type {ScreeningPackage}
   * @memberof B2bCandidatePayment
   */
  package: ScreeningPackage;
  /**
   *
   * @type {PaymentStatusEnum}
   * @memberof B2bCandidatePayment
   */
  payment_status?: PaymentStatusEnum;
  /**
   *
   * @type {B2bCandidatePaymentPayingEntity}
   * @memberof B2bCandidatePayment
   */
  paying_entity?: B2bCandidatePaymentPayingEntity | null;
  /**
   *
   * @type {PaymentTypeEnum}
   * @memberof B2bCandidatePayment
   */
  payment_type?: PaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof B2bCandidatePayment
   */
  payer_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof B2bCandidatePayment
   */
  payer_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof B2bCandidatePayment
   */
  completion_date?: string | null;
  /**
   *
   * @type {EmbeddedDropboxDocument}
   * @memberof B2bCandidatePayment
   */
  contract: EmbeddedDropboxDocument;
}
/**
 *
 * @export
 * @interface B2bCandidatePaymentNested
 */
export interface B2bCandidatePaymentNested {
  /**
   *
   * @type {ScreeningPackage}
   * @memberof B2bCandidatePaymentNested
   */
  package: ScreeningPackage;
  /**
   *
   * @type {PaymentStatusEnum}
   * @memberof B2bCandidatePaymentNested
   */
  payment_status?: PaymentStatusEnum;
  /**
   *
   * @type {B2bCandidatePaymentPayingEntity}
   * @memberof B2bCandidatePaymentNested
   */
  paying_entity?: B2bCandidatePaymentPayingEntity | null;
  /**
   *
   * @type {PaymentTypeEnum}
   * @memberof B2bCandidatePaymentNested
   */
  payment_type?: PaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof B2bCandidatePaymentNested
   */
  payer_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof B2bCandidatePaymentNested
   */
  payer_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof B2bCandidatePaymentNested
   */
  completion_date?: string | null;
}
/**
 * @type B2bCandidatePaymentPayingEntity
 * @export
 */
export type B2bCandidatePaymentPayingEntity =
  | BlankEnum
  | NullEnum
  | PayingEntityEnum;

/**
 *
 * @export
 * @interface B2bIntroduction
 */
export interface B2bIntroduction {
  /**
   *
   * @type {number}
   * @memberof B2bIntroduction
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof B2bIntroduction
   */
  donor_first_name: string;
  /**
   *
   * @type {string}
   * @memberof B2bIntroduction
   */
  donor_last_name: string;
  /**
   *
   * @type {string}
   * @memberof B2bIntroduction
   */
  parent_first_name: string;
  /**
   *
   * @type {string}
   * @memberof B2bIntroduction
   */
  parent_last_name: string;
  /**
   *
   * @type {string}
   * @memberof B2bIntroduction
   */
  parent_partner_first_name: string;
  /**
   *
   * @type {string}
   * @memberof B2bIntroduction
   */
  parent_partner_last_name: string;
  /**
   *
   * @type {IntroductionStageEnum}
   * @memberof B2bIntroduction
   */
  stage?: IntroductionStageEnum;
  /**
   *
   * @type {string}
   * @memberof B2bIntroduction
   */
  expiration_date?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof B2bIntroduction
   */
  call_scheduled?: boolean;
}
/**
 *
 * @export
 * @interface B2bScreeningPackagePrice
 */
export interface B2bScreeningPackagePrice {
  /**
   *
   * @type {string}
   * @memberof B2bScreeningPackagePrice
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof B2bScreeningPackagePrice
   */
  card_payment_fees?: string | null;
  /**
   *
   * @type {string}
   * @memberof B2bScreeningPackagePrice
   */
  bank_transfer_fees?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const BillingStatusEnum = {
  Complete: 'complete',
  Failed: 'failed',
  InProgress: 'in_progress',
  Pending: 'pending',
  Minus: '-',
} as const;

export type BillingStatusEnum =
  typeof BillingStatusEnum[keyof typeof BillingStatusEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const BirthControlMethodEnum = {
  Iud: 'iud',
  EssureIud: 'essure_iud',
  NonHormonalIud: 'non_hormonal_iud',
  HormonalImplant: 'hormonal_implant',
  DepoProvera: 'depo_provera',
  VaginalRing: 'vaginal_ring',
  Patch: 'patch',
  Pill: 'pill',
  TubalLigation: 'tubal_ligation',
  PartnerVasectomy: 'partner_vasectomy',
  Condoms: 'condoms',
  Other: 'other',
} as const;

export type BirthControlMethodEnum =
  typeof BirthControlMethodEnum[keyof typeof BirthControlMethodEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const BlankEnum = {
  Empty: '',
} as const;

export type BlankEnum = typeof BlankEnum[keyof typeof BlankEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const CandidateStepsEnum = {
  Deactivated: 'deactivated',
  Rejected: 'rejected',
  NotRegistered: 'not_registered',
  PersonalInformation: 'personal_information',
  Application: 'application',
  IdCheck: 'id_check',
  HipaaConsentForm: 'hipaa_consent_form',
  MedicalForm: 'medical_form',
  Checkr: 'checkr',
  FinalReview: 'final_review',
  Matching: 'matching',
  Complete: 'complete',
} as const;

export type CandidateStepsEnum =
  typeof CandidateStepsEnum[keyof typeof CandidateStepsEnum];

/**
 *
 * @export
 * @interface CheckrScreen
 */
export interface CheckrScreen {
  /**
   *
   * @type {Array<CheckrScreenCandidate>}
   * @memberof CheckrScreen
   */
  candidates: Array<CheckrScreenCandidate>;
  /**
   *
   * @type {Array<CheckrScreenCandidateInput>}
   * @memberof CheckrScreen
   */
  input_candidates: Array<CheckrScreenCandidateInput>;
}
/**
 *
 * @export
 * @interface CheckrScreenCandidate
 */
export interface CheckrScreenCandidate {
  /**
   *
   * @type {RoleFd4Enum}
   * @memberof CheckrScreenCandidate
   */
  role?: RoleFd4Enum;
  /**
   *
   * @type {CheckrScreenCandidateInvitationStatusEnum}
   * @memberof CheckrScreenCandidate
   */
  invitation_status?: CheckrScreenCandidateInvitationStatusEnum;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidate
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidate
   */
  candidate_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidate
   */
  invitation_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidate
   */
  invitation_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidate
   */
  report_id?: string | null;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof CheckrScreenCandidate
   */
  report?: { [key: string]: any };
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidate
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidate
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidate
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidate
   */
  state?: string | null;
  /**
   *
   * @type {CheckrScreenCandidateSearch}
   * @memberof CheckrScreenCandidate
   */
  searches: CheckrScreenCandidateSearch;
}
/**
 *
 * @export
 * @interface CheckrScreenCandidateInput
 */
export interface CheckrScreenCandidateInput {
  /**
   *
   * @type {RoleFd4Enum}
   * @memberof CheckrScreenCandidateInput
   */
  role: RoleFd4Enum;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidateInput
   */
  email?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const CheckrScreenCandidateInvitationStatusEnum = {
  NotCreated: 'not_created',
  Active: 'active',
  Expired: 'expired',
} as const;

export type CheckrScreenCandidateInvitationStatusEnum =
  typeof CheckrScreenCandidateInvitationStatusEnum[keyof typeof CheckrScreenCandidateInvitationStatusEnum];

/**
 *
 * @export
 * @interface CheckrScreenCandidateSearch
 */
export interface CheckrScreenCandidateSearch {
  /**
   *
   * @type {CheckrScreenCandidateSearchResult}
   * @memberof CheckrScreenCandidateSearch
   */
  sex_offender_search: CheckrScreenCandidateSearchResult;
  /**
   *
   * @type {CheckrScreenCandidateSearchResult}
   * @memberof CheckrScreenCandidateSearch
   */
  global_watchlist_search: CheckrScreenCandidateSearchResult;
  /**
   *
   * @type {CheckrScreenCandidateSearchResult}
   * @memberof CheckrScreenCandidateSearch
   */
  national_criminal_search: CheckrScreenCandidateSearchResult;
  /**
   *
   * @type {CheckrScreenCandidateSearchResult}
   * @memberof CheckrScreenCandidateSearch
   */
  ssn_trace: CheckrScreenCandidateSearchResult;
  /**
   *
   * @type {CheckrScreenCandidateSearchResult}
   * @memberof CheckrScreenCandidateSearch
   */
  county_criminal_searches: CheckrScreenCandidateSearchResult;
}
/**
 *
 * @export
 * @interface CheckrScreenCandidateSearchResult
 */
export interface CheckrScreenCandidateSearchResult {
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidateSearchResult
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidateSearchResult
   */
  result: string;
}
/**
 *
 * @export
 * @interface CheckrScreenCandidatesUserFromIntroduction
 */
export interface CheckrScreenCandidatesUserFromIntroduction {
  /**
   *
   * @type {number}
   * @memberof CheckrScreenCandidatesUserFromIntroduction
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CheckrScreenCandidatesUserFromIntroduction
   */
  report_status: string;
  /**
   *
   * @type {RoleFd4Enum}
   * @memberof CheckrScreenCandidatesUserFromIntroduction
   */
  role?: RoleFd4Enum;
}
/**
 *
 * @export
 * @interface CheckrScreenResendInvitations
 */
export interface CheckrScreenResendInvitations {
  /**
   *
   * @type {string}
   * @memberof CheckrScreenResendInvitations
   */
  candidate_id: string;
}
/**
 *
 * @export
 * @interface CheckrScreenUserFromIntroduction
 */
export interface CheckrScreenUserFromIntroduction {
  /**
   *
   * @type {Array<CheckrScreenCandidatesUserFromIntroduction>}
   * @memberof CheckrScreenUserFromIntroduction
   */
  candidates: Array<CheckrScreenCandidatesUserFromIntroduction>;
}
/**
 *
 * @export
 * @interface CheckrTask
 */
export interface CheckrTask {
  /**
   *
   * @type {number}
   * @memberof CheckrTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CheckrTask
   */
  task_type: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ComfortTravelingEnum = {
  '-_2': '-2',
  '-_1': '-1',
  _0: '0',
  _1: '1',
  _2: '2',
} as const;

export type ComfortTravelingEnum =
  typeof ComfortTravelingEnum[keyof typeof ComfortTravelingEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const CompletionTypeEnum = {
  Linked: 'linked',
  Independent: 'independent',
  Staff: 'staff',
  Mixed: 'mixed',
} as const;

export type CompletionTypeEnum =
  typeof CompletionTypeEnum[keyof typeof CompletionTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const CovidVaccinationPreferenceEnum = {
  FullyVaccinated: 'fully_vaccinated',
  WillingToBeVaccinated: 'willing_to_be_vaccinated',
  NoPreference: 'no_preference',
} as const;

export type CovidVaccinationPreferenceEnum =
  typeof CovidVaccinationPreferenceEnum[keyof typeof CovidVaccinationPreferenceEnum];

/**
 *
 * @export
 * @interface CreateEnvelope
 */
export interface CreateEnvelope {
  /**
   *
   * @type {number}
   * @memberof CreateEnvelope
   */
  template_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateEnvelope
   */
  parent_id: number;
}
/**
 *
 * @export
 * @interface CreateIntroduction
 */
export interface CreateIntroduction {
  /**
   *
   * @type {number}
   * @memberof CreateIntroduction
   */
  parent_id: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const CtaTypeEnum = {
  MatchProfile: 'match_profile',
  CheckOutMatches: 'check_out_matches',
  FindParents: 'find_parents',
  GetStarted: 'get_started',
  SeeYourMatch: 'see_your_match',
  SubscriptionIssue: 'subscription_issue',
} as const;

export type CtaTypeEnum = typeof CtaTypeEnum[keyof typeof CtaTypeEnum];

/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  id: number;
  /**
   *
   * @type {Subscription}
   * @memberof Customer
   */
  subscription: Subscription;
  /**
   *
   * @type {NodalMatchingPayment}
   * @memberof Customer
   */
  services_payment: NodalMatchingPayment;
  /**
   *
   * @type {boolean}
   * @memberof Customer
   */
  is_subscription_user?: boolean;
}
/**
 *
 * @export
 * @interface CustomerPortal
 */
export interface CustomerPortal {
  /**
   *
   * @type {string}
   * @memberof CustomerPortal
   */
  return_url?: string;
  /**
   *
   * @type {string}
   * @memberof CustomerPortal
   */
  customer_portal_url: string;
}
/**
 *
 * @export
 * @interface DashboardMatchingResponse
 */
export interface DashboardMatchingResponse {
  /**
   *
   * @type {LifetimeData}
   * @memberof DashboardMatchingResponse
   */
  lifetime_data: LifetimeData;
  /**
   *
   * @type {MatchingData}
   * @memberof DashboardMatchingResponse
   */
  matching_data: MatchingData;
  /**
   *
   * @type {StageData}
   * @memberof DashboardMatchingResponse
   */
  stages_data: StageData;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DataProviderTypeEnum = {
  ObGyn: 'ob_gyn',
  Pregnancy: 'pregnancy',
  IvfTransfers: 'ivf_transfers',
} as const;

export type DataProviderTypeEnum =
  typeof DataProviderTypeEnum[keyof typeof DataProviderTypeEnum];

/**
 *
 * @export
 * @interface DataPullStepDetail
 */
export interface DataPullStepDetail {
  /**
   *
   * @type {number}
   * @memberof DataPullStepDetail
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DataPullStepDetail
   */
  notes?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const DeliveryCarePreferenceEnum = {
  Obgyn: 'obgyn',
  Midwife: 'midwife',
  NoPreference: 'no_preference',
} as const;

export type DeliveryCarePreferenceEnum =
  typeof DeliveryCarePreferenceEnum[keyof typeof DeliveryCarePreferenceEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const DeliveryLocationPreferenceEnum = {
  HomeBirth: 'home_birth',
  BirthingCenter: 'birthing_center',
  Hospital: 'hospital',
  NoPreference: 'no_preference',
} as const;

export type DeliveryLocationPreferenceEnum =
  typeof DeliveryLocationPreferenceEnum[keyof typeof DeliveryLocationPreferenceEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const DisqualificationReasonEnum = {
  MedicalConcerns: 'medical_concerns',
  ApplicationFlags: 'application_flags',
  BackgroundCheckFlags: 'background_check_flags',
  Other: 'other',
} as const;

export type DisqualificationReasonEnum =
  typeof DisqualificationReasonEnum[keyof typeof DisqualificationReasonEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const DoctorClinicCountryEnum = {
  Us: 'us',
  Other: 'other',
} as const;

export type DoctorClinicCountryEnum =
  typeof DoctorClinicCountryEnum[keyof typeof DoctorClinicCountryEnum];

/**
 *
 * @export
 * @interface DocuSignTemplateList
 */
export interface DocuSignTemplateList {
  /**
   *
   * @type {number}
   * @memberof DocuSignTemplateList
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DocuSignTemplateList
   */
  name: string;
}
/**
 *
 * @export
 * @interface DocumentResource
 */
export interface DocumentResource {
  /**
   *
   * @type {string}
   * @memberof DocumentResource
   */
  file?: string | null;
  /**
   *
   * @type {string}
   * @memberof DocumentResource
   */
  external_resource_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof DocumentResource
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof DocumentResource
   */
  description?: string | null;
  /**
   *
   * @type {TaskStageEnum}
   * @memberof DocumentResource
   */
  stage?: TaskStageEnum;
  /**
   *
   * @type {string}
   * @memberof DocumentResource
   */
  original_file_name?: string | null;
  /**
   *
   * @type {number}
   * @memberof DocumentResource
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DocumentResource
   */
  created: string;
}
/**
 * @type DocumentType
 * @export
 */
export type DocumentType =
  | IpContractDocumentType
  | IpSignMatchAgreementDocumentType;

/**
 *
 * @export
 * @enum {string}
 */

export const DonorPassingReasonEnum = {
  LifestylesDontMatch: 'lifestyles_dont_match',
  TooFarAway: 'too_far_away',
  JourneyPreferencesDontMatch: 'journey_preferences_dont_match',
  Other: 'other',
} as const;

export interface InsuranceReview {
  id?: number;
  no_insurance: boolean;
  health_insurance: string | null;
  insurance_photo_front: string | null;
  insurance_photo_back: string | null;
}

export type DonorPassingReasonEnum =
  typeof DonorPassingReasonEnum[keyof typeof DonorPassingReasonEnum];

/**
 *
 * @export
 * @interface DonorProfile
 */
export interface DonorProfile {
  /**
   *
   * @type {number}
   * @memberof DonorProfile
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  maiden_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof DonorProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  is_military?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfile
   */
  desired_compensation?: number | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfile
   */
  number_of_children?: number;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  video_url: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  is_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  is_match_profile_completed: boolean;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  words_from_nodal?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  world_of_surrogacy?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  important_qual_ip?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  donor_support?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  three_words?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  passionate_about?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  additional_qs?: string | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof DonorProfile
   */
  first_time_parents?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof DonorProfile
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof DonorProfile
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof DonorProfile
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  confidentiality_comfort?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  covid_vaccination?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  willing_to_vaccinate?: boolean | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof DonorProfile
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof DonorProfile
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileComfortTraveling}
   * @memberof DonorProfile
   */
  comfort_traveling?: DonorProfileComfortTraveling | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof DonorProfile
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  comfort_donated_egg?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  comfort_donated_sperm?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  comfort_donated_embryo?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  single_embryo_comfort?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  multiple_embryo?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  willing_to_pump_breast_milk?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  willing_to_restrict_travel?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  independent_journey?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfile
   */
  bmi: number | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfile
   */
  height?: number | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfile
   */
  weight?: number | null;
  /**
   *
   * @type {DonorProfileReligion}
   * @memberof DonorProfile
   */
  religion?: DonorProfileReligion | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  hospital?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  translator_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof DonorProfile
   */
  domestic_couple_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileExperience}
   * @memberof DonorProfile
   */
  experience?: DonorProfileExperience | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {DonorProfileHouseholdIncome}
   * @memberof DonorProfile
   */
  household_income?: DonorProfileHouseholdIncome | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof DonorProfile
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof DonorProfile
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof DonorProfile
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {DonorProfilePreviousCSections}
   * @memberof DonorProfile
   */
  previous_c_sections?: DonorProfilePreviousCSections | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof DonorProfile
   */
  hospital_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof DonorProfile
   */
  clinic_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof DonorProfile
   */
  airport_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof DonorProfile
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  cleared_for_medical_review: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  all_stages_complete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  send_to_matching: boolean;
  /**
   *
   * @type {string}
   * @memberof DonorProfile
   */
  final_review_completion_date: string;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfile
   */
  final_review_completed: boolean;
  /**
   *
   * @type {ApiModel.InsuranceReview}
   * @memberof DonorProfile
   */
  insurance_review?: InsuranceReview;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  no_insurance?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  health_insurance?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  insurance_photo_front?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  insurance_photo_back?: string | null;
}
/**
 *
 * @export
 * @interface DonorProfileAddress
 */
export interface DonorProfileAddress {
  /**
   *
   * @type {string}
   * @memberof DonorProfileAddress
   */
  city?: string | null;
  /**
   *
   * @type {AddressState}
   * @memberof DonorProfileAddress
   */
  state?: AddressState | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileAddress
   */
  line1?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileAddress
   */
  line2?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileAddress
   */
  zipcode?: string | null;
  /**
   *
   * @type {Array<number>}
   * @memberof DonorProfileAddress
   */
  location: Array<number> | null;
}
/**
 * @type DonorProfileAfterDeliveryRelationship
 * @export
 */
export type DonorProfileAfterDeliveryRelationship =
  | AfterDeliveryRelationshipEnum
  | BlankEnum
  | NullEnum;

/**
 * @type DonorProfileComfortTraveling
 * @export
 */
export type DonorProfileComfortTraveling =
  | BlankEnum
  | ComfortTravelingEnum
  | NullEnum;

/**
 * @type DonorProfileDeliveryCarePreference
 * @export
 */
export type DonorProfileDeliveryCarePreference =
  | BlankEnum
  | DeliveryCarePreferenceEnum
  | NullEnum;

/**
 * @type DonorProfileDeliveryLocationPreference
 * @export
 */
export type DonorProfileDeliveryLocationPreference =
  | BlankEnum
  | DeliveryLocationPreferenceEnum
  | NullEnum;

/**
 * @type DonorProfileExperience
 * @export
 */
export type DonorProfileExperience = BlankEnum | ExperienceEnum | NullEnum;

/**
 *
 * @export
 * @interface DonorProfileFinalReview
 */
export interface DonorProfileFinalReview {
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof DonorProfileFinalReview
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileFinalReview
   */
  cleared_for_medical_review?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileFinalReview
   */
  all_stages_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileFinalReview
   */
  send_to_matching?: boolean;
}
/**
 * @type DonorProfileFirstTimeParents
 * @export
 */
export type DonorProfileFirstTimeParents =
  | BlankEnum
  | ImportanceEnum
  | NullEnum;

/**
 * @type DonorProfileHouseholdIncome
 * @export
 */
export type DonorProfileHouseholdIncome =
  | BlankEnum
  | HouseholdIncomeEnum
  | NullEnum;

/**
 * @type DonorProfileIdealStart
 * @export
 */
export type DonorProfileIdealStart = BlankEnum | IdealStartEnum | NullEnum;

/**
 * @type DonorProfileMatchProfileReviewStatus
 * @export
 */
export type DonorProfileMatchProfileReviewStatus =
  | BlankEnum
  | MatchProfileReviewStatusEnum
  | NullEnum;

/**
 * @type DonorProfilePreviousCSections
 * @export
 */
export type DonorProfilePreviousCSections = NullEnum | PreviousCSectionsEnum;

/**
 * @type DonorProfileRelationshipPreference
 * @export
 */
export type DonorProfileRelationshipPreference =
  | BlankEnum
  | NullEnum
  | RelationshipPreferenceEnum;

/**
 * @type DonorProfileRelationshipStatus
 * @export
 */
export type DonorProfileRelationshipStatus =
  | BlankEnum
  | NullEnum
  | RelationshipStatusEnum;

/**
 * @type DonorProfileReligion
 * @export
 */
export type DonorProfileReligion = BlankEnum | NullEnum | ReligionEnum;

/**
 *
 * @export
 * @interface DonorProfileScreen
 */
export interface DonorProfileScreen {
  /**
   *
   * @type {number}
   * @memberof DonorProfileScreen
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  maiden_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof DonorProfileScreen
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  is_military?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfileScreen
   */
  desired_compensation?: number | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfileScreen
   */
  number_of_children?: number;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  video_url: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  is_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  is_match_profile_completed: boolean;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  words_from_nodal?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  world_of_surrogacy?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  important_qual_ip?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  donor_support?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  three_words?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  passionate_about?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  additional_qs?: string | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof DonorProfileScreen
   */
  first_time_parents?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof DonorProfileScreen
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof DonorProfileScreen
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof DonorProfileScreen
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  confidentiality_comfort?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  covid_vaccination?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  willing_to_vaccinate?: boolean | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof DonorProfileScreen
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof DonorProfileScreen
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileComfortTraveling}
   * @memberof DonorProfileScreen
   */
  comfort_traveling?: DonorProfileComfortTraveling | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof DonorProfileScreen
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  comfort_donated_egg?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  comfort_donated_sperm?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  comfort_donated_embryo?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  single_embryo_comfort?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  multiple_embryo?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  willing_to_pump_breast_milk?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  willing_to_restrict_travel?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  independent_journey?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfileScreen
   */
  bmi: number | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfileScreen
   */
  height?: number | null;
  /**
   *
   * @type {number}
   * @memberof DonorProfileScreen
   */
  weight?: number | null;
  /**
   *
   * @type {DonorProfileReligion}
   * @memberof DonorProfileScreen
   */
  religion?: DonorProfileReligion | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  hospital?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  translator_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof DonorProfileScreen
   */
  domestic_couple_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileExperience}
   * @memberof DonorProfileScreen
   */
  experience?: DonorProfileExperience | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {DonorProfileHouseholdIncome}
   * @memberof DonorProfileScreen
   */
  household_income?: DonorProfileHouseholdIncome | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof DonorProfileScreen
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof DonorProfileScreen
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof DonorProfileScreen
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {DonorProfilePreviousCSections}
   * @memberof DonorProfileScreen
   */
  previous_c_sections?: DonorProfilePreviousCSections | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof DonorProfileScreen
   */
  hospital_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof DonorProfileScreen
   */
  clinic_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof DonorProfileScreen
   */
  airport_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof DonorProfileScreen
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  cleared_for_medical_review: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  all_stages_complete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  send_to_matching: boolean;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  final_review_completion_date: string;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  final_review_completed: boolean;
}
/**
 * @type DonorProfileTerminationChoice
 * @export
 */
export type DonorProfileTerminationChoice =
  | BlankEnum
  | NullEnum
  | TerminationChoice;

/**
 *
 * @export
 * @interface DonorQuestionnaireScreen
 */
export interface DonorQuestionnaireScreen {
  /**
   *
   * @type {number}
   * @memberof DonorQuestionnaireScreen
   */
  id: number;
  /**
   *
   * @type {Array<QuestionnaireFlag>}
   * @memberof DonorQuestionnaireScreen
   */
  flags: Array<QuestionnaireFlag>;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  us_citizen?: boolean;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof DonorQuestionnaireScreen
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {string}
   * @memberof DonorQuestionnaireScreen
   */
  partner_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorQuestionnaireScreen
   */
  partner_last_name?: string | null;
  /**
   *
   * @type {number}
   * @memberof DonorQuestionnaireScreen
   */
  sex_partners_last_6_months?: number | null;
  /**
   *
   * @type {DonorQuestionnaireScreenBirthControlMethod}
   * @memberof DonorQuestionnaireScreen
   */
  birth_control_method?: DonorQuestionnaireScreenBirthControlMethod | null;
  /**
   *
   * @type {string}
   * @memberof DonorQuestionnaireScreen
   */
  birth_control_method_details?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorQuestionnaireScreen
   */
  birth_control_pill_brand?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  history_of_pre_eclampsia?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  comfortable_abstaining_from_sex?: boolean;
  /**
   *
   * @type {string}
   * @memberof DonorQuestionnaireScreen
   */
  last_papsmear_date?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  receipt_of_government_assistance?: boolean;
  /**
   *
   * @type {number}
   * @memberof DonorQuestionnaireScreen
   */
  height?: number | null;
  /**
   *
   * @type {number}
   * @memberof DonorQuestionnaireScreen
   */
  weight?: number | null;
  /**
   *
   * @type {number}
   * @memberof DonorQuestionnaireScreen
   */
  previous_pregnancy_count?: number | null;
  /**
   *
   * @type {string}
   * @memberof DonorQuestionnaireScreen
   */
  last_pregnancy_delivery_date?: string | null;
  /**
   *
   * @type {DonorQuestionnaireScreenLastPregnancyDeliveryType}
   * @memberof DonorQuestionnaireScreen
   */
  last_pregnancy_delivery_type?: DonorQuestionnaireScreenLastPregnancyDeliveryType | null;
  /**
   *
   * @type {number}
   * @memberof DonorQuestionnaireScreen
   */
  previous_ivf_transfer_count?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  currently_breastfeeding?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof DonorQuestionnaireScreen
   */
  currently_breastfeeding_wean_date?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  miscarriage?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  native_american?: boolean;
  /**
   *
   * @type {number}
   * @memberof DonorQuestionnaireScreen
   */
  previous_c_section_count?: number | null;
  /**
   *
   * @type {number}
   * @memberof DonorQuestionnaireScreen
   */
  failed_embryo_count?: number | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  has_exclusion_conditions?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  suffered_from_postpartum_depression?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  usage_of_grass?: boolean;
  /**
   *
   * @type {string}
   * @memberof DonorQuestionnaireScreen
   */
  tattoo_last_year_date?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  suspect_postpartum_depression?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  has_family_support?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  finished_having_kids?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  history_of_depression?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  history_of_cardiac?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  history_of_autoimmune?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  history_of_abdominal?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  history_of_abuse?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  current_passport?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  willing_travel_to_canada?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  _legacy_smoked_last_year?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof DonorQuestionnaireScreen
   */
  smoked_last_year_within_house?: boolean;
  /**
   *
   * @type {DonorQuestionnaireScreenTraditionalSmokingHistory}
   * @memberof DonorQuestionnaireScreen
   */
  traditional_smoking_history?: DonorQuestionnaireScreenTraditionalSmokingHistory | null;
  /**
   *
   * @type {DonorQuestionnaireScreenAlternativeMethodsSmokingHistory}
   * @memberof DonorQuestionnaireScreen
   */
  alternative_methods_smoking_history?: DonorQuestionnaireScreenAlternativeMethodsSmokingHistory | null;
}
/**
 * @type DonorQuestionnaireScreenAlternativeMethodsSmokingHistory
 * @export
 */
export type DonorQuestionnaireScreenAlternativeMethodsSmokingHistory =
  | AlternativeMethodsSmokingHistoryEnum
  | BlankEnum
  | NullEnum;

/**
 * @type DonorQuestionnaireScreenBirthControlMethod
 * @export
 */
export type DonorQuestionnaireScreenBirthControlMethod =
  | BirthControlMethodEnum
  | BlankEnum
  | NullEnum;

/**
 * @type DonorQuestionnaireScreenLastPregnancyDeliveryType
 * @export
 */
export type DonorQuestionnaireScreenLastPregnancyDeliveryType =
  | BlankEnum
  | LastPregnancyDeliveryTypeEnum
  | NullEnum;

/**
 * @type DonorQuestionnaireScreenTraditionalSmokingHistory
 * @export
 */
export type DonorQuestionnaireScreenTraditionalSmokingHistory =
  | BlankEnum
  | NullEnum
  | TraditionalSmokingHistoryEnum;

/**
 *
 * @export
 * @enum {string}
 */

export const DropboxDocumentTypeEnum = {
  Hipaa: 'hipaa',
  ConsentForm: 'consent_form',
  ConsentFormB2b: 'consent_form_b2b',
  InvitationContract: 'invitation_contract',
  IpContractPs: 'ip_contract_ps',
  IpContractPp: 'ip_contract_pp',
  IpContractMp: 'ip_contract_mp',
  IpContractFs: 'ip_contract_fs',
  IpContractFp: 'ip_contract_fp',
  IpContractNs: 'ip_contract_ns',
  IpContractNp: 'ip_contract_np',
  MatchAgreementSingle: 'match_agreement_single',
  MatchAgreementPartner: 'match_agreement_partner',
  MatchSheet: 'match_sheet',
} as const;

export type DropboxDocumentTypeEnum =
  typeof DropboxDocumentTypeEnum[keyof typeof DropboxDocumentTypeEnum];

/**
 *
 * @export
 * @interface EggDonorQuestionnaireScreen
 */
export interface EggDonorQuestionnaireScreen {
  /**
   *
   * @type {number}
   * @memberof EggDonorQuestionnaireScreen
   */
  id: number;
  /**
   *
   * @type {Array<QuestionnaireFlag>}
   * @memberof EggDonorQuestionnaireScreen
   */
  flags: Array<QuestionnaireFlag>;
  /**
   *
   * @type {number}
   * @memberof EggDonorQuestionnaireScreen
   */
  height?: number | null;
  /**
   *
   * @type {number}
   * @memberof EggDonorQuestionnaireScreen
   */
  weight?: number | null;
  /**
   *
   * @type {EggDonorQuestionnaireScreenEthnicity}
   * @memberof EggDonorQuestionnaireScreen
   */
  ethnicity?: EggDonorQuestionnaireScreenEthnicity | null;
  /**
   *
   * @type {string}
   * @memberof EggDonorQuestionnaireScreen
   */
  ethnicity_other?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  native_american?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  us_citizen?: boolean;
  /**
   *
   * @type {EggDonorQuestionnaireScreenParentage}
   * @memberof EggDonorQuestionnaireScreen
   */
  parentage?: EggDonorQuestionnaireScreenParentage | null;
  /**
   *
   * @type {EggDonorQuestionnaireScreenHighestEducation}
   * @memberof EggDonorQuestionnaireScreen
   */
  highest_education?: EggDonorQuestionnaireScreenHighestEducation | null;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  is_convicted_of_crime?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  currently_pregnant?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  currently_breastfeeding?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof EggDonorQuestionnaireScreen
   */
  currently_breastfeeding_wean_date?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  both_ovaries?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  usage_of_grass?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  smoked_last_year?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  heroin_use?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  alcoholism?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  medications_depression?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  history_of_cardiac?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof EggDonorQuestionnaireScreen
   */
  history_of_autoimmune?: boolean;
}
/**
 * @type EggDonorQuestionnaireScreenEthnicity
 * @export
 */
export type EggDonorQuestionnaireScreenEthnicity =
  | BlankEnum
  | EthnicityEnum
  | NullEnum;

/**
 * @type EggDonorQuestionnaireScreenHighestEducation
 * @export
 */
export type EggDonorQuestionnaireScreenHighestEducation =
  | BlankEnum
  | HighestEducationEnum
  | NullEnum;

/**
 * @type EggDonorQuestionnaireScreenParentage
 * @export
 */
export type EggDonorQuestionnaireScreenParentage =
  | BlankEnum
  | NullEnum
  | ParentageEnum;

/**
 *
 * @export
 * @interface EmbeddedDropboxDocument
 */
export interface EmbeddedDropboxDocument {
  /**
   *
   * @type {number}
   * @memberof EmbeddedDropboxDocument
   */
  id: number;
  /**
   *
   * @type {DropboxDocumentTypeEnum}
   * @memberof EmbeddedDropboxDocument
   */
  document_type: DropboxDocumentTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof EmbeddedDropboxDocument
   */
  is_signed?: boolean;
  /**
   *
   * @type {string}
   * @memberof EmbeddedDropboxDocument
   */
  sign_id: string | null;
  /**
   *
   * @type {string}
   * @memberof EmbeddedDropboxDocument
   */
  sign_url: string | null;
  /**
   *
   * @type {string}
   * @memberof EmbeddedDropboxDocument
   */
  signed_file_url: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const EmbryoTransferTypeEnum = {
  Set: 'SET',
  Det: 'DET',
} as const;

export type EmbryoTransferTypeEnum =
  typeof EmbryoTransferTypeEnum[keyof typeof EmbryoTransferTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const EmbryosPgsTestedEnum = {
  Yes: 'yes',
  No: 'no',
  Planning: 'planning',
} as const;

export type EmbryosPgsTestedEnum =
  typeof EmbryosPgsTestedEnum[keyof typeof EmbryosPgsTestedEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const EthnicityEnum = {
  Ind: 'ind',
  Asi: 'asi',
  Afr: 'afr',
  Lat: 'lat',
  Haw: 'haw',
  W: 'w',
  O: 'o',
} as const;

export type EthnicityEnum = typeof EthnicityEnum[keyof typeof EthnicityEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ExperienceEnum = {
  Experienced: 'experienced',
  FirstTime: 'first_time',
} as const;

export type ExperienceEnum = typeof ExperienceEnum[keyof typeof ExperienceEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const FacilityTypeEnum = {
  Hospital: 'hospital',
  BirthCenter: 'birth_center',
  HomeBirth: 'home_birth',
  Other: 'other',
} as const;

export type FacilityTypeEnum =
  typeof FacilityTypeEnum[keyof typeof FacilityTypeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const FaxAdapterTypeEnum = {
  Pdf: 'pdf',
  Srfax: 'srfax',
  Email: 'email',
} as const;

export type FaxAdapterTypeEnum =
  typeof FaxAdapterTypeEnum[keyof typeof FaxAdapterTypeEnum];

/**
 *
 * @export
 * @interface FaxOutboxFaxStatus
 */
export interface FaxOutboxFaxStatus {
  /**
   *
   * @type {number}
   * @memberof FaxOutboxFaxStatus
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof FaxOutboxFaxStatus
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof FaxOutboxFaxStatus
   */
  updated: string;
  /**
   *
   * @type {string}
   * @memberof FaxOutboxFaxStatus
   */
  queued_fax_id?: string | null;
  /**
   *
   * @type {SentStatusEnum}
   * @memberof FaxOutboxFaxStatus
   */
  sent_status?: SentStatusEnum;
  /**
   *
   * @type {number}
   * @memberof FaxOutboxFaxStatus
   */
  record_pull: number;
}
/**
 *
 * @export
 * @interface FaxPdfDocument
 */
export interface FaxPdfDocument {
  /**
   *
   * @type {number}
   * @memberof FaxPdfDocument
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof FaxPdfDocument
   */
  content_url: string | null;
}
/**
 * @type FinalReviewObject
 * @export
 */
export type FinalReviewObject =
  | DonorProfileFinalReview
  | NavigatorProfileFinalReview
  | ParentsProfileFinalReview;

/**
 *
 * @export
 * @enum {string}
 */

export const FrozenEmbryoCountEnum = {
  _0: '0',
  _1: '1',
  _2: '2',
  _3: '3',
  _4: '4',
  _5: '5+',
} as const;

export type FrozenEmbryoCountEnum =
  typeof FrozenEmbryoCountEnum[keyof typeof FrozenEmbryoCountEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const HighestEducationEnum = {
  None: 'none',
  NurserySchool: 'nursery_school',
  HighSchool: 'high_school',
  HighSchoolGrad: 'high_school_grad',
  CollegeCredit: 'college_credit',
  Training: 'training',
  AssociateDegree: 'associate_degree',
  BachelorsDegree: 'bachelors_degree',
  MastersDegree: 'masters_degree',
  ProfessionalDegree: 'professional_degree',
  DoctorateDegree: 'doctorate_degree',
} as const;

export type HighestEducationEnum =
  typeof HighestEducationEnum[keyof typeof HighestEducationEnum];

/**
 *
 * @export
 * @interface HipaaConsentFormScreen
 */
export interface HipaaConsentFormScreen {
  /**
   *
   * @type {number}
   * @memberof HipaaConsentFormScreen
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof HipaaConsentFormScreen
   */
  hipaa_signed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HipaaConsentFormScreen
   */
  consent_form_signed?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const HouseholdIncomeEnum = {
  _35000: '<35000',
  _3500069000: '35000-69000',
  _7000099000: '70000-99000',
  _100000149000: '100000-149000',
  _150000: '>150000',
} as const;

export type HouseholdIncomeEnum =
  typeof HouseholdIncomeEnum[keyof typeof HouseholdIncomeEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const IdealStartEnum = {
  Asap: 'asap',
  Soon: 'soon',
  Later: 'later',
  Eventually: 'eventually',
} as const;

export type IdealStartEnum = typeof IdealStartEnum[keyof typeof IdealStartEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ImportanceEnum = {
  Not: 'not',
  Imp: 'imp',
  Mad: 'mad',
} as const;

export type ImportanceEnum = typeof ImportanceEnum[keyof typeof ImportanceEnum];

/**
 *
 * @export
 * @interface Introduction
 */
export interface Introduction {
  /**
   *
   * @type {number}
   * @memberof Introduction
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof Introduction
   */
  initial_parent_accepted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Introduction
   */
  match_accepted_parent?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Introduction
   */
  match_accepted_donor?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof Introduction
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof Introduction
   */
  updated: string;
  /**
   *
   * @type {string}
   * @memberof Introduction
   */
  expiration_date?: string | null;
  /**
   *
   * @type {IntroductionStageEnum}
   * @memberof Introduction
   */
  stage?: IntroductionStageEnum;
  /**
   *
   * @type {IntroductionParentPassingReason}
   * @memberof Introduction
   */
  parent_passing_reason?: IntroductionParentPassingReason | null;
  /**
   *
   * @type {string}
   * @memberof Introduction
   */
  parent_other_answer?: string | null;
  /**
   *
   * @type {IntroductionDonorPassingReason}
   * @memberof Introduction
   */
  donor_passing_reason?: IntroductionDonorPassingReason | null;
  /**
   *
   * @type {string}
   * @memberof Introduction
   */
  donor_other_answer?: string | null;
}
/**
 * @type IntroductionDonorPassingReason
 * @export
 */
export type IntroductionDonorPassingReason =
  | BlankEnum
  | DonorPassingReasonEnum
  | NullEnum;

/**
 *
 * @export
 * @interface IntroductionDonorProfile
 */
export interface IntroductionDonorProfile {
  /**
   *
   * @type {number}
   * @memberof IntroductionDonorProfile
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  maiden_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof IntroductionDonorProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  is_military?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof IntroductionDonorProfile
   */
  desired_compensation?: number | null;
  /**
   *
   * @type {number}
   * @memberof IntroductionDonorProfile
   */
  number_of_children?: number;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  video_url: string | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  is_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  is_match_profile_completed: boolean;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  words_from_nodal?: string | null;
  world_of_surrogacy?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  important_qual_ip?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  donor_support?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  three_words?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  passionate_about?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  additional_qs?: string | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof IntroductionDonorProfile
   */
  first_time_parents?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof IntroductionDonorProfile
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof IntroductionDonorProfile
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof IntroductionDonorProfile
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  confidentiality_comfort?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  covid_vaccination?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  willing_to_vaccinate?: boolean | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof IntroductionDonorProfile
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof IntroductionDonorProfile
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileComfortTraveling}
   * @memberof IntroductionDonorProfile
   */
  comfort_traveling?: DonorProfileComfortTraveling | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof IntroductionDonorProfile
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  comfort_donated_egg?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  comfort_donated_sperm?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  comfort_donated_embryo?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  single_embryo_comfort?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  multiple_embryo?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  willing_to_pump_breast_milk?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  willing_to_restrict_travel?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  independent_journey?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof IntroductionDonorProfile
   */
  bmi: number | null;
  /**
   *
   * @type {number}
   * @memberof IntroductionDonorProfile
   */
  height?: number | null;
  /**
   *
   * @type {number}
   * @memberof IntroductionDonorProfile
   */
  weight?: number | null;
  /**
   *
   * @type {DonorProfileReligion}
   * @memberof IntroductionDonorProfile
   */
  religion?: DonorProfileReligion | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  hospital?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  translator_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof IntroductionDonorProfile
   */
  domestic_couple_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileExperience}
   * @memberof IntroductionDonorProfile
   */
  experience?: DonorProfileExperience | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {DonorProfileHouseholdIncome}
   * @memberof IntroductionDonorProfile
   */
  household_income?: DonorProfileHouseholdIncome | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof IntroductionDonorProfile
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof IntroductionDonorProfile
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof IntroductionDonorProfile
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {DonorProfilePreviousCSections}
   * @memberof IntroductionDonorProfile
   */
  previous_c_sections?: DonorProfilePreviousCSections | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof IntroductionDonorProfile
   */
  hospital_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof IntroductionDonorProfile
   */
  clinic_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof IntroductionDonorProfile
   */
  airport_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof IntroductionDonorProfile
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  cleared_for_medical_review: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  all_stages_complete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  send_to_matching: boolean;
  /**
   *
   * @type {string}
   * @memberof IntroductionDonorProfile
   */
  final_review_completion_date: string;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionDonorProfile
   */
  final_review_completed: boolean;
  /**
   *
   * @type {UserNested}
   * @memberof IntroductionDonorProfile
   */
  user: UserNested;
}
/**
 *
 * @export
 * @interface IntroductionFull
 */
export interface IntroductionFull {
  /**
   *
   * @type {number}
   * @memberof IntroductionFull
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionFull
   */
  initial_parent_accepted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionFull
   */
  match_accepted_parent?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionFull
   */
  match_accepted_donor?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionFull
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof IntroductionFull
   */
  updated: string;
  /**
   *
   * @type {string}
   * @memberof IntroductionFull
   */
  expiration_date?: string | null;
  /**
   *
   * @type {IntroductionStageEnum}
   * @memberof IntroductionFull
   */
  stage?: IntroductionStageEnum;
  /**
   *
   * @type {IntroductionParentPassingReason}
   * @memberof IntroductionFull
   */
  parent_passing_reason?: IntroductionParentPassingReason | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionFull
   */
  parent_other_answer?: string | null;
  /**
   *
   * @type {IntroductionDonorPassingReason}
   * @memberof IntroductionFull
   */
  donor_passing_reason?: IntroductionDonorPassingReason | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionFull
   */
  donor_other_answer?: string | null;
  /**
   *
   * @type {IntroductionParentsProfile}
   * @memberof IntroductionFull
   */
  parent_profile: IntroductionParentsProfile;
  /**
   *
   * @type {IntroductionDonorProfile}
   * @memberof IntroductionFull
   */
  donor_profile: IntroductionDonorProfile;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionFull
   */
  call_scheduled?: boolean;
  /**
   *
   * @type {string}
   * @memberof IntroductionFull
   */
  call_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionFull
   */
  notes?: string | null;
}
/**
 * @type IntroductionParentPassingReason
 * @export
 */
export type IntroductionParentPassingReason =
  | BlankEnum
  | NullEnum
  | ParentPassingReasonEnum;

/**
 *
 * @export
 * @interface IntroductionParentsProfile
 */
export interface IntroductionParentsProfile {
  /**
   *
   * @type {number}
   * @memberof IntroductionParentsProfile
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof IntroductionParentsProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof IntroductionParentsProfile
   */
  preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof IntroductionParentsProfile
   */
  sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  occupation?: string | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof IntroductionParentsProfile
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {IntroductionParentsProfileReasonForApplying}
   * @memberof IntroductionParentsProfile
   */
  reason_for_applying?: IntroductionParentsProfileReasonForApplying | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  partner_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  partner_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  partner_last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof IntroductionParentsProfile
   */
  partner_address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  partner_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  partner_date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof IntroductionParentsProfile
   */
  partner_preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof IntroductionParentsProfile
   */
  partner_sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  video_url: string | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  is_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  is_match_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  is_military?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  we_enjoy?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  we_relax?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  successful_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  note_to_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  additional_qs?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof IntroductionParentsProfile
   */
  experience_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof IntroductionParentsProfile
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {IntroductionParentsProfileCovidVaccinationPreference}
   * @memberof IntroductionParentsProfile
   */
  covid_vaccination_preference?: IntroductionParentsProfileCovidVaccinationPreference | null;
  /**
   *
   * @type {Array<ReligionPreferenceEnum>}
   * @memberof IntroductionParentsProfile
   */
  religion_preference?: Array<ReligionPreferenceEnum> | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof IntroductionParentsProfile
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileUsingDonor}
   * @memberof IntroductionParentsProfile
   */
  using_donor?: IntroductionParentsProfileUsingDonor | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryoTransferType}
   * @memberof IntroductionParentsProfile
   */
  embryo_transfer_type?: IntroductionParentsProfileEmbryoTransferType | null;
  /**
   *
   * @type {IntroductionParentsProfileFrozenEmbryoCount}
   * @memberof IntroductionParentsProfile
   */
  frozen_embryo_count?: IntroductionParentsProfileFrozenEmbryoCount | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryosPgsTested}
   * @memberof IntroductionParentsProfile
   */
  embryos_pgs_tested?: IntroductionParentsProfileEmbryosPgsTested | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof IntroductionParentsProfile
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof IntroductionParentsProfile
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof IntroductionParentsProfile
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileAgency}
   * @memberof IntroductionParentsProfile
   */
  agency?: IntroductionParentsProfileAgency | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  agency_name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  clinic?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  clinic_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  clinic_contact_info?: string | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof IntroductionParentsProfile
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof IntroductionParentsProfile
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof IntroductionParentsProfile
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  twibling_journey?: boolean | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof IntroductionParentsProfile
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  all_stages_complete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  send_to_matching: boolean;
  /**
   *
   * @type {string}
   * @memberof IntroductionParentsProfile
   */
  final_review_completion_date: string;
  /**
   *
   * @type {boolean}
   * @memberof IntroductionParentsProfile
   */
  final_review_completed: boolean;
  /**
   *
   * @type {UserNested}
   * @memberof IntroductionParentsProfile
   */
  user: UserNested;
}
/**
 * @type IntroductionParentsProfileAgency
 * @export
 */
export type IntroductionParentsProfileAgency =
  | AgencyUsageEnum
  | BlankEnum
  | NullEnum;

/**
 * @type IntroductionParentsProfileCovidVaccinationPreference
 * @export
 */
export type IntroductionParentsProfileCovidVaccinationPreference =
  | BlankEnum
  | CovidVaccinationPreferenceEnum
  | NullEnum;

/**
 * @type IntroductionParentsProfileEmbryoTransferType
 * @export
 */
export type IntroductionParentsProfileEmbryoTransferType =
  | BlankEnum
  | EmbryoTransferTypeEnum
  | NullEnum;

/**
 * @type IntroductionParentsProfileEmbryosPgsTested
 * @export
 */
export type IntroductionParentsProfileEmbryosPgsTested =
  | BlankEnum
  | EmbryosPgsTestedEnum
  | NullEnum;

/**
 * @type IntroductionParentsProfileFrozenEmbryoCount
 * @export
 */
export type IntroductionParentsProfileFrozenEmbryoCount =
  | BlankEnum
  | FrozenEmbryoCountEnum
  | NullEnum;

/**
 * @type IntroductionParentsProfilePreferredPronouns
 * @export
 */
export type IntroductionParentsProfilePreferredPronouns =
  | BlankEnum
  | NullEnum
  | PronounPreferenceEnum;

/**
 * @type IntroductionParentsProfileReasonForApplying
 * @export
 */
export type IntroductionParentsProfileReasonForApplying =
  | BlankEnum
  | NullEnum
  | ReasonForApplyingEnum;

/**
 * @type IntroductionParentsProfileSexualOrientation
 * @export
 */
export type IntroductionParentsProfileSexualOrientation =
  | BlankEnum
  | NullEnum
  | SexualOrientationEnum;

/**
 * @type IntroductionParentsProfileUsingDonor
 * @export
 */
export type IntroductionParentsProfileUsingDonor =
  | BlankEnum
  | NullEnum
  | UsingDonorEnum;

/**
 *
 * @export
 * @enum {string}
 */

export const IntroductionStageEnum = {
  Intros: 'intros',
  MatchAcceptance: 'match_acceptance',
  Matched: 'matched',
  Broken: 'broken',
  Expired: 'expired',
} as const;

export type IntroductionStageEnum =
  typeof IntroductionStageEnum[keyof typeof IntroductionStageEnum];

/**
 *
 * @export
 * @interface Invitation
 */
export interface Invitation {
  /**
   *
   * @type {number}
   * @memberof Invitation
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Invitation
   */
  created: string;
  /**
   *
   * @type {InvitationRole}
   * @memberof Invitation
   */
  role?: InvitationRole | null;
  /**
   *
   * @type {string}
   * @memberof Invitation
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof Invitation
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof Invitation
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof Invitation
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof Invitation
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof Invitation
   */
  phone_number?: string | null;
  /**
   *
   * @type {UserNested}
   * @memberof Invitation
   */
  user: UserNested;
  /**
   *
   * @type {InvitationStatusEnum}
   * @memberof Invitation
   */
  status: InvitationStatusEnum;
  /**
   *
   * @type {Array<ScreenDetailsNested>}
   * @memberof Invitation
   */
  screens: Array<ScreenDetailsNested>;
  /**
   *
   * @type {Review}
   * @memberof Invitation
   */
  medical_review: Review;
  /**
   *
   * @type {string}
   * @memberof Invitation
   */
  organization_name: string;
  /**
   *
   * @type {number}
   * @memberof Invitation
   */
  organization_id?: number | null;
  /**
   *
   * @type {Array<B2bCandidatePayment>}
   * @memberof Invitation
   */
  screening_payments: Array<B2bCandidatePayment>;
}
/**
 *
 * @export
 * @interface InvitationExternalPayer
 */
export interface InvitationExternalPayer {
  /**
   *
   * @type {number}
   * @memberof InvitationExternalPayer
   */
  id: number;
  /**
   *
   * @type {InvitationStatusEnum}
   * @memberof InvitationExternalPayer
   */
  status?: InvitationStatusEnum;
  /**
   *
   * @type {B2bCandidatePayment}
   * @memberof InvitationExternalPayer
   */
  screening_payment: B2bCandidatePayment;
}
/**
 *
 * @export
 * @interface InvitationFinish
 */
export interface InvitationFinish {
  /**
   *
   * @type {string}
   * @memberof InvitationFinish
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof InvitationFinish
   */
  invitation_id_enc: string;
  /**
   *
   * @type {string}
   * @memberof InvitationFinish
   */
  password: string;
}
/**
 *
 * @export
 * @interface InvitationList
 */
export interface InvitationList {
  /**
   *
   * @type {number}
   * @memberof InvitationList
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof InvitationList
   */
  created: string;
  /**
   *
   * @type {InvitationRole}
   * @memberof InvitationList
   */
  role?: InvitationRole | null;
  /**
   *
   * @type {string}
   * @memberof InvitationList
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof InvitationList
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof InvitationList
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof InvitationList
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof InvitationList
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof InvitationList
   */
  phone_number?: string | null;
  /**
   *
   * @type {UserNested}
   * @memberof InvitationList
   */
  user: UserNested;
  /**
   *
   * @type {InvitationStatusEnum}
   * @memberof InvitationList
   */
  status: InvitationStatusEnum;
  /**
   *
   * @type {Array<ScreenDetailsNested>}
   * @memberof InvitationList
   */
  screens: Array<ScreenDetailsNested>;
  /**
   *
   * @type {Review}
   * @memberof InvitationList
   */
  medical_review: Review;
  /**
   *
   * @type {string}
   * @memberof InvitationList
   */
  organization_name: string;
  /**
   *
   * @type {number}
   * @memberof InvitationList
   */
  organization_id?: number | null;
  /**
   *
   * @type {Array<B2bCandidatePayment>}
   * @memberof InvitationList
   */
  screening_payments: Array<B2bCandidatePayment>;
}
/**
 *
 * @export
 * @interface InvitationNested
 */
export interface InvitationNested {
  /**
   *
   * @type {number}
   * @memberof InvitationNested
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof InvitationNested
   */
  created: string;
  /**
   *
   * @type {InvitationStatusEnum}
   * @memberof InvitationNested
   */
  status?: InvitationStatusEnum;
  /**
   *
   * @type {Array<B2bCandidatePaymentNested>}
   * @memberof InvitationNested
   */
  screening_payments: Array<B2bCandidatePaymentNested>;
}
/**
 * @type InvitationRole
 * @export
 */
export type InvitationRole = BlankEnum | NullEnum | RoleC4eEnum;

/**
 *
 * @export
 * @interface InvitationScreeningPackage
 */
export interface InvitationScreeningPackage {
  /**
   *
   * @type {InvitationScreeningPackagePayingEntity}
   * @memberof InvitationScreeningPackage
   */
  paying_entity?: InvitationScreeningPackagePayingEntity | null;
  /**
   *
   * @type {string}
   * @memberof InvitationScreeningPackage
   */
  payer_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof InvitationScreeningPackage
   */
  payer_email?: string | null;
  /**
   *
   * @type {number}
   * @memberof InvitationScreeningPackage
   */
  package?: number | null;
}
/**
 * @type InvitationScreeningPackagePayingEntity
 * @export
 */
export type InvitationScreeningPackagePayingEntity =
  | NullEnum
  | PayingEntityEnum;

/**
 *
 * @export
 * @enum {string}
 */

export const InvitationStatusEnum = {
  NotSent: 'not_sent',
  Sent: 'sent',
  Accepted: 'accepted',
  Canceled: 'canceled',
} as const;

export type InvitationStatusEnum =
  typeof InvitationStatusEnum[keyof typeof InvitationStatusEnum];

/**
 *
 * @export
 * @interface IpContractDocumentType
 */
export interface IpContractDocumentType {
  /**
   *
   * @type {IpContractDocumentTypeDocumentTypeEnum}
   * @memberof IpContractDocumentType
   */
  document_type: IpContractDocumentTypeDocumentTypeEnum;
}
/**
 *
 * @export
 * @enum {string}
 */

export const IpContractDocumentTypeDocumentTypeEnum = {
  Ps: 'ip_contract_ps',
  Pp: 'ip_contract_pp',
  Mp: 'ip_contract_mp',
  Fs: 'ip_contract_fs',
  Fp: 'ip_contract_fp',
  Ns: 'ip_contract_ns',
  Np: 'ip_contract_np',
} as const;

export type IpContractDocumentTypeDocumentTypeEnum =
  typeof IpContractDocumentTypeDocumentTypeEnum[keyof typeof IpContractDocumentTypeDocumentTypeEnum];

/**
 *
 * @export
 * @interface IpContractTask
 */
export interface IpContractTask {
  /**
   *
   * @type {number}
   * @memberof IpContractTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpContractTask
   */
  task_type: string;
  /**
   *
   * @type {SignatureRemoteDropboxDocument}
   * @memberof IpContractTask
   */
  document: SignatureRemoteDropboxDocument;
}
/**
 *
 * @export
 * @interface IpFinalMatchPaymentTask
 */
export interface IpFinalMatchPaymentTask {
  /**
   *
   * @type {number}
   * @memberof IpFinalMatchPaymentTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpFinalMatchPaymentTask
   */
  task_type: string;
  /**
   *
   * @type {boolean}
   * @memberof IpFinalMatchPaymentTask
   */
  final_payment_submitted: boolean;
}
/**
 *
 * @export
 * @interface IpMatchProfileTask
 */
export interface IpMatchProfileTask {
  /**
   *
   * @type {number}
   * @memberof IpMatchProfileTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpMatchProfileTask
   */
  task_type: string;
}
/**
 *
 * @export
 * @interface IpMatchingPaymentTask
 */
export interface IpMatchingPaymentTask {
  /**
   *
   * @type {number}
   * @memberof IpMatchingPaymentTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpMatchingPaymentTask
   */
  task_type: string;
}
/**
 *
 * @export
 * @interface IpNodalApplicationTask
 */
export interface IpNodalApplicationTask {
  /**
   *
   * @type {number}
   * @memberof IpNodalApplicationTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpNodalApplicationTask
   */
  task_type: string;
}
/**
 *
 * @export
 * @interface IpPersonalInformationTask
 */
export interface IpPersonalInformationTask {
  /**
   *
   * @type {number}
   * @memberof IpPersonalInformationTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpPersonalInformationTask
   */
  task_type: string;
}
/**
 *
 * @export
 * @interface IpReviewCallPreparationTask
 */
export interface IpReviewCallPreparationTask {
  /**
   *
   * @type {number}
   * @memberof IpReviewCallPreparationTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpReviewCallPreparationTask
   */
  task_type: string;
  /**
   *
   * @type {boolean}
   * @memberof IpReviewCallPreparationTask
   */
  documents_reviewed?: boolean;
}
/**
 *
 * @export
 * @interface IpReviewRunbookTask
 */
export interface IpReviewRunbookTask {
  /**
   *
   * @type {number}
   * @memberof IpReviewRunbookTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpReviewRunbookTask
   */
  task_type: string;
  /**
   *
   * @type {boolean}
   * @memberof IpReviewRunbookTask
   */
  documents_reviewed?: boolean;
}
/**
 *
 * @export
 * @interface IpSignMatchAgreementDocumentType
 */
export interface IpSignMatchAgreementDocumentType {
  /**
   *
   * @type {IpSignMatchAgreementDocumentTypeDocumentTypeEnum}
   * @memberof IpSignMatchAgreementDocumentType
   */
  document_type: IpSignMatchAgreementDocumentTypeDocumentTypeEnum;
}
/**
 *
 * @export
 * @enum {string}
 */

export const IpSignMatchAgreementDocumentTypeDocumentTypeEnum = {
  MatchAgreementPartner: 'match_agreement_partner',
} as const;

export type IpSignMatchAgreementDocumentTypeDocumentTypeEnum =
  typeof IpSignMatchAgreementDocumentTypeDocumentTypeEnum[keyof typeof IpSignMatchAgreementDocumentTypeDocumentTypeEnum];

/**
 *
 * @export
 * @interface IpSignMatchAgreementTask
 */
export interface IpSignMatchAgreementTask {
  /**
   *
   * @type {number}
   * @memberof IpSignMatchAgreementTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpSignMatchAgreementTask
   */
  task_type: string;
  /**
   *
   * @type {SignatureRemoteDropboxDocument}
   * @memberof IpSignMatchAgreementTask
   */
  document: SignatureRemoteDropboxDocument;
}
/**
 *
 * @export
 * @interface IpSignMatchSheetTask
 */
export interface IpSignMatchSheetTask {
  /**
   *
   * @type {number}
   * @memberof IpSignMatchSheetTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpSignMatchSheetTask
   */
  task_type: string;
  /**
   *
   * @type {SignatureRemoteDropboxDocument}
   * @memberof IpSignMatchSheetTask
   */
  document: SignatureRemoteDropboxDocument;
}
/**
 *
 * @export
 * @interface IpSupportCallTask
 */
export interface IpSupportCallTask {
  /**
   *
   * @type {number}
   * @memberof IpSupportCallTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof IpSupportCallTask
   */
  task_type: string;
  /**
   *
   * @type {boolean}
   * @memberof IpSupportCallTask
   */
  call_scheduling_requested?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof IpSupportCallTask
   */
  call_scheduled: boolean;
  /**
   *
   * @type {string}
   * @memberof IpSupportCallTask
   */
  call_date_time: string;
}
/**
 * Serializer for JWT authentication.
 * @export
 * @interface JWT
 */
export interface JWT {
  /**
   *
   * @type {string}
   * @memberof JWT
   */
  access_token: string;
  /**
   *
   * @type {string}
   * @memberof JWT
   */
  refresh_token: string;
  /**
   *
   * @type {User}
   * @memberof JWT
   */
  user: User;
}
/**
 *
 * @export
 * @enum {string}
 */

export const LastPregnancyDeliveryTypeEnum = {
  CSection: 'c_section',
  Vaginal: 'vaginal',
} as const;

export type LastPregnancyDeliveryTypeEnum =
  typeof LastPregnancyDeliveryTypeEnum[keyof typeof LastPregnancyDeliveryTypeEnum];

/**
 *
 * @export
 * @interface LifetimeData
 */
export interface LifetimeData {
  /**
   *
   * @type {number}
   * @memberof LifetimeData
   */
  donor_count: number;
  /**
   *
   * @type {number}
   * @memberof LifetimeData
   */
  ip_count: number;
  /**
   *
   * @type {number}
   * @memberof LifetimeData
   */
  med_reviews_completed_count: number;
  /**
   *
   * @type {number}
   * @memberof LifetimeData
   */
  med_reviews_legacy_completed_count: number;
  /**
   *
   * @type {number}
   * @memberof LifetimeData
   */
  matches_count: number;
  /**
   *
   * @type {number}
   * @memberof LifetimeData
   */
  disqualified_count: number;
}
/**
 *
 * @export
 * @interface Login
 */
export interface Login {
  /**
   *
   * @type {string}
   * @memberof Login
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof Login
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof Login
   */
  password: string;
}
/**
 *
 * @export
 * @interface MarketplaceParents
 */
export interface MarketplaceParents {
  /**
   *
   * @type {number}
   * @memberof MarketplaceParents
   */
  id: number;
  /**
   *
   * @type {ParentsProfile}
   * @memberof MarketplaceParents
   */
  profile: ParentsProfile;
  /**
   *
   * @type {Array<UserProfilePhoto>}
   * @memberof MarketplaceParents
   */
  profile_photos: Array<UserProfilePhoto>;
  /**
   *
   * @type {Introduction}
   * @memberof MarketplaceParents
   */
  introduction: Introduction;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MatchProfileReviewStatusEnum = {
  Pending: 'pending',
  ToReview: 'to_review',
  Accepted: 'accepted',
  Rejected: 'rejected',
} as const;

export type MatchProfileReviewStatusEnum =
  typeof MatchProfileReviewStatusEnum[keyof typeof MatchProfileReviewStatusEnum];

/**
 *
 * @export
 * @interface MatchingData
 */
export interface MatchingData {
  /**
   *
   * @type {number}
   * @memberof MatchingData
   */
  donors_matching_count: number;
  /**
   *
   * @type {number}
   * @memberof MatchingData
   */
  parents_matching_count: number;
}
/**
 * @type MatchingPaymentObject
 * @export
 */
export type MatchingPaymentObject = NodalMatchingPayment;

/**
 *
 * @export
 * @interface MedicalDocument
 */
export interface MedicalDocument {
  /**
   *
   * @type {number}
   * @memberof MedicalDocument
   */
  id: number;
  /**
   *
   * @type {MedicalDocumentDocumentTypeEnum}
   * @memberof MedicalDocument
   */
  document_type: MedicalDocumentDocumentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MedicalDocument
   */
  content_url: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalDocument
   */
  original_file_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalDocument
   */
  file_size?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalDocument
   */
  file_type?: string | null;
}
/**
 *
 * @export
 * @interface MedicalDocumentCreate
 */
export interface MedicalDocumentCreate {
  /**
   *
   * @type {number}
   * @memberof MedicalDocumentCreate
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof MedicalDocumentCreate
   */
  medical_review: number;
  /**
   *
   * @type {MedicalDocumentDocumentTypeEnum}
   * @memberof MedicalDocumentCreate
   */
  document_type: MedicalDocumentDocumentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof MedicalDocumentCreate
   */
  content?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MedicalDocumentDocumentTypeEnum = {
  FinalReview: 'final_review',
  ClinicalRecords: 'clinical_records',
  SurroDoc: 'surro_doc',
  SurroInsDoc: 'surro_ins_doc',
  Other: 'other',
} as const;

export type MedicalDocumentDocumentTypeEnum =
  typeof MedicalDocumentDocumentTypeEnum[keyof typeof MedicalDocumentDocumentTypeEnum];

/**
 *
 * @export
 * @interface InsuranceReviewScreen
 */
export interface InsuranceReviewScreen {
  /**
   *
   * @type {number}
   * @memberof InsuranceReviewScreen
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof InsuranceReviewScreen
   */
  health_insurance: string | null;
  /**
   *
   * @type {string}
   * @memberof InsuranceReviewScreen
   */
  insurance_photo_front: string | null;
  /**
   *
   * @type {string}
   * @memberof InsuranceReviewScreen
   */
  insurance_photo_back: string | null;
}

/**
 *
 * @export
 * @interface MedicalRecordReviewScreen
 */
export interface MedicalRecordReviewScreen {
  /**
   *
   * @type {number}
   * @memberof MedicalRecordReviewScreen
   */
  id: number;
  /**
   *
   * @type {Array<MedicalRecordReviewScreenPregnancyDetail>}
   * @memberof MedicalRecordReviewScreen
   */
  pregnancy_details?: Array<MedicalRecordReviewScreenPregnancyDetail> | null;
  /**
   *
   * @type {Array<MedicalRecordReviewScreenIvfTransferDetail>}
   * @memberof MedicalRecordReviewScreen
   */
  ivf_transfers_details?: Array<MedicalRecordReviewScreenIvfTransferDetail> | null;
  /**
   *
   * @type {Array<MedicalRecordReviewScreenObGynDetail>}
   * @memberof MedicalRecordReviewScreen
   */
  ob_gyn_details?: Array<MedicalRecordReviewScreenObGynDetail> | null;
}
/**
 *
 * @export
 * @interface MedicalRecordReviewScreenDocuments
 */
export interface MedicalRecordReviewScreenDocuments {
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenDocuments
   */
  insurance_photo_front: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenDocuments
   */
  insurance_photo_back: string | null;
}
/**
 *
 * @export
 * @interface MedicalRecordReviewScreenIvfTransferDetail
 */
export interface MedicalRecordReviewScreenIvfTransferDetail {
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenIvfTransferDetail
   */
  transfer_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenIvfTransferDetail
   */
  doctor_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenIvfTransferDetail
   */
  doctor_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenIvfTransferDetail
   */
  doctor_last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenIvfTransferDetail
   */
  doctor_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenIvfTransferDetail
   */
  doctor_clinic_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof MedicalRecordReviewScreenIvfTransferDetail
   */
  doctor_clinic_address?: DonorProfileAddress | null;
}
/**
 *
 * @export
 * @interface MedicalRecordReviewScreenObGynDetail
 */
export interface MedicalRecordReviewScreenObGynDetail {
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenObGynDetail
   */
  doctor_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenObGynDetail
   */
  doctor_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenObGynDetail
   */
  doctor_last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenObGynDetail
   */
  doctor_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenObGynDetail
   */
  doctor_clinic_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof MedicalRecordReviewScreenObGynDetail
   */
  doctor_clinic_address?: DonorProfileAddress | null;
}
/**
 *
 * @export
 * @interface MedicalRecordReviewScreenPregnancyDetail
 */
export interface MedicalRecordReviewScreenPregnancyDetail {
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  delivery_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  doctor_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  doctor_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  doctor_last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  doctor_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  doctor_clinic_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  doctor_clinic_address?: DonorProfileAddress | null;
  /**
   *
   * @type {MedicalRecordReviewScreenPregnancyDetailDoctorClinicCountry}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  doctor_clinic_country?: MedicalRecordReviewScreenPregnancyDetailDoctorClinicCountry | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  doctor_clinic_international_address?: string | null;
  /**
   *
   * @type {MedicalRecordReviewScreenPregnancyDetailFacilityType}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  facility_type?: MedicalRecordReviewScreenPregnancyDetailFacilityType | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  delivery_address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof MedicalRecordReviewScreenPregnancyDetail
   */
  delivery_international_address?: string | null;
}
/**
 * @type MedicalRecordReviewScreenPregnancyDetailDoctorClinicCountry
 * @export
 */
export type MedicalRecordReviewScreenPregnancyDetailDoctorClinicCountry =
  | BlankEnum
  | DoctorClinicCountryEnum
  | NullEnum;

/**
 * @type MedicalRecordReviewScreenPregnancyDetailFacilityType
 * @export
 */
export type MedicalRecordReviewScreenPregnancyDetailFacilityType =
  | BlankEnum
  | FacilityTypeEnum
  | NullEnum;

/**
 *
 * @export
 * @enum {string}
 */

export const MedicalReviewStatusEnum = {
  Rejected: 'rejected',
  NotApplicable: 'not_applicable',
  NotStarted: 'not_started',
  InProgress: 'in_progress',
  Finished: 'finished',
} as const;

export type MedicalReviewStatusEnum =
  typeof MedicalReviewStatusEnum[keyof typeof MedicalReviewStatusEnum];

/**
 *
 * @export
 * @interface MfmClearanceStepDetail
 */
export interface MfmClearanceStepDetail {
  /**
   *
   * @type {number}
   * @memberof MfmClearanceStepDetail
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof MfmClearanceStepDetail
   */
  mfm_review?: boolean | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const NameEnum = {
  Full: 'full',
  Traditional: 'traditional',
  Overview: 'overview',
  Basic: 'basic',
} as const;

export type NameEnum = typeof NameEnum[keyof typeof NameEnum];

/**
 *
 * @export
 * @interface NavigatorParentsProfile
 */
export interface NavigatorParentsProfile {
  /**
   *
   * @type {number}
   * @memberof NavigatorParentsProfile
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof NavigatorParentsProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof NavigatorParentsProfile
   */
  preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof NavigatorParentsProfile
   */
  sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  occupation?: string | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof NavigatorParentsProfile
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {IntroductionParentsProfileReasonForApplying}
   * @memberof NavigatorParentsProfile
   */
  reason_for_applying?: IntroductionParentsProfileReasonForApplying | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  partner_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  partner_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  partner_last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof NavigatorParentsProfile
   */
  partner_address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  partner_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  partner_date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof NavigatorParentsProfile
   */
  partner_preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof NavigatorParentsProfile
   */
  partner_sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  video_url: string | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  is_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  is_match_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  is_military?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  we_enjoy?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  we_relax?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  successful_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  note_to_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  additional_qs?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof NavigatorParentsProfile
   */
  experience_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof NavigatorParentsProfile
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {IntroductionParentsProfileCovidVaccinationPreference}
   * @memberof NavigatorParentsProfile
   */
  covid_vaccination_preference?: IntroductionParentsProfileCovidVaccinationPreference | null;
  /**
   *
   * @type {Array<ReligionPreferenceEnum>}
   * @memberof NavigatorParentsProfile
   */
  religion_preference?: Array<ReligionPreferenceEnum> | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof NavigatorParentsProfile
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileUsingDonor}
   * @memberof NavigatorParentsProfile
   */
  using_donor?: IntroductionParentsProfileUsingDonor | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryoTransferType}
   * @memberof NavigatorParentsProfile
   */
  embryo_transfer_type?: IntroductionParentsProfileEmbryoTransferType | null;
  /**
   *
   * @type {IntroductionParentsProfileFrozenEmbryoCount}
   * @memberof NavigatorParentsProfile
   */
  frozen_embryo_count?: IntroductionParentsProfileFrozenEmbryoCount | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryosPgsTested}
   * @memberof NavigatorParentsProfile
   */
  embryos_pgs_tested?: IntroductionParentsProfileEmbryosPgsTested | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof NavigatorParentsProfile
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof NavigatorParentsProfile
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof NavigatorParentsProfile
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileAgency}
   * @memberof NavigatorParentsProfile
   */
  agency?: IntroductionParentsProfileAgency | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  agency_name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  clinic?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  clinic_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  clinic_contact_info?: string | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof NavigatorParentsProfile
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof NavigatorParentsProfile
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof NavigatorParentsProfile
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  twibling_journey?: boolean | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof NavigatorParentsProfile
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  all_stages_complete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  send_to_matching: boolean;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfile
   */
  final_review_completion_date: string;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfile
   */
  final_review_completed: boolean;
  /**
   *
   * @type {InsuranceReview}
   * @memberof NavigatorParentsProfile
   */
  insurance_review?: InsuranceReview;
}
/**
 *
 * @export
 * @interface NavigatorParentsProfileScreen
 */
export interface NavigatorParentsProfileScreen {
  /**
   *
   * @type {number}
   * @memberof NavigatorParentsProfileScreen
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof NavigatorParentsProfileScreen
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof NavigatorParentsProfileScreen
   */
  preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof NavigatorParentsProfileScreen
   */
  sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  occupation?: string | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof NavigatorParentsProfileScreen
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {IntroductionParentsProfileReasonForApplying}
   * @memberof NavigatorParentsProfileScreen
   */
  reason_for_applying?: IntroductionParentsProfileReasonForApplying | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  video_url: string | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  is_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  is_match_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  is_military?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  we_enjoy?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  we_relax?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  successful_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  note_to_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  additional_qs?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof NavigatorParentsProfileScreen
   */
  experience_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof NavigatorParentsProfileScreen
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {IntroductionParentsProfileCovidVaccinationPreference}
   * @memberof NavigatorParentsProfileScreen
   */
  covid_vaccination_preference?: IntroductionParentsProfileCovidVaccinationPreference | null;
  /**
   *
   * @type {Array<ReligionPreferenceEnum>}
   * @memberof NavigatorParentsProfileScreen
   */
  religion_preference?: Array<ReligionPreferenceEnum> | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof NavigatorParentsProfileScreen
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileUsingDonor}
   * @memberof NavigatorParentsProfileScreen
   */
  using_donor?: IntroductionParentsProfileUsingDonor | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryoTransferType}
   * @memberof NavigatorParentsProfileScreen
   */
  embryo_transfer_type?: IntroductionParentsProfileEmbryoTransferType | null;
  /**
   *
   * @type {IntroductionParentsProfileFrozenEmbryoCount}
   * @memberof NavigatorParentsProfileScreen
   */
  frozen_embryo_count?: IntroductionParentsProfileFrozenEmbryoCount | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryosPgsTested}
   * @memberof NavigatorParentsProfileScreen
   */
  embryos_pgs_tested?: IntroductionParentsProfileEmbryosPgsTested | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof NavigatorParentsProfileScreen
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof NavigatorParentsProfileScreen
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof NavigatorParentsProfileScreen
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileAgency}
   * @memberof NavigatorParentsProfileScreen
   */
  agency?: IntroductionParentsProfileAgency | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  agency_name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  clinic?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  clinic_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  clinic_contact_info?: string | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof NavigatorParentsProfileScreen
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof NavigatorParentsProfileScreen
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof NavigatorParentsProfileScreen
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  twibling_journey?: boolean | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof NavigatorParentsProfileScreen
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  all_stages_complete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  send_to_matching: boolean;
  /**
   *
   * @type {string}
   * @memberof NavigatorParentsProfileScreen
   */
  final_review_completion_date: string;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorParentsProfileScreen
   */
  final_review_completed: boolean;
}
/**
 *
 * @export
 * @interface NavigatorProfileFinalReview
 */
export interface NavigatorProfileFinalReview {
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof NavigatorProfileFinalReview
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorProfileFinalReview
   */
  all_stages_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof NavigatorProfileFinalReview
   */
  send_to_matching?: boolean;
}
/**
 *
 * @export
 * @interface NodalMatchingPackagePrice
 */
export interface NodalMatchingPackagePrice {
  /**
   *
   * @type {number}
   * @memberof NodalMatchingPackagePrice
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof NodalMatchingPackagePrice
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof NodalMatchingPackagePrice
   */
  card_payment_fees?: string | null;
  /**
   *
   * @type {string}
   * @memberof NodalMatchingPackagePrice
   */
  bank_transfer_fees?: string | null;
  /**
   *
   * @type {PaymentTargetEnum}
   * @memberof NodalMatchingPackagePrice
   */
  payment_target?: PaymentTargetEnum;
}
/**
 *
 * @export
 * @interface NodalMatchingPayment
 */
export interface NodalMatchingPayment {
  /**
   *
   * @type {NodalMatchingPackagePrice}
   * @memberof NodalMatchingPayment
   */
  price: NodalMatchingPackagePrice;
  /**
   *
   * @type {PaymentStatusEnum}
   * @memberof NodalMatchingPayment
   */
  payment_status?: PaymentStatusEnum;
  /**
   *
   * @type {PaymentTypeEnum}
   * @memberof NodalMatchingPayment
   */
  payment_type?: PaymentTypeEnum;
  /**
   *
   * @type {string}
   * @memberof NodalMatchingPayment
   */
  completion_date?: string | null;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof NodalMatchingPayment
   */
  stripe_payment_method?: { [key: string]: any } | null;
}
/**
 *
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   *
   * @type {number}
   * @memberof Notification
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Notification
   */
  user: number;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  content: string;
  /**
   *
   * @type {NotificationCtaType}
   * @memberof Notification
   */
  cta_type?: NotificationCtaType | null;
  /**
   *
   * @type {string}
   * @memberof Notification
   */
  created: string;
  /**
   *
   * @type {boolean}
   * @memberof Notification
   */
  is_read?: boolean;
}
/**
 * @type NotificationCtaType
 * @export
 */
export type NotificationCtaType = BlankEnum | CtaTypeEnum | NullEnum;

/**
 *
 * @export
 * @enum {string}
 */

export const NullEnum = {
  Null: 'null',
} as const;

export type NullEnum = typeof NullEnum[keyof typeof NullEnum];

/**
 *
 * @export
 * @interface Organization
 */
export interface Organization {
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  business_type?: string | null;
  /**
   *
   * @type {OrganizationTypeEnum}
   * @memberof Organization
   */
  organization_type: OrganizationTypeEnum;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof Organization
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  feature_matching: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  feature_invitations: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  feature_navigator: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  is_prepaid?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof Organization
   */
  prepaid_screening_plans: Array<number>;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  created: string;
}
/**
 *
 * @export
 * @interface OrganizationCandidateProfile
 */
export interface OrganizationCandidateProfile {
  /**
   *
   * @type {number}
   * @memberof OrganizationCandidateProfile
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfile
   */
  maiden_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfile
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfile
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof OrganizationCandidateProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationCandidateProfile
   */
  is_profile_completed: boolean;
  /**
   *
   * @type {InsuranceReview}
   * @memberof OrganizationCandidateProfile
   */
  insurance_review?: InsuranceReview;
}
/**
 *
 * @export
 * @interface OrganizationCandidateProfileScreen
 */
export interface OrganizationCandidateProfileScreen {
  /**
   *
   * @type {number}
   * @memberof OrganizationCandidateProfileScreen
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfileScreen
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfileScreen
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfileScreen
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfileScreen
   */
  maiden_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfileScreen
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfileScreen
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfileScreen
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationCandidateProfileScreen
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof OrganizationCandidateProfileScreen
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationCandidateProfileScreen
   */
  is_profile_completed: boolean;
}
/**
 *
 * @export
 * @interface OrganizationNested
 */
export interface OrganizationNested {
  /**
   *
   * @type {number}
   * @memberof OrganizationNested
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationNested
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationNested
   */
  feature_matching?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationNested
   */
  feature_invitations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationNested
   */
  feature_navigator?: boolean;
}
/**
 *
 * @export
 * @interface OrganizationNonDetailed
 */
export interface OrganizationNonDetailed {
  /**
   *
   * @type {number}
   * @memberof OrganizationNonDetailed
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationNonDetailed
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationNonDetailed
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationNonDetailed
   */
  created: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationNonDetailed
   */
  total_active_sent: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const OrganizationTypeEnum = {
  Org: 'org',
  Ip: 'ip',
} as const;

export type OrganizationTypeEnum =
  typeof OrganizationTypeEnum[keyof typeof OrganizationTypeEnum];

/**
 *
 * @export
 * @interface OrganizationUserProfile
 */
export interface OrganizationUserProfile {
  /**
   *
   * @type {number}
   * @memberof OrganizationUserProfile
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationUserProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationUserProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationUserProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof OrganizationUserProfile
   */
  title?: string | null;
  /**
   *
   * @type {InsuranceReview}
   * @memberof OrganizationUserProfile
   */
  insurance_review?: InsuranceReview;
}
/**
 *
 * @export
 * @interface PaginatedB2BUserListList
 */
export interface PaginatedB2BUserListList {
  /**
   *
   * @type {number}
   * @memberof PaginatedB2BUserListList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedB2BUserListList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedB2BUserListList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<B2BUserList>}
   * @memberof PaginatedB2BUserListList
   */
  results?: Array<B2BUserList>;
}
/**
 *
 * @export
 * @interface PaginatedB2BUserNonDetailedListList
 */
export interface PaginatedB2BUserNonDetailedListList {
  /**
   *
   * @type {number}
   * @memberof PaginatedB2BUserNonDetailedListList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedB2BUserNonDetailedListList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedB2BUserNonDetailedListList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<B2BUserNonDetailedList>}
   * @memberof PaginatedB2BUserNonDetailedListList
   */
  results?: Array<B2BUserNonDetailedList>;
}
/**
 *
 * @export
 * @interface PaginatedB2bIntroductionList
 */
export interface PaginatedB2bIntroductionList {
  /**
   *
   * @type {number}
   * @memberof PaginatedB2bIntroductionList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedB2bIntroductionList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedB2bIntroductionList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<B2bIntroduction>}
   * @memberof PaginatedB2bIntroductionList
   */
  results?: Array<B2bIntroduction>;
}
/**
 *
 * @export
 * @interface PaginatedInvitationListList
 */
export interface PaginatedInvitationListList {
  /**
   *
   * @type {number}
   * @memberof PaginatedInvitationListList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedInvitationListList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedInvitationListList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<InvitationList>}
   * @memberof PaginatedInvitationListList
   */
  results?: Array<InvitationList>;
}
/**
 *
 * @export
 * @interface PaginatedOrganizationNonDetailedList
 */
export interface PaginatedOrganizationNonDetailedList {
  /**
   *
   * @type {number}
   * @memberof PaginatedOrganizationNonDetailedList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedOrganizationNonDetailedList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedOrganizationNonDetailedList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<OrganizationNonDetailed>}
   * @memberof PaginatedOrganizationNonDetailedList
   */
  results?: Array<OrganizationNonDetailed>;
}
/**
 *
 * @export
 * @interface PaginatedUserProfileViewList
 */
export interface PaginatedUserProfileViewList {
  /**
   *
   * @type {number}
   * @memberof PaginatedUserProfileViewList
   */
  count?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedUserProfileViewList
   */
  next?: string | null;
  /**
   *
   * @type {string}
   * @memberof PaginatedUserProfileViewList
   */
  previous?: string | null;
  /**
   *
   * @type {Array<UserProfileView>}
   * @memberof PaginatedUserProfileViewList
   */
  results?: Array<UserProfileView>;
}
/**
 *
 * @export
 * @interface ParentDocuSignEnvelope
 */
export interface ParentDocuSignEnvelope {
  /**
   *
   * @type {number}
   * @memberof ParentDocuSignEnvelope
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ParentDocuSignEnvelope
   */
  parent_name: string;
  /**
   *
   * @type {string}
   * @memberof ParentDocuSignEnvelope
   */
  parent_email: string;
  /**
   *
   * @type {string}
   * @memberof ParentDocuSignEnvelope
   */
  template_name: string;
  /**
   *
   * @type {ParentDocuSignEnvelopeStatusEnum}
   * @memberof ParentDocuSignEnvelope
   */
  status?: ParentDocuSignEnvelopeStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ParentDocuSignEnvelope
   */
  document_url: string;
  /**
   *
   * @type {string}
   * @memberof ParentDocuSignEnvelope
   */
  completed_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentDocuSignEnvelope
   */
  created: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ParentDocuSignEnvelopeStatusEnum = {
  Created: 'created',
  Sent: 'sent',
  Signed: 'signed',
  Declined: 'declined',
  Error: 'error',
} as const;

export type ParentDocuSignEnvelopeStatusEnum =
  typeof ParentDocuSignEnvelopeStatusEnum[keyof typeof ParentDocuSignEnvelopeStatusEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ParentPassingReasonEnum = {
  LifestylesDontMatch: 'lifestyles_dont_match',
  TooFarAway: 'too_far_away',
  JourneyPreferencesDontMatch: 'journey_preferences_dont_match',
  Other: 'other',
} as const;

export type ParentPassingReasonEnum =
  typeof ParentPassingReasonEnum[keyof typeof ParentPassingReasonEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ParentageEnum = {
  Natural: 'natural',
  Adopted: 'adopted',
  Donated: 'donated',
} as const;

export type ParentageEnum = typeof ParentageEnum[keyof typeof ParentageEnum];

/**
 *
 * @export
 * @interface ParentsProfile
 */
export interface ParentsProfile {
  /**
   *
   * @type {number}
   * @memberof ParentsProfile
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof ParentsProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof ParentsProfile
   */
  preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof ParentsProfile
   */
  sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  occupation?: string | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof ParentsProfile
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {IntroductionParentsProfileReasonForApplying}
   * @memberof ParentsProfile
   */
  reason_for_applying?: IntroductionParentsProfileReasonForApplying | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  partner_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  partner_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  partner_last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof ParentsProfile
   */
  partner_address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  partner_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  partner_date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof ParentsProfile
   */
  partner_preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof ParentsProfile
   */
  partner_sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  video_url: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  is_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  is_match_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  is_military?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  we_enjoy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  we_relax?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  successful_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  note_to_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  additional_qs?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof ParentsProfile
   */
  experience_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof ParentsProfile
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {IntroductionParentsProfileCovidVaccinationPreference}
   * @memberof ParentsProfile
   */
  covid_vaccination_preference?: IntroductionParentsProfileCovidVaccinationPreference | null;
  /**
   *
   * @type {Array<ReligionPreferenceEnum>}
   * @memberof ParentsProfile
   */
  religion_preference?: Array<ReligionPreferenceEnum> | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof ParentsProfile
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileUsingDonor}
   * @memberof ParentsProfile
   */
  using_donor?: IntroductionParentsProfileUsingDonor | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryoTransferType}
   * @memberof ParentsProfile
   */
  embryo_transfer_type?: IntroductionParentsProfileEmbryoTransferType | null;
  /**
   *
   * @type {IntroductionParentsProfileFrozenEmbryoCount}
   * @memberof ParentsProfile
   */
  frozen_embryo_count?: IntroductionParentsProfileFrozenEmbryoCount | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryosPgsTested}
   * @memberof ParentsProfile
   */
  embryos_pgs_tested?: IntroductionParentsProfileEmbryosPgsTested | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof ParentsProfile
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof ParentsProfile
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof ParentsProfile
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileAgency}
   * @memberof ParentsProfile
   */
  agency?: IntroductionParentsProfileAgency | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  agency_name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  clinic?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  clinic_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  clinic_contact_info?: string | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof ParentsProfile
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof ParentsProfile
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof ParentsProfile
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  twibling_journey?: boolean | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof ParentsProfile
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  all_stages_complete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  send_to_matching: boolean;
  /**
   *
   * @type {string}
   * @memberof ParentsProfile
   */
  final_review_completion_date: string;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfile
   */
  final_review_completed: boolean;
  /**
   *
   * @type {InsuranceReview}
   * @memberof ParentsProfile
   */
  insurance_review?: InsuranceReview;
  /**
   *
   * @type {boolean}
   * @memberof DonorProfileScreen
   */
  no_insurance?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  health_insurance?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  insurance_photo_front?: string | null;
  /**
   *
   * @type {string}
   * @memberof DonorProfileScreen
   */
  insurance_photo_back?: string | null;
}
/**
 *
 * @export
 * @interface ParentsProfileFinalReview
 */
export interface ParentsProfileFinalReview {
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof ParentsProfileFinalReview
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileFinalReview
   */
  all_stages_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileFinalReview
   */
  send_to_matching?: boolean;
  /**
   *
   * @type {InsuranceReview}
   * @memberof ParentsProfileFinalReview
   */
  insurance_review?: InsuranceReview;
}
/**
 *
 * @export
 * @interface ParentsProfileScreen
 */
export interface ParentsProfileScreen {
  /**
   *
   * @type {number}
   * @memberof ParentsProfileScreen
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof ParentsProfileScreen
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof ParentsProfileScreen
   */
  preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof ParentsProfileScreen
   */
  sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  occupation?: string | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof ParentsProfileScreen
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {IntroductionParentsProfileReasonForApplying}
   * @memberof ParentsProfileScreen
   */
  reason_for_applying?: IntroductionParentsProfileReasonForApplying | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  partner_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  partner_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  partner_last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof ParentsProfileScreen
   */
  partner_address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  partner_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  partner_date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof ParentsProfileScreen
   */
  partner_preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof ParentsProfileScreen
   */
  partner_sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  video_url: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  is_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  is_match_profile_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  is_military?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  we_enjoy?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  we_relax?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  successful_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  note_to_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  additional_qs?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof ParentsProfileScreen
   */
  experience_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof ParentsProfileScreen
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {IntroductionParentsProfileCovidVaccinationPreference}
   * @memberof ParentsProfileScreen
   */
  covid_vaccination_preference?: IntroductionParentsProfileCovidVaccinationPreference | null;
  /**
   *
   * @type {Array<ReligionPreferenceEnum>}
   * @memberof ParentsProfileScreen
   */
  religion_preference?: Array<ReligionPreferenceEnum> | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof ParentsProfileScreen
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileUsingDonor}
   * @memberof ParentsProfileScreen
   */
  using_donor?: IntroductionParentsProfileUsingDonor | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryoTransferType}
   * @memberof ParentsProfileScreen
   */
  embryo_transfer_type?: IntroductionParentsProfileEmbryoTransferType | null;
  /**
   *
   * @type {IntroductionParentsProfileFrozenEmbryoCount}
   * @memberof ParentsProfileScreen
   */
  frozen_embryo_count?: IntroductionParentsProfileFrozenEmbryoCount | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryosPgsTested}
   * @memberof ParentsProfileScreen
   */
  embryos_pgs_tested?: IntroductionParentsProfileEmbryosPgsTested | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof ParentsProfileScreen
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof ParentsProfileScreen
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof ParentsProfileScreen
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileAgency}
   * @memberof ParentsProfileScreen
   */
  agency?: IntroductionParentsProfileAgency | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  agency_name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  clinic?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  clinic_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  clinic_contact_info?: string | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof ParentsProfileScreen
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof ParentsProfileScreen
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof ParentsProfileScreen
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  twibling_journey?: boolean | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof ParentsProfileScreen
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  all_stages_complete: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  send_to_matching: boolean;
  /**
   *
   * @type {string}
   * @memberof ParentsProfileScreen
   */
  final_review_completion_date: string;
  /**
   *
   * @type {boolean}
   * @memberof ParentsProfileScreen
   */
  final_review_completed: boolean;
}
/**
 *
 * @export
 * @interface ParentsQuestionnaireScreen
 */
export interface ParentsQuestionnaireScreen {
  /**
   *
   * @type {number}
   * @memberof ParentsQuestionnaireScreen
   */
  id: number;
  /**
   *
   * @type {Array<QuestionnaireFlag>}
   * @memberof ParentsQuestionnaireScreen
   */
  flags: Array<QuestionnaireFlag>;
  /**
   *
   * @type {boolean}
   * @memberof ParentsQuestionnaireScreen
   */
  is_convicted_of_crime?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsQuestionnaireScreen
   */
  has_been_visited_by_social_service?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsQuestionnaireScreen
   */
  has_completed_psych_eval?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsQuestionnaireScreen
   */
  prepared_to_fund_escrow?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsQuestionnaireScreen
   */
  partner_is_convicted_of_crime?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsQuestionnaireScreen
   */
  partner_has_been_visited_by_social_service?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ParentsQuestionnaireScreen
   */
  partner_has_completed_psych_eval?: boolean;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof ParentsQuestionnaireScreen
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {IntroductionParentsProfileUsingDonor}
   * @memberof ParentsQuestionnaireScreen
   */
  using_donor?: IntroductionParentsProfileUsingDonor | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryoTransferType}
   * @memberof ParentsQuestionnaireScreen
   */
  embryo_transfer_type?: IntroductionParentsProfileEmbryoTransferType | null;
  /**
   *
   * @type {IntroductionParentsProfileFrozenEmbryoCount}
   * @memberof ParentsQuestionnaireScreen
   */
  frozen_embryo_count?: IntroductionParentsProfileFrozenEmbryoCount | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryosPgsTested}
   * @memberof ParentsQuestionnaireScreen
   */
  embryos_pgs_tested?: IntroductionParentsProfileEmbryosPgsTested | null;
  /**
   *
   * @type {boolean}
   * @memberof ParentsQuestionnaireScreen
   */
  twibling_journey?: boolean | null;
}
/**
 *
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
  /**
   *
   * @type {string}
   * @memberof PasswordChange
   */
  new_password1: string;
  /**
   *
   * @type {string}
   * @memberof PasswordChange
   */
  new_password2: string;
}
/**
 * Serializer for requesting a password reset e-mail.
 * @export
 * @interface PasswordReset
 */
export interface PasswordReset {
  /**
   *
   * @type {string}
   * @memberof PasswordReset
   */
  email: string;
}
/**
 * Serializer for confirming a password reset attempt.
 * @export
 * @interface PasswordResetConfirm
 */
export interface PasswordResetConfirm {
  /**
   *
   * @type {string}
   * @memberof PasswordResetConfirm
   */
  new_password1: string;
  /**
   *
   * @type {string}
   * @memberof PasswordResetConfirm
   */
  new_password2: string;
  /**
   *
   * @type {string}
   * @memberof PasswordResetConfirm
   */
  uid: string;
  /**
   *
   * @type {string}
   * @memberof PasswordResetConfirm
   */
  token: string;
}
/**
 *
 * @export
 * @interface PatchedB2BUser
 */
export interface PatchedB2BUser {
  /**
   *
   * @type {number}
   * @memberof PatchedB2BUser
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedB2BUser
   */
  date_joined?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedB2BUser
   */
  email?: string;
  /**
   *
   * @type {UserRoleEnum}
   * @memberof PatchedB2BUser
   */
  role?: UserRoleEnum;
  /**
   *
   * @type {string}
   * @memberof PatchedB2BUser
   */
  first_name?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedB2BUser
   */
  middle_name?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedB2BUser
   */
  last_name?: string;
  /**
   *
   * @type {Array<UserProfilePhoto>}
   * @memberof PatchedB2BUser
   */
  profile_photos?: Array<UserProfilePhoto>;
  /**
   *
   * @type {Review}
   * @memberof PatchedB2BUser
   */
  medical_review?: Review;
  /**
   *
   * @type {Array<ScreenDetailsNested>}
   * @memberof PatchedB2BUser
   */
  screens?: Array<ScreenDetailsNested>;
  /**
   *
   * @type {Array<InvitationNested>}
   * @memberof PatchedB2BUser
   */
  invitations?: Array<InvitationNested>;
  /**
   *
   * @type {Array<OrganizationNested>}
   * @memberof PatchedB2BUser
   */
  organizations?: Array<OrganizationNested>;
  /**
   *
   * @type {UserStatusEnum}
   * @memberof PatchedB2BUser
   */
  status?: UserStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof PatchedB2BUser
   */
  show_matching_badge?: boolean;
  /**
   *
   * @type {InvitationStatusEnum}
   * @memberof PatchedB2BUser
   */
  invitation_status?: InvitationStatusEnum | null;
  /**
   *
   * @type {ProfileObject}
   * @memberof PatchedB2BUser
   */
  profile?: ProfileObject;
  /**
   *
   * @type {SubscriptionObject}
   * @memberof PatchedB2BUser
   */
  subscription?: SubscriptionObject;
  /**
   *
   * @type {MatchingPaymentObject}
   * @memberof PatchedB2BUser
   */
  services_payment?: MatchingPaymentObject;
  /**
   *
   * @type {BillingStatusEnum}
   * @memberof PatchedB2BUser
   */
  billing_status?: BillingStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof PatchedB2BUser
   */
  application_flagged_for_review?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedB2BUser
   */
  match_profile_flagged_for_review?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchedB2BUser
   */
  last_login?: string | null;
}
/**
 *
 * @export
 * @interface PatchedB2BUserDisqualify
 */
export interface PatchedB2BUserDisqualify {
  /**
   *
   * @type {B2BUserDisqualifyStatusEnum}
   * @memberof PatchedB2BUserDisqualify
   */
  status?: B2BUserDisqualifyStatusEnum;
  /**
   *
   * @type {B2BUserDisqualifyDisqualificationReason}
   * @memberof PatchedB2BUserDisqualify
   */
  disqualification_reason?: B2BUserDisqualifyDisqualificationReason | null;
  /**
   *
   * @type {string}
   * @memberof PatchedB2BUserDisqualify
   */
  disqualification_reason_optional_details?: string | null;
}
/**
 * @type PatchedDocumentType
 * @export
 */
export type PatchedDocumentType =
  | PatchedIpContractDocumentType
  | PatchedIpSignMatchAgreementDocumentType;

/**
 *
 * @export
 * @interface PatchedDonorProfile
 */
export interface PatchedDonorProfile {
  /**
   *
   * @type {number}
   * @memberof PatchedDonorProfile
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  maiden_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedDonorProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  is_military?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof PatchedDonorProfile
   */
  desired_compensation?: number | null;
  /**
   *
   * @type {number}
   * @memberof PatchedDonorProfile
   */
  number_of_children?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  video_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  is_profile_completed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  is_match_profile_completed?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  words_from_nodal?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  world_of_surrogacy?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  important_qual_ip?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  donor_support?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  three_words?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  passionate_about?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  additional_qs?: string | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedDonorProfile
   */
  first_time_parents?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof PatchedDonorProfile
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedDonorProfile
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedDonorProfile
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  confidentiality_comfort?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  covid_vaccination?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  willing_to_vaccinate?: boolean | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof PatchedDonorProfile
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof PatchedDonorProfile
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileComfortTraveling}
   * @memberof PatchedDonorProfile
   */
  comfort_traveling?: DonorProfileComfortTraveling | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof PatchedDonorProfile
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  comfort_donated_egg?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  comfort_donated_sperm?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  comfort_donated_embryo?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  single_embryo_comfort?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  multiple_embryo?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  willing_to_pump_breast_milk?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  willing_to_restrict_travel?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  independent_journey?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof PatchedDonorProfile
   */
  bmi?: number | null;
  /**
   *
   * @type {number}
   * @memberof PatchedDonorProfile
   */
  height?: number | null;
  /**
   *
   * @type {number}
   * @memberof PatchedDonorProfile
   */
  weight?: number | null;
  /**
   *
   * @type {DonorProfileReligion}
   * @memberof PatchedDonorProfile
   */
  religion?: DonorProfileReligion | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  hospital?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  translator_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedDonorProfile
   */
  domestic_couple_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileExperience}
   * @memberof PatchedDonorProfile
   */
  experience?: DonorProfileExperience | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {DonorProfileHouseholdIncome}
   * @memberof PatchedDonorProfile
   */
  household_income?: DonorProfileHouseholdIncome | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof PatchedDonorProfile
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof PatchedDonorProfile
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof PatchedDonorProfile
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {DonorProfilePreviousCSections}
   * @memberof PatchedDonorProfile
   */
  previous_c_sections?: DonorProfilePreviousCSections | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedDonorProfile
   */
  hospital_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedDonorProfile
   */
  clinic_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedDonorProfile
   */
  airport_address?: DonorProfileAddress | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof PatchedDonorProfile
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  cleared_for_medical_review?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  all_stages_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  send_to_matching?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchedDonorProfile
   */
  final_review_completion_date?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfile
   */
  final_review_completed?: boolean;
}
/**
 *
 * @export
 * @interface PatchedDonorProfileFinalReview
 */
export interface PatchedDonorProfileFinalReview {
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof PatchedDonorProfileFinalReview
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfileFinalReview
   */
  cleared_for_medical_review?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfileFinalReview
   */
  all_stages_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedDonorProfileFinalReview
   */
  send_to_matching?: boolean;
}
/**
 *
 * @export
 * @interface PatchedEmbeddedDropboxDocument
 */
export interface PatchedEmbeddedDropboxDocument {
  /**
   *
   * @type {number}
   * @memberof PatchedEmbeddedDropboxDocument
   */
  id?: number;
  /**
   *
   * @type {DropboxDocumentTypeEnum}
   * @memberof PatchedEmbeddedDropboxDocument
   */
  document_type?: DropboxDocumentTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof PatchedEmbeddedDropboxDocument
   */
  is_signed?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchedEmbeddedDropboxDocument
   */
  sign_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedEmbeddedDropboxDocument
   */
  sign_url?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedEmbeddedDropboxDocument
   */
  signed_file_url?: string | null;
}
/**
 *
 * @export
 * @interface PatchedIntroductionFull
 */
export interface PatchedIntroductionFull {
  /**
   *
   * @type {number}
   * @memberof PatchedIntroductionFull
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof PatchedIntroductionFull
   */
  initial_parent_accepted?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedIntroductionFull
   */
  match_accepted_parent?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedIntroductionFull
   */
  match_accepted_donor?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof PatchedIntroductionFull
   */
  created?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedIntroductionFull
   */
  updated?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedIntroductionFull
   */
  expiration_date?: string | null;
  /**
   *
   * @type {IntroductionStageEnum}
   * @memberof PatchedIntroductionFull
   */
  stage?: IntroductionStageEnum;
  /**
   *
   * @type {IntroductionParentPassingReason}
   * @memberof PatchedIntroductionFull
   */
  parent_passing_reason?: IntroductionParentPassingReason | null;
  /**
   *
   * @type {string}
   * @memberof PatchedIntroductionFull
   */
  parent_other_answer?: string | null;
  /**
   *
   * @type {IntroductionDonorPassingReason}
   * @memberof PatchedIntroductionFull
   */
  donor_passing_reason?: IntroductionDonorPassingReason | null;
  /**
   *
   * @type {string}
   * @memberof PatchedIntroductionFull
   */
  donor_other_answer?: string | null;
  /**
   *
   * @type {IntroductionParentsProfile}
   * @memberof PatchedIntroductionFull
   */
  parent_profile?: IntroductionParentsProfile;
  /**
   *
   * @type {IntroductionDonorProfile}
   * @memberof PatchedIntroductionFull
   */
  donor_profile?: IntroductionDonorProfile;
  /**
   *
   * @type {boolean}
   * @memberof PatchedIntroductionFull
   */
  call_scheduled?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchedIntroductionFull
   */
  call_date?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedIntroductionFull
   */
  notes?: string | null;
}
/**
 *
 * @export
 * @interface PatchedInvitation
 */
export interface PatchedInvitation {
  /**
   *
   * @type {number}
   * @memberof PatchedInvitation
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedInvitation
   */
  created?: string;
  /**
   *
   * @type {InvitationRole}
   * @memberof PatchedInvitation
   */
  role?: InvitationRole | null;
  /**
   *
   * @type {string}
   * @memberof PatchedInvitation
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedInvitation
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedInvitation
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedInvitation
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedInvitation
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedInvitation
   */
  phone_number?: string | null;
  /**
   *
   * @type {UserNested}
   * @memberof PatchedInvitation
   */
  user?: UserNested;
  /**
   *
   * @type {InvitationStatusEnum}
   * @memberof PatchedInvitation
   */
  status?: InvitationStatusEnum;
  /**
   *
   * @type {Array<ScreenDetailsNested>}
   * @memberof PatchedInvitation
   */
  screens?: Array<ScreenDetailsNested>;
  /**
   *
   * @type {Review}
   * @memberof PatchedInvitation
   */
  medical_review?: Review;
  /**
   *
   * @type {string}
   * @memberof PatchedInvitation
   */
  organization_name?: string;
  /**
   *
   * @type {number}
   * @memberof PatchedInvitation
   */
  organization_id?: number | null;
  /**
   *
   * @type {Array<B2bCandidatePayment>}
   * @memberof PatchedInvitation
   */
  screening_payments?: Array<B2bCandidatePayment>;
}
/**
 *
 * @export
 * @interface PatchedIpContractDocumentType
 */
export interface PatchedIpContractDocumentType {
  /**
   *
   * @type {IpContractDocumentTypeDocumentTypeEnum}
   * @memberof PatchedIpContractDocumentType
   */
  document_type?: IpContractDocumentTypeDocumentTypeEnum;
}
/**
 *
 * @export
 * @interface PatchedIpSignMatchAgreementDocumentType
 */
export interface PatchedIpSignMatchAgreementDocumentType {
  /**
   *
   * @type {IpSignMatchAgreementDocumentTypeDocumentTypeEnum}
   * @memberof PatchedIpSignMatchAgreementDocumentType
   */
  document_type?: IpSignMatchAgreementDocumentTypeDocumentTypeEnum;
}
/**
 *
 * @export
 * @interface PatchedMedicalRecordReviewScreenDocuments
 */
export interface PatchedMedicalRecordReviewScreenDocuments {
  /**
   *
   * @type {string}
   * @memberof PatchedMedicalRecordReviewScreenDocuments
   */
  insurance_photo_front?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedMedicalRecordReviewScreenDocuments
   */
  insurance_photo_back?: string | null;
}
/**
 *
 * @export
 * @interface PatchedNavigatorParentsProfile
 */
export interface PatchedNavigatorParentsProfile {
  /**
   *
   * @type {number}
   * @memberof PatchedNavigatorParentsProfile
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedNavigatorParentsProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof PatchedNavigatorParentsProfile
   */
  preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof PatchedNavigatorParentsProfile
   */
  sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  occupation?: string | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof PatchedNavigatorParentsProfile
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {IntroductionParentsProfileReasonForApplying}
   * @memberof PatchedNavigatorParentsProfile
   */
  reason_for_applying?: IntroductionParentsProfileReasonForApplying | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  video_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  is_profile_completed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  is_match_profile_completed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  is_military?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  we_enjoy?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  we_relax?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  successful_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  note_to_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  additional_qs?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedNavigatorParentsProfile
   */
  experience_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof PatchedNavigatorParentsProfile
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {IntroductionParentsProfileCovidVaccinationPreference}
   * @memberof PatchedNavigatorParentsProfile
   */
  covid_vaccination_preference?: IntroductionParentsProfileCovidVaccinationPreference | null;
  /**
   *
   * @type {Array<ReligionPreferenceEnum>}
   * @memberof PatchedNavigatorParentsProfile
   */
  religion_preference?: Array<ReligionPreferenceEnum> | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedNavigatorParentsProfile
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileUsingDonor}
   * @memberof PatchedNavigatorParentsProfile
   */
  using_donor?: IntroductionParentsProfileUsingDonor | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryoTransferType}
   * @memberof PatchedNavigatorParentsProfile
   */
  embryo_transfer_type?: IntroductionParentsProfileEmbryoTransferType | null;
  /**
   *
   * @type {IntroductionParentsProfileFrozenEmbryoCount}
   * @memberof PatchedNavigatorParentsProfile
   */
  frozen_embryo_count?: IntroductionParentsProfileFrozenEmbryoCount | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryosPgsTested}
   * @memberof PatchedNavigatorParentsProfile
   */
  embryos_pgs_tested?: IntroductionParentsProfileEmbryosPgsTested | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof PatchedNavigatorParentsProfile
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof PatchedNavigatorParentsProfile
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedNavigatorParentsProfile
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileAgency}
   * @memberof PatchedNavigatorParentsProfile
   */
  agency?: IntroductionParentsProfileAgency | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  agency_name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  clinic?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  clinic_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  clinic_contact_info?: string | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof PatchedNavigatorParentsProfile
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof PatchedNavigatorParentsProfile
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof PatchedNavigatorParentsProfile
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  twibling_journey?: boolean | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof PatchedNavigatorParentsProfile
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  all_stages_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  send_to_matching?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchedNavigatorParentsProfile
   */
  final_review_completion_date?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNavigatorParentsProfile
   */
  final_review_completed?: boolean;
}
/**
 *
 * @export
 * @interface PatchedNotification
 */
export interface PatchedNotification {
  /**
   *
   * @type {number}
   * @memberof PatchedNotification
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof PatchedNotification
   */
  user?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedNotification
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedNotification
   */
  content?: string;
  /**
   *
   * @type {NotificationCtaType}
   * @memberof PatchedNotification
   */
  cta_type?: NotificationCtaType | null;
  /**
   *
   * @type {string}
   * @memberof PatchedNotification
   */
  created?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatchedNotification
   */
  is_read?: boolean;
}
/**
 *
 * @export
 * @interface PatchedOrganization
 */
export interface PatchedOrganization {
  /**
   *
   * @type {number}
   * @memberof PatchedOrganization
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganization
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganization
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganization
   */
  business_type?: string | null;
  /**
   *
   * @type {OrganizationTypeEnum}
   * @memberof PatchedOrganization
   */
  organization_type?: OrganizationTypeEnum;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedOrganization
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedOrganization
   */
  feature_matching?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedOrganization
   */
  feature_invitations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedOrganization
   */
  feature_navigator?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedOrganization
   */
  is_prepaid?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof PatchedOrganization
   */
  prepaid_screening_plans?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganization
   */
  created?: string;
}
/**
 *
 * @export
 * @interface PatchedOrganizationCandidateProfile
 */
export interface PatchedOrganizationCandidateProfile {
  /**
   *
   * @type {number}
   * @memberof PatchedOrganizationCandidateProfile
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationCandidateProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationCandidateProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationCandidateProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationCandidateProfile
   */
  maiden_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationCandidateProfile
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationCandidateProfile
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationCandidateProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationCandidateProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedOrganizationCandidateProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedOrganizationCandidateProfile
   */
  is_profile_completed?: boolean;
}
/**
 *
 * @export
 * @interface PatchedOrganizationUserProfile
 */
export interface PatchedOrganizationUserProfile {
  /**
   *
   * @type {number}
   * @memberof PatchedOrganizationUserProfile
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationUserProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationUserProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationUserProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedOrganizationUserProfile
   */
  title?: string | null;
}
/**
 *
 * @export
 * @interface PatchedParentsProfile
 */
export interface PatchedParentsProfile {
  /**
   *
   * @type {number}
   * @memberof PatchedParentsProfile
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedParentsProfile
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof PatchedParentsProfile
   */
  preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof PatchedParentsProfile
   */
  sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  occupation?: string | null;
  /**
   *
   * @type {DonorProfileRelationshipStatus}
   * @memberof PatchedParentsProfile
   */
  relationship_status?: DonorProfileRelationshipStatus | null;
  /**
   *
   * @type {IntroductionParentsProfileReasonForApplying}
   * @memberof PatchedParentsProfile
   */
  reason_for_applying?: IntroductionParentsProfileReasonForApplying | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  partner_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  partner_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  partner_last_name?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof PatchedParentsProfile
   */
  partner_address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  partner_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  partner_phone_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  partner_date_of_birth?: string | null;
  /**
   *
   * @type {IntroductionParentsProfilePreferredPronouns}
   * @memberof PatchedParentsProfile
   */
  partner_preferred_pronouns?: IntroductionParentsProfilePreferredPronouns | null;
  /**
   *
   * @type {IntroductionParentsProfileSexualOrientation}
   * @memberof PatchedParentsProfile
   */
  partner_sexual_orientation?: IntroductionParentsProfileSexualOrientation | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  partner_occupation?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  video?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  video_url?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  is_profile_completed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  is_match_profile_completed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  is_military?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  about?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  we_enjoy?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  we_relax?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  successful_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  note_to_match?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  additional_qs?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  lgbtq_comfort?: boolean | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedParentsProfile
   */
  experience_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {DonorProfileRelationshipPreference}
   * @memberof PatchedParentsProfile
   */
  relationship_preference?: DonorProfileRelationshipPreference | null;
  /**
   *
   * @type {IntroductionParentsProfileCovidVaccinationPreference}
   * @memberof PatchedParentsProfile
   */
  covid_vaccination_preference?: IntroductionParentsProfileCovidVaccinationPreference | null;
  /**
   *
   * @type {Array<ReligionPreferenceEnum>}
   * @memberof PatchedParentsProfile
   */
  religion_preference?: Array<ReligionPreferenceEnum> | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedParentsProfile
   */
  proximity_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileUsingDonor}
   * @memberof PatchedParentsProfile
   */
  using_donor?: IntroductionParentsProfileUsingDonor | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryoTransferType}
   * @memberof PatchedParentsProfile
   */
  embryo_transfer_type?: IntroductionParentsProfileEmbryoTransferType | null;
  /**
   *
   * @type {IntroductionParentsProfileFrozenEmbryoCount}
   * @memberof PatchedParentsProfile
   */
  frozen_embryo_count?: IntroductionParentsProfileFrozenEmbryoCount | null;
  /**
   *
   * @type {IntroductionParentsProfileEmbryosPgsTested}
   * @memberof PatchedParentsProfile
   */
  embryos_pgs_tested?: IntroductionParentsProfileEmbryosPgsTested | null;
  /**
   *
   * @type {DonorProfileDeliveryLocationPreference}
   * @memberof PatchedParentsProfile
   */
  delivery_location_preference?: DonorProfileDeliveryLocationPreference | null;
  /**
   *
   * @type {DonorProfileDeliveryCarePreference}
   * @memberof PatchedParentsProfile
   */
  delivery_care_preference?: DonorProfileDeliveryCarePreference | null;
  /**
   *
   * @type {DonorProfileFirstTimeParents}
   * @memberof PatchedParentsProfile
   */
  closeness_importance?: DonorProfileFirstTimeParents | null;
  /**
   *
   * @type {IntroductionParentsProfileAgency}
   * @memberof PatchedParentsProfile
   */
  agency?: IntroductionParentsProfileAgency | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  agency_name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  clinic?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  clinic_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  clinic_contact_info?: string | null;
  /**
   *
   * @type {DonorProfileTerminationChoice}
   * @memberof PatchedParentsProfile
   */
  termination_choice?: DonorProfileTerminationChoice | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  lgbtq_identify?: boolean | null;
  /**
   *
   * @type {DonorProfileIdealStart}
   * @memberof PatchedParentsProfile
   */
  ideal_start?: DonorProfileIdealStart | null;
  /**
   *
   * @type {DonorProfileAfterDeliveryRelationship}
   * @memberof PatchedParentsProfile
   */
  after_delivery_relationship?: DonorProfileAfterDeliveryRelationship | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  twibling_journey?: boolean | null;
  /**
   *
   * @type {DonorProfileMatchProfileReviewStatus}
   * @memberof PatchedParentsProfile
   */
  match_profile_review_status?: DonorProfileMatchProfileReviewStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  all_stages_complete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  send_to_matching?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchedParentsProfile
   */
  final_review_completion_date?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatchedParentsProfile
   */
  final_review_completed?: boolean;
}
/**
 * @type PatchedProfileObject
 * @export
 */
export type PatchedProfileObject =
  | PatchedDonorProfile
  | PatchedNavigatorParentsProfile
  | PatchedOrganizationCandidateProfile
  | PatchedOrganizationUserProfile
  | PatchedParentsProfile
  | PatchedSuperAdminProfile;

/**
 *
 * @export
 * @interface PatchedRemoteDropboxDocumentSigner
 */
export interface PatchedRemoteDropboxDocumentSigner {
  /**
   *
   * @type {number}
   * @memberof PatchedRemoteDropboxDocumentSigner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedRemoteDropboxDocumentSigner
   */
  sign_id?: string | null;
  /**
   *
   * @type {SignerRoleEnum}
   * @memberof PatchedRemoteDropboxDocumentSigner
   */
  signer_role?: SignerRoleEnum;
  /**
   *
   * @type {string}
   * @memberof PatchedRemoteDropboxDocumentSigner
   */
  signing_status_code?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedRemoteDropboxDocumentSigner
   */
  display_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedRemoteDropboxDocumentSigner
   */
  email_address?: string | null;
}
/**
 *
 * @export
 * @interface PatchedReviewStep
 */
export interface PatchedReviewStep {
  /**
   *
   * @type {string}
   * @memberof PatchedReviewStep
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedReviewStep
   */
  uid?: string;
  /**
   *
   * @type {ReviewStepStatusEnum}
   * @memberof PatchedReviewStep
   */
  status?: ReviewStepStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PatchedReviewStep
   */
  completion_date?: string | null;
  /**
   *
   * @type {ReviewStepContentObject}
   * @memberof PatchedReviewStep
   */
  content_object?: ReviewStepContentObject;
}
/**
 *
 * @export
 * @interface PatchedScreen
 */
export interface PatchedScreen {
  /**
   *
   * @type {number}
   * @memberof PatchedScreen
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedScreen
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedScreen
   */
  uid?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedScreen
   */
  type?: string;
  /**
   *
   * @type {UserNested}
   * @memberof PatchedScreen
   */
  user?: UserNested;
  /**
   *
   * @type {ScreenStatusEnum}
   * @memberof PatchedScreen
   */
  status?: ScreenStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PatchedScreen
   */
  status_details?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedScreen
   */
  requires_staff_review?: string;
  /**
   *
   * @type {ScreenContentObject}
   * @memberof PatchedScreen
   */
  content_object?: ScreenContentObject;
  /**
   *
   * @type {string}
   * @memberof PatchedScreen
   */
  completion_date?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof PatchedScreen
   */
  manual_check?: boolean;
}
/**
 *
 * @export
 * @interface PatchedSuperAdminProfile
 */
export interface PatchedSuperAdminProfile {
  /**
   *
   * @type {number}
   * @memberof PatchedSuperAdminProfile
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedSuperAdminProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedSuperAdminProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedSuperAdminProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedSuperAdminProfile
   */
  title?: string | null;
}
/**
 *
 * @export
 * @interface PatchedTask
 */
export interface PatchedTask {
  /**
   *
   * @type {number}
   * @memberof PatchedTask
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedTask
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof PatchedTask
   */
  uid?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedTask
   */
  type?: string;
  /**
   *
   * @type {number}
   * @memberof PatchedTask
   */
  order?: number;
  /**
   *
   * @type {number}
   * @memberof PatchedTask
   */
  user?: number;
  /**
   *
   * @type {TaskStageEnum}
   * @memberof PatchedTask
   */
  stage?: TaskStageEnum;
  /**
   *
   * @type {TaskStatusEnum}
   * @memberof PatchedTask
   */
  status?: TaskStatusEnum;
  /**
   *
   * @type {TaskContentObject}
   * @memberof PatchedTask
   */
  content_object?: TaskContentObject;
  /**
   *
   * @type {string}
   * @memberof PatchedTask
   */
  completion_date?: string | null;
  /**
   *
   * @type {CompletionTypeEnum}
   * @memberof PatchedTask
   */
  completion_type?: CompletionTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof PatchedTask
   */
  prerequisite_uids?: Array<string>;
}
/**
 *
 * @export
 * @interface PatchedUser
 */
export interface PatchedUser {
  /**
   *
   * @type {number}
   * @memberof PatchedUser
   */
  id?: number;
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @type {string}
   * @memberof PatchedUser
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof PatchedUser
   */
  email?: string;
  /**
   *
   * @type {UserRoleEnum}
   * @memberof PatchedUser
   */
  role?: UserRoleEnum;
  /**
   *
   * @type {UserStatusEnum}
   * @memberof PatchedUser
   */
  status?: UserStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof PatchedUser
   */
  show_matching_badge?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedUser
   */
  requires_staff_review?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedUser
   */
  passed_screens?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedUser
   */
  half_passed_screens?: boolean;
  /**
   *
   * @type {Array<UserProfileOwnPhoto>}
   * @memberof PatchedUser
   */
  profile_photos?: Array<UserProfileOwnPhoto>;
  /**
   *
   * @type {ProfileObject}
   * @memberof PatchedUser
   */
  profile?: ProfileObject;
  /**
   *
   * @type {number}
   * @memberof PatchedUser
   */
  introductions_left?: number;
  /**
   *
   * @type {boolean}
   * @memberof PatchedUser
   */
  has_nodal_services_payment_completed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PatchedUser
   */
  show_paywall?: boolean;
  /**
   *
   * @type {number}
   * @memberof PatchedUser
   */
  medical_review_id?: number;
}
/**
 *
 * @export
 * @interface PatchedUserNoteEditCreate
 */
export interface PatchedUserNoteEditCreate {
  /**
   *
   * @type {TypeEnum}
   * @memberof PatchedUserNoteEditCreate
   */
  type?: TypeEnum;
  /**
   *
   * @type {string}
   * @memberof PatchedUserNoteEditCreate
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof PatchedUserNoteEditCreate
   */
  user?: number;
  /**
   *
   * @type {string}
   * @memberof PatchedUserNoteEditCreate
   */
  created?: string;
  /**
   *
   * @type {number}
   * @memberof PatchedUserNoteEditCreate
   */
  id?: number;
}
/**
 * Serializer to serialize list of assigned support team members.
 * @export
 * @interface PatchedUserSupportTeamUser
 */
export interface PatchedUserSupportTeamUser {
  /**
   *
   * @type {number}
   * @memberof PatchedUserSupportTeamUser
   */
  id?: number;
  /**
   *
   * @type {Array<SupportTeamUser>}
   * @memberof PatchedUserSupportTeamUser
   */
  assigned_support_team_members?: Array<SupportTeamUser>;
  /**
   *
   * @type {Array<number>}
   * @memberof PatchedUserSupportTeamUser
   */
  assigned_support_team_members_ids?: Array<number>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PayingEntityEnum = {
  Org: 'org',
  ExtParents: 'ext_parents',
  Prepaid: 'prepaid',
} as const;

export type PayingEntityEnum =
  typeof PayingEntityEnum[keyof typeof PayingEntityEnum];

/**
 *
 * @export
 * @interface PaymentMethodSecret
 */
export interface PaymentMethodSecret {
  /**
   *
   * @type {string}
   * @memberof PaymentMethodSecret
   */
  client_secret: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PaymentStatusEnum = {
  NotInitiated: 'not_initiated',
  Initiated: 'initiated',
  Processing: 'processing',
  Completed: 'completed',
  Failed: 'failed',
} as const;

export type PaymentStatusEnum =
  typeof PaymentStatusEnum[keyof typeof PaymentStatusEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const PaymentTargetEnum = {
  B2BScreening: 'B2B_SCREENING',
  NodalSubscription: 'NODAL_SUBSCRIPTION',
  NodalMatching: 'NODAL_MATCHING',
  NodalNavigator: 'NODAL_NAVIGATOR',
} as const;

export type PaymentTargetEnum =
  typeof PaymentTargetEnum[keyof typeof PaymentTargetEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const PaymentTypeEnum = {
  CardPayment: 'card_payment',
  BankTransfer: 'bank_transfer',
  None: 'none',
} as const;

export type PaymentTypeEnum =
  typeof PaymentTypeEnum[keyof typeof PaymentTypeEnum];

/**
 *
 * @export
 * @interface PhoneCallScreen
 */
export interface PhoneCallScreen {
  /**
   *
   * @type {number}
   * @memberof PhoneCallScreen
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PhoneCallScreen
   */
  event_uri?: string | null;
  /**
   *
   * @type {string}
   * @memberof PhoneCallScreen
   */
  invitee_uri?: string | null;
}
/**
 *
 * @export
 * @interface Plan
 */
export interface Plan {
  /**
   *
   * @type {number}
   * @memberof Plan
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Plan
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof Plan
   */
  is_active?: boolean;
  /**
   *
   * @type {Price}
   * @memberof Plan
   */
  price: Price;
}
/**
 *
 * @export
 * @interface PlutoDataSync
 */
export interface PlutoDataSync {
  /**
   *
   * @type {number}
   * @memberof PlutoDataSync
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PlutoDataSync
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof PlutoDataSync
   */
  updated: string;
  /**
   *
   * @type {string}
   * @memberof PlutoDataSync
   */
  pluto_patient_id?: string | null;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof PlutoDataSync
   */
  results?: { [key: string]: any } | null;
  /**
   *
   * @type {boolean}
   * @memberof PlutoDataSync
   */
  sync_completed?: boolean;
  /**
   *
   * @type {number}
   * @memberof PlutoDataSync
   */
  record_pull: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PreviousCSectionsEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;

export type PreviousCSectionsEnum =
  typeof PreviousCSectionsEnum[keyof typeof PreviousCSectionsEnum];

/**
 *
 * @export
 * @interface Price
 */
export interface Price {
  /**
   *
   * @type {number}
   * @memberof Price
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  card_payment_fees?: string | null;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  bank_transfer_fees?: string | null;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  card_payment_stripe_price_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof Price
   */
  bank_transfer_stripe_price_id?: string | null;
  /**
   *
   * @type {number}
   * @memberof Price
   */
  plan: number;
}
/**
 * @type ProfileObject
 * @export
 */
export type ProfileObject =
  | DonorProfile
  | NavigatorParentsProfile
  | OrganizationCandidateProfile
  | OrganizationUserProfile
  | ParentsProfile
  | SuperAdminProfile;

/**
 *
 * @export
 * @enum {string}
 */

export const PronounPreferenceEnum = {
  He: 'he',
  She: 'she',
  The: 'the',
  Ze: 'ze',
  Ze2: 'ze2',
  Xe: 'xe',
  Oth: 'oth',
} as const;

export type PronounPreferenceEnum =
  typeof PronounPreferenceEnum[keyof typeof PronounPreferenceEnum];

/**
 *
 * @export
 * @interface QuestionnaireFlag
 */
export interface QuestionnaireFlag {
  /**
   *
   * @type {WeightEnum}
   * @memberof QuestionnaireFlag
   */
  weight?: WeightEnum;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireFlag
   */
  field_name: string;
  /**
   *
   * @type {string}
   * @memberof QuestionnaireFlag
   */
  description: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ReasonForApplyingEnum = {
  Mne: 'mne',
  Spa: 'spa',
  Lgb: 'lgb',
  Oth: 'oth',
} as const;

export type ReasonForApplyingEnum =
  typeof ReasonForApplyingEnum[keyof typeof ReasonForApplyingEnum];

/**
 *
 * @export
 * @interface RecordPull
 */
export interface RecordPull {
  /**
   *
   * @type {number}
   * @memberof RecordPull
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  created: string;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  updated: string;
  /**
   *
   * @type {Array<FaxPdfDocument>}
   * @memberof RecordPull
   */
  fax_pdf_documents: Array<FaxPdfDocument>;
  /**
   *
   * @type {Array<number>}
   * @memberof RecordPull
   */
  pluto_data_sync: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof RecordPull
   */
  fax_outbox_fax_status: Array<number>;
  /**
   *
   * @type {ThirdPartyProviderEnum}
   * @memberof RecordPull
   */
  third_party_provider: ThirdPartyProviderEnum;
  /**
   *
   * @type {RecordPullDataProviderType}
   * @memberof RecordPull
   */
  data_provider_type?: RecordPullDataProviderType | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  consent_file_path?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  date_of_birth?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  gender?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  phone_number?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof RecordPull
   */
  address?: DonorProfileAddress | null;
  /**
   *
   * @type {number}
   * @memberof RecordPull
   */
  delivery?: number | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  doctor_health_system?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  doctor_first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  doctor_middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  doctor_last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  doctor_date_of_service_start?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  doctor_date_of_service_end?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  doctor_phone_number?: string | null;
  /**
   *
   * @type {DonorProfileAddress}
   * @memberof RecordPull
   */
  doctor_address?: DonorProfileAddress | null;
  /**
   *
   * @type {string}
   * @memberof RecordPull
   */
  doctor_instructions?: string | null;
  /**
   *
   * @type {RecordPullFaxDetails}
   * @memberof RecordPull
   */
  fax_details?: RecordPullFaxDetails;
}
/**
 * @type RecordPullDataProviderType
 * @export
 */
export type RecordPullDataProviderType =
  | BlankEnum
  | DataProviderTypeEnum
  | NullEnum;

/**
 *
 * @export
 * @interface RecordPullFaxDetails
 */
export interface RecordPullFaxDetails {
  /**
   *
   * @type {boolean}
   * @memberof RecordPullFaxDetails
   */
  prenatal_records_selected?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof RecordPullFaxDetails
   */
  delivery_records_selected?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof RecordPullFaxDetails
   */
  disease_records_selected?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof RecordPullFaxDetails
   */
  other_records_selected?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  other_records_details?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof RecordPullFaxDetails
   */
  ob_clearance_letter_required?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  sender_email?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  to_fax_number?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  from_header?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  cp_from_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  cp_to_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  cp_organization?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  cp_subject?: string | null;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  cp_comment?: string | null;
  /**
   *
   * @type {FaxAdapterTypeEnum}
   * @memberof RecordPullFaxDetails
   */
  fax_adapter_type: FaxAdapterTypeEnum;
  /**
   *
   * @type {string}
   * @memberof RecordPullFaxDetails
   */
  to_email?: string | null;
}
/**
 *
 * @export
 * @interface RecordPullStepDetail
 */
export interface RecordPullStepDetail {
  /**
   *
   * @type {number}
   * @memberof RecordPullStepDetail
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof RecordPullStepDetail
   */
  notes?: string | null;
}
/**
 *
 * @export
 * @interface Register
 */
export interface Register {
  /**
   *
   * @type {string}
   * @memberof Register
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof Register
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof Register
   */
  password1: string;
  /**
   *
   * @type {string}
   * @memberof Register
   */
  password2: string;
  /**
   *
   * @type {RegisterRoleEnum}
   * @memberof Register
   */
  role?: RegisterRoleEnum;
}
/**
 *
 * @export
 * @enum {string}
 */

export const RegisterRoleEnum = {
  Dnr: 'dnr',
  Par: 'par',
  Oad: 'oad',
  Opa: 'opa',
  Odo: 'odo',
  Oed: 'oed',
  Osd: 'osd',
  Nap: 'nap',
} as const;

export type RegisterRoleEnum =
  typeof RegisterRoleEnum[keyof typeof RegisterRoleEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const RelationshipPreferenceEnum = {
  Single: 'single',
  Partnered: 'partnered',
  NoPreference: 'no_preference',
} as const;

export type RelationshipPreferenceEnum =
  typeof RelationshipPreferenceEnum[keyof typeof RelationshipPreferenceEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const RelationshipStatusEnum = {
  Sin: 'sin',
  Rel: 'rel',
  Eng: 'eng',
  Mar: 'mar',
  Div: 'div',
  Sep: 'sep',
  Wid: 'wid',
  Oth: 'oth',
} as const;

export type RelationshipStatusEnum =
  typeof RelationshipStatusEnum[keyof typeof RelationshipStatusEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ReligionEnum = {
  Christianity: 'christianity',
  RomanCatholicism: 'roman_catholicism',
  Judaism: 'judaism',
  Islam: 'islam',
  Hinduism: 'hinduism',
  Buddhism: 'buddhism',
  Spiritual: 'spiritual',
  Agnostic: 'agnostic',
  Atheist: 'atheist',
  Other: 'other',
} as const;

export type ReligionEnum = typeof ReligionEnum[keyof typeof ReligionEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const ReligionPreferenceEnum = {
  Christianity: 'christianity',
  RomanCatholicism: 'roman_catholicism',
  Judaism: 'judaism',
  Islam: 'islam',
  Hinduism: 'hinduism',
  Buddhism: 'buddhism',
  Spiritual: 'spiritual',
  Agnostic: 'agnostic',
  Atheist: 'atheist',
  Other: 'other',
  NoPreference: 'no_preference',
} as const;

export type ReligionPreferenceEnum =
  typeof ReligionPreferenceEnum[keyof typeof ReligionPreferenceEnum];

/**
 *
 * @export
 * @interface RemoteDropboxDocumentSigner
 */
export interface RemoteDropboxDocumentSigner {
  /**
   *
   * @type {number}
   * @memberof RemoteDropboxDocumentSigner
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof RemoteDropboxDocumentSigner
   */
  sign_id: string | null;
  /**
   *
   * @type {SignerRoleEnum}
   * @memberof RemoteDropboxDocumentSigner
   */
  signer_role: SignerRoleEnum;
  /**
   *
   * @type {string}
   * @memberof RemoteDropboxDocumentSigner
   */
  signing_status_code: string | null;
  /**
   *
   * @type {string}
   * @memberof RemoteDropboxDocumentSigner
   */
  display_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof RemoteDropboxDocumentSigner
   */
  email_address?: string | null;
}
/**
 *
 * @export
 * @interface ResendEmailVerification
 */
export interface ResendEmailVerification {
  /**
   *
   * @type {string}
   * @memberof ResendEmailVerification
   */
  email: string;
}
/**
 *
 * @export
 * @interface RestAuthDetail
 */
export interface RestAuthDetail {
  /**
   *
   * @type {string}
   * @memberof RestAuthDetail
   */
  detail: string;
}
/**
 *
 * @export
 * @interface Review
 */
export interface Review {
  /**
   *
   * @type {number}
   * @memberof Review
   */
  id: number;
  /**
   *
   * @type {Array<RecordPull>}
   * @memberof Review
   */
  pulls: Array<RecordPull>;
  /**
   *
   * @type {Array<ReviewStep>}
   * @memberof Review
   */
  steps: Array<ReviewStep>;
  /**
   *
   * @type {Array<MedicalDocument>}
   * @memberof Review
   */
  documents: Array<MedicalDocument>;
  /**
   *
   * @type {boolean}
   * @memberof Review
   */
  is_complete: boolean;
}
/**
 *
 * @export
 * @interface ReviewDecisionStepDetail
 */
export interface ReviewDecisionStepDetail {
  /**
   *
   * @type {number}
   * @memberof ReviewDecisionStepDetail
   */
  id: number;
}
/**
 *
 * @export
 * @interface ReviewNested
 */
export interface ReviewNested {
  /**
   *
   * @type {number}
   * @memberof ReviewNested
   */
  id: number;
  /**
   *
   * @type {Array<ReviewStep>}
   * @memberof ReviewNested
   */
  steps: Array<ReviewStep>;
  /**
   *
   * @type {boolean}
   * @memberof ReviewNested
   */
  is_complete: boolean;
  /**
   *
   * @type {number}
   * @memberof ReviewNested
   */
  pulls_count: number;
}
/**
 *
 * @export
 * @interface ReviewStep
 */
export interface ReviewStep {
  /**
   *
   * @type {string}
   * @memberof ReviewStep
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReviewStep
   */
  uid: string;
  /**
   *
   * @type {ReviewStepStatusEnum}
   * @memberof ReviewStep
   */
  status?: ReviewStepStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ReviewStep
   */
  completion_date?: string | null;
  /**
   *
   * @type {ReviewStepContentObject}
   * @memberof ReviewStep
   */
  content_object: ReviewStepContentObject;
}
/**
 * @type ReviewStepContentObject
 * @export
 */
export type ReviewStepContentObject =
  | DataPullStepDetail
  | MfmClearanceStepDetail
  | RecordPullStepDetail
  | ReviewDecisionStepDetail;

/**
 *
 * @export
 * @enum {string}
 */

export const ReviewStepStatusEnum = {
  Pending: 'pending',
  Complete: 'complete',
  Rejected: 'rejected',
} as const;

export type ReviewStepStatusEnum =
  typeof ReviewStepStatusEnum[keyof typeof ReviewStepStatusEnum];

/**
 *
 * @export
 * @interface ReviewStepUserSelf
 */
export interface ReviewStepUserSelf {
  /**
   *
   * @type {string}
   * @memberof ReviewStepUserSelf
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReviewStepUserSelf
   */
  uid: string;
  /**
   *
   * @type {ReviewStepStatusEnum}
   * @memberof ReviewStepUserSelf
   */
  status?: ReviewStepStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ReviewStepUserSelf
   */
  completion_date?: string | null;
}
/**
 *
 * @export
 * @interface ReviewUserSelf
 */
export interface ReviewUserSelf {
  /**
   *
   * @type {number}
   * @memberof ReviewUserSelf
   */
  id: number;
  /**
   *
   * @type {Array<ReviewStepUserSelf>}
   * @memberof ReviewUserSelf
   */
  steps: Array<ReviewStepUserSelf>;
  /**
   *
   * @type {boolean}
   * @memberof ReviewUserSelf
   */
  is_complete: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const RoleC4eEnum = {
  Odo: 'odo',
  Oed: 'oed',
  Osd: 'osd',
} as const;

export type RoleC4eEnum = typeof RoleC4eEnum[keyof typeof RoleC4eEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const RoleFd4Enum = {
  Primary: 'primary',
  Secondary: 'secondary',
} as const;

export type RoleFd4Enum = typeof RoleFd4Enum[keyof typeof RoleFd4Enum];

/**
 *
 * @export
 * @interface RolesAllAvailableScreen
 */
export interface RolesAllAvailableScreen {
  /**
   *
   * @type {string}
   * @memberof RolesAllAvailableScreen
   */
  role: string;
  /**
   *
   * @type {Array<AvailableScreen>}
   * @memberof RolesAllAvailableScreen
   */
  screens: Array<AvailableScreen>;
}
/**
 *
 * @export
 * @interface Screen
 */
export interface Screen {
  /**
   *
   * @type {number}
   * @memberof Screen
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Screen
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof Screen
   */
  uid: string;
  /**
   *
   * @type {string}
   * @memberof Screen
   */
  type: string;
  /**
   *
   * @type {UserNested}
   * @memberof Screen
   */
  user: UserNested;
  /**
   *
   * @type {ScreenStatusEnum}
   * @memberof Screen
   */
  status?: ScreenStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Screen
   */
  status_details?: string;
  /**
   *
   * @type {string}
   * @memberof Screen
   */
  requires_staff_review: string;
  /**
   *
   * @type {ScreenContentObject}
   * @memberof Screen
   */
  content_object: ScreenContentObject;
  /**
   *
   * @type {string}
   * @memberof Screen
   */
  completion_date?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Screen
   */
  manual_check?: boolean;
}
/**
 * @type ScreenContentObject
 * @export
 */
export type ScreenContentObject =
  | CheckrScreen
  | DonorProfileScreen
  | DonorQuestionnaireScreen
  | EggDonorQuestionnaireScreen
  | HipaaConsentFormScreen
  | MedicalRecordReviewScreen
  | NavigatorParentsProfileScreen
  | OrganizationCandidateProfileScreen
  | ParentsProfileScreen
  | ParentsQuestionnaireScreen
  | PhoneCallScreen
  | SpermDonorQuestionnaireScreen
  | StripeIdentityScreen
  | StripeInitiationFeeScreen;

/**
 *
 * @export
 * @interface ScreenDetailsNested
 */
export interface ScreenDetailsNested {
  /**
   *
   * @type {number}
   * @memberof ScreenDetailsNested
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ScreenDetailsNested
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof ScreenDetailsNested
   */
  uid: string;
  /**
   *
   * @type {string}
   * @memberof ScreenDetailsNested
   */
  type: string;
  /**
   *
   * @type {ScreenStatusEnum}
   * @memberof ScreenDetailsNested
   */
  status?: ScreenStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ScreenDetailsNested
   */
  status_details?: string;
  /**
   *
   * @type {string}
   * @memberof ScreenDetailsNested
   */
  requires_staff_review: string;
  /**
   *
   * @type {ScreenContentObject}
   * @memberof ScreenDetailsNested
   */
  content_object: ScreenContentObject;
  /**
   *
   * @type {string}
   * @memberof ScreenDetailsNested
   */
  completion_date?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ScreenDetailsNested
   */
  manual_check?: boolean;
}
/**
 * Screen serializer avoiding fetching content_object
 * @export
 * @interface ScreenNested
 */
export interface ScreenNested {
  /**
   *
   * @type {number}
   * @memberof ScreenNested
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ScreenNested
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof ScreenNested
   */
  uid: string;
  /**
   *
   * @type {string}
   * @memberof ScreenNested
   */
  type: string;
  /**
   *
   * @type {ScreenStatusEnum}
   * @memberof ScreenNested
   */
  status?: ScreenStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ScreenNested
   */
  status_details?: string;
  /**
   *
   * @type {string}
   * @memberof ScreenNested
   */
  requires_staff_review: string;
  /**
   *
   * @type {string}
   * @memberof ScreenNested
   */
  completion_date?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ScreenNested
   */
  manual_check?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ScreenStatusEnum = {
  Pend: 'pend',
  Proc: 'proc',
  App: 'app',
  Half: 'half',
  Rev: 'rev',
  Rej: 'rej',
} as const;

export type ScreenStatusEnum =
  typeof ScreenStatusEnum[keyof typeof ScreenStatusEnum];

/**
 *
 * @export
 * @interface ScreeningPackage
 */
export interface ScreeningPackage {
  /**
   *
   * @type {Array<string>}
   * @memberof ScreeningPackage
   */
  description_entries?: Array<string>;
  /**
   *
   * @type {B2bScreeningPackagePrice}
   * @memberof ScreeningPackage
   */
  screening_price: B2bScreeningPackagePrice;
  /**
   *
   * @type {NameEnum}
   * @memberof ScreeningPackage
   */
  name: NameEnum;
  /**
   *
   * @type {number}
   * @memberof ScreeningPackage
   */
  id: number;
  /**
   *
   * @type {Array<string>}
   * @memberof ScreeningPackage
   */
  screen_uids?: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const SentStatusEnum = {
  InProgress: 'in_progress',
  Sent: 'sent',
  Failed: 'failed',
  SendingEmail: 'sending_email',
} as const;

export type SentStatusEnum = typeof SentStatusEnum[keyof typeof SentStatusEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const SexualOrientationEnum = {
  Ase: 'ase',
  Bis: 'bis',
  Gay: 'gay',
  Het: 'het',
  Pan: 'pan',
  Que: 'que',
  Oth: 'oth',
  Pre: 'pre',
} as const;

export type SexualOrientationEnum =
  typeof SexualOrientationEnum[keyof typeof SexualOrientationEnum];

/**
 *
 * @export
 * @interface SignatureRemoteDropboxDocument
 */
export interface SignatureRemoteDropboxDocument {
  /**
   *
   * @type {number}
   * @memberof SignatureRemoteDropboxDocument
   */
  id: number;
  /**
   *
   * @type {SignatureRemoteDropboxDocumentDocumentType}
   * @memberof SignatureRemoteDropboxDocument
   */
  document_type: SignatureRemoteDropboxDocumentDocumentType | null;
  /**
   *
   * @type {string}
   * @memberof SignatureRemoteDropboxDocument
   */
  signed_file_url: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SignatureRemoteDropboxDocument
   */
  is_signed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SignatureRemoteDropboxDocument
   */
  is_in_progress: boolean;
  /**
   *
   * @type {Array<RemoteDropboxDocumentSigner>}
   * @memberof SignatureRemoteDropboxDocument
   */
  signers: Array<RemoteDropboxDocumentSigner>;
}
/**
 * @type SignatureRemoteDropboxDocumentDocumentType
 * @export
 */
export type SignatureRemoteDropboxDocumentDocumentType =
  | BlankEnum
  | DropboxDocumentTypeEnum
  | NullEnum;

/**
 *
 * @export
 * @enum {string}
 */

export const SignerRoleEnum = {
  IntendedParentA: 'Intended Parent A',
  IntendedParentB: 'Intended Parent B',
  IntendedParent: 'Intended Parent',
  Surrogate: 'Surrogate',
  NodalCeo: 'Nodal CEO',
} as const;

export type SignerRoleEnum = typeof SignerRoleEnum[keyof typeof SignerRoleEnum];

/**
 *
 * @export
 * @interface SpermDonorQuestionnaireScreen
 */
export interface SpermDonorQuestionnaireScreen {
  /**
   *
   * @type {number}
   * @memberof SpermDonorQuestionnaireScreen
   */
  id: number;
  /**
   *
   * @type {Array<QuestionnaireFlag>}
   * @memberof SpermDonorQuestionnaireScreen
   */
  flags: Array<QuestionnaireFlag>;
  /**
   *
   * @type {number}
   * @memberof SpermDonorQuestionnaireScreen
   */
  height?: number | null;
  /**
   *
   * @type {number}
   * @memberof SpermDonorQuestionnaireScreen
   */
  weight?: number | null;
  /**
   *
   * @type {EggDonorQuestionnaireScreenEthnicity}
   * @memberof SpermDonorQuestionnaireScreen
   */
  ethnicity?: EggDonorQuestionnaireScreenEthnicity | null;
  /**
   *
   * @type {string}
   * @memberof SpermDonorQuestionnaireScreen
   */
  ethnicity_other?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  native_american?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  us_citizen?: boolean;
  /**
   *
   * @type {EggDonorQuestionnaireScreenParentage}
   * @memberof SpermDonorQuestionnaireScreen
   */
  parentage?: EggDonorQuestionnaireScreenParentage | null;
  /**
   *
   * @type {EggDonorQuestionnaireScreenHighestEducation}
   * @memberof SpermDonorQuestionnaireScreen
   */
  highest_education?: EggDonorQuestionnaireScreenHighestEducation | null;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  is_convicted_of_crime?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  usage_of_grass?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  smoked_last_year?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  heroin_use?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  alcoholism?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  medications_depression?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  history_of_cardiac?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof SpermDonorQuestionnaireScreen
   */
  history_of_autoimmune?: boolean;
}
/**
 *
 * @export
 * @interface StaffCompletionIpFinalMatchPaymentTask
 */
export interface StaffCompletionIpFinalMatchPaymentTask {
  /**
   *
   * @type {number}
   * @memberof StaffCompletionIpFinalMatchPaymentTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof StaffCompletionIpFinalMatchPaymentTask
   */
  task_type: string;
  /**
   *
   * @type {boolean}
   * @memberof StaffCompletionIpFinalMatchPaymentTask
   */
  final_payment_submitted?: boolean;
}
/**
 *
 * @export
 * @interface StaffCompletionIpSupportCallTask
 */
export interface StaffCompletionIpSupportCallTask {
  /**
   *
   * @type {number}
   * @memberof StaffCompletionIpSupportCallTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof StaffCompletionIpSupportCallTask
   */
  task_type: string;
  /**
   *
   * @type {boolean}
   * @memberof StaffCompletionIpSupportCallTask
   */
  call_scheduling_requested: boolean;
  /**
   *
   * @type {boolean}
   * @memberof StaffCompletionIpSupportCallTask
   */
  call_scheduled?: boolean;
  /**
   *
   * @type {string}
   * @memberof StaffCompletionIpSupportCallTask
   */
  call_date_time?: string | null;
}
/**
 *
 * @export
 * @interface StageCount
 */
export interface StageCount {
  /**
   *
   * @type {number}
   * @memberof StageCount
   */
  donors_count: number;
  /**
   *
   * @type {number}
   * @memberof StageCount
   */
  parents_count: number;
  /**
   *
   * @type {number}
   * @memberof StageCount
   */
  avg_time: number;
  /**
   *
   * @type {number}
   * @memberof StageCount
   */
  sample_size: number;
  /**
   *
   * @type {Array<StepOverdueUser>}
   * @memberof StageCount
   */
  step_overdue_users: Array<StepOverdueUser>;
}
/**
 *
 * @export
 * @interface StageData
 */
export interface StageData {
  /**
   *
   * @type {StageDataApplication}
   * @memberof StageData
   */
  application: StageDataApplication | null;
  /**
   *
   * @type {StageDataApplication}
   * @memberof StageData
   */
  id_check: StageDataApplication | null;
  /**
   *
   * @type {StageDataApplication}
   * @memberof StageData
   */
  hipaa_consent_form: StageDataApplication | null;
  /**
   *
   * @type {StageDataApplication}
   * @memberof StageData
   */
  medical_form: StageDataApplication | null;
  /**
   *
   * @type {StageDataApplication}
   * @memberof StageData
   */
  checkr: StageDataApplication | null;
  /**
   *
   * @type {StageDataApplication}
   * @memberof StageData
   */
  final_review: StageDataApplication | null;
  /**
   *
   * @type {StageDataApplication}
   * @memberof StageData
   */
  matching: StageDataApplication | null;
  /**
   *
   * @type {StageDataApplication}
   * @memberof StageData
   */
  medical_review: StageDataApplication | null;
}
/**
 *
 * @export
 * @interface StageDataApplication
 */
export interface StageDataApplication {
  /**
   *
   * @type {number}
   * @memberof StageDataApplication
   */
  donors_count: number;
  /**
   *
   * @type {number}
   * @memberof StageDataApplication
   */
  parents_count: number;
  /**
   *
   * @type {number}
   * @memberof StageDataApplication
   */
  avg_time: number;
  /**
   *
   * @type {number}
   * @memberof StageDataApplication
   */
  sample_size: number;
  /**
   *
   * @type {Array<StepOverdueUser>}
   * @memberof StageDataApplication
   */
  step_overdue_users: Array<StepOverdueUser>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const StateEnum = {
  Al: 'AL',
  Ak: 'AK',
  Az: 'AZ',
  Ar: 'AR',
  Ca: 'CA',
  Co: 'CO',
  Ct: 'CT',
  De: 'DE',
  Fl: 'FL',
  Ga: 'GA',
  Hi: 'HI',
  Id: 'ID',
  Il: 'IL',
  In: 'IN',
  Ia: 'IA',
  Ks: 'KS',
  Ky: 'KY',
  La: 'LA',
  Me: 'ME',
  Md: 'MD',
  Ma: 'MA',
  Mi: 'MI',
  Mn: 'MN',
  Ms: 'MS',
  Mo: 'MO',
  Mt: 'MT',
  Ne: 'NE',
  Nv: 'NV',
  Nh: 'NH',
  Nj: 'NJ',
  Nm: 'NM',
  Ny: 'NY',
  Nc: 'NC',
  Nd: 'ND',
  Oh: 'OH',
  Ok: 'OK',
  Or: 'OR',
  Pa: 'PA',
  Ri: 'RI',
  Sc: 'SC',
  Sd: 'SD',
  Tn: 'TN',
  Tx: 'TX',
  Ut: 'UT',
  Vt: 'VT',
  Va: 'VA',
  Wa: 'WA',
  Wv: 'WV',
  Wi: 'WI',
  Wy: 'WY',
  Dc: 'DC',
} as const;

export type StateEnum = typeof StateEnum[keyof typeof StateEnum];

/**
 *
 * @export
 * @interface StepOverdueUser
 */
export interface StepOverdueUser {
  /**
   *
   * @type {number}
   * @memberof StepOverdueUser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof StepOverdueUser
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof StepOverdueUser
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof StepOverdueUser
   */
  last_login: string;
  /**
   *
   * @type {string}
   * @memberof StepOverdueUser
   */
  start_date_time: string;
}
/**
 *
 * @export
 * @interface StripeIdentityCheckVerificationSession
 */
export interface StripeIdentityCheckVerificationSession {
  /**
   *
   * @type {string}
   * @memberof StripeIdentityCheckVerificationSession
   */
  client_secret: string;
}
/**
 *
 * @export
 * @interface StripeIdentityScreen
 */
export interface StripeIdentityScreen {
  /**
   *
   * @type {number}
   * @memberof StripeIdentityScreen
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof StripeIdentityScreen
   */
  verification_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeIdentityScreen
   */
  client_secret?: string | null;
  /**
   *
   * @type {string}
   * @memberof StripeIdentityScreen
   */
  stripe_status?: string | null;
}
/**
 *
 * @export
 * @interface StripeIdentityTask
 */
export interface StripeIdentityTask {
  /**
   *
   * @type {number}
   * @memberof StripeIdentityTask
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof StripeIdentityTask
   */
  task_type: string;
}
/**
 *
 * @export
 * @interface StripeInitiationFeeScreen
 */
export interface StripeInitiationFeeScreen {
  /**
   *
   * @type {number}
   * @memberof StripeInitiationFeeScreen
   */
  id: number;
}
/**
 *
 * @export
 * @interface StripeInvitationPayment
 */
export interface StripeInvitationPayment {
  /**
   *
   * @type {PaymentMethodSecret}
   * @memberof StripeInvitationPayment
   */
  card: PaymentMethodSecret;
}
/**
 *
 * @export
 * @interface StripeNodalMatchingPayment
 */
export interface StripeNodalMatchingPayment {
  /**
   *
   * @type {PaymentMethodSecret}
   * @memberof StripeNodalMatchingPayment
   */
  card: PaymentMethodSecret;
  /**
   *
   * @type {PaymentMethodSecret}
   * @memberof StripeNodalMatchingPayment
   */
  us_bank_account: PaymentMethodSecret;
}
/**
 *
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   *
   * @type {Plan}
   * @memberof Subscription
   */
  plan: Plan;
  /**
   *
   * @type {PaymentTypeEnum}
   * @memberof Subscription
   */
  payment_type?: PaymentTypeEnum;
  /**
   *
   * @type {SubscriptionStatusEnum}
   * @memberof Subscription
   */
  status?: SubscriptionStatusEnum;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof Subscription
   */
  stripe_payment_method?: { [key: string]: any } | null;
  /**
   *
   * @type {string}
   * @memberof Subscription
   */
  stripe_subscription_id?: string | null;
}
/**
 * @type SubscriptionObject
 * @export
 */
export type SubscriptionObject = Subscription;

/**
 *
 * @export
 * @enum {string}
 */

export const SubscriptionStatusEnum = {
  Active: 'active',
  Processing: 'processing',
  PaymentFailed: 'payment_failed',
  SubscriptionFailed: 'subscription_failed',
  Inactive: 'inactive',
} as const;

export type SubscriptionStatusEnum =
  typeof SubscriptionStatusEnum[keyof typeof SubscriptionStatusEnum];

/**
 *
 * @export
 * @interface SuperAdminProfile
 */
export interface SuperAdminProfile {
  /**
   *
   * @type {number}
   * @memberof SuperAdminProfile
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SuperAdminProfile
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof SuperAdminProfile
   */
  middle_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof SuperAdminProfile
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof SuperAdminProfile
   */
  title?: string | null;
  /**
   *
   * @type {InsuranceReview}
   * @memberof SuperAdminProfile
   */
  insurance_review?: InsuranceReview;
}
/**
 * Serializer to serialize details of support team member.
 * @export
 * @interface SupportTeamUser
 */
export interface SupportTeamUser {
  /**
   *
   * @type {number}
   * @memberof SupportTeamUser
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SupportTeamUser
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupportTeamUser
   */
  last_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupportTeamUser
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof SupportTeamUser
   */
  display_photo_url: string | null;
}
/**
 *
 * @export
 * @interface Task
 */
export interface Task {
  /**
   *
   * @type {number}
   * @memberof Task
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  uid: string;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof Task
   */
  order?: number;
  /**
   *
   * @type {number}
   * @memberof Task
   */
  user: number;
  /**
   *
   * @type {TaskStageEnum}
   * @memberof Task
   */
  stage?: TaskStageEnum;
  /**
   *
   * @type {TaskStatusEnum}
   * @memberof Task
   */
  status?: TaskStatusEnum;
  /**
   *
   * @type {TaskContentObject}
   * @memberof Task
   */
  content_object: TaskContentObject;
  /**
   *
   * @type {string}
   * @memberof Task
   */
  completion_date?: string | null;
  /**
   *
   * @type {CompletionTypeEnum}
   * @memberof Task
   */
  completion_type?: CompletionTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof Task
   */
  prerequisite_uids?: Array<string>;
}
/**
 * @type TaskContentObject
 * @export
 */
export type TaskContentObject =
  | CheckrTask
  | IpContractTask
  | IpFinalMatchPaymentTask
  | IpMatchProfileTask
  | IpMatchingPaymentTask
  | IpNodalApplicationTask
  | IpPersonalInformationTask
  | IpReviewCallPreparationTask
  | IpReviewRunbookTask
  | IpSignMatchAgreementTask
  | IpSignMatchSheetTask
  | IpSupportCallTask
  | StaffCompletionIpFinalMatchPaymentTask
  | StaffCompletionIpSupportCallTask
  | StripeIdentityTask;

/**
 *
 * @export
 * @enum {string}
 */

export const TaskStageEnum = {
  Screening: 'screening',
  MatchProfile: 'match_profile',
  Introduction: 'introduction',
  Match: 'match',
} as const;

export type TaskStageEnum = typeof TaskStageEnum[keyof typeof TaskStageEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const TaskStatusEnum = {
  Pending: 'pending',
  Processing: 'processing',
  Complete: 'complete',
  Review: 'review',
  Rejected: 'rejected',
} as const;

export type TaskStatusEnum = typeof TaskStatusEnum[keyof typeof TaskStatusEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const TerminationChoice = {
  OnMedicAdvice: 'on_medic_advice',
  AtAMatchRequest: 'at_a_match_request',
  NotWilling: 'not_willing',
} as const;

export type TerminationChoice =
  typeof TerminationChoice[keyof typeof TerminationChoice];

/**
 *
 * @export
 * @enum {string}
 */

export const ThirdPartyProviderEnum = {
  Datavant: 'datavant',
  Pluto: 'pluto',
  Fax: 'fax',
} as const;

export type ThirdPartyProviderEnum =
  typeof ThirdPartyProviderEnum[keyof typeof ThirdPartyProviderEnum];

/**
 *
 * @export
 * @interface TokenRefresh
 */
export interface TokenRefresh {
  /**
   *
   * @type {string}
   * @memberof TokenRefresh
   */
  access: string;
  /**
   *
   * @type {string}
   * @memberof TokenRefresh
   */
  refresh: string;
}
/**
 *
 * @export
 * @interface TokenVerify
 */
export interface TokenVerify {
  /**
   *
   * @type {string}
   * @memberof TokenVerify
   */
  token: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TraditionalSmokingHistoryEnum = {
  Never: 'never',
  CurrentUser: 'current_user',
  Yes16MonthsAgo: 'yes_1_6_months_ago',
  Yes612MonthsAgo: 'yes_6_12_months_ago',
  YesOver12MonthsAgo: 'yes_over_12_months_ago',
} as const;

export type TraditionalSmokingHistoryEnum =
  typeof TraditionalSmokingHistoryEnum[keyof typeof TraditionalSmokingHistoryEnum];

/**
 *
 * @export
 * @enum {string}
 */

export const TypeEnum = {
  Auto: 'auto',
  Manual: 'manual',
} as const;

export type TypeEnum = typeof TypeEnum[keyof typeof TypeEnum];

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id: number;
  /**
   * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
   * @type {string}
   * @memberof User
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {UserRoleEnum}
   * @memberof User
   */
  role: UserRoleEnum;
  /**
   *
   * @type {UserStatusEnum}
   * @memberof User
   */
  status: UserStatusEnum;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  show_matching_badge?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  requires_staff_review: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  passed_screens: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  half_passed_screens: boolean;
  /**
   *
   * @type {Array<UserProfileOwnPhoto>}
   * @memberof User
   */
  profile_photos: Array<UserProfileOwnPhoto>;
  /**
   *
   * @type {ProfileObject}
   * @memberof User
   */
  profile: ProfileObject;
  /**
   *
   * @type {number}
   * @memberof User
   */
  introductions_left: number;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  has_nodal_services_payment_completed: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  show_paywall: boolean;
  /**
   *
   * @type {number}
   * @memberof User
   */
  medical_review_id: number;
}
/**
 *
 * @export
 * @interface UserFavoriteParents
 */
export interface UserFavoriteParents {
  /**
   *
   * @type {number}
   * @memberof UserFavoriteParents
   */
  donor: number;
  /**
   *
   * @type {number}
   * @memberof UserFavoriteParents
   */
  liked_parent: number;
  /**
   *
   * @type {MarketplaceParents}
   * @memberof UserFavoriteParents
   */
  parent_profile: MarketplaceParents;
  /**
   *
   * @type {string}
   * @memberof UserFavoriteParents
   */
  created: string;
}
/**
 *
 * @export
 * @interface UserFromIntroductionScreen
 */
export interface UserFromIntroductionScreen {
  /**
   *
   * @type {number}
   * @memberof UserFromIntroductionScreen
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserFromIntroductionScreen
   */
  title?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserFromIntroductionScreen
   */
  uid: string;
  /**
   *
   * @type {string}
   * @memberof UserFromIntroductionScreen
   */
  type: string;
  /**
   *
   * @type {ScreenStatusEnum}
   * @memberof UserFromIntroductionScreen
   */
  status?: ScreenStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UserFromIntroductionScreen
   */
  completion_date?: string | null;
}
/**
 *
 * @export
 * @interface UserNested
 */
export interface UserNested {
  /**
   *
   * @type {number}
   * @memberof UserNested
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserNested
   */
  first_name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserNested
   */
  last_name?: string | null;
  /**
   *
   * @type {Array<UserProfileOwnPhoto>}
   * @memberof UserNested
   */
  profile_photos: Array<UserProfileOwnPhoto>;
}
/**
 *
 * @export
 * @interface UserNote
 */
export interface UserNote {
  /**
   *
   * @type {TypeEnum}
   * @memberof UserNote
   */
  type?: TypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserNote
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof UserNote
   */
  user?: number | null;
  /**
   *
   * @type {string}
   * @memberof UserNote
   */
  created: string;
  /**
   *
   * @type {number}
   * @memberof UserNote
   */
  id: number;
}
/**
 *
 * @export
 * @interface UserNoteEditCreate
 */
export interface UserNoteEditCreate {
  /**
   *
   * @type {TypeEnum}
   * @memberof UserNoteEditCreate
   */
  type?: TypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserNoteEditCreate
   */
  content?: string;
  /**
   *
   * @type {number}
   * @memberof UserNoteEditCreate
   */
  user: number;
  /**
   *
   * @type {string}
   * @memberof UserNoteEditCreate
   */
  created: string;
  /**
   *
   * @type {number}
   * @memberof UserNoteEditCreate
   */
  id: number;
}
/**
 *
 * @export
 * @interface UserProfileOwnPhoto
 */
export interface UserProfileOwnPhoto {
  /**
   *
   * @type {number}
   * @memberof UserProfileOwnPhoto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserProfileOwnPhoto
   */
  image: string;
  /**
   *
   * @type {string}
   * @memberof UserProfileOwnPhoto
   */
  image_url: string | null;
  /**
   *
   * @type {number}
   * @memberof UserProfileOwnPhoto
   */
  order?: number;
}
/**
 *
 * @export
 * @interface UserProfilePhoto
 */
export interface UserProfilePhoto {
  /**
   *
   * @type {number}
   * @memberof UserProfilePhoto
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof UserProfilePhoto
   */
  image: string;
  /**
   *
   * @type {string}
   * @memberof UserProfilePhoto
   */
  image_url: string | null;
  /**
   *
   * @type {number}
   * @memberof UserProfilePhoto
   */
  order?: number;
}
/**
 *
 * @export
 * @interface UserProfileView
 */
export interface UserProfileView {
  /**
   *
   * @type {number}
   * @memberof UserProfileView
   */
  viewer: number;
  /**
   *
   * @type {number}
   * @memberof UserProfileView
   */
  viewee: number;
  /**
   *
   * @type {string}
   * @memberof UserProfileView
   */
  created: string;
}
/**
 *
 * @export
 * @interface UserProfileViewCount
 */
export interface UserProfileViewCount {
  /**
   *
   * @type {number}
   * @memberof UserProfileViewCount
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof UserProfileViewCount
   */
  unique_count: number;
}
/**
 *
 * @export
 * @interface UserProfileViewDetailed
 */
export interface UserProfileViewDetailed {
  /**
   *
   * @type {UserNested}
   * @memberof UserProfileViewDetailed
   */
  viewer: UserNested;
  /**
   *
   * @type {UserNested}
   * @memberof UserProfileViewDetailed
   */
  viewee: UserNested;
  /**
   *
   * @type {string}
   * @memberof UserProfileViewDetailed
   */
  created: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const UserRoleEnum = {
  Dnr: 'dnr',
  Dnm: 'dnm',
  Par: 'par',
  Advr: 'advr',
  Mgr: 'mgr',
  Oad: 'oad',
  Opa: 'opa',
  Oss: 'oss',
  Oms: 'oms',
  Odo: 'odo',
  Oed: 'oed',
  Osd: 'osd',
  Oextp: 'oextp',
  Sad: 'sad',
  Nap: 'nap',
} as const;

export type UserRoleEnum = typeof UserRoleEnum[keyof typeof UserRoleEnum];

/**
 *
 * @export
 * @interface UserStage
 */
export interface UserStage {
  /**
   *
   * @type {UserStageForcedTasksStage}
   * @memberof UserStage
   */
  forced_tasks_stage: UserStageForcedTasksStage | null;
  /**
   *
   * @type {TaskStageEnum}
   * @memberof UserStage
   */
  calculated_task_stage: TaskStageEnum;
}
/**
 * @type UserStageForcedTasksStage
 * @export
 */
export type UserStageForcedTasksStage = NullEnum | TaskStageEnum;

/**
 *
 * @export
 * @enum {string}
 */

export const UserStatusEnum = {
  Dis: 'dis',
  Scr: 'scr',
  'Scr+': 'scr+',
  Mat: 'mat',
  'Mat+': 'mat+',
  Dea: 'dea',
  Nac: 'nac',
} as const;

export type UserStatusEnum = typeof UserStatusEnum[keyof typeof UserStatusEnum];

/**
 * Serializer to serialize list of assigned support team members.
 * @export
 * @interface UserSupportTeamUser
 */
export interface UserSupportTeamUser {
  /**
   *
   * @type {number}
   * @memberof UserSupportTeamUser
   */
  id: number;
  /**
   *
   * @type {Array<SupportTeamUser>}
   * @memberof UserSupportTeamUser
   */
  assigned_support_team_members: Array<SupportTeamUser>;
  /**
   *
   * @type {Array<number>}
   * @memberof UserSupportTeamUser
   */
  assigned_support_team_members_ids: Array<number>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const UsingDonorEnum = {
  No: 'no',
  Egg: 'egg',
  Spe: 'spe',
  Bot: 'bot',
  Doc: 'doc',
} as const;

export type UsingDonorEnum = typeof UsingDonorEnum[keyof typeof UsingDonorEnum];

/**
 *
 * @export
 * @interface VerifyEmail
 */
export interface VerifyEmail {
  /**
   *
   * @type {string}
   * @memberof VerifyEmail
   */
  key: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const WeightEnum = {
  Review: 'review',
  SoftReview: 'soft_review',
  Autoreject: 'autoreject',
} as const;

export type WeightEnum = typeof WeightEnum[keyof typeof WeightEnum];

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {Login} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginCreate: async (
      login: Login,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'login' is not null or undefined
      assertParamExists('authLoginCreate', 'login', login);
      const localVarPath = `/api/auth/login/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        login,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogoutCreate: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/logout/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChange} passwordChange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authPasswordChangeCreate: async (
      passwordChange: PasswordChange,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordChange' is not null or undefined
      assertParamExists(
        'authPasswordChangeCreate',
        'passwordChange',
        passwordChange,
      );
      const localVarPath = `/api/auth/password/change/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordChange,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirm} passwordResetConfirm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authPasswordResetConfirmCreate: async (
      passwordResetConfirm: PasswordResetConfirm,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordResetConfirm' is not null or undefined
      assertParamExists(
        'authPasswordResetConfirmCreate',
        'passwordResetConfirm',
        passwordResetConfirm,
      );
      const localVarPath = `/api/auth/password/reset/confirm/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordResetConfirm,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param {PasswordReset} passwordReset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authPasswordResetCreate: async (
      passwordReset: PasswordReset,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'passwordReset' is not null or undefined
      assertParamExists(
        'authPasswordResetCreate',
        'passwordReset',
        passwordReset,
      );
      const localVarPath = `/api/auth/password/reset/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        passwordReset,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefresh} tokenRefresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authTokenRefreshCreate: async (
      tokenRefresh: TokenRefresh,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenRefresh' is not null or undefined
      assertParamExists('authTokenRefreshCreate', 'tokenRefresh', tokenRefresh);
      const localVarPath = `/api/auth/token/refresh/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tokenRefresh,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerify} tokenVerify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authTokenVerifyCreate: async (
      tokenVerify: TokenVerify,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'tokenVerify' is not null or undefined
      assertParamExists('authTokenVerifyCreate', 'tokenVerify', tokenVerify);
      const localVarPath = `/api/auth/token/verify/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tokenVerify,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {PatchedUser} [patchedUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authUserPartialUpdate: async (
      patchedUser?: PatchedUser,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/user/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedUser,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authUserRetrieve: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/auth/user/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authUserUpdate: async (
      user: User,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'user' is not null or undefined
      assertParamExists('authUserUpdate', 'user', user);
      const localVarPath = `/api/auth/user/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        user,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {Login} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLoginCreate(
      login: Login,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWT>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginCreate(
        login,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authLogoutCreate(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authLogoutCreate(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChange} passwordChange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authPasswordChangeCreate(
      passwordChange: PasswordChange,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authPasswordChangeCreate(
          passwordChange,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirm} passwordResetConfirm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authPasswordResetConfirmCreate(
      passwordResetConfirm: PasswordResetConfirm,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authPasswordResetConfirmCreate(
          passwordResetConfirm,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param {PasswordReset} passwordReset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authPasswordResetCreate(
      passwordReset: PasswordReset,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authPasswordResetCreate(
          passwordReset,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefresh} tokenRefresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authTokenRefreshCreate(
      tokenRefresh: TokenRefresh,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authTokenRefreshCreate(
          tokenRefresh,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerify} tokenVerify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authTokenVerifyCreate(
      tokenVerify: TokenVerify,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenVerify>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authTokenVerifyCreate(
          tokenVerify,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {PatchedUser} [patchedUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authUserPartialUpdate(
      patchedUser?: PatchedUser,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authUserPartialUpdate(
          patchedUser,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authUserRetrieve(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.authUserRetrieve(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authUserUpdate(
      user: User,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authUserUpdate(
        user,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {Login} login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLoginCreate(login: Login, options?: any): AxiosPromise<JWT> {
      return localVarFp
        .authLoginCreate(login, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authLogoutCreate(options?: any): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .authLogoutCreate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChange} passwordChange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authPasswordChangeCreate(
      passwordChange: PasswordChange,
      options?: any,
    ): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .authPasswordChangeCreate(passwordChange, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirm} passwordResetConfirm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authPasswordResetConfirmCreate(
      passwordResetConfirm: PasswordResetConfirm,
      options?: any,
    ): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .authPasswordResetConfirmCreate(passwordResetConfirm, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param {PasswordReset} passwordReset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authPasswordResetCreate(
      passwordReset: PasswordReset,
      options?: any,
    ): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .authPasswordResetCreate(passwordReset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {TokenRefresh} tokenRefresh
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authTokenRefreshCreate(
      tokenRefresh: TokenRefresh,
      options?: any,
    ): AxiosPromise<TokenRefresh> {
      return localVarFp
        .authTokenRefreshCreate(tokenRefresh, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
     * @param {TokenVerify} tokenVerify
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authTokenVerifyCreate(
      tokenVerify: TokenVerify,
      options?: any,
    ): AxiosPromise<TokenVerify> {
      return localVarFp
        .authTokenVerifyCreate(tokenVerify, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {PatchedUser} [patchedUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authUserPartialUpdate(
      patchedUser?: PatchedUser,
      options?: any,
    ): AxiosPromise<User> {
      return localVarFp
        .authUserPartialUpdate(patchedUser, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authUserRetrieve(options?: any): AxiosPromise<User> {
      return localVarFp
        .authUserRetrieve(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authUserUpdate(user: User, options?: any): AxiosPromise<User> {
      return localVarFp
        .authUserUpdate(user, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for authLoginCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthLoginCreateRequest
 */
export interface AuthApiAuthLoginCreateRequest {
  /**
   *
   * @type {Login}
   * @memberof AuthApiAuthLoginCreate
   */
  readonly login: Login;
}

/**
 * Request parameters for authPasswordChangeCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthPasswordChangeCreateRequest
 */
export interface AuthApiAuthPasswordChangeCreateRequest {
  /**
   *
   * @type {PasswordChange}
   * @memberof AuthApiAuthPasswordChangeCreate
   */
  readonly passwordChange: PasswordChange;
}

/**
 * Request parameters for authPasswordResetConfirmCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthPasswordResetConfirmCreateRequest
 */
export interface AuthApiAuthPasswordResetConfirmCreateRequest {
  /**
   *
   * @type {PasswordResetConfirm}
   * @memberof AuthApiAuthPasswordResetConfirmCreate
   */
  readonly passwordResetConfirm: PasswordResetConfirm;
}

/**
 * Request parameters for authPasswordResetCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthPasswordResetCreateRequest
 */
export interface AuthApiAuthPasswordResetCreateRequest {
  /**
   *
   * @type {PasswordReset}
   * @memberof AuthApiAuthPasswordResetCreate
   */
  readonly passwordReset: PasswordReset;
}

/**
 * Request parameters for authTokenRefreshCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthTokenRefreshCreateRequest
 */
export interface AuthApiAuthTokenRefreshCreateRequest {
  /**
   *
   * @type {TokenRefresh}
   * @memberof AuthApiAuthTokenRefreshCreate
   */
  readonly tokenRefresh: TokenRefresh;
}

/**
 * Request parameters for authTokenVerifyCreate operation in AuthApi.
 * @export
 * @interface AuthApiAuthTokenVerifyCreateRequest
 */
export interface AuthApiAuthTokenVerifyCreateRequest {
  /**
   *
   * @type {TokenVerify}
   * @memberof AuthApiAuthTokenVerifyCreate
   */
  readonly tokenVerify: TokenVerify;
}

/**
 * Request parameters for authUserPartialUpdate operation in AuthApi.
 * @export
 * @interface AuthApiAuthUserPartialUpdateRequest
 */
export interface AuthApiAuthUserPartialUpdateRequest {
  /**
   *
   * @type {PatchedUser}
   * @memberof AuthApiAuthUserPartialUpdate
   */
  readonly patchedUser?: PatchedUser;
}

/**
 * Request parameters for authUserUpdate operation in AuthApi.
 * @export
 * @interface AuthApiAuthUserUpdateRequest
 */
export interface AuthApiAuthUserUpdateRequest {
  /**
   *
   * @type {User}
   * @memberof AuthApiAuthUserUpdate
   */
  readonly user: User;
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
   * @param {AuthApiAuthLoginCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLoginCreate(
    requestParameters: AuthApiAuthLoginCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authLoginCreate(requestParameters.login, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authLogoutCreate(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authLogoutCreate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
   * @param {AuthApiAuthPasswordChangeCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authPasswordChangeCreate(
    requestParameters: AuthApiAuthPasswordChangeCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authPasswordChangeCreate(requestParameters.passwordChange, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
   * @param {AuthApiAuthPasswordResetConfirmCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authPasswordResetConfirmCreate(
    requestParameters: AuthApiAuthPasswordResetConfirmCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authPasswordResetConfirmCreate(
        requestParameters.passwordResetConfirm,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
   * @param {AuthApiAuthPasswordResetCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authPasswordResetCreate(
    requestParameters: AuthApiAuthPasswordResetCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authPasswordResetCreate(requestParameters.passwordReset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
   * @param {AuthApiAuthTokenRefreshCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authTokenRefreshCreate(
    requestParameters: AuthApiAuthTokenRefreshCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authTokenRefreshCreate(requestParameters.tokenRefresh, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Takes a token and indicates if it is valid.  This view provides no information about a token\'s fitness for a particular use.
   * @param {AuthApiAuthTokenVerifyCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authTokenVerifyCreate(
    requestParameters: AuthApiAuthTokenVerifyCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authTokenVerifyCreate(requestParameters.tokenVerify, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {AuthApiAuthUserPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authUserPartialUpdate(
    requestParameters: AuthApiAuthUserPartialUpdateRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authUserPartialUpdate(requestParameters.patchedUser, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authUserRetrieve(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .authUserRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
   * @param {AuthApiAuthUserUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public authUserUpdate(
    requestParameters: AuthApiAuthUserUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return AuthApiFp(this.configuration)
      .authUserUpdate(requestParameters.user, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * B2bApi - axios parameter creator
 * @export
 */
export const B2bApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bDashboardMatchingRetrieve: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/b2b/dashboard/matching/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [donorParentPartnerName]
     * @param {boolean} [hasCallScheduled]
     * @param {Array<'-display_priority' | '-donor_first_name' | '-expiration_date' | '-parent_first_name' | '-stage' | 'display_priority' | 'donor_first_name' | 'expiration_date' | 'parent_first_name' | 'stage'>} [ordering] Ordering
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'broken' | 'expired' | 'intros' | 'match_acceptance' | 'matched'>} [stage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bIntroductionsList: async (
      donorParentPartnerName?: string,
      hasCallScheduled?: boolean,
      ordering?: Array<
        | '-display_priority'
        | '-donor_first_name'
        | '-expiration_date'
        | '-parent_first_name'
        | '-stage'
        | 'display_priority'
        | 'donor_first_name'
        | 'expiration_date'
        | 'parent_first_name'
        | 'stage'
      >,
      page?: number,
      pageSize?: number,
      stage?: Array<
        'broken' | 'expired' | 'intros' | 'match_acceptance' | 'matched'
      >,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/b2b/introductions/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (donorParentPartnerName !== undefined) {
        localVarQueryParameter['donor_parent_partner_name'] =
          donorParentPartnerName;
      }

      if (hasCallScheduled !== undefined) {
        localVarQueryParameter['has_call_scheduled'] = hasCallScheduled;
      }

      if (ordering) {
        localVarQueryParameter['ordering'] = ordering.join(
          COLLECTION_FORMATS.csv,
        );
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (stage) {
        localVarQueryParameter['stage'] = stage;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsCancelCreate: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bInvitationsCancelCreate', 'id', id);
      const localVarPath = `/api/b2b/invitations/{id}/cancel/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {InvitationList} [invitationList]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsCreate: async (
      invitationList?: InvitationList,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/b2b/invitations/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        invitationList,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsExternalPayerRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bInvitationsExternalPayerRetrieve', 'id', id);
      const localVarPath = `/api/b2b/invitations/external-payer/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {InvitationFinish} invitationFinish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsFinishCreate: async (
      invitationFinish: InvitationFinish,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'invitationFinish' is not null or undefined
      assertParamExists(
        'b2bInvitationsFinishCreate',
        'invitationFinish',
        invitationFinish,
      );
      const localVarPath = `/api/b2b/invitations/finish/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        invitationFinish,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} [isPaid]
     * @param {Array<'-created' | '-search_name_display' | '-status' | 'created' | 'search_name_display' | 'status'>} [ordering] Ordering
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'-' | 'ext_parents' | 'org' | 'prepaid'>} [payingEntity]
     * @param {string} [searchNameDisplay]
     * @param {Array<'accepted' | 'canceled' | 'not_sent' | 'sent'>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsList: async (
      isPaid?: boolean,
      ordering?: Array<
        | '-created'
        | '-search_name_display'
        | '-status'
        | 'created'
        | 'search_name_display'
        | 'status'
      >,
      page?: number,
      pageSize?: number,
      payingEntity?: Array<'-' | 'ext_parents' | 'org' | 'prepaid'>,
      searchNameDisplay?: string,
      status?: Array<'accepted' | 'canceled' | 'not_sent' | 'sent'>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/b2b/invitations/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (isPaid !== undefined) {
        localVarQueryParameter['is_paid'] = isPaid;
      }

      if (ordering) {
        localVarQueryParameter['ordering'] = ordering.join(
          COLLECTION_FORMATS.csv,
        );
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (payingEntity) {
        localVarQueryParameter['paying_entity'] = payingEntity;
      }

      if (searchNameDisplay !== undefined) {
        localVarQueryParameter['search_name_display'] = searchNameDisplay;
      }

      if (status) {
        localVarQueryParameter['status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedInvitation} [patchedInvitation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsPartialUpdate: async (
      id: number,
      patchedInvitation?: PatchedInvitation,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bInvitationsPartialUpdate', 'id', id);
      const localVarPath = `/api/b2b/invitations/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedInvitation,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsResendCreate: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bInvitationsResendCreate', 'id', id);
      const localVarPath = `/api/b2b/invitations/{id}/resend/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bInvitationsRetrieve', 'id', id);
      const localVarPath = `/api/b2b/invitations/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {InvitationScreeningPackage} [invitationScreeningPackage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsScreeningPackageCreate: async (
      id: number,
      invitationScreeningPackage?: InvitationScreeningPackage,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bInvitationsScreeningPackageCreate', 'id', id);
      const localVarPath =
        `/api/b2b/invitations/{id}/screening_package/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        invitationScreeningPackage,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsSendExternalPaymentLinkCreate: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'b2bInvitationsSendExternalPaymentLinkCreate',
        'id',
        id,
      );
      const localVarPath =
        `/api/b2b/invitations/{id}/send-external-payment-link/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsSubmitCreate: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bInvitationsSubmitCreate', 'id', id);
      const localVarPath = `/api/b2b/invitations/{id}/submit/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Invitation} [invitation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsUpdate: async (
      id: number,
      invitation?: Invitation,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bInvitationsUpdate', 'id', id);
      const localVarPath = `/api/b2b/invitations/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        invitation,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/b2b/organizations/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [name]
     * @param {Array<'-created' | '-name' | '-total_active_sent' | '-type' | 'created' | 'name' | 'total_active_sent' | 'type'>} [ordering] Ordering
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsNonDetailedList: async (
      name?: string,
      ordering?: Array<
        | '-created'
        | '-name'
        | '-total_active_sent'
        | '-type'
        | 'created'
        | 'name'
        | 'total_active_sent'
        | 'type'
      >,
      page?: number,
      pageSize?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/b2b/organizations-non-detailed/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (name !== undefined) {
        localVarQueryParameter['name'] = name;
      }

      if (ordering) {
        localVarQueryParameter['ordering'] = ordering.join(
          COLLECTION_FORMATS.csv,
        );
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedOrganization} [patchedOrganization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsPartialUpdate: async (
      id: number,
      patchedOrganization?: PatchedOrganization,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bOrganizationsPartialUpdate', 'id', id);
      const localVarPath = `/api/b2b/organizations/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedOrganization,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bOrganizationsRetrieve', 'id', id);
      const localVarPath = `/api/b2b/organizations/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Organization} organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsUpdate: async (
      id: number,
      organization: Organization,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bOrganizationsUpdate', 'id', id);
      // verify required parameter 'organization' is not null or undefined
      assertParamExists('b2bOrganizationsUpdate', 'organization', organization);
      const localVarPath = `/api/b2b/organizations/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        organization,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bRoleScreensList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/b2b/role-screens/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedB2BUserDisqualify} [patchedB2BUserDisqualify]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserDisqualifyPartialUpdate: async (
      id: number,
      patchedB2BUserDisqualify?: PatchedB2BUserDisqualify,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUserDisqualifyPartialUpdate', 'id', id);
      const localVarPath = `/api/b2b/user/disqualify/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedB2BUserDisqualify,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {B2BUserDisqualify} [b2BUserDisqualify]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserDisqualifyUpdate: async (
      id: number,
      b2BUserDisqualify?: B2BUserDisqualify,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUserDisqualifyUpdate', 'id', id);
      const localVarPath = `/api/b2b/user/disqualify/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        b2BUserDisqualify,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedDonorProfileFinalReview} [patchedDonorProfileFinalReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfileFinalReviewPartialUpdate: async (
      id: number,
      patchedDonorProfileFinalReview?: PatchedDonorProfileFinalReview,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUserProfileFinalReviewPartialUpdate', 'id', id);
      const localVarPath = `/api/b2b/user-profile/{id}/final-review/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedDonorProfileFinalReview,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {DonorProfileFinalReview} [donorProfileFinalReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfileFinalReviewUpdate: async (
      id: number,
      donorProfileFinalReview?: DonorProfileFinalReview,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUserProfileFinalReviewUpdate', 'id', id);
      const localVarPath = `/api/b2b/user-profile/{id}/final-review/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        donorProfileFinalReview,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedProfileObject} [patchedProfileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfilePartialUpdate: async (
      id: number,
      patchedProfileObject?: PatchedProfileObject,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUserProfilePartialUpdate', 'id', id);
      const localVarPath = `/api/b2b/user-profile/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedProfileObject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfileRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUserProfileRetrieve', 'id', id);
      const localVarPath = `/api/b2b/user-profile/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {ProfileObject} [profileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfileUpdate: async (
      id: number,
      profileObject?: ProfileObject,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUserProfileUpdate', 'id', id);
      const localVarPath = `/api/b2b/user-profile/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        profileObject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserTasksList: async (
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('b2bUserTasksList', 'userId', userId);
      const localVarPath = `/api/b2b/user/{user_id}/tasks/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {boolean} [applicationFlaggedToReview]
     * @param {boolean} [billingCompleted]
     * @param {Array<'application' | 'checkr' | 'complete' | 'deactivated' | 'final_review' | 'hipaa_consent_form' | 'id_check' | 'matching' | 'medical_form' | 'not_registered' | 'personal_information' | 'rejected'>} [candidateSteps]
     * @param {boolean} [emptyScreens]
     * @param {boolean} [matchProfileToReview]
     * @param {Array<'finished' | 'in_progress' | 'not_applicable' | 'not_started' | 'rejected'>} [medicalReviewStatus]
     * @param {Array<'-billing_status' | '-date_joined' | '-display_priority' | '-medical_review_status' | '-search_name_display' | '-status' | 'billing_status' | 'date_joined' | 'display_priority' | 'medical_review_status' | 'search_name_display' | 'status'>} [ordering] Ordering
     * @param {number} [organizationId]
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'advr' | 'dnm' | 'dnr' | 'mgr' | 'nap' | 'oad' | 'odo' | 'oed' | 'oextp' | 'oms' | 'opa' | 'osd' | 'oss' | 'par' | 'sad'>} [role]
     * @param {string} [searchNameDisplay]
     * @param {Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersList: async (
      applicationFlaggedToReview?: boolean,
      billingCompleted?: boolean,
      candidateSteps?: Array<
        | 'application'
        | 'checkr'
        | 'complete'
        | 'deactivated'
        | 'final_review'
        | 'hipaa_consent_form'
        | 'id_check'
        | 'matching'
        | 'medical_form'
        | 'not_registered'
        | 'personal_information'
        | 'rejected'
      >,
      emptyScreens?: boolean,
      matchProfileToReview?: boolean,
      medicalReviewStatus?: Array<
        | 'finished'
        | 'in_progress'
        | 'not_applicable'
        | 'not_started'
        | 'rejected'
      >,
      ordering?: Array<
        | '-billing_status'
        | '-date_joined'
        | '-display_priority'
        | '-medical_review_status'
        | '-search_name_display'
        | '-status'
        | 'billing_status'
        | 'date_joined'
        | 'display_priority'
        | 'medical_review_status'
        | 'search_name_display'
        | 'status'
      >,
      organizationId?: number,
      page?: number,
      pageSize?: number,
      role?: Array<
        | 'advr'
        | 'dnm'
        | 'dnr'
        | 'mgr'
        | 'nap'
        | 'oad'
        | 'odo'
        | 'oed'
        | 'oextp'
        | 'oms'
        | 'opa'
        | 'osd'
        | 'oss'
        | 'par'
        | 'sad'
      >,
      searchNameDisplay?: string,
      status?: Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/b2b/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (applicationFlaggedToReview !== undefined) {
        localVarQueryParameter['application_flagged_to_review'] =
          applicationFlaggedToReview;
      }

      if (billingCompleted !== undefined) {
        localVarQueryParameter['billing_completed'] = billingCompleted;
      }

      if (candidateSteps) {
        localVarQueryParameter['candidate_steps'] = candidateSteps;
      }

      if (emptyScreens !== undefined) {
        localVarQueryParameter['empty_screens'] = emptyScreens;
      }

      if (matchProfileToReview !== undefined) {
        localVarQueryParameter['match_profile_to_review'] =
          matchProfileToReview;
      }

      if (medicalReviewStatus) {
        localVarQueryParameter['medical_review_status'] = medicalReviewStatus;
      }

      if (ordering) {
        localVarQueryParameter['ordering'] = ordering.join(
          COLLECTION_FORMATS.csv,
        );
      }

      if (organizationId !== undefined) {
        localVarQueryParameter['organization_id'] = organizationId;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (role) {
        localVarQueryParameter['role'] = role;
      }

      if (searchNameDisplay !== undefined) {
        localVarQueryParameter['search_name_display'] = searchNameDisplay;
      }

      if (status) {
        localVarQueryParameter['status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {Array<'-date_joined' | '-display_priority' | '-email' | '-search_name_display' | '-status' | '-title' | 'date_joined' | 'display_priority' | 'email' | 'search_name_display' | 'status' | 'title'>} [ordering] Ordering
     * @param {number} [organizationId]
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'advr' | 'dnm' | 'dnr' | 'mgr' | 'nap' | 'oad' | 'odo' | 'oed' | 'oextp' | 'oms' | 'opa' | 'osd' | 'oss' | 'par' | 'sad'>} [role]
     * @param {string} [searchNameDisplay]
     * @param {Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersNonDetailedList: async (
      ordering?: Array<
        | '-date_joined'
        | '-display_priority'
        | '-email'
        | '-search_name_display'
        | '-status'
        | '-title'
        | 'date_joined'
        | 'display_priority'
        | 'email'
        | 'search_name_display'
        | 'status'
        | 'title'
      >,
      organizationId?: number,
      page?: number,
      pageSize?: number,
      role?: Array<
        | 'advr'
        | 'dnm'
        | 'dnr'
        | 'mgr'
        | 'nap'
        | 'oad'
        | 'odo'
        | 'oed'
        | 'oextp'
        | 'oms'
        | 'opa'
        | 'osd'
        | 'oss'
        | 'par'
        | 'sad'
      >,
      searchNameDisplay?: string,
      status?: Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/b2b/users-non-detailed/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (ordering) {
        localVarQueryParameter['ordering'] = ordering.join(
          COLLECTION_FORMATS.csv,
        );
      }

      if (organizationId !== undefined) {
        localVarQueryParameter['organization_id'] = organizationId;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['page_size'] = pageSize;
      }

      if (role) {
        localVarQueryParameter['role'] = role;
      }

      if (searchNameDisplay !== undefined) {
        localVarQueryParameter['search_name_display'] = searchNameDisplay;
      }

      if (status) {
        localVarQueryParameter['status'] = status;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedB2BUser} [patchedB2BUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersPartialUpdate: async (
      id: number,
      patchedB2BUser?: PatchedB2BUser,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUsersPartialUpdate', 'id', id);
      const localVarPath = `/api/b2b/users/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedB2BUser,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUsersRetrieve', 'id', id);
      const localVarPath = `/api/b2b/users/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {B2BUser} b2BUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersUpdate: async (
      id: number,
      b2BUser: B2BUser,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('b2bUsersUpdate', 'id', id);
      // verify required parameter 'b2BUser' is not null or undefined
      assertParamExists('b2bUsersUpdate', 'b2BUser', b2BUser);
      const localVarPath = `/api/b2b/users/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        b2BUser,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * B2bApi - functional programming interface
 * @export
 */
export const B2bApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = B2bApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bDashboardMatchingRetrieve(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<DashboardMatchingResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bDashboardMatchingRetrieve(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [donorParentPartnerName]
     * @param {boolean} [hasCallScheduled]
     * @param {Array<'-display_priority' | '-donor_first_name' | '-expiration_date' | '-parent_first_name' | '-stage' | 'display_priority' | 'donor_first_name' | 'expiration_date' | 'parent_first_name' | 'stage'>} [ordering] Ordering
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'broken' | 'expired' | 'intros' | 'match_acceptance' | 'matched'>} [stage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bIntroductionsList(
      donorParentPartnerName?: string,
      hasCallScheduled?: boolean,
      ordering?: Array<
        | '-display_priority'
        | '-donor_first_name'
        | '-expiration_date'
        | '-parent_first_name'
        | '-stage'
        | 'display_priority'
        | 'donor_first_name'
        | 'expiration_date'
        | 'parent_first_name'
        | 'stage'
      >,
      page?: number,
      pageSize?: number,
      stage?: Array<
        'broken' | 'expired' | 'intros' | 'match_acceptance' | 'matched'
      >,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedB2bIntroductionList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bIntroductionsList(
          donorParentPartnerName,
          hasCallScheduled,
          ordering,
          page,
          pageSize,
          stage,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsCancelCreate(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsCancelCreate(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {InvitationList} [invitationList]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsCreate(
      invitationList?: InvitationList,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsCreate(
          invitationList,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsExternalPayerRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InvitationExternalPayer>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsExternalPayerRetrieve(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {InvitationFinish} invitationFinish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsFinishCreate(
      invitationFinish: InvitationFinish,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsFinishCreate(
          invitationFinish,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {boolean} [isPaid]
     * @param {Array<'-created' | '-search_name_display' | '-status' | 'created' | 'search_name_display' | 'status'>} [ordering] Ordering
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'-' | 'ext_parents' | 'org' | 'prepaid'>} [payingEntity]
     * @param {string} [searchNameDisplay]
     * @param {Array<'accepted' | 'canceled' | 'not_sent' | 'sent'>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsList(
      isPaid?: boolean,
      ordering?: Array<
        | '-created'
        | '-search_name_display'
        | '-status'
        | 'created'
        | 'search_name_display'
        | 'status'
      >,
      page?: number,
      pageSize?: number,
      payingEntity?: Array<'-' | 'ext_parents' | 'org' | 'prepaid'>,
      searchNameDisplay?: string,
      status?: Array<'accepted' | 'canceled' | 'not_sent' | 'sent'>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedInvitationListList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsList(
          isPaid,
          ordering,
          page,
          pageSize,
          payingEntity,
          searchNameDisplay,
          status,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedInvitation} [patchedInvitation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsPartialUpdate(
      id: number,
      patchedInvitation?: PatchedInvitation,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsPartialUpdate(
          id,
          patchedInvitation,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsResendCreate(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsResendCreate(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {InvitationScreeningPackage} [invitationScreeningPackage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsScreeningPackageCreate(
      id: number,
      invitationScreeningPackage?: InvitationScreeningPackage,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<InvitationScreeningPackage>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsScreeningPackageCreate(
          id,
          invitationScreeningPackage,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsSendExternalPaymentLinkCreate(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsSendExternalPaymentLinkCreate(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsSubmitCreate(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsSubmitCreate(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {Invitation} [invitation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bInvitationsUpdate(
      id: number,
      invitation?: Invitation,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Invitation>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bInvitationsUpdate(
          id,
          invitation,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bOrganizationsList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<Organization>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bOrganizationsList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [name]
     * @param {Array<'-created' | '-name' | '-total_active_sent' | '-type' | 'created' | 'name' | 'total_active_sent' | 'type'>} [ordering] Ordering
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bOrganizationsNonDetailedList(
      name?: string,
      ordering?: Array<
        | '-created'
        | '-name'
        | '-total_active_sent'
        | '-type'
        | 'created'
        | 'name'
        | 'total_active_sent'
        | 'type'
      >,
      page?: number,
      pageSize?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedOrganizationNonDetailedList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bOrganizationsNonDetailedList(
          name,
          ordering,
          page,
          pageSize,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedOrganization} [patchedOrganization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bOrganizationsPartialUpdate(
      id: number,
      patchedOrganization?: PatchedOrganization,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bOrganizationsPartialUpdate(
          id,
          patchedOrganization,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bOrganizationsRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bOrganizationsRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {Organization} organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bOrganizationsUpdate(
      id: number,
      organization: Organization,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Organization>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bOrganizationsUpdate(
          id,
          organization,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bRoleScreensList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<RolesAllAvailableScreen>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bRoleScreensList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedB2BUserDisqualify} [patchedB2BUserDisqualify]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUserDisqualifyPartialUpdate(
      id: number,
      patchedB2BUserDisqualify?: PatchedB2BUserDisqualify,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<B2BUserDisqualify>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUserDisqualifyPartialUpdate(
          id,
          patchedB2BUserDisqualify,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {B2BUserDisqualify} [b2BUserDisqualify]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUserDisqualifyUpdate(
      id: number,
      b2BUserDisqualify?: B2BUserDisqualify,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<B2BUserDisqualify>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUserDisqualifyUpdate(
          id,
          b2BUserDisqualify,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedDonorProfileFinalReview} [patchedDonorProfileFinalReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUserProfileFinalReviewPartialUpdate(
      id: number,
      patchedDonorProfileFinalReview?: PatchedDonorProfileFinalReview,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FinalReviewObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUserProfileFinalReviewPartialUpdate(
          id,
          patchedDonorProfileFinalReview,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {DonorProfileFinalReview} [donorProfileFinalReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUserProfileFinalReviewUpdate(
      id: number,
      donorProfileFinalReview?: DonorProfileFinalReview,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FinalReviewObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUserProfileFinalReviewUpdate(
          id,
          donorProfileFinalReview,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedProfileObject} [patchedProfileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUserProfilePartialUpdate(
      id: number,
      patchedProfileObject?: PatchedProfileObject,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUserProfilePartialUpdate(
          id,
          patchedProfileObject,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUserProfileRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUserProfileRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {ProfileObject} [profileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUserProfileUpdate(
      id: number,
      profileObject?: ProfileObject,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUserProfileUpdate(
          id,
          profileObject,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUserTasksList(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Task>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUserTasksList(userId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {boolean} [applicationFlaggedToReview]
     * @param {boolean} [billingCompleted]
     * @param {Array<'application' | 'checkr' | 'complete' | 'deactivated' | 'final_review' | 'hipaa_consent_form' | 'id_check' | 'matching' | 'medical_form' | 'not_registered' | 'personal_information' | 'rejected'>} [candidateSteps]
     * @param {boolean} [emptyScreens]
     * @param {boolean} [matchProfileToReview]
     * @param {Array<'finished' | 'in_progress' | 'not_applicable' | 'not_started' | 'rejected'>} [medicalReviewStatus]
     * @param {Array<'-billing_status' | '-date_joined' | '-display_priority' | '-medical_review_status' | '-search_name_display' | '-status' | 'billing_status' | 'date_joined' | 'display_priority' | 'medical_review_status' | 'search_name_display' | 'status'>} [ordering] Ordering
     * @param {number} [organizationId]
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'advr' | 'dnm' | 'dnr' | 'mgr' | 'nap' | 'oad' | 'odo' | 'oed' | 'oextp' | 'oms' | 'opa' | 'osd' | 'oss' | 'par' | 'sad'>} [role]
     * @param {string} [searchNameDisplay]
     * @param {Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUsersList(
      applicationFlaggedToReview?: boolean,
      billingCompleted?: boolean,
      candidateSteps?: Array<
        | 'application'
        | 'checkr'
        | 'complete'
        | 'deactivated'
        | 'final_review'
        | 'hipaa_consent_form'
        | 'id_check'
        | 'matching'
        | 'medical_form'
        | 'not_registered'
        | 'personal_information'
        | 'rejected'
      >,
      emptyScreens?: boolean,
      matchProfileToReview?: boolean,
      medicalReviewStatus?: Array<
        | 'finished'
        | 'in_progress'
        | 'not_applicable'
        | 'not_started'
        | 'rejected'
      >,
      ordering?: Array<
        | '-billing_status'
        | '-date_joined'
        | '-display_priority'
        | '-medical_review_status'
        | '-search_name_display'
        | '-status'
        | 'billing_status'
        | 'date_joined'
        | 'display_priority'
        | 'medical_review_status'
        | 'search_name_display'
        | 'status'
      >,
      organizationId?: number,
      page?: number,
      pageSize?: number,
      role?: Array<
        | 'advr'
        | 'dnm'
        | 'dnr'
        | 'mgr'
        | 'nap'
        | 'oad'
        | 'odo'
        | 'oed'
        | 'oextp'
        | 'oms'
        | 'opa'
        | 'osd'
        | 'oss'
        | 'par'
        | 'sad'
      >,
      searchNameDisplay?: string,
      status?: Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedB2BUserListList>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.b2bUsersList(
        applicationFlaggedToReview,
        billingCompleted,
        candidateSteps,
        emptyScreens,
        matchProfileToReview,
        medicalReviewStatus,
        ordering,
        organizationId,
        page,
        pageSize,
        role,
        searchNameDisplay,
        status,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {Array<'-date_joined' | '-display_priority' | '-email' | '-search_name_display' | '-status' | '-title' | 'date_joined' | 'display_priority' | 'email' | 'search_name_display' | 'status' | 'title'>} [ordering] Ordering
     * @param {number} [organizationId]
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'advr' | 'dnm' | 'dnr' | 'mgr' | 'nap' | 'oad' | 'odo' | 'oed' | 'oextp' | 'oms' | 'opa' | 'osd' | 'oss' | 'par' | 'sad'>} [role]
     * @param {string} [searchNameDisplay]
     * @param {Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUsersNonDetailedList(
      ordering?: Array<
        | '-date_joined'
        | '-display_priority'
        | '-email'
        | '-search_name_display'
        | '-status'
        | '-title'
        | 'date_joined'
        | 'display_priority'
        | 'email'
        | 'search_name_display'
        | 'status'
        | 'title'
      >,
      organizationId?: number,
      page?: number,
      pageSize?: number,
      role?: Array<
        | 'advr'
        | 'dnm'
        | 'dnr'
        | 'mgr'
        | 'nap'
        | 'oad'
        | 'odo'
        | 'oed'
        | 'oextp'
        | 'oms'
        | 'opa'
        | 'osd'
        | 'oss'
        | 'par'
        | 'sad'
      >,
      searchNameDisplay?: string,
      status?: Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedB2BUserNonDetailedListList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUsersNonDetailedList(
          ordering,
          organizationId,
          page,
          pageSize,
          role,
          searchNameDisplay,
          status,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedB2BUser} [patchedB2BUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUsersPartialUpdate(
      id: number,
      patchedB2BUser?: PatchedB2BUser,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<B2BUser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUsersPartialUpdate(
          id,
          patchedB2BUser,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUsersRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<B2BUser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.b2bUsersRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {B2BUser} b2BUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async b2bUsersUpdate(
      id: number,
      b2BUser: B2BUser,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<B2BUser>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.b2bUsersUpdate(
        id,
        b2BUser,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * B2bApi - factory interface
 * @export
 */
export const B2bApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = B2bApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bDashboardMatchingRetrieve(
      options?: any,
    ): AxiosPromise<DashboardMatchingResponse> {
      return localVarFp
        .b2bDashboardMatchingRetrieve(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [donorParentPartnerName]
     * @param {boolean} [hasCallScheduled]
     * @param {Array<'-display_priority' | '-donor_first_name' | '-expiration_date' | '-parent_first_name' | '-stage' | 'display_priority' | 'donor_first_name' | 'expiration_date' | 'parent_first_name' | 'stage'>} [ordering] Ordering
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'broken' | 'expired' | 'intros' | 'match_acceptance' | 'matched'>} [stage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bIntroductionsList(
      donorParentPartnerName?: string,
      hasCallScheduled?: boolean,
      ordering?: Array<
        | '-display_priority'
        | '-donor_first_name'
        | '-expiration_date'
        | '-parent_first_name'
        | '-stage'
        | 'display_priority'
        | 'donor_first_name'
        | 'expiration_date'
        | 'parent_first_name'
        | 'stage'
      >,
      page?: number,
      pageSize?: number,
      stage?: Array<
        'broken' | 'expired' | 'intros' | 'match_acceptance' | 'matched'
      >,
      options?: any,
    ): AxiosPromise<PaginatedB2bIntroductionList> {
      return localVarFp
        .b2bIntroductionsList(
          donorParentPartnerName,
          hasCallScheduled,
          ordering,
          page,
          pageSize,
          stage,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsCancelCreate(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .b2bInvitationsCancelCreate(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {InvitationList} [invitationList]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsCreate(
      invitationList?: InvitationList,
      options?: any,
    ): AxiosPromise<InvitationList> {
      return localVarFp
        .b2bInvitationsCreate(invitationList, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsExternalPayerRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<InvitationExternalPayer> {
      return localVarFp
        .b2bInvitationsExternalPayerRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {InvitationFinish} invitationFinish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsFinishCreate(
      invitationFinish: InvitationFinish,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .b2bInvitationsFinishCreate(invitationFinish, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [isPaid]
     * @param {Array<'-created' | '-search_name_display' | '-status' | 'created' | 'search_name_display' | 'status'>} [ordering] Ordering
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'-' | 'ext_parents' | 'org' | 'prepaid'>} [payingEntity]
     * @param {string} [searchNameDisplay]
     * @param {Array<'accepted' | 'canceled' | 'not_sent' | 'sent'>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsList(
      isPaid?: boolean,
      ordering?: Array<
        | '-created'
        | '-search_name_display'
        | '-status'
        | 'created'
        | 'search_name_display'
        | 'status'
      >,
      page?: number,
      pageSize?: number,
      payingEntity?: Array<'-' | 'ext_parents' | 'org' | 'prepaid'>,
      searchNameDisplay?: string,
      status?: Array<'accepted' | 'canceled' | 'not_sent' | 'sent'>,
      options?: any,
    ): AxiosPromise<PaginatedInvitationListList> {
      return localVarFp
        .b2bInvitationsList(
          isPaid,
          ordering,
          page,
          pageSize,
          payingEntity,
          searchNameDisplay,
          status,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedInvitation} [patchedInvitation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsPartialUpdate(
      id: number,
      patchedInvitation?: PatchedInvitation,
      options?: any,
    ): AxiosPromise<Invitation> {
      return localVarFp
        .b2bInvitationsPartialUpdate(id, patchedInvitation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsResendCreate(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .b2bInvitationsResendCreate(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<Invitation> {
      return localVarFp
        .b2bInvitationsRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {InvitationScreeningPackage} [invitationScreeningPackage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsScreeningPackageCreate(
      id: number,
      invitationScreeningPackage?: InvitationScreeningPackage,
      options?: any,
    ): AxiosPromise<InvitationScreeningPackage> {
      return localVarFp
        .b2bInvitationsScreeningPackageCreate(
          id,
          invitationScreeningPackage,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsSendExternalPaymentLinkCreate(
      id: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .b2bInvitationsSendExternalPaymentLinkCreate(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsSubmitCreate(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .b2bInvitationsSubmitCreate(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Invitation} [invitation]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bInvitationsUpdate(
      id: number,
      invitation?: Invitation,
      options?: any,
    ): AxiosPromise<Invitation> {
      return localVarFp
        .b2bInvitationsUpdate(id, invitation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsList(options?: any): AxiosPromise<Array<Organization>> {
      return localVarFp
        .b2bOrganizationsList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [name]
     * @param {Array<'-created' | '-name' | '-total_active_sent' | '-type' | 'created' | 'name' | 'total_active_sent' | 'type'>} [ordering] Ordering
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsNonDetailedList(
      name?: string,
      ordering?: Array<
        | '-created'
        | '-name'
        | '-total_active_sent'
        | '-type'
        | 'created'
        | 'name'
        | 'total_active_sent'
        | 'type'
      >,
      page?: number,
      pageSize?: number,
      options?: any,
    ): AxiosPromise<PaginatedOrganizationNonDetailedList> {
      return localVarFp
        .b2bOrganizationsNonDetailedList(
          name,
          ordering,
          page,
          pageSize,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedOrganization} [patchedOrganization]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsPartialUpdate(
      id: number,
      patchedOrganization?: PatchedOrganization,
      options?: any,
    ): AxiosPromise<Organization> {
      return localVarFp
        .b2bOrganizationsPartialUpdate(id, patchedOrganization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<Organization> {
      return localVarFp
        .b2bOrganizationsRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Organization} organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bOrganizationsUpdate(
      id: number,
      organization: Organization,
      options?: any,
    ): AxiosPromise<Organization> {
      return localVarFp
        .b2bOrganizationsUpdate(id, organization, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bRoleScreensList(
      options?: any,
    ): AxiosPromise<Array<RolesAllAvailableScreen>> {
      return localVarFp
        .b2bRoleScreensList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedB2BUserDisqualify} [patchedB2BUserDisqualify]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserDisqualifyPartialUpdate(
      id: number,
      patchedB2BUserDisqualify?: PatchedB2BUserDisqualify,
      options?: any,
    ): AxiosPromise<B2BUserDisqualify> {
      return localVarFp
        .b2bUserDisqualifyPartialUpdate(id, patchedB2BUserDisqualify, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {B2BUserDisqualify} [b2BUserDisqualify]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserDisqualifyUpdate(
      id: number,
      b2BUserDisqualify?: B2BUserDisqualify,
      options?: any,
    ): AxiosPromise<B2BUserDisqualify> {
      return localVarFp
        .b2bUserDisqualifyUpdate(id, b2BUserDisqualify, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedDonorProfileFinalReview} [patchedDonorProfileFinalReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfileFinalReviewPartialUpdate(
      id: number,
      patchedDonorProfileFinalReview?: PatchedDonorProfileFinalReview,
      options?: any,
    ): AxiosPromise<FinalReviewObject> {
      return localVarFp
        .b2bUserProfileFinalReviewPartialUpdate(
          id,
          patchedDonorProfileFinalReview,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {DonorProfileFinalReview} [donorProfileFinalReview]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfileFinalReviewUpdate(
      id: number,
      donorProfileFinalReview?: DonorProfileFinalReview,
      options?: any,
    ): AxiosPromise<FinalReviewObject> {
      return localVarFp
        .b2bUserProfileFinalReviewUpdate(id, donorProfileFinalReview, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedProfileObject} [patchedProfileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfilePartialUpdate(
      id: number,
      patchedProfileObject?: PatchedProfileObject,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .b2bUserProfilePartialUpdate(id, patchedProfileObject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfileRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<ProfileObject> {
      return localVarFp
        .b2bUserProfileRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {ProfileObject} [profileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserProfileUpdate(
      id: number,
      profileObject?: ProfileObject,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .b2bUserProfileUpdate(id, profileObject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUserTasksList(userId: number, options?: any): AxiosPromise<Array<Task>> {
      return localVarFp
        .b2bUserTasksList(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {boolean} [applicationFlaggedToReview]
     * @param {boolean} [billingCompleted]
     * @param {Array<'application' | 'checkr' | 'complete' | 'deactivated' | 'final_review' | 'hipaa_consent_form' | 'id_check' | 'matching' | 'medical_form' | 'not_registered' | 'personal_information' | 'rejected'>} [candidateSteps]
     * @param {boolean} [emptyScreens]
     * @param {boolean} [matchProfileToReview]
     * @param {Array<'finished' | 'in_progress' | 'not_applicable' | 'not_started' | 'rejected'>} [medicalReviewStatus]
     * @param {Array<'-billing_status' | '-date_joined' | '-display_priority' | '-medical_review_status' | '-search_name_display' | '-status' | 'billing_status' | 'date_joined' | 'display_priority' | 'medical_review_status' | 'search_name_display' | 'status'>} [ordering] Ordering
     * @param {number} [organizationId]
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'advr' | 'dnm' | 'dnr' | 'mgr' | 'nap' | 'oad' | 'odo' | 'oed' | 'oextp' | 'oms' | 'opa' | 'osd' | 'oss' | 'par' | 'sad'>} [role]
     * @param {string} [searchNameDisplay]
     * @param {Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersList(
      applicationFlaggedToReview?: boolean,
      billingCompleted?: boolean,
      candidateSteps?: Array<
        | 'application'
        | 'checkr'
        | 'complete'
        | 'deactivated'
        | 'final_review'
        | 'hipaa_consent_form'
        | 'id_check'
        | 'matching'
        | 'medical_form'
        | 'not_registered'
        | 'personal_information'
        | 'rejected'
      >,
      emptyScreens?: boolean,
      matchProfileToReview?: boolean,
      medicalReviewStatus?: Array<
        | 'finished'
        | 'in_progress'
        | 'not_applicable'
        | 'not_started'
        | 'rejected'
      >,
      ordering?: Array<
        | '-billing_status'
        | '-date_joined'
        | '-display_priority'
        | '-medical_review_status'
        | '-search_name_display'
        | '-status'
        | 'billing_status'
        | 'date_joined'
        | 'display_priority'
        | 'medical_review_status'
        | 'search_name_display'
        | 'status'
      >,
      organizationId?: number,
      page?: number,
      pageSize?: number,
      role?: Array<
        | 'advr'
        | 'dnm'
        | 'dnr'
        | 'mgr'
        | 'nap'
        | 'oad'
        | 'odo'
        | 'oed'
        | 'oextp'
        | 'oms'
        | 'opa'
        | 'osd'
        | 'oss'
        | 'par'
        | 'sad'
      >,
      searchNameDisplay?: string,
      status?: Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>,
      options?: any,
    ): AxiosPromise<PaginatedB2BUserListList> {
      return localVarFp
        .b2bUsersList(
          applicationFlaggedToReview,
          billingCompleted,
          candidateSteps,
          emptyScreens,
          matchProfileToReview,
          medicalReviewStatus,
          ordering,
          organizationId,
          page,
          pageSize,
          role,
          searchNameDisplay,
          status,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {Array<'-date_joined' | '-display_priority' | '-email' | '-search_name_display' | '-status' | '-title' | 'date_joined' | 'display_priority' | 'email' | 'search_name_display' | 'status' | 'title'>} [ordering] Ordering
     * @param {number} [organizationId]
     * @param {number} [page] A page number within the paginated result set.
     * @param {number} [pageSize] Number of results to return per page.
     * @param {Array<'advr' | 'dnm' | 'dnr' | 'mgr' | 'nap' | 'oad' | 'odo' | 'oed' | 'oextp' | 'oms' | 'opa' | 'osd' | 'oss' | 'par' | 'sad'>} [role]
     * @param {string} [searchNameDisplay]
     * @param {Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>} [status]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersNonDetailedList(
      ordering?: Array<
        | '-date_joined'
        | '-display_priority'
        | '-email'
        | '-search_name_display'
        | '-status'
        | '-title'
        | 'date_joined'
        | 'display_priority'
        | 'email'
        | 'search_name_display'
        | 'status'
        | 'title'
      >,
      organizationId?: number,
      page?: number,
      pageSize?: number,
      role?: Array<
        | 'advr'
        | 'dnm'
        | 'dnr'
        | 'mgr'
        | 'nap'
        | 'oad'
        | 'odo'
        | 'oed'
        | 'oextp'
        | 'oms'
        | 'opa'
        | 'osd'
        | 'oss'
        | 'par'
        | 'sad'
      >,
      searchNameDisplay?: string,
      status?: Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>,
      options?: any,
    ): AxiosPromise<PaginatedB2BUserNonDetailedListList> {
      return localVarFp
        .b2bUsersNonDetailedList(
          ordering,
          organizationId,
          page,
          pageSize,
          role,
          searchNameDisplay,
          status,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedB2BUser} [patchedB2BUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersPartialUpdate(
      id: number,
      patchedB2BUser?: PatchedB2BUser,
      options?: any,
    ): AxiosPromise<B2BUser> {
      return localVarFp
        .b2bUsersPartialUpdate(id, patchedB2BUser, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersRetrieve(id: number, options?: any): AxiosPromise<B2BUser> {
      return localVarFp
        .b2bUsersRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {B2BUser} b2BUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    b2bUsersUpdate(
      id: number,
      b2BUser: B2BUser,
      options?: any,
    ): AxiosPromise<B2BUser> {
      return localVarFp
        .b2bUsersUpdate(id, b2BUser, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for b2bIntroductionsList operation in B2bApi.
 * @export
 * @interface B2bApiB2bIntroductionsListRequest
 */
export interface B2bApiB2bIntroductionsListRequest {
  /**
   *
   * @type {string}
   * @memberof B2bApiB2bIntroductionsList
   */
  readonly donorParentPartnerName?: string;

  /**
   *
   * @type {boolean}
   * @memberof B2bApiB2bIntroductionsList
   */
  readonly hasCallScheduled?: boolean;

  /**
   * Ordering
   * @type {Array<'-display_priority' | '-donor_first_name' | '-expiration_date' | '-parent_first_name' | '-stage' | 'display_priority' | 'donor_first_name' | 'expiration_date' | 'parent_first_name' | 'stage'>}
   * @memberof B2bApiB2bIntroductionsList
   */
  readonly ordering?: Array<
    | '-display_priority'
    | '-donor_first_name'
    | '-expiration_date'
    | '-parent_first_name'
    | '-stage'
    | 'display_priority'
    | 'donor_first_name'
    | 'expiration_date'
    | 'parent_first_name'
    | 'stage'
  >;

  /**
   * A page number within the paginated result set.
   * @type {number}
   * @memberof B2bApiB2bIntroductionsList
   */
  readonly page?: number;

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof B2bApiB2bIntroductionsList
   */
  readonly pageSize?: number;

  /**
   *
   * @type {Array<'broken' | 'expired' | 'intros' | 'match_acceptance' | 'matched'>}
   * @memberof B2bApiB2bIntroductionsList
   */
  readonly stage?: Array<
    'broken' | 'expired' | 'intros' | 'match_acceptance' | 'matched'
  >;
}

/**
 * Request parameters for b2bInvitationsCancelCreate operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsCancelCreateRequest
 */
export interface B2bApiB2bInvitationsCancelCreateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bInvitationsCancelCreate
   */
  readonly id: number;
}

/**
 * Request parameters for b2bInvitationsCreate operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsCreateRequest
 */
export interface B2bApiB2bInvitationsCreateRequest {
  /**
   *
   * @type {InvitationList}
   * @memberof B2bApiB2bInvitationsCreate
   */
  readonly invitationList?: InvitationList;
}

/**
 * Request parameters for b2bInvitationsExternalPayerRetrieve operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsExternalPayerRetrieveRequest
 */
export interface B2bApiB2bInvitationsExternalPayerRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bInvitationsExternalPayerRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for b2bInvitationsFinishCreate operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsFinishCreateRequest
 */
export interface B2bApiB2bInvitationsFinishCreateRequest {
  /**
   *
   * @type {InvitationFinish}
   * @memberof B2bApiB2bInvitationsFinishCreate
   */
  readonly invitationFinish: InvitationFinish;
}

/**
 * Request parameters for b2bInvitationsList operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsListRequest
 */
export interface B2bApiB2bInvitationsListRequest {
  /**
   *
   * @type {boolean}
   * @memberof B2bApiB2bInvitationsList
   */
  readonly isPaid?: boolean;

  /**
   * Ordering
   * @type {Array<'-created' | '-search_name_display' | '-status' | 'created' | 'search_name_display' | 'status'>}
   * @memberof B2bApiB2bInvitationsList
   */
  readonly ordering?: Array<
    | '-created'
    | '-search_name_display'
    | '-status'
    | 'created'
    | 'search_name_display'
    | 'status'
  >;

  /**
   * A page number within the paginated result set.
   * @type {number}
   * @memberof B2bApiB2bInvitationsList
   */
  readonly page?: number;

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof B2bApiB2bInvitationsList
   */
  readonly pageSize?: number;

  /**
   *
   * @type {Array<'-' | 'ext_parents' | 'org' | 'prepaid'>}
   * @memberof B2bApiB2bInvitationsList
   */
  readonly payingEntity?: Array<'-' | 'ext_parents' | 'org' | 'prepaid'>;

  /**
   *
   * @type {string}
   * @memberof B2bApiB2bInvitationsList
   */
  readonly searchNameDisplay?: string;

  /**
   *
   * @type {Array<'accepted' | 'canceled' | 'not_sent' | 'sent'>}
   * @memberof B2bApiB2bInvitationsList
   */
  readonly status?: Array<'accepted' | 'canceled' | 'not_sent' | 'sent'>;
}

/**
 * Request parameters for b2bInvitationsPartialUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsPartialUpdateRequest
 */
export interface B2bApiB2bInvitationsPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bInvitationsPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedInvitation}
   * @memberof B2bApiB2bInvitationsPartialUpdate
   */
  readonly patchedInvitation?: PatchedInvitation;
}

/**
 * Request parameters for b2bInvitationsResendCreate operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsResendCreateRequest
 */
export interface B2bApiB2bInvitationsResendCreateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bInvitationsResendCreate
   */
  readonly id: number;
}

/**
 * Request parameters for b2bInvitationsRetrieve operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsRetrieveRequest
 */
export interface B2bApiB2bInvitationsRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bInvitationsRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for b2bInvitationsScreeningPackageCreate operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsScreeningPackageCreateRequest
 */
export interface B2bApiB2bInvitationsScreeningPackageCreateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bInvitationsScreeningPackageCreate
   */
  readonly id: number;

  /**
   *
   * @type {InvitationScreeningPackage}
   * @memberof B2bApiB2bInvitationsScreeningPackageCreate
   */
  readonly invitationScreeningPackage?: InvitationScreeningPackage;
}

/**
 * Request parameters for b2bInvitationsSendExternalPaymentLinkCreate operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsSendExternalPaymentLinkCreateRequest
 */
export interface B2bApiB2bInvitationsSendExternalPaymentLinkCreateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bInvitationsSendExternalPaymentLinkCreate
   */
  readonly id: number;
}

/**
 * Request parameters for b2bInvitationsSubmitCreate operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsSubmitCreateRequest
 */
export interface B2bApiB2bInvitationsSubmitCreateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bInvitationsSubmitCreate
   */
  readonly id: number;
}

/**
 * Request parameters for b2bInvitationsUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bInvitationsUpdateRequest
 */
export interface B2bApiB2bInvitationsUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bInvitationsUpdate
   */
  readonly id: number;

  /**
   *
   * @type {Invitation}
   * @memberof B2bApiB2bInvitationsUpdate
   */
  readonly invitation?: Invitation;
}

/**
 * Request parameters for b2bOrganizationsNonDetailedList operation in B2bApi.
 * @export
 * @interface B2bApiB2bOrganizationsNonDetailedListRequest
 */
export interface B2bApiB2bOrganizationsNonDetailedListRequest {
  /**
   *
   * @type {string}
   * @memberof B2bApiB2bOrganizationsNonDetailedList
   */
  readonly name?: string;

  /**
   * Ordering
   * @type {Array<'-created' | '-name' | '-total_active_sent' | '-type' | 'created' | 'name' | 'total_active_sent' | 'type'>}
   * @memberof B2bApiB2bOrganizationsNonDetailedList
   */
  readonly ordering?: Array<
    | '-created'
    | '-name'
    | '-total_active_sent'
    | '-type'
    | 'created'
    | 'name'
    | 'total_active_sent'
    | 'type'
  >;

  /**
   * A page number within the paginated result set.
   * @type {number}
   * @memberof B2bApiB2bOrganizationsNonDetailedList
   */
  readonly page?: number;

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof B2bApiB2bOrganizationsNonDetailedList
   */
  readonly pageSize?: number;
}

/**
 * Request parameters for b2bOrganizationsPartialUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bOrganizationsPartialUpdateRequest
 */
export interface B2bApiB2bOrganizationsPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bOrganizationsPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedOrganization}
   * @memberof B2bApiB2bOrganizationsPartialUpdate
   */
  readonly patchedOrganization?: PatchedOrganization;
}

/**
 * Request parameters for b2bOrganizationsRetrieve operation in B2bApi.
 * @export
 * @interface B2bApiB2bOrganizationsRetrieveRequest
 */
export interface B2bApiB2bOrganizationsRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bOrganizationsRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for b2bOrganizationsUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bOrganizationsUpdateRequest
 */
export interface B2bApiB2bOrganizationsUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bOrganizationsUpdate
   */
  readonly id: number;

  /**
   *
   * @type {Organization}
   * @memberof B2bApiB2bOrganizationsUpdate
   */
  readonly organization: Organization;
}

/**
 * Request parameters for b2bUserDisqualifyPartialUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bUserDisqualifyPartialUpdateRequest
 */
export interface B2bApiB2bUserDisqualifyPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUserDisqualifyPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedB2BUserDisqualify}
   * @memberof B2bApiB2bUserDisqualifyPartialUpdate
   */
  readonly patchedB2BUserDisqualify?: PatchedB2BUserDisqualify;
}

/**
 * Request parameters for b2bUserDisqualifyUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bUserDisqualifyUpdateRequest
 */
export interface B2bApiB2bUserDisqualifyUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUserDisqualifyUpdate
   */
  readonly id: number;

  /**
   *
   * @type {B2BUserDisqualify}
   * @memberof B2bApiB2bUserDisqualifyUpdate
   */
  readonly b2BUserDisqualify?: B2BUserDisqualify;
}

/**
 * Request parameters for b2bUserProfileFinalReviewPartialUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bUserProfileFinalReviewPartialUpdateRequest
 */
export interface B2bApiB2bUserProfileFinalReviewPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUserProfileFinalReviewPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedDonorProfileFinalReview}
   * @memberof B2bApiB2bUserProfileFinalReviewPartialUpdate
   */
  readonly patchedDonorProfileFinalReview?: PatchedDonorProfileFinalReview;
}

/**
 * Request parameters for b2bUserProfileFinalReviewUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bUserProfileFinalReviewUpdateRequest
 */
export interface B2bApiB2bUserProfileFinalReviewUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUserProfileFinalReviewUpdate
   */
  readonly id: number;

  /**
   *
   * @type {DonorProfileFinalReview}
   * @memberof B2bApiB2bUserProfileFinalReviewUpdate
   */
  readonly donorProfileFinalReview?: DonorProfileFinalReview;
}

/**
 * Request parameters for b2bUserProfilePartialUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bUserProfilePartialUpdateRequest
 */
export interface B2bApiB2bUserProfilePartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUserProfilePartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedProfileObject}
   * @memberof B2bApiB2bUserProfilePartialUpdate
   */
  readonly patchedProfileObject?: PatchedProfileObject;
}

/**
 * Request parameters for b2bUserProfileRetrieve operation in B2bApi.
 * @export
 * @interface B2bApiB2bUserProfileRetrieveRequest
 */
export interface B2bApiB2bUserProfileRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUserProfileRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for b2bUserProfileUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bUserProfileUpdateRequest
 */
export interface B2bApiB2bUserProfileUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUserProfileUpdate
   */
  readonly id: number;

  /**
   *
   * @type {ProfileObject}
   * @memberof B2bApiB2bUserProfileUpdate
   */
  readonly profileObject?: ProfileObject;
}

/**
 * Request parameters for b2bUserTasksList operation in B2bApi.
 * @export
 * @interface B2bApiB2bUserTasksListRequest
 */
export interface B2bApiB2bUserTasksListRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUserTasksList
   */
  readonly userId: number;
}

/**
 * Request parameters for b2bUsersList operation in B2bApi.
 * @export
 * @interface B2bApiB2bUsersListRequest
 */
export interface B2bApiB2bUsersListRequest {
  /**
   *
   * @type {boolean}
   * @memberof B2bApiB2bUsersList
   */
  readonly applicationFlaggedToReview?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof B2bApiB2bUsersList
   */
  readonly billingCompleted?: boolean;

  /**
   *
   * @type {Array<'application' | 'checkr' | 'complete' | 'deactivated' | 'final_review' | 'hipaa_consent_form' | 'id_check' | 'matching' | 'medical_form' | 'not_registered' | 'personal_information' | 'rejected'>}
   * @memberof B2bApiB2bUsersList
   */
  readonly candidateSteps?: Array<
    | 'application'
    | 'checkr'
    | 'complete'
    | 'deactivated'
    | 'final_review'
    | 'hipaa_consent_form'
    | 'id_check'
    | 'matching'
    | 'medical_form'
    | 'not_registered'
    | 'personal_information'
    | 'rejected'
  >;

  /**
   *
   * @type {boolean}
   * @memberof B2bApiB2bUsersList
   */
  readonly emptyScreens?: boolean;

  /**
   *
   * @type {boolean}
   * @memberof B2bApiB2bUsersList
   */
  readonly matchProfileToReview?: boolean;

  /**
   *
   * @type {Array<'finished' | 'in_progress' | 'not_applicable' | 'not_started' | 'rejected'>}
   * @memberof B2bApiB2bUsersList
   */
  readonly medicalReviewStatus?: Array<
    'finished' | 'in_progress' | 'not_applicable' | 'not_started' | 'rejected'
  >;

  /**
   * Ordering
   * @type {Array<'-billing_status' | '-date_joined' | '-display_priority' | '-medical_review_status' | '-search_name_display' | '-status' | 'billing_status' | 'date_joined' | 'display_priority' | 'medical_review_status' | 'search_name_display' | 'status'>}
   * @memberof B2bApiB2bUsersList
   */
  readonly ordering?: Array<
    | '-billing_status'
    | '-date_joined'
    | '-display_priority'
    | '-medical_review_status'
    | '-search_name_display'
    | '-status'
    | 'billing_status'
    | 'date_joined'
    | 'display_priority'
    | 'medical_review_status'
    | 'search_name_display'
    | 'status'
  >;

  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUsersList
   */
  readonly organizationId?: number;

  /**
   * A page number within the paginated result set.
   * @type {number}
   * @memberof B2bApiB2bUsersList
   */
  readonly page?: number;

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof B2bApiB2bUsersList
   */
  readonly pageSize?: number;

  /**
   *
   * @type {Array<'advr' | 'dnm' | 'dnr' | 'mgr' | 'nap' | 'oad' | 'odo' | 'oed' | 'oextp' | 'oms' | 'opa' | 'osd' | 'oss' | 'par' | 'sad'>}
   * @memberof B2bApiB2bUsersList
   */
  readonly role?: Array<
    | 'advr'
    | 'dnm'
    | 'dnr'
    | 'mgr'
    | 'nap'
    | 'oad'
    | 'odo'
    | 'oed'
    | 'oextp'
    | 'oms'
    | 'opa'
    | 'osd'
    | 'oss'
    | 'par'
    | 'sad'
  >;

  /**
   *
   * @type {string}
   * @memberof B2bApiB2bUsersList
   */
  readonly searchNameDisplay?: string;

  /**
   *
   * @type {Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>}
   * @memberof B2bApiB2bUsersList
   */
  readonly status?: Array<
    'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'
  >;
}

/**
 * Request parameters for b2bUsersNonDetailedList operation in B2bApi.
 * @export
 * @interface B2bApiB2bUsersNonDetailedListRequest
 */
export interface B2bApiB2bUsersNonDetailedListRequest {
  /**
   * Ordering
   * @type {Array<'-date_joined' | '-display_priority' | '-email' | '-search_name_display' | '-status' | '-title' | 'date_joined' | 'display_priority' | 'email' | 'search_name_display' | 'status' | 'title'>}
   * @memberof B2bApiB2bUsersNonDetailedList
   */
  readonly ordering?: Array<
    | '-date_joined'
    | '-display_priority'
    | '-email'
    | '-search_name_display'
    | '-status'
    | '-title'
    | 'date_joined'
    | 'display_priority'
    | 'email'
    | 'search_name_display'
    | 'status'
    | 'title'
  >;

  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUsersNonDetailedList
   */
  readonly organizationId?: number;

  /**
   * A page number within the paginated result set.
   * @type {number}
   * @memberof B2bApiB2bUsersNonDetailedList
   */
  readonly page?: number;

  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof B2bApiB2bUsersNonDetailedList
   */
  readonly pageSize?: number;

  /**
   *
   * @type {Array<'advr' | 'dnm' | 'dnr' | 'mgr' | 'nap' | 'oad' | 'odo' | 'oed' | 'oextp' | 'oms' | 'opa' | 'osd' | 'oss' | 'par' | 'sad'>}
   * @memberof B2bApiB2bUsersNonDetailedList
   */
  readonly role?: Array<
    | 'advr'
    | 'dnm'
    | 'dnr'
    | 'mgr'
    | 'nap'
    | 'oad'
    | 'odo'
    | 'oed'
    | 'oextp'
    | 'oms'
    | 'opa'
    | 'osd'
    | 'oss'
    | 'par'
    | 'sad'
  >;

  /**
   *
   * @type {string}
   * @memberof B2bApiB2bUsersNonDetailedList
   */
  readonly searchNameDisplay?: string;

  /**
   *
   * @type {Array<'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'>}
   * @memberof B2bApiB2bUsersNonDetailedList
   */
  readonly status?: Array<
    'dea' | 'dis' | 'mat' | 'mat+' | 'nac' | 'scr' | 'scr+'
  >;
}

/**
 * Request parameters for b2bUsersPartialUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bUsersPartialUpdateRequest
 */
export interface B2bApiB2bUsersPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUsersPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedB2BUser}
   * @memberof B2bApiB2bUsersPartialUpdate
   */
  readonly patchedB2BUser?: PatchedB2BUser;
}

/**
 * Request parameters for b2bUsersRetrieve operation in B2bApi.
 * @export
 * @interface B2bApiB2bUsersRetrieveRequest
 */
export interface B2bApiB2bUsersRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUsersRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for b2bUsersUpdate operation in B2bApi.
 * @export
 * @interface B2bApiB2bUsersUpdateRequest
 */
export interface B2bApiB2bUsersUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof B2bApiB2bUsersUpdate
   */
  readonly id: number;

  /**
   *
   * @type {B2BUser}
   * @memberof B2bApiB2bUsersUpdate
   */
  readonly b2BUser: B2BUser;
}

/**
 * B2bApi - object-oriented interface
 * @export
 * @class B2bApi
 * @extends {BaseAPI}
 */
export class B2bApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bDashboardMatchingRetrieve(options?: AxiosRequestConfig) {
    return B2bApiFp(this.configuration)
      .b2bDashboardMatchingRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bIntroductionsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bIntroductionsList(
    requestParameters: B2bApiB2bIntroductionsListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bIntroductionsList(
        requestParameters.donorParentPartnerName,
        requestParameters.hasCallScheduled,
        requestParameters.ordering,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.stage,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsCancelCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsCancelCreate(
    requestParameters: B2bApiB2bInvitationsCancelCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsCancelCreate(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsCreate(
    requestParameters: B2bApiB2bInvitationsCreateRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsCreate(requestParameters.invitationList, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsExternalPayerRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsExternalPayerRetrieve(
    requestParameters: B2bApiB2bInvitationsExternalPayerRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsExternalPayerRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsFinishCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsFinishCreate(
    requestParameters: B2bApiB2bInvitationsFinishCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsFinishCreate(requestParameters.invitationFinish, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsList(
    requestParameters: B2bApiB2bInvitationsListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsList(
        requestParameters.isPaid,
        requestParameters.ordering,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.payingEntity,
        requestParameters.searchNameDisplay,
        requestParameters.status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsPartialUpdate(
    requestParameters: B2bApiB2bInvitationsPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsPartialUpdate(
        requestParameters.id,
        requestParameters.patchedInvitation,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsResendCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsResendCreate(
    requestParameters: B2bApiB2bInvitationsResendCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsResendCreate(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsRetrieve(
    requestParameters: B2bApiB2bInvitationsRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsScreeningPackageCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsScreeningPackageCreate(
    requestParameters: B2bApiB2bInvitationsScreeningPackageCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsScreeningPackageCreate(
        requestParameters.id,
        requestParameters.invitationScreeningPackage,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsSendExternalPaymentLinkCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsSendExternalPaymentLinkCreate(
    requestParameters: B2bApiB2bInvitationsSendExternalPaymentLinkCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsSendExternalPaymentLinkCreate(
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsSubmitCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsSubmitCreate(
    requestParameters: B2bApiB2bInvitationsSubmitCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsSubmitCreate(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bInvitationsUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bInvitationsUpdate(
    requestParameters: B2bApiB2bInvitationsUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bInvitationsUpdate(
        requestParameters.id,
        requestParameters.invitation,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bOrganizationsList(options?: AxiosRequestConfig) {
    return B2bApiFp(this.configuration)
      .b2bOrganizationsList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bOrganizationsNonDetailedListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bOrganizationsNonDetailedList(
    requestParameters: B2bApiB2bOrganizationsNonDetailedListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bOrganizationsNonDetailedList(
        requestParameters.name,
        requestParameters.ordering,
        requestParameters.page,
        requestParameters.pageSize,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bOrganizationsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bOrganizationsPartialUpdate(
    requestParameters: B2bApiB2bOrganizationsPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bOrganizationsPartialUpdate(
        requestParameters.id,
        requestParameters.patchedOrganization,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bOrganizationsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bOrganizationsRetrieve(
    requestParameters: B2bApiB2bOrganizationsRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bOrganizationsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bOrganizationsUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bOrganizationsUpdate(
    requestParameters: B2bApiB2bOrganizationsUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bOrganizationsUpdate(
        requestParameters.id,
        requestParameters.organization,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bRoleScreensList(options?: AxiosRequestConfig) {
    return B2bApiFp(this.configuration)
      .b2bRoleScreensList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUserDisqualifyPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUserDisqualifyPartialUpdate(
    requestParameters: B2bApiB2bUserDisqualifyPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUserDisqualifyPartialUpdate(
        requestParameters.id,
        requestParameters.patchedB2BUserDisqualify,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUserDisqualifyUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUserDisqualifyUpdate(
    requestParameters: B2bApiB2bUserDisqualifyUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUserDisqualifyUpdate(
        requestParameters.id,
        requestParameters.b2BUserDisqualify,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUserProfileFinalReviewPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUserProfileFinalReviewPartialUpdate(
    requestParameters: B2bApiB2bUserProfileFinalReviewPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUserProfileFinalReviewPartialUpdate(
        requestParameters.id,
        requestParameters.patchedDonorProfileFinalReview,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUserProfileFinalReviewUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUserProfileFinalReviewUpdate(
    requestParameters: B2bApiB2bUserProfileFinalReviewUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUserProfileFinalReviewUpdate(
        requestParameters.id,
        requestParameters.donorProfileFinalReview,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUserProfilePartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUserProfilePartialUpdate(
    requestParameters: B2bApiB2bUserProfilePartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUserProfilePartialUpdate(
        requestParameters.id,
        requestParameters.patchedProfileObject,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUserProfileRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUserProfileRetrieve(
    requestParameters: B2bApiB2bUserProfileRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUserProfileRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUserProfileUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUserProfileUpdate(
    requestParameters: B2bApiB2bUserProfileUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUserProfileUpdate(
        requestParameters.id,
        requestParameters.profileObject,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUserTasksListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUserTasksList(
    requestParameters: B2bApiB2bUserTasksListRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUserTasksList(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUsersListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUsersList(
    requestParameters: B2bApiB2bUsersListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUsersList(
        requestParameters.applicationFlaggedToReview,
        requestParameters.billingCompleted,
        requestParameters.candidateSteps,
        requestParameters.emptyScreens,
        requestParameters.matchProfileToReview,
        requestParameters.medicalReviewStatus,
        requestParameters.ordering,
        requestParameters.organizationId,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.role,
        requestParameters.searchNameDisplay,
        requestParameters.status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUsersNonDetailedListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUsersNonDetailedList(
    requestParameters: B2bApiB2bUsersNonDetailedListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUsersNonDetailedList(
        requestParameters.ordering,
        requestParameters.organizationId,
        requestParameters.page,
        requestParameters.pageSize,
        requestParameters.role,
        requestParameters.searchNameDisplay,
        requestParameters.status,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUsersPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUsersPartialUpdate(
    requestParameters: B2bApiB2bUsersPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUsersPartialUpdate(
        requestParameters.id,
        requestParameters.patchedB2BUser,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUsersRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUsersRetrieve(
    requestParameters: B2bApiB2bUsersRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUsersRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {B2bApiB2bUsersUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof B2bApi
   */
  public b2bUsersUpdate(
    requestParameters: B2bApiB2bUsersUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return B2bApiFp(this.configuration)
      .b2bUsersUpdate(requestParameters.id, requestParameters.b2BUser, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CustomerPortal} [customerPortal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingCustomerPortalCreate: async (
      customerPortal?: CustomerPortal,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/customer-portal/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        customerPortal,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingCustomerRetrieve: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/customer/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingPlansList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/plans/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingScreeningPackagesList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/screening-packages`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeInvitationPaymentInitiatePaymentCreate: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'billingStripeInvitationPaymentInitiatePaymentCreate',
        'id',
        id,
      );
      const localVarPath =
        `/api/billing/stripe-invitation-payment/{id}/initiate-payment/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeInvitationPaymentRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('billingStripeInvitationPaymentRetrieve', 'id', id);
      const localVarPath =
        `/api/billing/stripe-invitation-payment/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeNodalServicesPaymentInitiatePaymentCreate: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/stripe-nodal-services-payment/initiate-payment/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeNodalServicesPaymentRetrieve: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/stripe-nodal-services-payment/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Old subscription flow implementation, left for now as subscriptions are still ongoing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeSubscriptionInitiatePaymentCreate: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/billing/stripe-subscription/initiate-payment/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Old subscription flow implementation, left for now as subscriptions are still ongoing, disabled from using
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeSubscriptionRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('billingStripeSubscriptionRetrieve', 'id', id);
      const localVarPath = `/api/billing/stripe-subscription/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CustomerPortal} [customerPortal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingCustomerPortalCreate(
      customerPortal?: CustomerPortal,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerPortal>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingCustomerPortalCreate(
          customerPortal,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingCustomerRetrieve(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingCustomerRetrieve(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingPlansList(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Plan>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingPlansList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingScreeningPackagesList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ScreeningPackage>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingScreeningPackagesList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingStripeInvitationPaymentInitiatePaymentCreate(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingStripeInvitationPaymentInitiatePaymentCreate(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingStripeInvitationPaymentRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StripeInvitationPayment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingStripeInvitationPaymentRetrieve(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingStripeNodalServicesPaymentInitiatePaymentCreate(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingStripeNodalServicesPaymentInitiatePaymentCreate(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingStripeNodalServicesPaymentRetrieve(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StripeNodalMatchingPayment>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingStripeNodalServicesPaymentRetrieve(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Old subscription flow implementation, left for now as subscriptions are still ongoing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingStripeSubscriptionInitiatePaymentCreate(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingStripeSubscriptionInitiatePaymentCreate(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Old subscription flow implementation, left for now as subscriptions are still ongoing, disabled from using
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async billingStripeSubscriptionRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.billingStripeSubscriptionRetrieve(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BillingApiFp(configuration);
  return {
    /**
     *
     * @param {CustomerPortal} [customerPortal]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingCustomerPortalCreate(
      customerPortal?: CustomerPortal,
      options?: any,
    ): AxiosPromise<CustomerPortal> {
      return localVarFp
        .billingCustomerPortalCreate(customerPortal, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingCustomerRetrieve(options?: any): AxiosPromise<Customer> {
      return localVarFp
        .billingCustomerRetrieve(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingPlansList(options?: any): AxiosPromise<Array<Plan>> {
      return localVarFp
        .billingPlansList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingScreeningPackagesList(
      options?: any,
    ): AxiosPromise<Array<ScreeningPackage>> {
      return localVarFp
        .billingScreeningPackagesList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeInvitationPaymentInitiatePaymentCreate(
      id: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .billingStripeInvitationPaymentInitiatePaymentCreate(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeInvitationPaymentRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<StripeInvitationPayment> {
      return localVarFp
        .billingStripeInvitationPaymentRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeNodalServicesPaymentInitiatePaymentCreate(
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .billingStripeNodalServicesPaymentInitiatePaymentCreate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeNodalServicesPaymentRetrieve(
      options?: any,
    ): AxiosPromise<StripeNodalMatchingPayment> {
      return localVarFp
        .billingStripeNodalServicesPaymentRetrieve(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Old subscription flow implementation, left for now as subscriptions are still ongoing
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeSubscriptionInitiatePaymentCreate(
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .billingStripeSubscriptionInitiatePaymentCreate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Old subscription flow implementation, left for now as subscriptions are still ongoing, disabled from using
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    billingStripeSubscriptionRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .billingStripeSubscriptionRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for billingCustomerPortalCreate operation in BillingApi.
 * @export
 * @interface BillingApiBillingCustomerPortalCreateRequest
 */
export interface BillingApiBillingCustomerPortalCreateRequest {
  /**
   *
   * @type {CustomerPortal}
   * @memberof BillingApiBillingCustomerPortalCreate
   */
  readonly customerPortal?: CustomerPortal;
}

/**
 * Request parameters for billingStripeInvitationPaymentInitiatePaymentCreate operation in BillingApi.
 * @export
 * @interface BillingApiBillingStripeInvitationPaymentInitiatePaymentCreateRequest
 */
export interface BillingApiBillingStripeInvitationPaymentInitiatePaymentCreateRequest {
  /**
   *
   * @type {number}
   * @memberof BillingApiBillingStripeInvitationPaymentInitiatePaymentCreate
   */
  readonly id: number;
}

/**
 * Request parameters for billingStripeInvitationPaymentRetrieve operation in BillingApi.
 * @export
 * @interface BillingApiBillingStripeInvitationPaymentRetrieveRequest
 */
export interface BillingApiBillingStripeInvitationPaymentRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof BillingApiBillingStripeInvitationPaymentRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for billingStripeSubscriptionRetrieve operation in BillingApi.
 * @export
 * @interface BillingApiBillingStripeSubscriptionRetrieveRequest
 */
export interface BillingApiBillingStripeSubscriptionRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof BillingApiBillingStripeSubscriptionRetrieve
   */
  readonly id: number;
}

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
  /**
   *
   * @param {BillingApiBillingCustomerPortalCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingCustomerPortalCreate(
    requestParameters: BillingApiBillingCustomerPortalCreateRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .billingCustomerPortalCreate(requestParameters.customerPortal, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingCustomerRetrieve(options?: AxiosRequestConfig) {
    return BillingApiFp(this.configuration)
      .billingCustomerRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingPlansList(options?: AxiosRequestConfig) {
    return BillingApiFp(this.configuration)
      .billingPlansList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingScreeningPackagesList(options?: AxiosRequestConfig) {
    return BillingApiFp(this.configuration)
      .billingScreeningPackagesList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BillingApiBillingStripeInvitationPaymentInitiatePaymentCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingStripeInvitationPaymentInitiatePaymentCreate(
    requestParameters: BillingApiBillingStripeInvitationPaymentInitiatePaymentCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .billingStripeInvitationPaymentInitiatePaymentCreate(
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {BillingApiBillingStripeInvitationPaymentRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingStripeInvitationPaymentRetrieve(
    requestParameters: BillingApiBillingStripeInvitationPaymentRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .billingStripeInvitationPaymentRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingStripeNodalServicesPaymentInitiatePaymentCreate(
    options?: AxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .billingStripeNodalServicesPaymentInitiatePaymentCreate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingStripeNodalServicesPaymentRetrieve(
    options?: AxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .billingStripeNodalServicesPaymentRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Old subscription flow implementation, left for now as subscriptions are still ongoing
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingStripeSubscriptionInitiatePaymentCreate(
    options?: AxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .billingStripeSubscriptionInitiatePaymentCreate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Old subscription flow implementation, left for now as subscriptions are still ongoing, disabled from using
   * @param {BillingApiBillingStripeSubscriptionRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BillingApi
   */
  public billingStripeSubscriptionRetrieve(
    requestParameters: BillingApiBillingStripeSubscriptionRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return BillingApiFp(this.configuration)
      .billingStripeSubscriptionRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DocusignApi - axios parameter creator
 * @export
 */
export const DocusignApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} parentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docusignDocumentsList: async (
      parentId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'parentId' is not null or undefined
      assertParamExists('docusignDocumentsList', 'parentId', parentId);
      const localVarPath = `/api/docusign/documents/{parent_id}/`.replace(
        `{${'parent_id'}}`,
        encodeURIComponent(String(parentId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateEnvelope} createEnvelope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docusignEnvelopeCreateCreate: async (
      createEnvelope: CreateEnvelope,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createEnvelope' is not null or undefined
      assertParamExists(
        'docusignEnvelopeCreateCreate',
        'createEnvelope',
        createEnvelope,
      );
      const localVarPath = `/api/docusign/envelope/create/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEnvelope,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docusignTemplatesList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/docusign/templates/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DocusignApi - functional programming interface
 * @export
 */
export const DocusignApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DocusignApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} parentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docusignDocumentsList(
      parentId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<ParentDocuSignEnvelope>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.docusignDocumentsList(
          parentId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {CreateEnvelope} createEnvelope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docusignEnvelopeCreateCreate(
      createEnvelope: CreateEnvelope,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateEnvelope>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.docusignEnvelopeCreateCreate(
          createEnvelope,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async docusignTemplatesList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DocuSignTemplateList>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.docusignTemplatesList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * DocusignApi - factory interface
 * @export
 */
export const DocusignApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DocusignApiFp(configuration);
  return {
    /**
     *
     * @param {number} parentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docusignDocumentsList(
      parentId: number,
      options?: any,
    ): AxiosPromise<Array<ParentDocuSignEnvelope>> {
      return localVarFp
        .docusignDocumentsList(parentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {CreateEnvelope} createEnvelope
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docusignEnvelopeCreateCreate(
      createEnvelope: CreateEnvelope,
      options?: any,
    ): AxiosPromise<CreateEnvelope> {
      return localVarFp
        .docusignEnvelopeCreateCreate(createEnvelope, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    docusignTemplatesList(
      options?: any,
    ): AxiosPromise<Array<DocuSignTemplateList>> {
      return localVarFp
        .docusignTemplatesList(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for docusignDocumentsList operation in DocusignApi.
 * @export
 * @interface DocusignApiDocusignDocumentsListRequest
 */
export interface DocusignApiDocusignDocumentsListRequest {
  /**
   *
   * @type {number}
   * @memberof DocusignApiDocusignDocumentsList
   */
  readonly parentId: number;
}

/**
 * Request parameters for docusignEnvelopeCreateCreate operation in DocusignApi.
 * @export
 * @interface DocusignApiDocusignEnvelopeCreateCreateRequest
 */
export interface DocusignApiDocusignEnvelopeCreateCreateRequest {
  /**
   *
   * @type {CreateEnvelope}
   * @memberof DocusignApiDocusignEnvelopeCreateCreate
   */
  readonly createEnvelope: CreateEnvelope;
}

/**
 * DocusignApi - object-oriented interface
 * @export
 * @class DocusignApi
 * @extends {BaseAPI}
 */
export class DocusignApi extends BaseAPI {
  /**
   *
   * @param {DocusignApiDocusignDocumentsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocusignApi
   */
  public docusignDocumentsList(
    requestParameters: DocusignApiDocusignDocumentsListRequest,
    options?: AxiosRequestConfig,
  ) {
    return DocusignApiFp(this.configuration)
      .docusignDocumentsList(requestParameters.parentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DocusignApiDocusignEnvelopeCreateCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocusignApi
   */
  public docusignEnvelopeCreateCreate(
    requestParameters: DocusignApiDocusignEnvelopeCreateCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return DocusignApiFp(this.configuration)
      .docusignEnvelopeCreateCreate(requestParameters.createEnvelope, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DocusignApi
   */
  public docusignTemplatesList(options?: AxiosRequestConfig) {
    return DocusignApiFp(this.configuration)
      .docusignTemplatesList(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FavoriteParentsApi - axios parameter creator
 * @export
 */
export const FavoriteParentsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {UserFavoriteParents} userFavoriteParents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    favoriteParentsCreate: async (
      userFavoriteParents: UserFavoriteParents,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userFavoriteParents' is not null or undefined
      assertParamExists(
        'favoriteParentsCreate',
        'userFavoriteParents',
        userFavoriteParents,
      );
      const localVarPath = `/api/favorite-parents/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userFavoriteParents,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} likedParent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    favoriteParentsDestroy: async (
      likedParent: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'likedParent' is not null or undefined
      assertParamExists('favoriteParentsDestroy', 'likedParent', likedParent);
      const localVarPath = `/api/favorite-parents/{liked_parent}/`.replace(
        `{${'liked_parent'}}`,
        encodeURIComponent(String(likedParent)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    favoriteParentsList: async (
      ordering?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/favorite-parents/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} likedParent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    favoriteParentsRetrieve: async (
      likedParent: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'likedParent' is not null or undefined
      assertParamExists('favoriteParentsRetrieve', 'likedParent', likedParent);
      const localVarPath = `/api/favorite-parents/{liked_parent}/`.replace(
        `{${'liked_parent'}}`,
        encodeURIComponent(String(likedParent)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FavoriteParentsApi - functional programming interface
 * @export
 */
export const FavoriteParentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    FavoriteParentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {UserFavoriteParents} userFavoriteParents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async favoriteParentsCreate(
      userFavoriteParents: UserFavoriteParents,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserFavoriteParents>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.favoriteParentsCreate(
          userFavoriteParents,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} likedParent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async favoriteParentsDestroy(
      likedParent: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.favoriteParentsDestroy(
          likedParent,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async favoriteParentsList(
      ordering?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserFavoriteParents>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.favoriteParentsList(ordering, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} likedParent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async favoriteParentsRetrieve(
      likedParent: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserFavoriteParents>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.favoriteParentsRetrieve(
          likedParent,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * FavoriteParentsApi - factory interface
 * @export
 */
export const FavoriteParentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FavoriteParentsApiFp(configuration);
  return {
    /**
     *
     * @param {UserFavoriteParents} userFavoriteParents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    favoriteParentsCreate(
      userFavoriteParents: UserFavoriteParents,
      options?: any,
    ): AxiosPromise<UserFavoriteParents> {
      return localVarFp
        .favoriteParentsCreate(userFavoriteParents, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} likedParent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    favoriteParentsDestroy(
      likedParent: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .favoriteParentsDestroy(likedParent, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    favoriteParentsList(
      ordering?: string,
      options?: any,
    ): AxiosPromise<Array<UserFavoriteParents>> {
      return localVarFp
        .favoriteParentsList(ordering, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} likedParent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    favoriteParentsRetrieve(
      likedParent: number,
      options?: any,
    ): AxiosPromise<UserFavoriteParents> {
      return localVarFp
        .favoriteParentsRetrieve(likedParent, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for favoriteParentsCreate operation in FavoriteParentsApi.
 * @export
 * @interface FavoriteParentsApiFavoriteParentsCreateRequest
 */
export interface FavoriteParentsApiFavoriteParentsCreateRequest {
  /**
   *
   * @type {UserFavoriteParents}
   * @memberof FavoriteParentsApiFavoriteParentsCreate
   */
  readonly userFavoriteParents: UserFavoriteParents;
}

/**
 * Request parameters for favoriteParentsDestroy operation in FavoriteParentsApi.
 * @export
 * @interface FavoriteParentsApiFavoriteParentsDestroyRequest
 */
export interface FavoriteParentsApiFavoriteParentsDestroyRequest {
  /**
   *
   * @type {number}
   * @memberof FavoriteParentsApiFavoriteParentsDestroy
   */
  readonly likedParent: number;
}

/**
 * Request parameters for favoriteParentsList operation in FavoriteParentsApi.
 * @export
 * @interface FavoriteParentsApiFavoriteParentsListRequest
 */
export interface FavoriteParentsApiFavoriteParentsListRequest {
  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof FavoriteParentsApiFavoriteParentsList
   */
  readonly ordering?: string;
}

/**
 * Request parameters for favoriteParentsRetrieve operation in FavoriteParentsApi.
 * @export
 * @interface FavoriteParentsApiFavoriteParentsRetrieveRequest
 */
export interface FavoriteParentsApiFavoriteParentsRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof FavoriteParentsApiFavoriteParentsRetrieve
   */
  readonly likedParent: number;
}

/**
 * FavoriteParentsApi - object-oriented interface
 * @export
 * @class FavoriteParentsApi
 * @extends {BaseAPI}
 */
export class FavoriteParentsApi extends BaseAPI {
  /**
   *
   * @param {FavoriteParentsApiFavoriteParentsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoriteParentsApi
   */
  public favoriteParentsCreate(
    requestParameters: FavoriteParentsApiFavoriteParentsCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return FavoriteParentsApiFp(this.configuration)
      .favoriteParentsCreate(requestParameters.userFavoriteParents, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FavoriteParentsApiFavoriteParentsDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoriteParentsApi
   */
  public favoriteParentsDestroy(
    requestParameters: FavoriteParentsApiFavoriteParentsDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return FavoriteParentsApiFp(this.configuration)
      .favoriteParentsDestroy(requestParameters.likedParent, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FavoriteParentsApiFavoriteParentsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoriteParentsApi
   */
  public favoriteParentsList(
    requestParameters: FavoriteParentsApiFavoriteParentsListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return FavoriteParentsApiFp(this.configuration)
      .favoriteParentsList(requestParameters.ordering, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {FavoriteParentsApiFavoriteParentsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FavoriteParentsApi
   */
  public favoriteParentsRetrieve(
    requestParameters: FavoriteParentsApiFavoriteParentsRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return FavoriteParentsApiFp(this.configuration)
      .favoriteParentsRetrieve(requestParameters.likedParent, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * IntroductionsApi - axios parameter creator
 * @export
 */
export const IntroductionsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {CreateIntroduction} createIntroduction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsCreate: async (
      createIntroduction: CreateIntroduction,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'createIntroduction' is not null or undefined
      assertParamExists(
        'introductionsCreate',
        'createIntroduction',
        createIntroduction,
      );
      const localVarPath = `/api/introductions/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createIntroduction,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/introductions/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedIntroductionFull} [patchedIntroductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsPartialUpdate: async (
      id: number,
      patchedIntroductionFull?: PatchedIntroductionFull,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('introductionsPartialUpdate', 'id', id);
      const localVarPath = `/api/introductions/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedIntroductionFull,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IntroductionFull} [introductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsUpdate: async (
      id: number,
      introductionFull?: IntroductionFull,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('introductionsUpdate', 'id', id);
      const localVarPath = `/api/introductions/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        introductionFull,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve introduction for request user, where url arg user_id is second introduction user with opposite role. F.e. request user (id=1) is donor, and wants to get introduction details with parent (id=2), calling endpoint with `/2/`.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsUserRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('introductionsUserRetrieve', 'id', id);
      const localVarPath = `/api/introductions/user/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * IntroductionsApi - functional programming interface
 * @export
 */
export const IntroductionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    IntroductionsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CreateIntroduction} createIntroduction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async introductionsCreate(
      createIntroduction: CreateIntroduction,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IntroductionFull>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.introductionsCreate(
          createIntroduction,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async introductionsList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<IntroductionFull>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.introductionsList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedIntroductionFull} [patchedIntroductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async introductionsPartialUpdate(
      id: number,
      patchedIntroductionFull?: PatchedIntroductionFull,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IntroductionFull>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.introductionsPartialUpdate(
          id,
          patchedIntroductionFull,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {IntroductionFull} [introductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async introductionsUpdate(
      id: number,
      introductionFull?: IntroductionFull,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IntroductionFull>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.introductionsUpdate(
          id,
          introductionFull,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Retrieve introduction for request user, where url arg user_id is second introduction user with opposite role. F.e. request user (id=1) is donor, and wants to get introduction details with parent (id=2), calling endpoint with `/2/`.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async introductionsUserRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IntroductionFull>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.introductionsUserRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * IntroductionsApi - factory interface
 * @export
 */
export const IntroductionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = IntroductionsApiFp(configuration);
  return {
    /**
     *
     * @param {CreateIntroduction} createIntroduction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsCreate(
      createIntroduction: CreateIntroduction,
      options?: any,
    ): AxiosPromise<IntroductionFull> {
      return localVarFp
        .introductionsCreate(createIntroduction, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsList(options?: any): AxiosPromise<Array<IntroductionFull>> {
      return localVarFp
        .introductionsList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedIntroductionFull} [patchedIntroductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsPartialUpdate(
      id: number,
      patchedIntroductionFull?: PatchedIntroductionFull,
      options?: any,
    ): AxiosPromise<IntroductionFull> {
      return localVarFp
        .introductionsPartialUpdate(id, patchedIntroductionFull, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IntroductionFull} [introductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsUpdate(
      id: number,
      introductionFull?: IntroductionFull,
      options?: any,
    ): AxiosPromise<IntroductionFull> {
      return localVarFp
        .introductionsUpdate(id, introductionFull, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve introduction for request user, where url arg user_id is second introduction user with opposite role. F.e. request user (id=1) is donor, and wants to get introduction details with parent (id=2), calling endpoint with `/2/`.
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    introductionsUserRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<IntroductionFull> {
      return localVarFp
        .introductionsUserRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for introductionsCreate operation in IntroductionsApi.
 * @export
 * @interface IntroductionsApiIntroductionsCreateRequest
 */
export interface IntroductionsApiIntroductionsCreateRequest {
  /**
   *
   * @type {CreateIntroduction}
   * @memberof IntroductionsApiIntroductionsCreate
   */
  readonly createIntroduction: CreateIntroduction;
}

/**
 * Request parameters for introductionsPartialUpdate operation in IntroductionsApi.
 * @export
 * @interface IntroductionsApiIntroductionsPartialUpdateRequest
 */
export interface IntroductionsApiIntroductionsPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof IntroductionsApiIntroductionsPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedIntroductionFull}
   * @memberof IntroductionsApiIntroductionsPartialUpdate
   */
  readonly patchedIntroductionFull?: PatchedIntroductionFull;
}

/**
 * Request parameters for introductionsUpdate operation in IntroductionsApi.
 * @export
 * @interface IntroductionsApiIntroductionsUpdateRequest
 */
export interface IntroductionsApiIntroductionsUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof IntroductionsApiIntroductionsUpdate
   */
  readonly id: number;

  /**
   *
   * @type {IntroductionFull}
   * @memberof IntroductionsApiIntroductionsUpdate
   */
  readonly introductionFull?: IntroductionFull;
}

/**
 * Request parameters for introductionsUserRetrieve operation in IntroductionsApi.
 * @export
 * @interface IntroductionsApiIntroductionsUserRetrieveRequest
 */
export interface IntroductionsApiIntroductionsUserRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof IntroductionsApiIntroductionsUserRetrieve
   */
  readonly id: number;
}

/**
 * IntroductionsApi - object-oriented interface
 * @export
 * @class IntroductionsApi
 * @extends {BaseAPI}
 */
export class IntroductionsApi extends BaseAPI {
  /**
   *
   * @param {IntroductionsApiIntroductionsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntroductionsApi
   */
  public introductionsCreate(
    requestParameters: IntroductionsApiIntroductionsCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return IntroductionsApiFp(this.configuration)
      .introductionsCreate(requestParameters.createIntroduction, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntroductionsApi
   */
  public introductionsList(options?: AxiosRequestConfig) {
    return IntroductionsApiFp(this.configuration)
      .introductionsList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {IntroductionsApiIntroductionsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntroductionsApi
   */
  public introductionsPartialUpdate(
    requestParameters: IntroductionsApiIntroductionsPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return IntroductionsApiFp(this.configuration)
      .introductionsPartialUpdate(
        requestParameters.id,
        requestParameters.patchedIntroductionFull,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {IntroductionsApiIntroductionsUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntroductionsApi
   */
  public introductionsUpdate(
    requestParameters: IntroductionsApiIntroductionsUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return IntroductionsApiFp(this.configuration)
      .introductionsUpdate(
        requestParameters.id,
        requestParameters.introductionFull,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve introduction for request user, where url arg user_id is second introduction user with opposite role. F.e. request user (id=1) is donor, and wants to get introduction details with parent (id=2), calling endpoint with `/2/`.
   * @param {IntroductionsApiIntroductionsUserRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof IntroductionsApi
   */
  public introductionsUserRetrieve(
    requestParameters: IntroductionsApiIntroductionsUserRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return IntroductionsApiFp(this.configuration)
      .introductionsUserRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MarketplaceApi - axios parameter creator
 * @export
 */
export const MarketplaceApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketplaceParentsList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/marketplace/parents/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MarketplaceApi - functional programming interface
 * @export
 */
export const MarketplaceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MarketplaceApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async marketplaceParentsList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<MarketplaceParents>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.marketplaceParentsList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MarketplaceApi - factory interface
 * @export
 */
export const MarketplaceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MarketplaceApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    marketplaceParentsList(
      options?: any,
    ): AxiosPromise<Array<MarketplaceParents>> {
      return localVarFp
        .marketplaceParentsList(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MarketplaceApi - object-oriented interface
 * @export
 * @class MarketplaceApi
 * @extends {BaseAPI}
 */
export class MarketplaceApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MarketplaceApi
   */
  public marketplaceParentsList(options?: AxiosRequestConfig) {
    return MarketplaceApiFp(this.configuration)
      .marketplaceParentsList(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MedicalReviewsApi - axios parameter creator
 * @export
 */
export const MedicalReviewsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsFaxOutboxFaxStatusDetailsRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'medicalReviewsFaxOutboxFaxStatusDetailsRetrieve',
        'id',
        id,
      );
      const localVarPath =
        `/api/medical-reviews/fax-outbox-fax-status/{id}/details`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMatchProfileRetrieve: async (
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('medicalReviewsMatchProfileRetrieve', 'userId', userId);
      const localVarPath =
        `/api/medical-reviews/match-profile/{user_id}/`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(userId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MedicalDocumentCreate} medicalDocumentCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsInsuranceDocumentsCreateCreate: async (
      medicalDocumentCreate: MedicalDocumentCreate,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'medicalDocumentCreate' is not null or undefined
      assertParamExists(
        'medicalReviewsInsuranceDocumentsCreateCreate',
        'medicalDocumentCreate',
        medicalDocumentCreate,
      );
      const localVarPath = `/api/medical-reviews/insurance-document/create/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        medicalDocumentCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {MedicalDocumentCreate} medicalDocumentCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMedicalDocumentCreateCreate: async (
      medicalDocumentCreate: MedicalDocumentCreate,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'medicalDocumentCreate' is not null or undefined
      assertParamExists(
        'medicalReviewsMedicalDocumentCreateCreate',
        'medicalDocumentCreate',
        medicalDocumentCreate,
      );
      const localVarPath = `/api/medical-reviews/medical-document/create/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        medicalDocumentCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsInsuranceDocumentsDeleteDestroy: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'medicalReviewsInsuranceDocumentsDeleteDestroy',
        'id',
        id,
      );
      const localVarPath =
        `/api/medical-reviews/insurance-document/delete/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMedicalDocumentDeleteDestroy: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('medicalReviewsMedicalDocumentDeleteDestroy', 'id', id);
      const localVarPath =
        `/api/medical-reviews/medical-document/delete/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsInsuranceDocumentsOwnList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/medical-reviews/insurance-documents-own/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMedicalDocumentsOwnList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/medical-reviews/medical-documents-own/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMedicalDocumentsCareGapList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/medical-reviews/care_gap_recommendations/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsPlutoDataSyncDetailsRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('medicalReviewsPlutoDataSyncDetailsRetrieve', 'id', id);
      const localVarPath =
        `/api/medical-reviews/pluto-data-sync/{id}/details`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsPlutoDataSyncUpdateStatusCreate: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'medicalReviewsPlutoDataSyncUpdateStatusCreate',
        'id',
        id,
      );
      const localVarPath =
        `/api/medical-reviews/pluto-data-sync/{id}/update-status`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {RecordPull} recordPull
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsPullCreate: async (
      userId: number,
      recordPull: RecordPull,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('medicalReviewsPullCreate', 'userId', userId);
      // verify required parameter 'recordPull' is not null or undefined
      assertParamExists('medicalReviewsPullCreate', 'recordPull', recordPull);
      const localVarPath = `/api/medical-reviews/{user_id}/pull/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        recordPull,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsRetrieve: async (
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('medicalReviewsRetrieve', 'userId', userId);
      const localVarPath = `/api/medical-reviews/{user_id}/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} stepUid
     * @param {number} userId
     * @param {PatchedReviewStep} [patchedReviewStep]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsStepPartialUpdate: async (
      stepUid: string,
      userId: number,
      patchedReviewStep?: PatchedReviewStep,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'stepUid' is not null or undefined
      assertParamExists('medicalReviewsStepPartialUpdate', 'stepUid', stepUid);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('medicalReviewsStepPartialUpdate', 'userId', userId);
      const localVarPath = `/api/medical-reviews/{user_id}/step/{step_uid}/`
        .replace(`{${'step_uid'}}`, encodeURIComponent(String(stepUid)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedReviewStep,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} stepUid
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsStepRetrieve: async (
      stepUid: string,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'stepUid' is not null or undefined
      assertParamExists('medicalReviewsStepRetrieve', 'stepUid', stepUid);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('medicalReviewsStepRetrieve', 'userId', userId);
      const localVarPath = `/api/medical-reviews/{user_id}/step/{step_uid}/`
        .replace(`{${'step_uid'}}`, encodeURIComponent(String(stepUid)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} stepUid
     * @param {number} userId
     * @param {ReviewStep} reviewStep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsStepUpdate: async (
      stepUid: string,
      userId: number,
      reviewStep: ReviewStep,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'stepUid' is not null or undefined
      assertParamExists('medicalReviewsStepUpdate', 'stepUid', stepUid);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('medicalReviewsStepUpdate', 'userId', userId);
      // verify required parameter 'reviewStep' is not null or undefined
      assertParamExists('medicalReviewsStepUpdate', 'reviewStep', reviewStep);
      const localVarPath = `/api/medical-reviews/{user_id}/step/{step_uid}/`
        .replace(`{${'step_uid'}}`, encodeURIComponent(String(stepUid)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reviewStep,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MedicalReviewsApi - functional programming interface
 * @export
 */
export const MedicalReviewsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    MedicalReviewsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsFaxOutboxFaxStatusDetailsRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<FaxOutboxFaxStatus>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsFaxOutboxFaxStatusDetailsRetrieve(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsMatchProfileRetrieve(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewUserSelf>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsMatchProfileRetrieve(
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {MedicalDocumentCreate} medicalDocumentCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsInsuranceDocumentsCreateCreate(
      medicalDocumentCreate: MedicalDocumentCreate,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MedicalDocument>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsInsuranceDocumentsCreateCreate(
          medicalDocumentCreate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {MedicalDocumentCreate} medicalDocumentCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsMedicalDocumentCreateCreate(
      medicalDocumentCreate: MedicalDocumentCreate,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MedicalDocument>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsMedicalDocumentCreateCreate(
          medicalDocumentCreate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsInsuranceDocumentsDeleteDestroy(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsInsuranceDocumentsDeleteDestroy(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsMedicalDocumentDeleteDestroy(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsMedicalDocumentDeleteDestroy(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsInsuranceDocumentsOwnList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<MedicalDocument>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsInsuranceDocumentsOwnList(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsMedicalDocumentsOwnList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<MedicalDocument>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsMedicalDocumentsOwnList(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsMedicalDocumentsCareGapList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<MedicalDocument>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsMedicalDocumentsCareGapList(
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsPlutoDataSyncDetailsRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlutoDataSync>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsPlutoDataSyncDetailsRetrieve(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsPlutoDataSyncUpdateStatusCreate(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlutoDataSync>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsPlutoDataSyncUpdateStatusCreate(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {RecordPull} recordPull
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsPullCreate(
      userId: number,
      recordPull: RecordPull,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsPullCreate(
          userId,
          recordPull,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsRetrieve(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsRetrieve(userId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} stepUid
     * @param {number} userId
     * @param {PatchedReviewStep} [patchedReviewStep]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsStepPartialUpdate(
      stepUid: string,
      userId: number,
      patchedReviewStep?: PatchedReviewStep,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewStep>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsStepPartialUpdate(
          stepUid,
          userId,
          patchedReviewStep,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} stepUid
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsStepRetrieve(
      stepUid: string,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewStep>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsStepRetrieve(
          stepUid,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} stepUid
     * @param {number} userId
     * @param {ReviewStep} reviewStep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async medicalReviewsStepUpdate(
      stepUid: string,
      userId: number,
      reviewStep: ReviewStep,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewStep>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.medicalReviewsStepUpdate(
          stepUid,
          userId,
          reviewStep,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * MedicalReviewsApi - factory interface
 * @export
 */
export const MedicalReviewsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MedicalReviewsApiFp(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsFaxOutboxFaxStatusDetailsRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<FaxOutboxFaxStatus> {
      return localVarFp
        .medicalReviewsFaxOutboxFaxStatusDetailsRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMatchProfileRetrieve(
      userId: number,
      options?: any,
    ): AxiosPromise<ReviewUserSelf> {
      return localVarFp
        .medicalReviewsMatchProfileRetrieve(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {MedicalDocumentCreate} medicalDocumentCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMedicalDocumentCreateCreate(
      medicalDocumentCreate: MedicalDocumentCreate,
      options?: any,
    ): AxiosPromise<MedicalDocument> {
      return localVarFp
        .medicalReviewsMedicalDocumentCreateCreate(
          medicalDocumentCreate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMedicalDocumentDeleteDestroy(
      id: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .medicalReviewsMedicalDocumentDeleteDestroy(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMedicalDocumentsOwnList(
      options?: any,
    ): AxiosPromise<Array<MedicalDocument>> {
      return localVarFp
        .medicalReviewsMedicalDocumentsOwnList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsMedicalDocumentsCareGapList(
      options?: any,
    ): AxiosPromise<Array<MedicalDocument>> {
      return localVarFp
        .medicalReviewsMedicalDocumentsCareGapList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsPlutoDataSyncDetailsRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<PlutoDataSync> {
      return localVarFp
        .medicalReviewsPlutoDataSyncDetailsRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsPlutoDataSyncUpdateStatusCreate(
      id: number,
      options?: any,
    ): AxiosPromise<PlutoDataSync> {
      return localVarFp
        .medicalReviewsPlutoDataSyncUpdateStatusCreate(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {RecordPull} recordPull
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsPullCreate(
      userId: number,
      recordPull: RecordPull,
      options?: any,
    ): AxiosPromise<Review> {
      return localVarFp
        .medicalReviewsPullCreate(userId, recordPull, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsRetrieve(
      userId: number,
      options?: any,
    ): AxiosPromise<Review> {
      return localVarFp
        .medicalReviewsRetrieve(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} stepUid
     * @param {number} userId
     * @param {PatchedReviewStep} [patchedReviewStep]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsStepPartialUpdate(
      stepUid: string,
      userId: number,
      patchedReviewStep?: PatchedReviewStep,
      options?: any,
    ): AxiosPromise<ReviewStep> {
      return localVarFp
        .medicalReviewsStepPartialUpdate(
          stepUid,
          userId,
          patchedReviewStep,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} stepUid
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsStepRetrieve(
      stepUid: string,
      userId: number,
      options?: any,
    ): AxiosPromise<ReviewStep> {
      return localVarFp
        .medicalReviewsStepRetrieve(stepUid, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} stepUid
     * @param {number} userId
     * @param {ReviewStep} reviewStep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    medicalReviewsStepUpdate(
      stepUid: string,
      userId: number,
      reviewStep: ReviewStep,
      options?: any,
    ): AxiosPromise<ReviewStep> {
      return localVarFp
        .medicalReviewsStepUpdate(stepUid, userId, reviewStep, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for medicalReviewsFaxOutboxFaxStatusDetailsRetrieve operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsFaxOutboxFaxStatusDetailsRetrieveRequest
 */
export interface MedicalReviewsApiMedicalReviewsFaxOutboxFaxStatusDetailsRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsFaxOutboxFaxStatusDetailsRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for medicalReviewsMatchProfileRetrieve operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsMatchProfileRetrieveRequest
 */
export interface MedicalReviewsApiMedicalReviewsMatchProfileRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsMatchProfileRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for medicalReviewsInsuranceDocumentCreateCreate operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsInsuranceDocumentCreateCreateRequest
 */
export interface MedicalReviewsApiMedicalReviewsInsuranceDocumentCreateCreateRequest {
  /**
   *
   * @type {MedicalDocumentCreate}
   * @memberof MedicalReviewsApiMedicalReviewsInsuranceDocumentCreateCreate
   */
  readonly insuranceDocumentCreate: MedicalDocumentCreate;
}

/**
 * Request parameters for medicalReviewsMedicalDocumentCreateCreate operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsMedicalDocumentCreateCreateRequest
 */
export interface MedicalReviewsApiMedicalReviewsMedicalDocumentCreateCreateRequest {
  /**
   *
   * @type {MedicalDocumentCreate}
   * @memberof MedicalReviewsApiMedicalReviewsMedicalDocumentCreateCreate
   */
  readonly medicalDocumentCreate: MedicalDocumentCreate;
}

/**
 * Request parameters for medicalReviewsInsuranceDocumentDeleteDestroy operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsInsuranceDocumentDeleteDestroyRequest
 */
export interface MedicalReviewsApiMedicalReviewsInsuranceDocumentDeleteDestroyRequest {
  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsInsuranceDocumentDeleteDestroy
   */
  readonly id: number;
}

/**
 * Request parameters for medicalReviewsMedicalDocumentDeleteDestroy operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsMedicalDocumentDeleteDestroyRequest
 */
export interface MedicalReviewsApiMedicalReviewsMedicalDocumentDeleteDestroyRequest {
  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsMedicalDocumentDeleteDestroy
   */
  readonly id: number;
}

/**
 * Request parameters for medicalReviewsPlutoDataSyncDetailsRetrieve operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsPlutoDataSyncDetailsRetrieveRequest
 */
export interface MedicalReviewsApiMedicalReviewsPlutoDataSyncDetailsRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsPlutoDataSyncDetailsRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for medicalReviewsPlutoDataSyncUpdateStatusCreate operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsPlutoDataSyncUpdateStatusCreateRequest
 */
export interface MedicalReviewsApiMedicalReviewsPlutoDataSyncUpdateStatusCreateRequest {
  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsPlutoDataSyncUpdateStatusCreate
   */
  readonly id: number;
}

/**
 * Request parameters for medicalReviewsPullCreate operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsPullCreateRequest
 */
export interface MedicalReviewsApiMedicalReviewsPullCreateRequest {
  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsPullCreate
   */
  readonly userId: number;

  /**
   *
   * @type {RecordPull}
   * @memberof MedicalReviewsApiMedicalReviewsPullCreate
   */
  readonly recordPull: RecordPull;
}

/**
 * Request parameters for medicalReviewsRetrieve operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsRetrieveRequest
 */
export interface MedicalReviewsApiMedicalReviewsRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for medicalReviewsStepPartialUpdate operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsStepPartialUpdateRequest
 */
export interface MedicalReviewsApiMedicalReviewsStepPartialUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof MedicalReviewsApiMedicalReviewsStepPartialUpdate
   */
  readonly stepUid: string;

  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsStepPartialUpdate
   */
  readonly userId: number;

  /**
   *
   * @type {PatchedReviewStep}
   * @memberof MedicalReviewsApiMedicalReviewsStepPartialUpdate
   */
  readonly patchedReviewStep?: PatchedReviewStep;
}

/**
 * Request parameters for medicalReviewsStepRetrieve operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsStepRetrieveRequest
 */
export interface MedicalReviewsApiMedicalReviewsStepRetrieveRequest {
  /**
   *
   * @type {string}
   * @memberof MedicalReviewsApiMedicalReviewsStepRetrieve
   */
  readonly stepUid: string;

  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsStepRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for medicalReviewsStepUpdate operation in MedicalReviewsApi.
 * @export
 * @interface MedicalReviewsApiMedicalReviewsStepUpdateRequest
 */
export interface MedicalReviewsApiMedicalReviewsStepUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof MedicalReviewsApiMedicalReviewsStepUpdate
   */
  readonly stepUid: string;

  /**
   *
   * @type {number}
   * @memberof MedicalReviewsApiMedicalReviewsStepUpdate
   */
  readonly userId: number;

  /**
   *
   * @type {ReviewStep}
   * @memberof MedicalReviewsApiMedicalReviewsStepUpdate
   */
  readonly reviewStep: ReviewStep;
}

/**
 * MedicalReviewsApi - object-oriented interface
 * @export
 * @class MedicalReviewsApi
 * @extends {BaseAPI}
 */
export class MedicalReviewsApi extends BaseAPI {
  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsFaxOutboxFaxStatusDetailsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsFaxOutboxFaxStatusDetailsRetrieve(
    requestParameters: MedicalReviewsApiMedicalReviewsFaxOutboxFaxStatusDetailsRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsFaxOutboxFaxStatusDetailsRetrieve(
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsMatchProfileRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsMatchProfileRetrieve(
    requestParameters: MedicalReviewsApiMedicalReviewsMatchProfileRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsMatchProfileRetrieve(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsInsuranceDocumentsCreateCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsInsuranceDocumentsCreateCreate(
    requestParameters: MedicalReviewsApiMedicalReviewsInsuranceDocumentCreateCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsInsuranceDocumentsCreateCreate(
        requestParameters.insuranceDocumentCreate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsMedicalDocumentCreateCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsMedicalDocumentCreateCreate(
    requestParameters: MedicalReviewsApiMedicalReviewsMedicalDocumentCreateCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsMedicalDocumentCreateCreate(
        requestParameters.medicalDocumentCreate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsInsuranceDocumentDeleteDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsInsuranceDocumentsDeleteDestroy(
    requestParameters: MedicalReviewsApiMedicalReviewsInsuranceDocumentDeleteDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsInsuranceDocumentsDeleteDestroy(
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsMedicalDocumentDeleteDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsMedicalDocumentDeleteDestroy(
    requestParameters: MedicalReviewsApiMedicalReviewsMedicalDocumentDeleteDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsMedicalDocumentDeleteDestroy(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsInsuranceDocumentsOwnList(options?: AxiosRequestConfig) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsInsuranceDocumentsOwnList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsMedicalDocumentsOwnList(options?: AxiosRequestConfig) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsMedicalDocumentsOwnList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsMedicalDocumentsCareGapList(
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsMedicalDocumentsCareGapList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsPlutoDataSyncDetailsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsPlutoDataSyncDetailsRetrieve(
    requestParameters: MedicalReviewsApiMedicalReviewsPlutoDataSyncDetailsRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsPlutoDataSyncDetailsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsPlutoDataSyncUpdateStatusCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsPlutoDataSyncUpdateStatusCreate(
    requestParameters: MedicalReviewsApiMedicalReviewsPlutoDataSyncUpdateStatusCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsPlutoDataSyncUpdateStatusCreate(
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsPullCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsPullCreate(
    requestParameters: MedicalReviewsApiMedicalReviewsPullCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsPullCreate(
        requestParameters.userId,
        requestParameters.recordPull,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsRetrieve(
    requestParameters: MedicalReviewsApiMedicalReviewsRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsRetrieve(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsStepPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsStepPartialUpdate(
    requestParameters: MedicalReviewsApiMedicalReviewsStepPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsStepPartialUpdate(
        requestParameters.stepUid,
        requestParameters.userId,
        requestParameters.patchedReviewStep,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsStepRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsStepRetrieve(
    requestParameters: MedicalReviewsApiMedicalReviewsStepRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsStepRetrieve(
        requestParameters.stepUid,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {MedicalReviewsApiMedicalReviewsStepUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalReviewsApi
   */
  public medicalReviewsStepUpdate(
    requestParameters: MedicalReviewsApiMedicalReviewsStepUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return MedicalReviewsApiFp(this.configuration)
      .medicalReviewsStepUpdate(
        requestParameters.stepUid,
        requestParameters.userId,
        requestParameters.reviewStep,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {Notification} notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsCreate: async (
      notification: Notification,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'notification' is not null or undefined
      assertParamExists('notificationsCreate', 'notification', notification);
      const localVarPath = `/api/notifications/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notification,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notifications/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsMarkAsReadCreate: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/notifications/mark-as-read/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedNotification} [patchedNotification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsPartialUpdate: async (
      id: number,
      patchedNotification?: PatchedNotification,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('notificationsPartialUpdate', 'id', id);
      const localVarPath = `/api/notifications/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedNotification,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Notification} notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsUpdate: async (
      id: number,
      notification: Notification,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('notificationsUpdate', 'id', id);
      // verify required parameter 'notification' is not null or undefined
      assertParamExists('notificationsUpdate', 'notification', notification);
      const localVarPath = `/api/notifications/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        notification,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    NotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Notification} notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsCreate(
      notification: Notification,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsCreate(
          notification,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<Notification>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsMarkAsReadCreate(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsMarkAsReadCreate(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedNotification} [patchedNotification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsPartialUpdate(
      id: number,
      patchedNotification?: PatchedNotification,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsPartialUpdate(
          id,
          patchedNotification,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {Notification} notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async notificationsUpdate(
      id: number,
      notification: Notification,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.notificationsUpdate(
          id,
          notification,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = NotificationsApiFp(configuration);
  return {
    /**
     *
     * @param {Notification} notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsCreate(
      notification: Notification,
      options?: any,
    ): AxiosPromise<Notification> {
      return localVarFp
        .notificationsCreate(notification, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsList(options?: any): AxiosPromise<Array<Notification>> {
      return localVarFp
        .notificationsList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsMarkAsReadCreate(options?: any): AxiosPromise<void> {
      return localVarFp
        .notificationsMarkAsReadCreate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedNotification} [patchedNotification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsPartialUpdate(
      id: number,
      patchedNotification?: PatchedNotification,
      options?: any,
    ): AxiosPromise<Notification> {
      return localVarFp
        .notificationsPartialUpdate(id, patchedNotification, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Notification} notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    notificationsUpdate(
      id: number,
      notification: Notification,
      options?: any,
    ): AxiosPromise<Notification> {
      return localVarFp
        .notificationsUpdate(id, notification, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for notificationsCreate operation in NotificationsApi.
 * @export
 * @interface NotificationsApiNotificationsCreateRequest
 */
export interface NotificationsApiNotificationsCreateRequest {
  /**
   *
   * @type {Notification}
   * @memberof NotificationsApiNotificationsCreate
   */
  readonly notification: Notification;
}

/**
 * Request parameters for notificationsPartialUpdate operation in NotificationsApi.
 * @export
 * @interface NotificationsApiNotificationsPartialUpdateRequest
 */
export interface NotificationsApiNotificationsPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof NotificationsApiNotificationsPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedNotification}
   * @memberof NotificationsApiNotificationsPartialUpdate
   */
  readonly patchedNotification?: PatchedNotification;
}

/**
 * Request parameters for notificationsUpdate operation in NotificationsApi.
 * @export
 * @interface NotificationsApiNotificationsUpdateRequest
 */
export interface NotificationsApiNotificationsUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof NotificationsApiNotificationsUpdate
   */
  readonly id: number;

  /**
   *
   * @type {Notification}
   * @memberof NotificationsApiNotificationsUpdate
   */
  readonly notification: Notification;
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
  /**
   *
   * @param {NotificationsApiNotificationsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsCreate(
    requestParameters: NotificationsApiNotificationsCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return NotificationsApiFp(this.configuration)
      .notificationsCreate(requestParameters.notification, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsList(options?: AxiosRequestConfig) {
    return NotificationsApiFp(this.configuration)
      .notificationsList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsMarkAsReadCreate(options?: AxiosRequestConfig) {
    return NotificationsApiFp(this.configuration)
      .notificationsMarkAsReadCreate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NotificationsApiNotificationsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsPartialUpdate(
    requestParameters: NotificationsApiNotificationsPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return NotificationsApiFp(this.configuration)
      .notificationsPartialUpdate(
        requestParameters.id,
        requestParameters.patchedNotification,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {NotificationsApiNotificationsUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationsApi
   */
  public notificationsUpdate(
    requestParameters: NotificationsApiNotificationsUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return NotificationsApiFp(this.configuration)
      .notificationsUpdate(
        requestParameters.id,
        requestParameters.notification,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * RegistrationApi - axios parameter creator
 * @export
 */
export const RegistrationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {Register} register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationCreate: async (
      register: Register,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'register' is not null or undefined
      assertParamExists('registrationCreate', 'register', register);
      const localVarPath = `/api/registration/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        register,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ResendEmailVerification} resendEmailVerification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationResendEmailCreate: async (
      resendEmailVerification: ResendEmailVerification,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'resendEmailVerification' is not null or undefined
      assertParamExists(
        'registrationResendEmailCreate',
        'resendEmailVerification',
        resendEmailVerification,
      );
      const localVarPath = `/api/registration/resend-email/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        resendEmailVerification,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {VerifyEmail} verifyEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationVerifyEmailCreate: async (
      verifyEmail: VerifyEmail,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'verifyEmail' is not null or undefined
      assertParamExists(
        'registrationVerifyEmailCreate',
        'verifyEmail',
        verifyEmail,
      );
      const localVarPath = `/api/registration/verify-email/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        verifyEmail,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RegistrationApi - functional programming interface
 * @export
 */
export const RegistrationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    RegistrationApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {Register} register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationCreate(
      register: Register,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registrationCreate(register, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ResendEmailVerification} resendEmailVerification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationResendEmailCreate(
      resendEmailVerification: ResendEmailVerification,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registrationResendEmailCreate(
          resendEmailVerification,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {VerifyEmail} verifyEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async registrationVerifyEmailCreate(
      verifyEmail: VerifyEmail,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.registrationVerifyEmailCreate(
          verifyEmail,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * RegistrationApi - factory interface
 * @export
 */
export const RegistrationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = RegistrationApiFp(configuration);
  return {
    /**
     *
     * @param {Register} register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationCreate(
      register: Register,
      options?: any,
    ): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .registrationCreate(register, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ResendEmailVerification} resendEmailVerification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationResendEmailCreate(
      resendEmailVerification: ResendEmailVerification,
      options?: any,
    ): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .registrationResendEmailCreate(resendEmailVerification, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {VerifyEmail} verifyEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    registrationVerifyEmailCreate(
      verifyEmail: VerifyEmail,
      options?: any,
    ): AxiosPromise<RestAuthDetail> {
      return localVarFp
        .registrationVerifyEmailCreate(verifyEmail, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for registrationCreate operation in RegistrationApi.
 * @export
 * @interface RegistrationApiRegistrationCreateRequest
 */
export interface RegistrationApiRegistrationCreateRequest {
  /**
   *
   * @type {Register}
   * @memberof RegistrationApiRegistrationCreate
   */
  readonly register: Register;
}

/**
 * Request parameters for registrationResendEmailCreate operation in RegistrationApi.
 * @export
 * @interface RegistrationApiRegistrationResendEmailCreateRequest
 */
export interface RegistrationApiRegistrationResendEmailCreateRequest {
  /**
   *
   * @type {ResendEmailVerification}
   * @memberof RegistrationApiRegistrationResendEmailCreate
   */
  readonly resendEmailVerification: ResendEmailVerification;
}

/**
 * Request parameters for registrationVerifyEmailCreate operation in RegistrationApi.
 * @export
 * @interface RegistrationApiRegistrationVerifyEmailCreateRequest
 */
export interface RegistrationApiRegistrationVerifyEmailCreateRequest {
  /**
   *
   * @type {VerifyEmail}
   * @memberof RegistrationApiRegistrationVerifyEmailCreate
   */
  readonly verifyEmail: VerifyEmail;
}

/**
 * RegistrationApi - object-oriented interface
 * @export
 * @class RegistrationApi
 * @extends {BaseAPI}
 */
export class RegistrationApi extends BaseAPI {
  /**
   *
   * @param {RegistrationApiRegistrationCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationApi
   */
  public registrationCreate(
    requestParameters: RegistrationApiRegistrationCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return RegistrationApiFp(this.configuration)
      .registrationCreate(requestParameters.register, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RegistrationApiRegistrationResendEmailCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationApi
   */
  public registrationResendEmailCreate(
    requestParameters: RegistrationApiRegistrationResendEmailCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return RegistrationApiFp(this.configuration)
      .registrationResendEmailCreate(
        requestParameters.resendEmailVerification,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {RegistrationApiRegistrationVerifyEmailCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RegistrationApi
   */
  public registrationVerifyEmailCreate(
    requestParameters: RegistrationApiRegistrationVerifyEmailCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return RegistrationApiFp(this.configuration)
      .registrationVerifyEmailCreate(requestParameters.verifyEmail, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SchemaApi - axios parameter creator
 * @export
 */
export const SchemaApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param {'json' | 'yaml'} [format]
     * @param {'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant'} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schemaRetrieve: async (
      format?: 'json' | 'yaml',
      lang?:
        | 'af'
        | 'ar'
        | 'ar-dz'
        | 'ast'
        | 'az'
        | 'be'
        | 'bg'
        | 'bn'
        | 'br'
        | 'bs'
        | 'ca'
        | 'cs'
        | 'cy'
        | 'da'
        | 'de'
        | 'dsb'
        | 'el'
        | 'en'
        | 'en-au'
        | 'en-gb'
        | 'eo'
        | 'es'
        | 'es-ar'
        | 'es-co'
        | 'es-mx'
        | 'es-ni'
        | 'es-ve'
        | 'et'
        | 'eu'
        | 'fa'
        | 'fi'
        | 'fr'
        | 'fy'
        | 'ga'
        | 'gd'
        | 'gl'
        | 'he'
        | 'hi'
        | 'hr'
        | 'hsb'
        | 'hu'
        | 'hy'
        | 'ia'
        | 'id'
        | 'ig'
        | 'io'
        | 'is'
        | 'it'
        | 'ja'
        | 'ka'
        | 'kab'
        | 'kk'
        | 'km'
        | 'kn'
        | 'ko'
        | 'ky'
        | 'lb'
        | 'lt'
        | 'lv'
        | 'mk'
        | 'ml'
        | 'mn'
        | 'mr'
        | 'ms'
        | 'my'
        | 'nb'
        | 'ne'
        | 'nl'
        | 'nn'
        | 'os'
        | 'pa'
        | 'pl'
        | 'pt'
        | 'pt-br'
        | 'ro'
        | 'ru'
        | 'sk'
        | 'sl'
        | 'sq'
        | 'sr'
        | 'sr-latn'
        | 'sv'
        | 'sw'
        | 'ta'
        | 'te'
        | 'tg'
        | 'th'
        | 'tk'
        | 'tr'
        | 'tt'
        | 'udm'
        | 'uk'
        | 'ur'
        | 'uz'
        | 'vi'
        | 'zh-hans'
        | 'zh-hant',
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/schema/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (lang !== undefined) {
        localVarQueryParameter['lang'] = lang;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SchemaApi - functional programming interface
 * @export
 */
export const SchemaApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SchemaApiAxiosParamCreator(configuration);
  return {
    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param {'json' | 'yaml'} [format]
     * @param {'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant'} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async schemaRetrieve(
      format?: 'json' | 'yaml',
      lang?:
        | 'af'
        | 'ar'
        | 'ar-dz'
        | 'ast'
        | 'az'
        | 'be'
        | 'bg'
        | 'bn'
        | 'br'
        | 'bs'
        | 'ca'
        | 'cs'
        | 'cy'
        | 'da'
        | 'de'
        | 'dsb'
        | 'el'
        | 'en'
        | 'en-au'
        | 'en-gb'
        | 'eo'
        | 'es'
        | 'es-ar'
        | 'es-co'
        | 'es-mx'
        | 'es-ni'
        | 'es-ve'
        | 'et'
        | 'eu'
        | 'fa'
        | 'fi'
        | 'fr'
        | 'fy'
        | 'ga'
        | 'gd'
        | 'gl'
        | 'he'
        | 'hi'
        | 'hr'
        | 'hsb'
        | 'hu'
        | 'hy'
        | 'ia'
        | 'id'
        | 'ig'
        | 'io'
        | 'is'
        | 'it'
        | 'ja'
        | 'ka'
        | 'kab'
        | 'kk'
        | 'km'
        | 'kn'
        | 'ko'
        | 'ky'
        | 'lb'
        | 'lt'
        | 'lv'
        | 'mk'
        | 'ml'
        | 'mn'
        | 'mr'
        | 'ms'
        | 'my'
        | 'nb'
        | 'ne'
        | 'nl'
        | 'nn'
        | 'os'
        | 'pa'
        | 'pl'
        | 'pt'
        | 'pt-br'
        | 'ro'
        | 'ru'
        | 'sk'
        | 'sl'
        | 'sq'
        | 'sr'
        | 'sr-latn'
        | 'sv'
        | 'sw'
        | 'ta'
        | 'te'
        | 'tg'
        | 'th'
        | 'tk'
        | 'tr'
        | 'tt'
        | 'udm'
        | 'uk'
        | 'ur'
        | 'uz'
        | 'vi'
        | 'zh-hans'
        | 'zh-hant',
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<{ [key: string]: any }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.schemaRetrieve(
        format,
        lang,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * SchemaApi - factory interface
 * @export
 */
export const SchemaApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SchemaApiFp(configuration);
  return {
    /**
     * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     * @param {'json' | 'yaml'} [format]
     * @param {'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant'} [lang]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    schemaRetrieve(
      format?: 'json' | 'yaml',
      lang?:
        | 'af'
        | 'ar'
        | 'ar-dz'
        | 'ast'
        | 'az'
        | 'be'
        | 'bg'
        | 'bn'
        | 'br'
        | 'bs'
        | 'ca'
        | 'cs'
        | 'cy'
        | 'da'
        | 'de'
        | 'dsb'
        | 'el'
        | 'en'
        | 'en-au'
        | 'en-gb'
        | 'eo'
        | 'es'
        | 'es-ar'
        | 'es-co'
        | 'es-mx'
        | 'es-ni'
        | 'es-ve'
        | 'et'
        | 'eu'
        | 'fa'
        | 'fi'
        | 'fr'
        | 'fy'
        | 'ga'
        | 'gd'
        | 'gl'
        | 'he'
        | 'hi'
        | 'hr'
        | 'hsb'
        | 'hu'
        | 'hy'
        | 'ia'
        | 'id'
        | 'ig'
        | 'io'
        | 'is'
        | 'it'
        | 'ja'
        | 'ka'
        | 'kab'
        | 'kk'
        | 'km'
        | 'kn'
        | 'ko'
        | 'ky'
        | 'lb'
        | 'lt'
        | 'lv'
        | 'mk'
        | 'ml'
        | 'mn'
        | 'mr'
        | 'ms'
        | 'my'
        | 'nb'
        | 'ne'
        | 'nl'
        | 'nn'
        | 'os'
        | 'pa'
        | 'pl'
        | 'pt'
        | 'pt-br'
        | 'ro'
        | 'ru'
        | 'sk'
        | 'sl'
        | 'sq'
        | 'sr'
        | 'sr-latn'
        | 'sv'
        | 'sw'
        | 'ta'
        | 'te'
        | 'tg'
        | 'th'
        | 'tk'
        | 'tr'
        | 'tt'
        | 'udm'
        | 'uk'
        | 'ur'
        | 'uz'
        | 'vi'
        | 'zh-hans'
        | 'zh-hant',
      options?: any,
    ): AxiosPromise<{ [key: string]: any }> {
      return localVarFp
        .schemaRetrieve(format, lang, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for schemaRetrieve operation in SchemaApi.
 * @export
 * @interface SchemaApiSchemaRetrieveRequest
 */
export interface SchemaApiSchemaRetrieveRequest {
  /**
   *
   * @type {'json' | 'yaml'}
   * @memberof SchemaApiSchemaRetrieve
   */
  readonly format?: 'json' | 'yaml';

  /**
   *
   * @type {'af' | 'ar' | 'ar-dz' | 'ast' | 'az' | 'be' | 'bg' | 'bn' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'dsb' | 'el' | 'en' | 'en-au' | 'en-gb' | 'eo' | 'es' | 'es-ar' | 'es-co' | 'es-mx' | 'es-ni' | 'es-ve' | 'et' | 'eu' | 'fa' | 'fi' | 'fr' | 'fy' | 'ga' | 'gd' | 'gl' | 'he' | 'hi' | 'hr' | 'hsb' | 'hu' | 'hy' | 'ia' | 'id' | 'ig' | 'io' | 'is' | 'it' | 'ja' | 'ka' | 'kab' | 'kk' | 'km' | 'kn' | 'ko' | 'ky' | 'lb' | 'lt' | 'lv' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'my' | 'nb' | 'ne' | 'nl' | 'nn' | 'os' | 'pa' | 'pl' | 'pt' | 'pt-br' | 'ro' | 'ru' | 'sk' | 'sl' | 'sq' | 'sr' | 'sr-latn' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tr' | 'tt' | 'udm' | 'uk' | 'ur' | 'uz' | 'vi' | 'zh-hans' | 'zh-hant'}
   * @memberof SchemaApiSchemaRetrieve
   */
  readonly lang?:
    | 'af'
    | 'ar'
    | 'ar-dz'
    | 'ast'
    | 'az'
    | 'be'
    | 'bg'
    | 'bn'
    | 'br'
    | 'bs'
    | 'ca'
    | 'cs'
    | 'cy'
    | 'da'
    | 'de'
    | 'dsb'
    | 'el'
    | 'en'
    | 'en-au'
    | 'en-gb'
    | 'eo'
    | 'es'
    | 'es-ar'
    | 'es-co'
    | 'es-mx'
    | 'es-ni'
    | 'es-ve'
    | 'et'
    | 'eu'
    | 'fa'
    | 'fi'
    | 'fr'
    | 'fy'
    | 'ga'
    | 'gd'
    | 'gl'
    | 'he'
    | 'hi'
    | 'hr'
    | 'hsb'
    | 'hu'
    | 'hy'
    | 'ia'
    | 'id'
    | 'ig'
    | 'io'
    | 'is'
    | 'it'
    | 'ja'
    | 'ka'
    | 'kab'
    | 'kk'
    | 'km'
    | 'kn'
    | 'ko'
    | 'ky'
    | 'lb'
    | 'lt'
    | 'lv'
    | 'mk'
    | 'ml'
    | 'mn'
    | 'mr'
    | 'ms'
    | 'my'
    | 'nb'
    | 'ne'
    | 'nl'
    | 'nn'
    | 'os'
    | 'pa'
    | 'pl'
    | 'pt'
    | 'pt-br'
    | 'ro'
    | 'ru'
    | 'sk'
    | 'sl'
    | 'sq'
    | 'sr'
    | 'sr-latn'
    | 'sv'
    | 'sw'
    | 'ta'
    | 'te'
    | 'tg'
    | 'th'
    | 'tk'
    | 'tr'
    | 'tt'
    | 'udm'
    | 'uk'
    | 'ur'
    | 'uz'
    | 'vi'
    | 'zh-hans'
    | 'zh-hant';
}

/**
 * SchemaApi - object-oriented interface
 * @export
 * @class SchemaApi
 * @extends {BaseAPI}
 */
export class SchemaApi extends BaseAPI {
  /**
   * OpenApi3 schema for this API. Format can be selected via content negotiation.  - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
   * @param {SchemaApiSchemaRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SchemaApi
   */
  public schemaRetrieve(
    requestParameters: SchemaApiSchemaRetrieveRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return SchemaApiFp(this.configuration)
      .schemaRetrieve(requestParameters.format, requestParameters.lang, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ScreensApi - axios parameter creator
 * @export
 */
export const ScreensApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensCheckrScreenForIntroductionUsersRetrieve: async (
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists(
        'screensCheckrScreenForIntroductionUsersRetrieve',
        'userId',
        userId,
      );
      const localVarPath =
        `/api/screens/checkr-screen/for-introduction-users/{user_id}/`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(userId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensForIntroductionUsersList: async (
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('screensForIntroductionUsersList', 'userId', userId);
      const localVarPath =
        `/api/screens/for-introduction-users/{user_id}/`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(userId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedMedicalRecordReviewScreenDocuments} [patchedMedicalRecordReviewScreenDocuments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensInsuranceDocumentPartialUpdate: async (
      id: number,
      patchedMedicalRecordReviewScreenDocuments?: PatchedMedicalRecordReviewScreenDocuments,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('screensInsuranceDocumentPartialUpdate', 'id', id);
      const localVarPath = `/api/screens/{id}/medical-document/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedMedicalRecordReviewScreenDocuments,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensInsuranceDocumentRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('screensInsuranceDocumentRetrieve', 'id', id);
      const localVarPath = `/api/screens/{id}/medical-document/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {MedicalRecordReviewScreenDocuments} medicalRecordReviewScreenDocuments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensInsuranceDocumentUpdate: async (
      id: number,
      medicalRecordReviewScreenDocuments: MedicalRecordReviewScreenDocuments,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('screensInsuranceDocumentUpdate', 'id', id);
      // verify required parameter 'medicalRecordReviewScreenDocuments' is not null or undefined
      assertParamExists(
        'screensInsuranceDocumentUpdate',
        'medicalRecordReviewScreenDocuments',
        medicalRecordReviewScreenDocuments,
      );
      const localVarPath = `/api/screens/{id}/insurance-document/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        medicalRecordReviewScreenDocuments,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/screens/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedScreen} [patchedScreen]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensPartialUpdate: async (
      id: number,
      patchedScreen?: PatchedScreen,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('screensPartialUpdate', 'id', id);
      const localVarPath = `/api/screens/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedScreen,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CheckrScreenResendInvitations} checkrScreenResendInvitations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensResendCheckrInvitationsCreate: async (
      id: number,
      checkrScreenResendInvitations: CheckrScreenResendInvitations,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('screensResendCheckrInvitationsCreate', 'id', id);
      // verify required parameter 'checkrScreenResendInvitations' is not null or undefined
      assertParamExists(
        'screensResendCheckrInvitationsCreate',
        'checkrScreenResendInvitations',
        checkrScreenResendInvitations,
      );
      const localVarPath =
        `/api/screens/{id}/resend-checkr-invitations/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkrScreenResendInvitations,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {CheckrScreen} checkrScreen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensResubmitCheckrCreate: async (
      id: number,
      checkrScreen: CheckrScreen,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('screensResubmitCheckrCreate', 'id', id);
      // verify required parameter 'checkrScreen' is not null or undefined
      assertParamExists(
        'screensResubmitCheckrCreate',
        'checkrScreen',
        checkrScreen,
      );
      const localVarPath = `/api/screens/{id}/resubmit-checkr/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        checkrScreen,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('screensRetrieve', 'id', id);
      const localVarPath = `/api/screens/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensStripeIdCheckVerificationSessionRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists(
        'screensStripeIdCheckVerificationSessionRetrieve',
        'id',
        id,
      );
      const localVarPath =
        `/api/screens/{id}/stripe-id-check-verification-session/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(id)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {ScreenContentObject} [screenContentObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensSubmitCreate: async (
      id: number,
      screenContentObject?: ScreenContentObject,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('screensSubmitCreate', 'id', id);
      const localVarPath = `/api/screens/{id}/submit/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        screenContentObject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Screen} screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensUpdate: async (
      id: number,
      screen: Screen,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('screensUpdate', 'id', id);
      // verify required parameter 'screen' is not null or undefined
      assertParamExists('screensUpdate', 'screen', screen);
      const localVarPath = `/api/screens/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        screen,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ScreensApi - functional programming interface
 * @export
 */
export const ScreensApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ScreensApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensCheckrScreenForIntroductionUsersRetrieve(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CheckrScreenUserFromIntroduction>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensCheckrScreenForIntroductionUsersRetrieve(
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensForIntroductionUsersList(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserFromIntroductionScreen>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensForIntroductionUsersList(
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedMedicalRecordReviewScreenDocuments} [patchedMedicalRecordReviewScreenDocuments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensInsuranceDocumentPartialUpdate(
      id: number,
      patchedMedicalRecordReviewScreenDocuments?: PatchedMedicalRecordReviewScreenDocuments,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MedicalRecordReviewScreenDocuments>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensInsuranceDocumentPartialUpdate(
          id,
          patchedMedicalRecordReviewScreenDocuments,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensInsuranceDocumentRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MedicalRecordReviewScreenDocuments>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensInsuranceDocumentRetrieve(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {MedicalRecordReviewScreenDocuments} medicalRecordReviewScreenDocuments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensInsuranceDocumentUpdate(
      id: number,
      medicalRecordReviewScreenDocuments: MedicalRecordReviewScreenDocuments,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MedicalRecordReviewScreenDocuments>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensInsuranceDocumentUpdate(
          id,
          medicalRecordReviewScreenDocuments,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensList(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Screen>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.screensList(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedScreen} [patchedScreen]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensPartialUpdate(
      id: number,
      patchedScreen?: PatchedScreen,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Screen>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensPartialUpdate(
          id,
          patchedScreen,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {CheckrScreenResendInvitations} checkrScreenResendInvitations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensResendCheckrInvitationsCreate(
      id: number,
      checkrScreenResendInvitations: CheckrScreenResendInvitations,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CheckrScreenResendInvitations>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensResendCheckrInvitationsCreate(
          id,
          checkrScreenResendInvitations,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {CheckrScreen} checkrScreen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensResubmitCheckrCreate(
      id: number,
      checkrScreen: CheckrScreen,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckrScreen>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensResubmitCheckrCreate(
          id,
          checkrScreen,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Screen>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.screensRetrieve(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensStripeIdCheckVerificationSessionRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<StripeIdentityCheckVerificationSession>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensStripeIdCheckVerificationSessionRetrieve(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {ScreenContentObject} [screenContentObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensSubmitCreate(
      id: number,
      screenContentObject?: ScreenContentObject,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Screen>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.screensSubmitCreate(
          id,
          screenContentObject,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {Screen} screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async screensUpdate(
      id: number,
      screen: Screen,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Screen>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.screensUpdate(
        id,
        screen,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ScreensApi - factory interface
 * @export
 */
export const ScreensApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ScreensApiFp(configuration);
  return {
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensCheckrScreenForIntroductionUsersRetrieve(
      userId: number,
      options?: any,
    ): AxiosPromise<CheckrScreenUserFromIntroduction> {
      return localVarFp
        .screensCheckrScreenForIntroductionUsersRetrieve(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensForIntroductionUsersList(
      userId: number,
      options?: any,
    ): AxiosPromise<Array<UserFromIntroductionScreen>> {
      return localVarFp
        .screensForIntroductionUsersList(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedMedicalRecordReviewScreenDocuments} [patchedMedicalRecordReviewScreenDocuments]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensInsuranceDocumentPartialUpdate(
      id: number,
      patchedMedicalRecordReviewScreenDocuments?: PatchedMedicalRecordReviewScreenDocuments,
      options?: any,
    ): AxiosPromise<MedicalRecordReviewScreenDocuments> {
      return localVarFp
        .screensInsuranceDocumentPartialUpdate(
          id,
          patchedMedicalRecordReviewScreenDocuments,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensInsuranceDocumentRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<MedicalRecordReviewScreenDocuments> {
      return localVarFp
        .screensInsuranceDocumentRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {MedicalRecordReviewScreenDocuments} medicalRecordReviewScreenDocuments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensInsuranceDocumentUpdate(
      id: number,
      medicalRecordReviewScreenDocuments: MedicalRecordReviewScreenDocuments,
      options?: any,
    ): AxiosPromise<MedicalRecordReviewScreenDocuments> {
      return localVarFp
        .screensInsuranceDocumentUpdate(
          id,
          medicalRecordReviewScreenDocuments,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensList(options?: any): AxiosPromise<Array<Screen>> {
      return localVarFp
        .screensList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedScreen} [patchedScreen]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensPartialUpdate(
      id: number,
      patchedScreen?: PatchedScreen,
      options?: any,
    ): AxiosPromise<Screen> {
      return localVarFp
        .screensPartialUpdate(id, patchedScreen, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CheckrScreenResendInvitations} checkrScreenResendInvitations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensResendCheckrInvitationsCreate(
      id: number,
      checkrScreenResendInvitations: CheckrScreenResendInvitations,
      options?: any,
    ): AxiosPromise<CheckrScreenResendInvitations> {
      return localVarFp
        .screensResendCheckrInvitationsCreate(
          id,
          checkrScreenResendInvitations,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {CheckrScreen} checkrScreen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensResubmitCheckrCreate(
      id: number,
      checkrScreen: CheckrScreen,
      options?: any,
    ): AxiosPromise<CheckrScreen> {
      return localVarFp
        .screensResubmitCheckrCreate(id, checkrScreen, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensRetrieve(id: number, options?: any): AxiosPromise<Screen> {
      return localVarFp
        .screensRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensStripeIdCheckVerificationSessionRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<StripeIdentityCheckVerificationSession> {
      return localVarFp
        .screensStripeIdCheckVerificationSessionRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {ScreenContentObject} [screenContentObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensSubmitCreate(
      id: number,
      screenContentObject?: ScreenContentObject,
      options?: any,
    ): AxiosPromise<Screen> {
      return localVarFp
        .screensSubmitCreate(id, screenContentObject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Screen} screen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    screensUpdate(
      id: number,
      screen: Screen,
      options?: any,
    ): AxiosPromise<Screen> {
      return localVarFp
        .screensUpdate(id, screen, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for screensCheckrScreenForIntroductionUsersRetrieve operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensCheckrScreenForIntroductionUsersRetrieveRequest
 */
export interface ScreensApiScreensCheckrScreenForIntroductionUsersRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensCheckrScreenForIntroductionUsersRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for screensForIntroductionUsersList operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensForIntroductionUsersListRequest
 */
export interface ScreensApiScreensForIntroductionUsersListRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensForIntroductionUsersList
   */
  readonly userId: number;
}

/**
 * Request parameters for screensInsuranceDocumentPartialUpdate operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensInsuranceDocumentPartialUpdateRequest
 */
export interface ScreensApiScreensInsuranceDocumentPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensInsuranceDocumentPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedMedicalRecordReviewScreenDocuments}
   * @memberof ScreensApiScreensInsuranceDocumentPartialUpdate
   */
  readonly patchedMedicalRecordReviewScreenDocuments?: PatchedMedicalRecordReviewScreenDocuments;
}

/**
 * Request parameters for screensInsuranceDocumentRetrieve operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensInsuranceDocumentRetrieveRequest
 */
export interface ScreensApiScreensInsuranceDocumentRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensInsuranceDocumentRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for screensInsuranceDocumentUpdate operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensInsuranceDocumentUpdateRequest
 */
export interface ScreensApiScreensInsuranceDocumentUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensInsuranceDocumentUpdate
   */
  readonly id: number;

  /**
   *
   * @type {MedicalRecordReviewScreenDocuments}
   * @memberof ScreensApiScreensInsuranceDocumentUpdate
   */
  readonly medicalRecordReviewScreenDocuments: MedicalRecordReviewScreenDocuments;
}

/**
 * Request parameters for screensPartialUpdate operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensPartialUpdateRequest
 */
export interface ScreensApiScreensPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedScreen}
   * @memberof ScreensApiScreensPartialUpdate
   */
  readonly patchedScreen?: PatchedScreen;
}

/**
 * Request parameters for screensResendCheckrInvitationsCreate operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensResendCheckrInvitationsCreateRequest
 */
export interface ScreensApiScreensResendCheckrInvitationsCreateRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensResendCheckrInvitationsCreate
   */
  readonly id: number;

  /**
   *
   * @type {CheckrScreenResendInvitations}
   * @memberof ScreensApiScreensResendCheckrInvitationsCreate
   */
  readonly checkrScreenResendInvitations: CheckrScreenResendInvitations;
}

/**
 * Request parameters for screensResubmitCheckrCreate operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensResubmitCheckrCreateRequest
 */
export interface ScreensApiScreensResubmitCheckrCreateRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensResubmitCheckrCreate
   */
  readonly id: number;

  /**
   *
   * @type {CheckrScreen}
   * @memberof ScreensApiScreensResubmitCheckrCreate
   */
  readonly checkrScreen: CheckrScreen;
}

/**
 * Request parameters for screensRetrieve operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensRetrieveRequest
 */
export interface ScreensApiScreensRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for screensStripeIdCheckVerificationSessionRetrieve operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensStripeIdCheckVerificationSessionRetrieveRequest
 */
export interface ScreensApiScreensStripeIdCheckVerificationSessionRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensStripeIdCheckVerificationSessionRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for screensSubmitCreate operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensSubmitCreateRequest
 */
export interface ScreensApiScreensSubmitCreateRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensSubmitCreate
   */
  readonly id: number;

  /**
   *
   * @type {ScreenContentObject}
   * @memberof ScreensApiScreensSubmitCreate
   */
  readonly screenContentObject?: ScreenContentObject;
}

/**
 * Request parameters for screensUpdate operation in ScreensApi.
 * @export
 * @interface ScreensApiScreensUpdateRequest
 */
export interface ScreensApiScreensUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof ScreensApiScreensUpdate
   */
  readonly id: number;

  /**
   *
   * @type {Screen}
   * @memberof ScreensApiScreensUpdate
   */
  readonly screen: Screen;
}

/**
 * ScreensApi - object-oriented interface
 * @export
 * @class ScreensApi
 * @extends {BaseAPI}
 */
export class ScreensApi extends BaseAPI {
  /**
   *
   * @param {ScreensApiScreensCheckrScreenForIntroductionUsersRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensCheckrScreenForIntroductionUsersRetrieve(
    requestParameters: ScreensApiScreensCheckrScreenForIntroductionUsersRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensCheckrScreenForIntroductionUsersRetrieve(
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensForIntroductionUsersListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensForIntroductionUsersList(
    requestParameters: ScreensApiScreensForIntroductionUsersListRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensForIntroductionUsersList(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensInsuranceDocumentPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensInsuranceDocumentPartialUpdate(
    requestParameters: ScreensApiScreensInsuranceDocumentPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensInsuranceDocumentPartialUpdate(
        requestParameters.id,
        requestParameters.patchedMedicalRecordReviewScreenDocuments,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensInsuranceDocumentRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensInsuranceDocumentRetrieve(
    requestParameters: ScreensApiScreensInsuranceDocumentRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensInsuranceDocumentRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensInsuranceDocumentUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensInsuranceDocumentUpdate(
    requestParameters: ScreensApiScreensInsuranceDocumentUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensInsuranceDocumentUpdate(
        requestParameters.id,
        requestParameters.medicalRecordReviewScreenDocuments,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensList(options?: AxiosRequestConfig) {
    return ScreensApiFp(this.configuration)
      .screensList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensPartialUpdate(
    requestParameters: ScreensApiScreensPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensPartialUpdate(
        requestParameters.id,
        requestParameters.patchedScreen,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensResendCheckrInvitationsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensResendCheckrInvitationsCreate(
    requestParameters: ScreensApiScreensResendCheckrInvitationsCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensResendCheckrInvitationsCreate(
        requestParameters.id,
        requestParameters.checkrScreenResendInvitations,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensResubmitCheckrCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensResubmitCheckrCreate(
    requestParameters: ScreensApiScreensResubmitCheckrCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensResubmitCheckrCreate(
        requestParameters.id,
        requestParameters.checkrScreen,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensRetrieve(
    requestParameters: ScreensApiScreensRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensStripeIdCheckVerificationSessionRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensStripeIdCheckVerificationSessionRetrieve(
    requestParameters: ScreensApiScreensStripeIdCheckVerificationSessionRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensStripeIdCheckVerificationSessionRetrieve(
        requestParameters.id,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensSubmitCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensSubmitCreate(
    requestParameters: ScreensApiScreensSubmitCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensSubmitCreate(
        requestParameters.id,
        requestParameters.screenContentObject,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ScreensApiScreensUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScreensApi
   */
  public screensUpdate(
    requestParameters: ScreensApiScreensUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return ScreensApiFp(this.configuration)
      .screensUpdate(requestParameters.id, requestParameters.screen, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentResourcesList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/tasks/document-resources/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} signerId
     * @param {number} taskId
     * @param {PatchedRemoteDropboxDocumentSigner} [patchedRemoteDropboxDocumentSigner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentSignerPartialUpdate: async (
      signerId: number,
      taskId: number,
      patchedRemoteDropboxDocumentSigner?: PatchedRemoteDropboxDocumentSigner,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'signerId' is not null or undefined
      assertParamExists(
        'tasksDocumentSignerPartialUpdate',
        'signerId',
        signerId,
      );
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('tasksDocumentSignerPartialUpdate', 'taskId', taskId);
      const localVarPath = `/api/tasks/{task_id}/document-signer/{signer_id}/`
        .replace(`{${'signer_id'}}`, encodeURIComponent(String(signerId)))
        .replace(`{${'task_id'}}`, encodeURIComponent(String(taskId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedRemoteDropboxDocumentSigner,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} signerId
     * @param {number} taskId
     * @param {RemoteDropboxDocumentSigner} [remoteDropboxDocumentSigner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentSignerUpdate: async (
      signerId: number,
      taskId: number,
      remoteDropboxDocumentSigner?: RemoteDropboxDocumentSigner,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'signerId' is not null or undefined
      assertParamExists('tasksDocumentSignerUpdate', 'signerId', signerId);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('tasksDocumentSignerUpdate', 'taskId', taskId);
      const localVarPath = `/api/tasks/{task_id}/document-signer/{signer_id}/`
        .replace(`{${'signer_id'}}`, encodeURIComponent(String(signerId)))
        .replace(`{${'task_id'}}`, encodeURIComponent(String(taskId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        remoteDropboxDocumentSigner,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedDocumentType} [patchedDocumentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentTypePartialUpdate: async (
      id: number,
      patchedDocumentType?: PatchedDocumentType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tasksDocumentTypePartialUpdate', 'id', id);
      const localVarPath = `/api/tasks/{id}/document-type/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedDocumentType,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {DocumentType} [documentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentTypeUpdate: async (
      id: number,
      documentType?: DocumentType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tasksDocumentTypeUpdate', 'id', id);
      const localVarPath = `/api/tasks/{id}/document-type/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        documentType,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/tasks/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksMeStageRetrieve: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/tasks/me/stage/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedTask} [patchedTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksPartialUpdate: async (
      id: number,
      patchedTask?: PatchedTask,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tasksPartialUpdate', 'id', id);
      const localVarPath = `/api/tasks/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedTask,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tasksRetrieve', 'id', id);
      const localVarPath = `/api/tasks/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * View used to submit tasks with completion type staff only
     * @param {number} id
     * @param {TaskContentObject} [taskContentObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksStaffSubmitCreate: async (
      id: number,
      taskContentObject?: TaskContentObject,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tasksStaffSubmitCreate', 'id', id);
      const localVarPath = `/api/tasks/{id}/staff-submit/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        taskContentObject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * View used to submit tasks with completion type independent only
     * @param {number} id
     * @param {TaskContentObject} [taskContentObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksSubmitCreate: async (
      id: number,
      taskContentObject?: TaskContentObject,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tasksSubmitCreate', 'id', id);
      const localVarPath = `/api/tasks/{id}/submit/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        taskContentObject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {Task} task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksUpdate: async (
      id: number,
      task: Task,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('tasksUpdate', 'id', id);
      // verify required parameter 'task' is not null or undefined
      assertParamExists('tasksUpdate', 'task', task);
      const localVarPath = `/api/tasks/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        task,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksDocumentResourcesList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<DocumentResource>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tasksDocumentResourcesList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} signerId
     * @param {number} taskId
     * @param {PatchedRemoteDropboxDocumentSigner} [patchedRemoteDropboxDocumentSigner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksDocumentSignerPartialUpdate(
      signerId: number,
      taskId: number,
      patchedRemoteDropboxDocumentSigner?: PatchedRemoteDropboxDocumentSigner,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RemoteDropboxDocumentSigner>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tasksDocumentSignerPartialUpdate(
          signerId,
          taskId,
          patchedRemoteDropboxDocumentSigner,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} signerId
     * @param {number} taskId
     * @param {RemoteDropboxDocumentSigner} [remoteDropboxDocumentSigner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksDocumentSignerUpdate(
      signerId: number,
      taskId: number,
      remoteDropboxDocumentSigner?: RemoteDropboxDocumentSigner,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<RemoteDropboxDocumentSigner>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tasksDocumentSignerUpdate(
          signerId,
          taskId,
          remoteDropboxDocumentSigner,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedDocumentType} [patchedDocumentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksDocumentTypePartialUpdate(
      id: number,
      patchedDocumentType?: PatchedDocumentType,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tasksDocumentTypePartialUpdate(
          id,
          patchedDocumentType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {DocumentType} [documentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksDocumentTypeUpdate(
      id: number,
      documentType?: DocumentType,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tasksDocumentTypeUpdate(
          id,
          documentType,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksList(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Task>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tasksList(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksMeStageRetrieve(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStage>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tasksMeStageRetrieve(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedTask} [patchedTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksPartialUpdate(
      id: number,
      patchedTask?: PatchedTask,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tasksPartialUpdate(
          id,
          patchedTask,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tasksRetrieve(
        id,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * View used to submit tasks with completion type staff only
     * @param {number} id
     * @param {TaskContentObject} [taskContentObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksStaffSubmitCreate(
      id: number,
      taskContentObject?: TaskContentObject,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tasksStaffSubmitCreate(
          id,
          taskContentObject,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * View used to submit tasks with completion type independent only
     * @param {number} id
     * @param {TaskContentObject} [taskContentObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksSubmitCreate(
      id: number,
      taskContentObject?: TaskContentObject,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.tasksSubmitCreate(
          id,
          taskContentObject,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {Task} task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tasksUpdate(
      id: number,
      task: Task,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tasksUpdate(
        id,
        task,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TasksApiFp(configuration);
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentResourcesList(
      options?: any,
    ): AxiosPromise<Array<DocumentResource>> {
      return localVarFp
        .tasksDocumentResourcesList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} signerId
     * @param {number} taskId
     * @param {PatchedRemoteDropboxDocumentSigner} [patchedRemoteDropboxDocumentSigner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentSignerPartialUpdate(
      signerId: number,
      taskId: number,
      patchedRemoteDropboxDocumentSigner?: PatchedRemoteDropboxDocumentSigner,
      options?: any,
    ): AxiosPromise<RemoteDropboxDocumentSigner> {
      return localVarFp
        .tasksDocumentSignerPartialUpdate(
          signerId,
          taskId,
          patchedRemoteDropboxDocumentSigner,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} signerId
     * @param {number} taskId
     * @param {RemoteDropboxDocumentSigner} [remoteDropboxDocumentSigner]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentSignerUpdate(
      signerId: number,
      taskId: number,
      remoteDropboxDocumentSigner?: RemoteDropboxDocumentSigner,
      options?: any,
    ): AxiosPromise<RemoteDropboxDocumentSigner> {
      return localVarFp
        .tasksDocumentSignerUpdate(
          signerId,
          taskId,
          remoteDropboxDocumentSigner,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedDocumentType} [patchedDocumentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentTypePartialUpdate(
      id: number,
      patchedDocumentType?: PatchedDocumentType,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .tasksDocumentTypePartialUpdate(id, patchedDocumentType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {DocumentType} [documentType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksDocumentTypeUpdate(
      id: number,
      documentType?: DocumentType,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .tasksDocumentTypeUpdate(id, documentType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksList(options?: any): AxiosPromise<Array<Task>> {
      return localVarFp
        .tasksList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksMeStageRetrieve(options?: any): AxiosPromise<UserStage> {
      return localVarFp
        .tasksMeStageRetrieve(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedTask} [patchedTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksPartialUpdate(
      id: number,
      patchedTask?: PatchedTask,
      options?: any,
    ): AxiosPromise<Task> {
      return localVarFp
        .tasksPartialUpdate(id, patchedTask, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksRetrieve(id: number, options?: any): AxiosPromise<Task> {
      return localVarFp
        .tasksRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * View used to submit tasks with completion type staff only
     * @param {number} id
     * @param {TaskContentObject} [taskContentObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksStaffSubmitCreate(
      id: number,
      taskContentObject?: TaskContentObject,
      options?: any,
    ): AxiosPromise<Task> {
      return localVarFp
        .tasksStaffSubmitCreate(id, taskContentObject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * View used to submit tasks with completion type independent only
     * @param {number} id
     * @param {TaskContentObject} [taskContentObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksSubmitCreate(
      id: number,
      taskContentObject?: TaskContentObject,
      options?: any,
    ): AxiosPromise<Task> {
      return localVarFp
        .tasksSubmitCreate(id, taskContentObject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {Task} task
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tasksUpdate(id: number, task: Task, options?: any): AxiosPromise<Task> {
      return localVarFp
        .tasksUpdate(id, task, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for tasksDocumentSignerPartialUpdate operation in TasksApi.
 * @export
 * @interface TasksApiTasksDocumentSignerPartialUpdateRequest
 */
export interface TasksApiTasksDocumentSignerPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiTasksDocumentSignerPartialUpdate
   */
  readonly signerId: number;

  /**
   *
   * @type {number}
   * @memberof TasksApiTasksDocumentSignerPartialUpdate
   */
  readonly taskId: number;

  /**
   *
   * @type {PatchedRemoteDropboxDocumentSigner}
   * @memberof TasksApiTasksDocumentSignerPartialUpdate
   */
  readonly patchedRemoteDropboxDocumentSigner?: PatchedRemoteDropboxDocumentSigner;
}

/**
 * Request parameters for tasksDocumentSignerUpdate operation in TasksApi.
 * @export
 * @interface TasksApiTasksDocumentSignerUpdateRequest
 */
export interface TasksApiTasksDocumentSignerUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiTasksDocumentSignerUpdate
   */
  readonly signerId: number;

  /**
   *
   * @type {number}
   * @memberof TasksApiTasksDocumentSignerUpdate
   */
  readonly taskId: number;

  /**
   *
   * @type {RemoteDropboxDocumentSigner}
   * @memberof TasksApiTasksDocumentSignerUpdate
   */
  readonly remoteDropboxDocumentSigner?: RemoteDropboxDocumentSigner;
}

/**
 * Request parameters for tasksDocumentTypePartialUpdate operation in TasksApi.
 * @export
 * @interface TasksApiTasksDocumentTypePartialUpdateRequest
 */
export interface TasksApiTasksDocumentTypePartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiTasksDocumentTypePartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedDocumentType}
   * @memberof TasksApiTasksDocumentTypePartialUpdate
   */
  readonly patchedDocumentType?: PatchedDocumentType;
}

/**
 * Request parameters for tasksDocumentTypeUpdate operation in TasksApi.
 * @export
 * @interface TasksApiTasksDocumentTypeUpdateRequest
 */
export interface TasksApiTasksDocumentTypeUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiTasksDocumentTypeUpdate
   */
  readonly id: number;

  /**
   *
   * @type {DocumentType}
   * @memberof TasksApiTasksDocumentTypeUpdate
   */
  readonly documentType?: DocumentType;
}

/**
 * Request parameters for tasksPartialUpdate operation in TasksApi.
 * @export
 * @interface TasksApiTasksPartialUpdateRequest
 */
export interface TasksApiTasksPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiTasksPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedTask}
   * @memberof TasksApiTasksPartialUpdate
   */
  readonly patchedTask?: PatchedTask;
}

/**
 * Request parameters for tasksRetrieve operation in TasksApi.
 * @export
 * @interface TasksApiTasksRetrieveRequest
 */
export interface TasksApiTasksRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiTasksRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for tasksStaffSubmitCreate operation in TasksApi.
 * @export
 * @interface TasksApiTasksStaffSubmitCreateRequest
 */
export interface TasksApiTasksStaffSubmitCreateRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiTasksStaffSubmitCreate
   */
  readonly id: number;

  /**
   *
   * @type {TaskContentObject}
   * @memberof TasksApiTasksStaffSubmitCreate
   */
  readonly taskContentObject?: TaskContentObject;
}

/**
 * Request parameters for tasksSubmitCreate operation in TasksApi.
 * @export
 * @interface TasksApiTasksSubmitCreateRequest
 */
export interface TasksApiTasksSubmitCreateRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiTasksSubmitCreate
   */
  readonly id: number;

  /**
   *
   * @type {TaskContentObject}
   * @memberof TasksApiTasksSubmitCreate
   */
  readonly taskContentObject?: TaskContentObject;
}

/**
 * Request parameters for tasksUpdate operation in TasksApi.
 * @export
 * @interface TasksApiTasksUpdateRequest
 */
export interface TasksApiTasksUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof TasksApiTasksUpdate
   */
  readonly id: number;

  /**
   *
   * @type {Task}
   * @memberof TasksApiTasksUpdate
   */
  readonly task: Task;
}

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksDocumentResourcesList(options?: AxiosRequestConfig) {
    return TasksApiFp(this.configuration)
      .tasksDocumentResourcesList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TasksApiTasksDocumentSignerPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksDocumentSignerPartialUpdate(
    requestParameters: TasksApiTasksDocumentSignerPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .tasksDocumentSignerPartialUpdate(
        requestParameters.signerId,
        requestParameters.taskId,
        requestParameters.patchedRemoteDropboxDocumentSigner,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TasksApiTasksDocumentSignerUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksDocumentSignerUpdate(
    requestParameters: TasksApiTasksDocumentSignerUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .tasksDocumentSignerUpdate(
        requestParameters.signerId,
        requestParameters.taskId,
        requestParameters.remoteDropboxDocumentSigner,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TasksApiTasksDocumentTypePartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksDocumentTypePartialUpdate(
    requestParameters: TasksApiTasksDocumentTypePartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .tasksDocumentTypePartialUpdate(
        requestParameters.id,
        requestParameters.patchedDocumentType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TasksApiTasksDocumentTypeUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksDocumentTypeUpdate(
    requestParameters: TasksApiTasksDocumentTypeUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .tasksDocumentTypeUpdate(
        requestParameters.id,
        requestParameters.documentType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksList(options?: AxiosRequestConfig) {
    return TasksApiFp(this.configuration)
      .tasksList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksMeStageRetrieve(options?: AxiosRequestConfig) {
    return TasksApiFp(this.configuration)
      .tasksMeStageRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TasksApiTasksPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksPartialUpdate(
    requestParameters: TasksApiTasksPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .tasksPartialUpdate(
        requestParameters.id,
        requestParameters.patchedTask,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TasksApiTasksRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksRetrieve(
    requestParameters: TasksApiTasksRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .tasksRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * View used to submit tasks with completion type staff only
   * @param {TasksApiTasksStaffSubmitCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksStaffSubmitCreate(
    requestParameters: TasksApiTasksStaffSubmitCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .tasksStaffSubmitCreate(
        requestParameters.id,
        requestParameters.taskContentObject,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * View used to submit tasks with completion type independent only
   * @param {TasksApiTasksSubmitCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksSubmitCreate(
    requestParameters: TasksApiTasksSubmitCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .tasksSubmitCreate(
        requestParameters.id,
        requestParameters.taskContentObject,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {TasksApiTasksUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TasksApi
   */
  public tasksUpdate(
    requestParameters: TasksApiTasksUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return TasksApiFp(this.configuration)
      .tasksUpdate(requestParameters.id, requestParameters.task, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserPhotosApi - axios parameter creator
 * @export
 */
export const UserPhotosApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} id
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosCreate: async (
      id: number,
      image: string,
      imageUrl: string,
      order?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userPhotosCreate', 'id', id);
      // verify required parameter 'image' is not null or undefined
      assertParamExists('userPhotosCreate', 'image', image);
      // verify required parameter 'imageUrl' is not null or undefined
      assertParamExists('userPhotosCreate', 'imageUrl', imageUrl);
      const localVarPath = `/api/user-photos/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (id !== undefined) {
        localVarFormParams.append('id', id as any);
      }

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      if (imageUrl !== undefined) {
        localVarFormParams.append('image_url', imageUrl as any);
      }

      if (order !== undefined) {
        localVarFormParams.append('order', order as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosDestroy: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userPhotosDestroy', 'id', id);
      const localVarPath = `/api/user-photos/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/user-photos/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} [id2]
     * @param {string} [image]
     * @param {string} [imageUrl]
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosPartialUpdate: async (
      id: number,
      id2?: number,
      image?: string,
      imageUrl?: string,
      order?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userPhotosPartialUpdate', 'id', id);
      const localVarPath = `/api/user-photos/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (id2 !== undefined) {
        localVarFormParams.append('id', id2 as any);
      }

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      if (imageUrl !== undefined) {
        localVarFormParams.append('image_url', imageUrl as any);
      }

      if (order !== undefined) {
        localVarFormParams.append('order', order as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userPhotosRetrieve', 'id', id);
      const localVarPath = `/api/user-photos/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} id2
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosUpdate: async (
      id: number,
      id2: number,
      image: string,
      imageUrl: string,
      order?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('userPhotosUpdate', 'id', id);
      // verify required parameter 'id2' is not null or undefined
      assertParamExists('userPhotosUpdate', 'id2', id2);
      // verify required parameter 'image' is not null or undefined
      assertParamExists('userPhotosUpdate', 'image', image);
      // verify required parameter 'imageUrl' is not null or undefined
      assertParamExists('userPhotosUpdate', 'imageUrl', imageUrl);
      const localVarPath = `/api/user-photos/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (id2 !== undefined) {
        localVarFormParams.append('id', id2 as any);
      }

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      if (imageUrl !== undefined) {
        localVarFormParams.append('image_url', imageUrl as any);
      }

      if (order !== undefined) {
        localVarFormParams.append('order', order as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserPhotosApi - functional programming interface
 * @export
 */
export const UserPhotosApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    UserPhotosApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPhotosCreate(
      id: number,
      image: string,
      imageUrl: string,
      order?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfileOwnPhoto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userPhotosCreate(
          id,
          image,
          imageUrl,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPhotosDestroy(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userPhotosDestroy(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPhotosList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserProfileOwnPhoto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userPhotosList(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} [id2]
     * @param {string} [image]
     * @param {string} [imageUrl]
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPhotosPartialUpdate(
      id: number,
      id2?: number,
      image?: string,
      imageUrl?: string,
      order?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfileOwnPhoto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userPhotosPartialUpdate(
          id,
          id2,
          image,
          imageUrl,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPhotosRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfileOwnPhoto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userPhotosRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} id2
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userPhotosUpdate(
      id: number,
      id2: number,
      image: string,
      imageUrl: string,
      order?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfileOwnPhoto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.userPhotosUpdate(
          id,
          id2,
          image,
          imageUrl,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UserPhotosApi - factory interface
 * @export
 */
export const UserPhotosApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserPhotosApiFp(configuration);
  return {
    /**
     *
     * @param {number} id
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosCreate(
      id: number,
      image: string,
      imageUrl: string,
      order?: number,
      options?: any,
    ): AxiosPromise<UserProfileOwnPhoto> {
      return localVarFp
        .userPhotosCreate(id, image, imageUrl, order, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosDestroy(id: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .userPhotosDestroy(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosList(options?: any): AxiosPromise<Array<UserProfileOwnPhoto>> {
      return localVarFp
        .userPhotosList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} [id2]
     * @param {string} [image]
     * @param {string} [imageUrl]
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosPartialUpdate(
      id: number,
      id2?: number,
      image?: string,
      imageUrl?: string,
      order?: number,
      options?: any,
    ): AxiosPromise<UserProfileOwnPhoto> {
      return localVarFp
        .userPhotosPartialUpdate(id, id2, image, imageUrl, order, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<UserProfileOwnPhoto> {
      return localVarFp
        .userPhotosRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} id2
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userPhotosUpdate(
      id: number,
      id2: number,
      image: string,
      imageUrl: string,
      order?: number,
      options?: any,
    ): AxiosPromise<UserProfileOwnPhoto> {
      return localVarFp
        .userPhotosUpdate(id, id2, image, imageUrl, order, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for userPhotosCreate operation in UserPhotosApi.
 * @export
 * @interface UserPhotosApiUserPhotosCreateRequest
 */
export interface UserPhotosApiUserPhotosCreateRequest {
  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosCreate
   */
  readonly id: number;

  /**
   *
   * @type {string}
   * @memberof UserPhotosApiUserPhotosCreate
   */
  readonly image: string;

  /**
   *
   * @type {string}
   * @memberof UserPhotosApiUserPhotosCreate
   */
  readonly imageUrl: string;

  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosCreate
   */
  readonly order?: number;
}

/**
 * Request parameters for userPhotosDestroy operation in UserPhotosApi.
 * @export
 * @interface UserPhotosApiUserPhotosDestroyRequest
 */
export interface UserPhotosApiUserPhotosDestroyRequest {
  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosDestroy
   */
  readonly id: number;
}

/**
 * Request parameters for userPhotosPartialUpdate operation in UserPhotosApi.
 * @export
 * @interface UserPhotosApiUserPhotosPartialUpdateRequest
 */
export interface UserPhotosApiUserPhotosPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosPartialUpdate
   */
  readonly id2?: number;

  /**
   *
   * @type {string}
   * @memberof UserPhotosApiUserPhotosPartialUpdate
   */
  readonly image?: string;

  /**
   *
   * @type {string}
   * @memberof UserPhotosApiUserPhotosPartialUpdate
   */
  readonly imageUrl?: string;

  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosPartialUpdate
   */
  readonly order?: number;
}

/**
 * Request parameters for userPhotosRetrieve operation in UserPhotosApi.
 * @export
 * @interface UserPhotosApiUserPhotosRetrieveRequest
 */
export interface UserPhotosApiUserPhotosRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for userPhotosUpdate operation in UserPhotosApi.
 * @export
 * @interface UserPhotosApiUserPhotosUpdateRequest
 */
export interface UserPhotosApiUserPhotosUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosUpdate
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosUpdate
   */
  readonly id2: number;

  /**
   *
   * @type {string}
   * @memberof UserPhotosApiUserPhotosUpdate
   */
  readonly image: string;

  /**
   *
   * @type {string}
   * @memberof UserPhotosApiUserPhotosUpdate
   */
  readonly imageUrl: string;

  /**
   *
   * @type {number}
   * @memberof UserPhotosApiUserPhotosUpdate
   */
  readonly order?: number;
}

/**
 * UserPhotosApi - object-oriented interface
 * @export
 * @class UserPhotosApi
 * @extends {BaseAPI}
 */
export class UserPhotosApi extends BaseAPI {
  /**
   *
   * @param {UserPhotosApiUserPhotosCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserPhotosApi
   */
  public userPhotosCreate(
    requestParameters: UserPhotosApiUserPhotosCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserPhotosApiFp(this.configuration)
      .userPhotosCreate(
        requestParameters.id,
        requestParameters.image,
        requestParameters.imageUrl,
        requestParameters.order,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserPhotosApiUserPhotosDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserPhotosApi
   */
  public userPhotosDestroy(
    requestParameters: UserPhotosApiUserPhotosDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserPhotosApiFp(this.configuration)
      .userPhotosDestroy(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserPhotosApi
   */
  public userPhotosList(options?: AxiosRequestConfig) {
    return UserPhotosApiFp(this.configuration)
      .userPhotosList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserPhotosApiUserPhotosPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserPhotosApi
   */
  public userPhotosPartialUpdate(
    requestParameters: UserPhotosApiUserPhotosPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserPhotosApiFp(this.configuration)
      .userPhotosPartialUpdate(
        requestParameters.id,
        requestParameters.id2,
        requestParameters.image,
        requestParameters.imageUrl,
        requestParameters.order,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserPhotosApiUserPhotosRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserPhotosApi
   */
  public userPhotosRetrieve(
    requestParameters: UserPhotosApiUserPhotosRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserPhotosApiFp(this.configuration)
      .userPhotosRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserPhotosApiUserPhotosUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserPhotosApi
   */
  public userPhotosUpdate(
    requestParameters: UserPhotosApiUserPhotosUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UserPhotosApiFp(this.configuration)
      .userPhotosUpdate(
        requestParameters.id,
        requestParameters.id2,
        requestParameters.image,
        requestParameters.imageUrl,
        requestParameters.order,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDocumentsList: async (
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersDocumentsList', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/documents/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {PatchedEmbeddedDropboxDocument} [patchedEmbeddedDropboxDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDocumentsPartialUpdate: async (
      id: number,
      userId: number,
      patchedEmbeddedDropboxDocument?: PatchedEmbeddedDropboxDocument,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersDocumentsPartialUpdate', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersDocumentsPartialUpdate', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/documents/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedEmbeddedDropboxDocument,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDocumentsRetrieve: async (
      id: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersDocumentsRetrieve', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersDocumentsRetrieve', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/documents/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {EmbeddedDropboxDocument} [embeddedDropboxDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDocumentsUpdate: async (
      id: number,
      userId: number,
      embeddedDropboxDocument?: EmbeddedDropboxDocument,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersDocumentsUpdate', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersDocumentsUpdate', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/documents/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        embeddedDropboxDocument,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedByList: async (
      userId: number,
      ordering?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersFavoritedByList', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/favorited-by/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} donor
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedByRetrieve: async (
      donor: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'donor' is not null or undefined
      assertParamExists('usersFavoritedByRetrieve', 'donor', donor);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersFavoritedByRetrieve', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/favorited-by/{donor}/`
        .replace(`{${'donor'}}`, encodeURIComponent(String(donor)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {UserFavoriteParents} userFavoriteParents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedCreate: async (
      userId: number,
      userFavoriteParents: UserFavoriteParents,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersFavoritedCreate', 'userId', userId);
      // verify required parameter 'userFavoriteParents' is not null or undefined
      assertParamExists(
        'usersFavoritedCreate',
        'userFavoriteParents',
        userFavoriteParents,
      );
      const localVarPath = `/api/users/{user_id}/favorited/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userFavoriteParents,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} likedParent
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedDestroy: async (
      likedParent: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'likedParent' is not null or undefined
      assertParamExists('usersFavoritedDestroy', 'likedParent', likedParent);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersFavoritedDestroy', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/favorited/{liked_parent}/`
        .replace(`{${'liked_parent'}}`, encodeURIComponent(String(likedParent)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedList: async (
      userId: number,
      ordering?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersFavoritedList', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/favorited/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} likedParent
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedRetrieve: async (
      likedParent: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'likedParent' is not null or undefined
      assertParamExists('usersFavoritedRetrieve', 'likedParent', likedParent);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersFavoritedRetrieve', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/favorited/{liked_parent}/`
        .replace(`{${'liked_parent'}}`, encodeURIComponent(String(likedParent)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIntroductionsList: async (
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersIntroductionsList', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/introductions/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedIntroductionFull} [patchedIntroductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIntroductionsPartialUpdate: async (
      id: number,
      patchedIntroductionFull?: PatchedIntroductionFull,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersIntroductionsPartialUpdate', 'id', id);
      const localVarPath = `/api/users/introductions/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedIntroductionFull,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIntroductionsRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersIntroductionsRetrieve', 'id', id);
      const localVarPath = `/api/users/introductions/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {IntroductionFull} [introductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIntroductionsUpdate: async (
      id: number,
      introductionFull?: IntroductionFull,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersIntroductionsUpdate', 'id', id);
      const localVarPath = `/api/users/introductions/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        introductionFull,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMarketplaceUsersList: async (
      userId: number,
      ordering?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersMarketplaceUsersList', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/marketplace-users/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} parent
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMarketplaceUsersRetrieve: async (
      parent: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'parent' is not null or undefined
      assertParamExists('usersMarketplaceUsersRetrieve', 'parent', parent);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersMarketplaceUsersRetrieve', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/marketplace-users/{parent}/`
        .replace(`{${'parent'}}`, encodeURIComponent(String(parent)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {PatchedProfileObject} [patchedProfileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeProfilePartialUpdate: async (
      patchedProfileObject?: PatchedProfileObject,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/me/profile/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedProfileObject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeProfileRetrieve: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/me/profile/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ProfileObject} [profileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeProfileUpdate: async (
      profileObject?: ProfileObject,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/me/profile/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        profileObject,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeRetrieve: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/me/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {number} id
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosCreate: async (
      userId: number,
      id: number,
      image: string,
      imageUrl: string,
      order?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersPhotosCreate', 'userId', userId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersPhotosCreate', 'id', id);
      // verify required parameter 'image' is not null or undefined
      assertParamExists('usersPhotosCreate', 'image', image);
      // verify required parameter 'imageUrl' is not null or undefined
      assertParamExists('usersPhotosCreate', 'imageUrl', imageUrl);
      const localVarPath = `/api/users/{user_id}/photos/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (id !== undefined) {
        localVarFormParams.append('id', id as any);
      }

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      if (imageUrl !== undefined) {
        localVarFormParams.append('image_url', imageUrl as any);
      }

      if (order !== undefined) {
        localVarFormParams.append('order', order as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosDestroy: async (
      id: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersPhotosDestroy', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersPhotosDestroy', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/photos/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosList: async (
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersPhotosList', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/photos/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {number} [id2]
     * @param {string} [image]
     * @param {string} [imageUrl]
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosPartialUpdate: async (
      id: number,
      userId: number,
      id2?: number,
      image?: string,
      imageUrl?: string,
      order?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersPhotosPartialUpdate', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersPhotosPartialUpdate', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/photos/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (id2 !== undefined) {
        localVarFormParams.append('id', id2 as any);
      }

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      if (imageUrl !== undefined) {
        localVarFormParams.append('image_url', imageUrl as any);
      }

      if (order !== undefined) {
        localVarFormParams.append('order', order as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosRetrieve: async (
      id: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersPhotosRetrieve', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersPhotosRetrieve', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/photos/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {number} id2
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosUpdate: async (
      id: number,
      userId: number,
      id2: number,
      image: string,
      imageUrl: string,
      order?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersPhotosUpdate', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersPhotosUpdate', 'userId', userId);
      // verify required parameter 'id2' is not null or undefined
      assertParamExists('usersPhotosUpdate', 'id2', id2);
      // verify required parameter 'image' is not null or undefined
      assertParamExists('usersPhotosUpdate', 'image', image);
      // verify required parameter 'imageUrl' is not null or undefined
      assertParamExists('usersPhotosUpdate', 'imageUrl', imageUrl);
      const localVarPath = `/api/users/{user_id}/photos/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration &&
        configuration.formDataCtor) ||
        FormData)();

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (id2 !== undefined) {
        localVarFormParams.append('id', id2 as any);
      }

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      if (imageUrl !== undefined) {
        localVarFormParams.append('image_url', imageUrl as any);
      }

      if (order !== undefined) {
        localVarFormParams.append('order', order as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersProfileViewCountRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersProfileViewCountRetrieve', 'id', id);
      const localVarPath = `/api/users/{id}/profile-view-count/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserProfileView} userProfileView
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersProfileViewsCreate: async (
      userProfileView: UserProfileView,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userProfileView' is not null or undefined
      assertParamExists(
        'usersProfileViewsCreate',
        'userProfileView',
        userProfileView,
      );
      const localVarPath = `/api/users/profile-views/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userProfileView,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersProfileViewsList: async (
      limit?: number,
      offset?: number,
      ordering?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/profile-views/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (offset !== undefined) {
        localVarQueryParameter['offset'] = offset;
      }

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersRetrieve: async (
      username: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'username' is not null or undefined
      assertParamExists('usersRetrieve', 'username', username);
      const localVarPath = `/api/users/{username}/`.replace(
        `{${'username'}}`,
        encodeURIComponent(String(username)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {PatchedUserSupportTeamUser} [patchedUserSupportTeamUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersSupportTeamPartialUpdate: async (
      id: number,
      patchedUserSupportTeamUser?: PatchedUserSupportTeamUser,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersSupportTeamPartialUpdate', 'id', id);
      const localVarPath = `/api/users/support-team/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedUserSupportTeamUser,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersSupportTeamRetrieve: async (
      id: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersSupportTeamRetrieve', 'id', id);
      const localVarPath = `/api/users/support-team/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {UserSupportTeamUser} userSupportTeamUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersSupportTeamUpdate: async (
      id: number,
      userSupportTeamUser: UserSupportTeamUser,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersSupportTeamUpdate', 'id', id);
      // verify required parameter 'userSupportTeamUser' is not null or undefined
      assertParamExists(
        'usersSupportTeamUpdate',
        'userSupportTeamUser',
        userSupportTeamUser,
      );
      const localVarPath = `/api/users/support-team/{id}/`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userSupportTeamUser,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersSupportTeamUsersList: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/users/support-team/users/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNoteDestroy: async (
      id: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersUserNoteDestroy', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersUserNoteDestroy', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/user-note/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {PatchedUserNoteEditCreate} [patchedUserNoteEditCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNotePartialUpdate: async (
      id: number,
      userId: number,
      patchedUserNoteEditCreate?: PatchedUserNoteEditCreate,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersUserNotePartialUpdate', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersUserNotePartialUpdate', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/user-note/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PATCH',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        patchedUserNoteEditCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNoteRetrieve: async (
      id: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersUserNoteRetrieve', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersUserNoteRetrieve', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/user-note/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {UserNoteEditCreate} [userNoteEditCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNoteUpdate: async (
      id: number,
      userId: number,
      userNoteEditCreate?: UserNoteEditCreate,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersUserNoteUpdate', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersUserNoteUpdate', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/user-note/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'PUT',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userNoteEditCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {UserNoteEditCreate} [userNoteEditCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNotesCreate: async (
      userId: number,
      userNoteEditCreate?: UserNoteEditCreate,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersUserNotesCreate', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/user-notes/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        userNoteEditCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNotesList: async (
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersUserNotesList', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/user-notes/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersViewedByList: async (
      userId: number,
      ordering?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersViewedByList', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/viewed-by/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersViewedByRetrieve: async (
      id: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersViewedByRetrieve', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersViewedByRetrieve', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/viewed-by/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersViewedList: async (
      userId: number,
      ordering?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersViewedList', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/viewed/`.replace(
        `{${'user_id'}}`,
        encodeURIComponent(String(userId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      if (ordering !== undefined) {
        localVarQueryParameter['ordering'] = ordering;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersViewedRetrieve: async (
      id: number,
      userId: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('usersViewedRetrieve', 'id', id);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('usersViewedRetrieve', 'userId', userId);
      const localVarPath = `/api/users/{user_id}/viewed/{id}/`
        .replace(`{${'id'}}`, encodeURIComponent(String(id)))
        .replace(`{${'user_id'}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDocumentsList(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<EmbeddedDropboxDocument>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersDocumentsList(userId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {PatchedEmbeddedDropboxDocument} [patchedEmbeddedDropboxDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDocumentsPartialUpdate(
      id: number,
      userId: number,
      patchedEmbeddedDropboxDocument?: PatchedEmbeddedDropboxDocument,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EmbeddedDropboxDocument>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersDocumentsPartialUpdate(
          id,
          userId,
          patchedEmbeddedDropboxDocument,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDocumentsRetrieve(
      id: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EmbeddedDropboxDocument>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersDocumentsRetrieve(
          id,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {EmbeddedDropboxDocument} [embeddedDropboxDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersDocumentsUpdate(
      id: number,
      userId: number,
      embeddedDropboxDocument?: EmbeddedDropboxDocument,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<EmbeddedDropboxDocument>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersDocumentsUpdate(
          id,
          userId,
          embeddedDropboxDocument,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersFavoritedByList(
      userId: number,
      ordering?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserFavoriteParents>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersFavoritedByList(
          userId,
          ordering,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} donor
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersFavoritedByRetrieve(
      donor: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserFavoriteParents>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersFavoritedByRetrieve(
          donor,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {UserFavoriteParents} userFavoriteParents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersFavoritedCreate(
      userId: number,
      userFavoriteParents: UserFavoriteParents,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserFavoriteParents>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersFavoritedCreate(
          userId,
          userFavoriteParents,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} likedParent
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersFavoritedDestroy(
      likedParent: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersFavoritedDestroy(
          likedParent,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersFavoritedList(
      userId: number,
      ordering?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserFavoriteParents>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersFavoritedList(
          userId,
          ordering,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} likedParent
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersFavoritedRetrieve(
      likedParent: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserFavoriteParents>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersFavoritedRetrieve(
          likedParent,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersIntroductionsList(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<IntroductionFull>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersIntroductionsList(userId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedIntroductionFull} [patchedIntroductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersIntroductionsPartialUpdate(
      id: number,
      patchedIntroductionFull?: PatchedIntroductionFull,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IntroductionFull>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersIntroductionsPartialUpdate(
          id,
          patchedIntroductionFull,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersIntroductionsRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IntroductionFull>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersIntroductionsRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {IntroductionFull} [introductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersIntroductionsUpdate(
      id: number,
      introductionFull?: IntroductionFull,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IntroductionFull>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersIntroductionsUpdate(
          id,
          introductionFull,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersList(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersList(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersMarketplaceUsersList(
      userId: number,
      ordering?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<MarketplaceParents>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersMarketplaceUsersList(
          userId,
          ordering,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} parent
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersMarketplaceUsersRetrieve(
      parent: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<MarketplaceParents>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersMarketplaceUsersRetrieve(
          parent,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {PatchedProfileObject} [patchedProfileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersMeProfilePartialUpdate(
      patchedProfileObject?: PatchedProfileObject,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersMeProfilePartialUpdate(
          patchedProfileObject,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersMeProfileRetrieve(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileObject>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersMeProfileRetrieve(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {ProfileObject} [profileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersMeProfileUpdate(
      profileObject?: ProfileObject,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersMeProfileUpdate(
          profileObject,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersMeRetrieve(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeRetrieve(
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {number} id
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPhotosCreate(
      userId: number,
      id: number,
      image: string,
      imageUrl: string,
      order?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfilePhoto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersPhotosCreate(
          userId,
          id,
          image,
          imageUrl,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPhotosDestroy(
      id: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersPhotosDestroy(id, userId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPhotosList(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserProfilePhoto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersPhotosList(
        userId,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {number} [id2]
     * @param {string} [image]
     * @param {string} [imageUrl]
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPhotosPartialUpdate(
      id: number,
      userId: number,
      id2?: number,
      image?: string,
      imageUrl?: string,
      order?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfilePhoto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersPhotosPartialUpdate(
          id,
          userId,
          id2,
          image,
          imageUrl,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPhotosRetrieve(
      id: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfilePhoto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersPhotosRetrieve(
          id,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {number} id2
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersPhotosUpdate(
      id: number,
      userId: number,
      id2: number,
      image: string,
      imageUrl: string,
      order?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfilePhoto>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersPhotosUpdate(
          id,
          userId,
          id2,
          image,
          imageUrl,
          order,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersProfileViewCountRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfileViewCount>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersProfileViewCountRetrieve(
          id,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {UserProfileView} userProfileView
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersProfileViewsCreate(
      userProfileView: UserProfileView,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfileView>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersProfileViewsCreate(
          userProfileView,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersProfileViewsList(
      limit?: number,
      offset?: number,
      ordering?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<PaginatedUserProfileViewList>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersProfileViewsList(
          limit,
          offset,
          ordering,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersRetrieve(
      username: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersRetrieve(
        username,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {PatchedUserSupportTeamUser} [patchedUserSupportTeamUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersSupportTeamPartialUpdate(
      id: number,
      patchedUserSupportTeamUser?: PatchedUserSupportTeamUser,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserSupportTeamUser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersSupportTeamPartialUpdate(
          id,
          patchedUserSupportTeamUser,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersSupportTeamRetrieve(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserSupportTeamUser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersSupportTeamRetrieve(id, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {UserSupportTeamUser} userSupportTeamUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersSupportTeamUpdate(
      id: number,
      userSupportTeamUser: UserSupportTeamUser,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserSupportTeamUser>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersSupportTeamUpdate(
          id,
          userSupportTeamUser,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersSupportTeamUsersList(
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<SupportTeamUser>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersSupportTeamUsersList(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUserNoteDestroy(
      id: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersUserNoteDestroy(
          id,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {PatchedUserNoteEditCreate} [patchedUserNoteEditCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUserNotePartialUpdate(
      id: number,
      userId: number,
      patchedUserNoteEditCreate?: PatchedUserNoteEditCreate,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserNoteEditCreate>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersUserNotePartialUpdate(
          id,
          userId,
          patchedUserNoteEditCreate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUserNoteRetrieve(
      id: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserNoteEditCreate>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersUserNoteRetrieve(
          id,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {UserNoteEditCreate} [userNoteEditCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUserNoteUpdate(
      id: number,
      userId: number,
      userNoteEditCreate?: UserNoteEditCreate,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserNoteEditCreate>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersUserNoteUpdate(
          id,
          userId,
          userNoteEditCreate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {UserNoteEditCreate} [userNoteEditCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUserNotesCreate(
      userId: number,
      userNoteEditCreate?: UserNoteEditCreate,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNote>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersUserNotesCreate(
          userId,
          userNoteEditCreate,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersUserNotesList(
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserNote>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersUserNotesList(userId, options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersViewedByList(
      userId: number,
      ordering?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserProfileViewDetailed>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersViewedByList(
          userId,
          ordering,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersViewedByRetrieve(
      id: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfileViewDetailed>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersViewedByRetrieve(
          id,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersViewedList(
      userId: number,
      ordering?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<UserProfileViewDetailed>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.usersViewedList(
        userId,
        ordering,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async usersViewedRetrieve(
      id: number,
      userId: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<UserProfileViewDetailed>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.usersViewedRetrieve(
          id,
          userId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDocumentsList(
      userId: number,
      options?: any,
    ): AxiosPromise<Array<EmbeddedDropboxDocument>> {
      return localVarFp
        .usersDocumentsList(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {PatchedEmbeddedDropboxDocument} [patchedEmbeddedDropboxDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDocumentsPartialUpdate(
      id: number,
      userId: number,
      patchedEmbeddedDropboxDocument?: PatchedEmbeddedDropboxDocument,
      options?: any,
    ): AxiosPromise<EmbeddedDropboxDocument> {
      return localVarFp
        .usersDocumentsPartialUpdate(
          id,
          userId,
          patchedEmbeddedDropboxDocument,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDocumentsRetrieve(
      id: number,
      userId: number,
      options?: any,
    ): AxiosPromise<EmbeddedDropboxDocument> {
      return localVarFp
        .usersDocumentsRetrieve(id, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {EmbeddedDropboxDocument} [embeddedDropboxDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersDocumentsUpdate(
      id: number,
      userId: number,
      embeddedDropboxDocument?: EmbeddedDropboxDocument,
      options?: any,
    ): AxiosPromise<EmbeddedDropboxDocument> {
      return localVarFp
        .usersDocumentsUpdate(id, userId, embeddedDropboxDocument, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedByList(
      userId: number,
      ordering?: string,
      options?: any,
    ): AxiosPromise<Array<UserFavoriteParents>> {
      return localVarFp
        .usersFavoritedByList(userId, ordering, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} donor
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedByRetrieve(
      donor: number,
      userId: number,
      options?: any,
    ): AxiosPromise<UserFavoriteParents> {
      return localVarFp
        .usersFavoritedByRetrieve(donor, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {UserFavoriteParents} userFavoriteParents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedCreate(
      userId: number,
      userFavoriteParents: UserFavoriteParents,
      options?: any,
    ): AxiosPromise<UserFavoriteParents> {
      return localVarFp
        .usersFavoritedCreate(userId, userFavoriteParents, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} likedParent
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedDestroy(
      likedParent: number,
      userId: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .usersFavoritedDestroy(likedParent, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedList(
      userId: number,
      ordering?: string,
      options?: any,
    ): AxiosPromise<Array<UserFavoriteParents>> {
      return localVarFp
        .usersFavoritedList(userId, ordering, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} likedParent
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersFavoritedRetrieve(
      likedParent: number,
      userId: number,
      options?: any,
    ): AxiosPromise<UserFavoriteParents> {
      return localVarFp
        .usersFavoritedRetrieve(likedParent, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIntroductionsList(
      userId: number,
      options?: any,
    ): AxiosPromise<Array<IntroductionFull>> {
      return localVarFp
        .usersIntroductionsList(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedIntroductionFull} [patchedIntroductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIntroductionsPartialUpdate(
      id: number,
      patchedIntroductionFull?: PatchedIntroductionFull,
      options?: any,
    ): AxiosPromise<IntroductionFull> {
      return localVarFp
        .usersIntroductionsPartialUpdate(id, patchedIntroductionFull, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIntroductionsRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<IntroductionFull> {
      return localVarFp
        .usersIntroductionsRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {IntroductionFull} [introductionFull]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersIntroductionsUpdate(
      id: number,
      introductionFull?: IntroductionFull,
      options?: any,
    ): AxiosPromise<IntroductionFull> {
      return localVarFp
        .usersIntroductionsUpdate(id, introductionFull, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersList(options?: any): AxiosPromise<Array<User>> {
      return localVarFp
        .usersList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMarketplaceUsersList(
      userId: number,
      ordering?: string,
      options?: any,
    ): AxiosPromise<Array<MarketplaceParents>> {
      return localVarFp
        .usersMarketplaceUsersList(userId, ordering, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} parent
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMarketplaceUsersRetrieve(
      parent: number,
      userId: number,
      options?: any,
    ): AxiosPromise<MarketplaceParents> {
      return localVarFp
        .usersMarketplaceUsersRetrieve(parent, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {PatchedProfileObject} [patchedProfileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeProfilePartialUpdate(
      patchedProfileObject?: PatchedProfileObject,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .usersMeProfilePartialUpdate(patchedProfileObject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeProfileRetrieve(options?: any): AxiosPromise<ProfileObject> {
      return localVarFp
        .usersMeProfileRetrieve(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {ProfileObject} [profileObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeProfileUpdate(
      profileObject?: ProfileObject,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .usersMeProfileUpdate(profileObject, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersMeRetrieve(options?: any): AxiosPromise<User> {
      return localVarFp
        .usersMeRetrieve(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {number} id
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosCreate(
      userId: number,
      id: number,
      image: string,
      imageUrl: string,
      order?: number,
      options?: any,
    ): AxiosPromise<UserProfilePhoto> {
      return localVarFp
        .usersPhotosCreate(userId, id, image, imageUrl, order, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosDestroy(
      id: number,
      userId: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .usersPhotosDestroy(id, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosList(
      userId: number,
      options?: any,
    ): AxiosPromise<Array<UserProfilePhoto>> {
      return localVarFp
        .usersPhotosList(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {number} [id2]
     * @param {string} [image]
     * @param {string} [imageUrl]
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosPartialUpdate(
      id: number,
      userId: number,
      id2?: number,
      image?: string,
      imageUrl?: string,
      order?: number,
      options?: any,
    ): AxiosPromise<UserProfilePhoto> {
      return localVarFp
        .usersPhotosPartialUpdate(
          id,
          userId,
          id2,
          image,
          imageUrl,
          order,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosRetrieve(
      id: number,
      userId: number,
      options?: any,
    ): AxiosPromise<UserProfilePhoto> {
      return localVarFp
        .usersPhotosRetrieve(id, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {number} id2
     * @param {string} image
     * @param {string} imageUrl
     * @param {number} [order]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersPhotosUpdate(
      id: number,
      userId: number,
      id2: number,
      image: string,
      imageUrl: string,
      order?: number,
      options?: any,
    ): AxiosPromise<UserProfilePhoto> {
      return localVarFp
        .usersPhotosUpdate(id, userId, id2, image, imageUrl, order, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersProfileViewCountRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<UserProfileViewCount> {
      return localVarFp
        .usersProfileViewCountRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {UserProfileView} userProfileView
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersProfileViewsCreate(
      userProfileView: UserProfileView,
      options?: any,
    ): AxiosPromise<UserProfileView> {
      return localVarFp
        .usersProfileViewsCreate(userProfileView, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} [limit] Number of results to return per page.
     * @param {number} [offset] The initial index from which to return the results.
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersProfileViewsList(
      limit?: number,
      offset?: number,
      ordering?: string,
      options?: any,
    ): AxiosPromise<PaginatedUserProfileViewList> {
      return localVarFp
        .usersProfileViewsList(limit, offset, ordering, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersRetrieve(username: string, options?: any): AxiosPromise<User> {
      return localVarFp
        .usersRetrieve(username, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {PatchedUserSupportTeamUser} [patchedUserSupportTeamUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersSupportTeamPartialUpdate(
      id: number,
      patchedUserSupportTeamUser?: PatchedUserSupportTeamUser,
      options?: any,
    ): AxiosPromise<UserSupportTeamUser> {
      return localVarFp
        .usersSupportTeamPartialUpdate(id, patchedUserSupportTeamUser, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersSupportTeamRetrieve(
      id: number,
      options?: any,
    ): AxiosPromise<UserSupportTeamUser> {
      return localVarFp
        .usersSupportTeamRetrieve(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {UserSupportTeamUser} userSupportTeamUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersSupportTeamUpdate(
      id: number,
      userSupportTeamUser: UserSupportTeamUser,
      options?: any,
    ): AxiosPromise<UserSupportTeamUser> {
      return localVarFp
        .usersSupportTeamUpdate(id, userSupportTeamUser, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersSupportTeamUsersList(
      options?: any,
    ): AxiosPromise<Array<SupportTeamUser>> {
      return localVarFp
        .usersSupportTeamUsersList(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNoteDestroy(
      id: number,
      userId: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .usersUserNoteDestroy(id, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {PatchedUserNoteEditCreate} [patchedUserNoteEditCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNotePartialUpdate(
      id: number,
      userId: number,
      patchedUserNoteEditCreate?: PatchedUserNoteEditCreate,
      options?: any,
    ): AxiosPromise<UserNoteEditCreate> {
      return localVarFp
        .usersUserNotePartialUpdate(
          id,
          userId,
          patchedUserNoteEditCreate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNoteRetrieve(
      id: number,
      userId: number,
      options?: any,
    ): AxiosPromise<UserNoteEditCreate> {
      return localVarFp
        .usersUserNoteRetrieve(id, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {UserNoteEditCreate} [userNoteEditCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNoteUpdate(
      id: number,
      userId: number,
      userNoteEditCreate?: UserNoteEditCreate,
      options?: any,
    ): AxiosPromise<UserNoteEditCreate> {
      return localVarFp
        .usersUserNoteUpdate(id, userId, userNoteEditCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {UserNoteEditCreate} [userNoteEditCreate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNotesCreate(
      userId: number,
      userNoteEditCreate?: UserNoteEditCreate,
      options?: any,
    ): AxiosPromise<UserNote> {
      return localVarFp
        .usersUserNotesCreate(userId, userNoteEditCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersUserNotesList(
      userId: number,
      options?: any,
    ): AxiosPromise<Array<UserNote>> {
      return localVarFp
        .usersUserNotesList(userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersViewedByList(
      userId: number,
      ordering?: string,
      options?: any,
    ): AxiosPromise<Array<UserProfileViewDetailed>> {
      return localVarFp
        .usersViewedByList(userId, ordering, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersViewedByRetrieve(
      id: number,
      userId: number,
      options?: any,
    ): AxiosPromise<UserProfileViewDetailed> {
      return localVarFp
        .usersViewedByRetrieve(id, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} userId
     * @param {string} [ordering] Which field to use when ordering the results.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersViewedList(
      userId: number,
      ordering?: string,
      options?: any,
    ): AxiosPromise<Array<UserProfileViewDetailed>> {
      return localVarFp
        .usersViewedList(userId, ordering, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {number} id
     * @param {number} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersViewedRetrieve(
      id: number,
      userId: number,
      options?: any,
    ): AxiosPromise<UserProfileViewDetailed> {
      return localVarFp
        .usersViewedRetrieve(id, userId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for usersDocumentsList operation in UsersApi.
 * @export
 * @interface UsersApiUsersDocumentsListRequest
 */
export interface UsersApiUsersDocumentsListRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersDocumentsList
   */
  readonly userId: number;
}

/**
 * Request parameters for usersDocumentsPartialUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersDocumentsPartialUpdateRequest
 */
export interface UsersApiUsersDocumentsPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersDocumentsPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersDocumentsPartialUpdate
   */
  readonly userId: number;

  /**
   *
   * @type {PatchedEmbeddedDropboxDocument}
   * @memberof UsersApiUsersDocumentsPartialUpdate
   */
  readonly patchedEmbeddedDropboxDocument?: PatchedEmbeddedDropboxDocument;
}

/**
 * Request parameters for usersDocumentsRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersDocumentsRetrieveRequest
 */
export interface UsersApiUsersDocumentsRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersDocumentsRetrieve
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersDocumentsRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for usersDocumentsUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersDocumentsUpdateRequest
 */
export interface UsersApiUsersDocumentsUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersDocumentsUpdate
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersDocumentsUpdate
   */
  readonly userId: number;

  /**
   *
   * @type {EmbeddedDropboxDocument}
   * @memberof UsersApiUsersDocumentsUpdate
   */
  readonly embeddedDropboxDocument?: EmbeddedDropboxDocument;
}

/**
 * Request parameters for usersFavoritedByList operation in UsersApi.
 * @export
 * @interface UsersApiUsersFavoritedByListRequest
 */
export interface UsersApiUsersFavoritedByListRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersFavoritedByList
   */
  readonly userId: number;

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof UsersApiUsersFavoritedByList
   */
  readonly ordering?: string;
}

/**
 * Request parameters for usersFavoritedByRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersFavoritedByRetrieveRequest
 */
export interface UsersApiUsersFavoritedByRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersFavoritedByRetrieve
   */
  readonly donor: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersFavoritedByRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for usersFavoritedCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersFavoritedCreateRequest
 */
export interface UsersApiUsersFavoritedCreateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersFavoritedCreate
   */
  readonly userId: number;

  /**
   *
   * @type {UserFavoriteParents}
   * @memberof UsersApiUsersFavoritedCreate
   */
  readonly userFavoriteParents: UserFavoriteParents;
}

/**
 * Request parameters for usersFavoritedDestroy operation in UsersApi.
 * @export
 * @interface UsersApiUsersFavoritedDestroyRequest
 */
export interface UsersApiUsersFavoritedDestroyRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersFavoritedDestroy
   */
  readonly likedParent: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersFavoritedDestroy
   */
  readonly userId: number;
}

/**
 * Request parameters for usersFavoritedList operation in UsersApi.
 * @export
 * @interface UsersApiUsersFavoritedListRequest
 */
export interface UsersApiUsersFavoritedListRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersFavoritedList
   */
  readonly userId: number;

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof UsersApiUsersFavoritedList
   */
  readonly ordering?: string;
}

/**
 * Request parameters for usersFavoritedRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersFavoritedRetrieveRequest
 */
export interface UsersApiUsersFavoritedRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersFavoritedRetrieve
   */
  readonly likedParent: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersFavoritedRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for usersIntroductionsList operation in UsersApi.
 * @export
 * @interface UsersApiUsersIntroductionsListRequest
 */
export interface UsersApiUsersIntroductionsListRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersIntroductionsList
   */
  readonly userId: number;
}

/**
 * Request parameters for usersIntroductionsPartialUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersIntroductionsPartialUpdateRequest
 */
export interface UsersApiUsersIntroductionsPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersIntroductionsPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedIntroductionFull}
   * @memberof UsersApiUsersIntroductionsPartialUpdate
   */
  readonly patchedIntroductionFull?: PatchedIntroductionFull;
}

/**
 * Request parameters for usersIntroductionsRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersIntroductionsRetrieveRequest
 */
export interface UsersApiUsersIntroductionsRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersIntroductionsRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for usersIntroductionsUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersIntroductionsUpdateRequest
 */
export interface UsersApiUsersIntroductionsUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersIntroductionsUpdate
   */
  readonly id: number;

  /**
   *
   * @type {IntroductionFull}
   * @memberof UsersApiUsersIntroductionsUpdate
   */
  readonly introductionFull?: IntroductionFull;
}

/**
 * Request parameters for usersMarketplaceUsersList operation in UsersApi.
 * @export
 * @interface UsersApiUsersMarketplaceUsersListRequest
 */
export interface UsersApiUsersMarketplaceUsersListRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersMarketplaceUsersList
   */
  readonly userId: number;

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof UsersApiUsersMarketplaceUsersList
   */
  readonly ordering?: string;
}

/**
 * Request parameters for usersMarketplaceUsersRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersMarketplaceUsersRetrieveRequest
 */
export interface UsersApiUsersMarketplaceUsersRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersMarketplaceUsersRetrieve
   */
  readonly parent: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersMarketplaceUsersRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for usersMeProfilePartialUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersMeProfilePartialUpdateRequest
 */
export interface UsersApiUsersMeProfilePartialUpdateRequest {
  /**
   *
   * @type {PatchedProfileObject}
   * @memberof UsersApiUsersMeProfilePartialUpdate
   */
  readonly patchedProfileObject?: PatchedProfileObject;
}

/**
 * Request parameters for usersMeProfileUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersMeProfileUpdateRequest
 */
export interface UsersApiUsersMeProfileUpdateRequest {
  /**
   *
   * @type {ProfileObject}
   * @memberof UsersApiUsersMeProfileUpdate
   */
  readonly profileObject?: ProfileObject;
}

/**
 * Request parameters for usersPhotosCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersPhotosCreateRequest
 */
export interface UsersApiUsersPhotosCreateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosCreate
   */
  readonly userId: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosCreate
   */
  readonly id: number;

  /**
   *
   * @type {string}
   * @memberof UsersApiUsersPhotosCreate
   */
  readonly image: string;

  /**
   *
   * @type {string}
   * @memberof UsersApiUsersPhotosCreate
   */
  readonly imageUrl: string;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosCreate
   */
  readonly order?: number;
}

/**
 * Request parameters for usersPhotosDestroy operation in UsersApi.
 * @export
 * @interface UsersApiUsersPhotosDestroyRequest
 */
export interface UsersApiUsersPhotosDestroyRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosDestroy
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosDestroy
   */
  readonly userId: number;
}

/**
 * Request parameters for usersPhotosList operation in UsersApi.
 * @export
 * @interface UsersApiUsersPhotosListRequest
 */
export interface UsersApiUsersPhotosListRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosList
   */
  readonly userId: number;
}

/**
 * Request parameters for usersPhotosPartialUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersPhotosPartialUpdateRequest
 */
export interface UsersApiUsersPhotosPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosPartialUpdate
   */
  readonly userId: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosPartialUpdate
   */
  readonly id2?: number;

  /**
   *
   * @type {string}
   * @memberof UsersApiUsersPhotosPartialUpdate
   */
  readonly image?: string;

  /**
   *
   * @type {string}
   * @memberof UsersApiUsersPhotosPartialUpdate
   */
  readonly imageUrl?: string;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosPartialUpdate
   */
  readonly order?: number;
}

/**
 * Request parameters for usersPhotosRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersPhotosRetrieveRequest
 */
export interface UsersApiUsersPhotosRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosRetrieve
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for usersPhotosUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersPhotosUpdateRequest
 */
export interface UsersApiUsersPhotosUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosUpdate
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosUpdate
   */
  readonly userId: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosUpdate
   */
  readonly id2: number;

  /**
   *
   * @type {string}
   * @memberof UsersApiUsersPhotosUpdate
   */
  readonly image: string;

  /**
   *
   * @type {string}
   * @memberof UsersApiUsersPhotosUpdate
   */
  readonly imageUrl: string;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersPhotosUpdate
   */
  readonly order?: number;
}

/**
 * Request parameters for usersProfileViewCountRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersProfileViewCountRetrieveRequest
 */
export interface UsersApiUsersProfileViewCountRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersProfileViewCountRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for usersProfileViewsCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersProfileViewsCreateRequest
 */
export interface UsersApiUsersProfileViewsCreateRequest {
  /**
   *
   * @type {UserProfileView}
   * @memberof UsersApiUsersProfileViewsCreate
   */
  readonly userProfileView: UserProfileView;
}

/**
 * Request parameters for usersProfileViewsList operation in UsersApi.
 * @export
 * @interface UsersApiUsersProfileViewsListRequest
 */
export interface UsersApiUsersProfileViewsListRequest {
  /**
   * Number of results to return per page.
   * @type {number}
   * @memberof UsersApiUsersProfileViewsList
   */
  readonly limit?: number;

  /**
   * The initial index from which to return the results.
   * @type {number}
   * @memberof UsersApiUsersProfileViewsList
   */
  readonly offset?: number;

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof UsersApiUsersProfileViewsList
   */
  readonly ordering?: string;
}

/**
 * Request parameters for usersRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersRetrieveRequest
 */
export interface UsersApiUsersRetrieveRequest {
  /**
   *
   * @type {string}
   * @memberof UsersApiUsersRetrieve
   */
  readonly username: string;
}

/**
 * Request parameters for usersSupportTeamPartialUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersSupportTeamPartialUpdateRequest
 */
export interface UsersApiUsersSupportTeamPartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersSupportTeamPartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {PatchedUserSupportTeamUser}
   * @memberof UsersApiUsersSupportTeamPartialUpdate
   */
  readonly patchedUserSupportTeamUser?: PatchedUserSupportTeamUser;
}

/**
 * Request parameters for usersSupportTeamRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersSupportTeamRetrieveRequest
 */
export interface UsersApiUsersSupportTeamRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersSupportTeamRetrieve
   */
  readonly id: number;
}

/**
 * Request parameters for usersSupportTeamUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersSupportTeamUpdateRequest
 */
export interface UsersApiUsersSupportTeamUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersSupportTeamUpdate
   */
  readonly id: number;

  /**
   *
   * @type {UserSupportTeamUser}
   * @memberof UsersApiUsersSupportTeamUpdate
   */
  readonly userSupportTeamUser: UserSupportTeamUser;
}

/**
 * Request parameters for usersUserNoteDestroy operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserNoteDestroyRequest
 */
export interface UsersApiUsersUserNoteDestroyRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNoteDestroy
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNoteDestroy
   */
  readonly userId: number;
}

/**
 * Request parameters for usersUserNotePartialUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserNotePartialUpdateRequest
 */
export interface UsersApiUsersUserNotePartialUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNotePartialUpdate
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNotePartialUpdate
   */
  readonly userId: number;

  /**
   *
   * @type {PatchedUserNoteEditCreate}
   * @memberof UsersApiUsersUserNotePartialUpdate
   */
  readonly patchedUserNoteEditCreate?: PatchedUserNoteEditCreate;
}

/**
 * Request parameters for usersUserNoteRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserNoteRetrieveRequest
 */
export interface UsersApiUsersUserNoteRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNoteRetrieve
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNoteRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for usersUserNoteUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserNoteUpdateRequest
 */
export interface UsersApiUsersUserNoteUpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNoteUpdate
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNoteUpdate
   */
  readonly userId: number;

  /**
   *
   * @type {UserNoteEditCreate}
   * @memberof UsersApiUsersUserNoteUpdate
   */
  readonly userNoteEditCreate?: UserNoteEditCreate;
}

/**
 * Request parameters for usersUserNotesCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserNotesCreateRequest
 */
export interface UsersApiUsersUserNotesCreateRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNotesCreate
   */
  readonly userId: number;

  /**
   *
   * @type {UserNoteEditCreate}
   * @memberof UsersApiUsersUserNotesCreate
   */
  readonly userNoteEditCreate?: UserNoteEditCreate;
}

/**
 * Request parameters for usersUserNotesList operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserNotesListRequest
 */
export interface UsersApiUsersUserNotesListRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersUserNotesList
   */
  readonly userId: number;
}

/**
 * Request parameters for usersViewedByList operation in UsersApi.
 * @export
 * @interface UsersApiUsersViewedByListRequest
 */
export interface UsersApiUsersViewedByListRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersViewedByList
   */
  readonly userId: number;

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof UsersApiUsersViewedByList
   */
  readonly ordering?: string;
}

/**
 * Request parameters for usersViewedByRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersViewedByRetrieveRequest
 */
export interface UsersApiUsersViewedByRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersViewedByRetrieve
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersViewedByRetrieve
   */
  readonly userId: number;
}

/**
 * Request parameters for usersViewedList operation in UsersApi.
 * @export
 * @interface UsersApiUsersViewedListRequest
 */
export interface UsersApiUsersViewedListRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersViewedList
   */
  readonly userId: number;

  /**
   * Which field to use when ordering the results.
   * @type {string}
   * @memberof UsersApiUsersViewedList
   */
  readonly ordering?: string;
}

/**
 * Request parameters for usersViewedRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersViewedRetrieveRequest
 */
export interface UsersApiUsersViewedRetrieveRequest {
  /**
   *
   * @type {number}
   * @memberof UsersApiUsersViewedRetrieve
   */
  readonly id: number;

  /**
   *
   * @type {number}
   * @memberof UsersApiUsersViewedRetrieve
   */
  readonly userId: number;
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   *
   * @param {UsersApiUsersDocumentsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDocumentsList(
    requestParameters: UsersApiUsersDocumentsListRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersDocumentsList(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersDocumentsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDocumentsPartialUpdate(
    requestParameters: UsersApiUsersDocumentsPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersDocumentsPartialUpdate(
        requestParameters.id,
        requestParameters.userId,
        requestParameters.patchedEmbeddedDropboxDocument,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersDocumentsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDocumentsRetrieve(
    requestParameters: UsersApiUsersDocumentsRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersDocumentsRetrieve(
        requestParameters.id,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersDocumentsUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersDocumentsUpdate(
    requestParameters: UsersApiUsersDocumentsUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersDocumentsUpdate(
        requestParameters.id,
        requestParameters.userId,
        requestParameters.embeddedDropboxDocument,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersFavoritedByListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersFavoritedByList(
    requestParameters: UsersApiUsersFavoritedByListRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersFavoritedByList(
        requestParameters.userId,
        requestParameters.ordering,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersFavoritedByRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersFavoritedByRetrieve(
    requestParameters: UsersApiUsersFavoritedByRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersFavoritedByRetrieve(
        requestParameters.donor,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersFavoritedCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersFavoritedCreate(
    requestParameters: UsersApiUsersFavoritedCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersFavoritedCreate(
        requestParameters.userId,
        requestParameters.userFavoriteParents,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersFavoritedDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersFavoritedDestroy(
    requestParameters: UsersApiUsersFavoritedDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersFavoritedDestroy(
        requestParameters.likedParent,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersFavoritedListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersFavoritedList(
    requestParameters: UsersApiUsersFavoritedListRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersFavoritedList(
        requestParameters.userId,
        requestParameters.ordering,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersFavoritedRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersFavoritedRetrieve(
    requestParameters: UsersApiUsersFavoritedRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersFavoritedRetrieve(
        requestParameters.likedParent,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersIntroductionsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersIntroductionsList(
    requestParameters: UsersApiUsersIntroductionsListRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersIntroductionsList(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersIntroductionsPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersIntroductionsPartialUpdate(
    requestParameters: UsersApiUsersIntroductionsPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersIntroductionsPartialUpdate(
        requestParameters.id,
        requestParameters.patchedIntroductionFull,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersIntroductionsRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersIntroductionsRetrieve(
    requestParameters: UsersApiUsersIntroductionsRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersIntroductionsRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersIntroductionsUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersIntroductionsUpdate(
    requestParameters: UsersApiUsersIntroductionsUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersIntroductionsUpdate(
        requestParameters.id,
        requestParameters.introductionFull,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersList(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersMarketplaceUsersListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersMarketplaceUsersList(
    requestParameters: UsersApiUsersMarketplaceUsersListRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersMarketplaceUsersList(
        requestParameters.userId,
        requestParameters.ordering,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersMarketplaceUsersRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersMarketplaceUsersRetrieve(
    requestParameters: UsersApiUsersMarketplaceUsersRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersMarketplaceUsersRetrieve(
        requestParameters.parent,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersMeProfilePartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersMeProfilePartialUpdate(
    requestParameters: UsersApiUsersMeProfilePartialUpdateRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersMeProfilePartialUpdate(
        requestParameters.patchedProfileObject,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersMeProfileRetrieve(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersMeProfileRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersMeProfileUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersMeProfileUpdate(
    requestParameters: UsersApiUsersMeProfileUpdateRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersMeProfileUpdate(requestParameters.profileObject, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersMeRetrieve(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersMeRetrieve(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersPhotosCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersPhotosCreate(
    requestParameters: UsersApiUsersPhotosCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersPhotosCreate(
        requestParameters.userId,
        requestParameters.id,
        requestParameters.image,
        requestParameters.imageUrl,
        requestParameters.order,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersPhotosDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersPhotosDestroy(
    requestParameters: UsersApiUsersPhotosDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersPhotosDestroy(
        requestParameters.id,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersPhotosListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersPhotosList(
    requestParameters: UsersApiUsersPhotosListRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersPhotosList(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersPhotosPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersPhotosPartialUpdate(
    requestParameters: UsersApiUsersPhotosPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersPhotosPartialUpdate(
        requestParameters.id,
        requestParameters.userId,
        requestParameters.id2,
        requestParameters.image,
        requestParameters.imageUrl,
        requestParameters.order,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersPhotosRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersPhotosRetrieve(
    requestParameters: UsersApiUsersPhotosRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersPhotosRetrieve(
        requestParameters.id,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersPhotosUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersPhotosUpdate(
    requestParameters: UsersApiUsersPhotosUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersPhotosUpdate(
        requestParameters.id,
        requestParameters.userId,
        requestParameters.id2,
        requestParameters.image,
        requestParameters.imageUrl,
        requestParameters.order,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersProfileViewCountRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersProfileViewCountRetrieve(
    requestParameters: UsersApiUsersProfileViewCountRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersProfileViewCountRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersProfileViewsCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersProfileViewsCreate(
    requestParameters: UsersApiUsersProfileViewsCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersProfileViewsCreate(requestParameters.userProfileView, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersProfileViewsListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersProfileViewsList(
    requestParameters: UsersApiUsersProfileViewsListRequest = {},
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersProfileViewsList(
        requestParameters.limit,
        requestParameters.offset,
        requestParameters.ordering,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersRetrieve(
    requestParameters: UsersApiUsersRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersRetrieve(requestParameters.username, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersSupportTeamPartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersSupportTeamPartialUpdate(
    requestParameters: UsersApiUsersSupportTeamPartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersSupportTeamPartialUpdate(
        requestParameters.id,
        requestParameters.patchedUserSupportTeamUser,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersSupportTeamRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersSupportTeamRetrieve(
    requestParameters: UsersApiUsersSupportTeamRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersSupportTeamRetrieve(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersSupportTeamUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersSupportTeamUpdate(
    requestParameters: UsersApiUsersSupportTeamUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersSupportTeamUpdate(
        requestParameters.id,
        requestParameters.userSupportTeamUser,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersSupportTeamUsersList(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .usersSupportTeamUsersList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersUserNoteDestroyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUserNoteDestroy(
    requestParameters: UsersApiUsersUserNoteDestroyRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersUserNoteDestroy(
        requestParameters.id,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersUserNotePartialUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUserNotePartialUpdate(
    requestParameters: UsersApiUsersUserNotePartialUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersUserNotePartialUpdate(
        requestParameters.id,
        requestParameters.userId,
        requestParameters.patchedUserNoteEditCreate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersUserNoteRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUserNoteRetrieve(
    requestParameters: UsersApiUsersUserNoteRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersUserNoteRetrieve(
        requestParameters.id,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersUserNoteUpdateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUserNoteUpdate(
    requestParameters: UsersApiUsersUserNoteUpdateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersUserNoteUpdate(
        requestParameters.id,
        requestParameters.userId,
        requestParameters.userNoteEditCreate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersUserNotesCreateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUserNotesCreate(
    requestParameters: UsersApiUsersUserNotesCreateRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersUserNotesCreate(
        requestParameters.userId,
        requestParameters.userNoteEditCreate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersUserNotesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersUserNotesList(
    requestParameters: UsersApiUsersUserNotesListRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersUserNotesList(requestParameters.userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersViewedByListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersViewedByList(
    requestParameters: UsersApiUsersViewedByListRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersViewedByList(
        requestParameters.userId,
        requestParameters.ordering,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersViewedByRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersViewedByRetrieve(
    requestParameters: UsersApiUsersViewedByRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersViewedByRetrieve(
        requestParameters.id,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersViewedListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersViewedList(
    requestParameters: UsersApiUsersViewedListRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersViewedList(
        requestParameters.userId,
        requestParameters.ordering,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UsersApiUsersViewedRetrieveRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public usersViewedRetrieve(
    requestParameters: UsersApiUsersViewedRetrieveRequest,
    options?: AxiosRequestConfig,
  ) {
    return UsersApiFp(this.configuration)
      .usersViewedRetrieve(
        requestParameters.id,
        requestParameters.userId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get the report or candidate ID from the webhook payload. Persist the report ID if it\'s available, and update the report via the CheckR API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksCheckrCreate: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/webhooks/checkr/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksStripeCreate: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/webhooks/stripe/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksStripeIdentityCreate: async (
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/webhooks/stripe-identity/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication cookieAuth required

      // authentication jwtCookieAuth required

      // authentication jwtHeaderAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      // authentication tokenAuth required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'Authorization',
        configuration,
      );

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration);
  return {
    /**
     * Get the report or candidate ID from the webhook payload. Persist the report ID if it\'s available, and update the report via the CheckR API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webhooksCheckrCreate(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.webhooksCheckrCreate(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webhooksStripeCreate(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.webhooksStripeCreate(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webhooksStripeIdentityCreate(
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.webhooksStripeIdentityCreate(options);
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WebhooksApiFp(configuration);
  return {
    /**
     * Get the report or candidate ID from the webhook payload. Persist the report ID if it\'s available, and update the report via the CheckR API.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksCheckrCreate(options?: any): AxiosPromise<void> {
      return localVarFp
        .webhooksCheckrCreate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksStripeCreate(options?: any): AxiosPromise<void> {
      return localVarFp
        .webhooksStripeCreate(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webhooksStripeIdentityCreate(options?: any): AxiosPromise<void> {
      return localVarFp
        .webhooksStripeIdentityCreate(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
  /**
   * Get the report or candidate ID from the webhook payload. Persist the report ID if it\'s available, and update the report via the CheckR API.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public webhooksCheckrCreate(options?: AxiosRequestConfig) {
    return WebhooksApiFp(this.configuration)
      .webhooksCheckrCreate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public webhooksStripeCreate(options?: AxiosRequestConfig) {
    return WebhooksApiFp(this.configuration)
      .webhooksStripeCreate(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebhooksApi
   */
  public webhooksStripeIdentityCreate(options?: AxiosRequestConfig) {
    return WebhooksApiFp(this.configuration)
      .webhooksStripeIdentityCreate(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
