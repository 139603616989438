import type { FC } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useApiClient } from '@nodal/api';
import { queryKeys } from '@nodal/core/consts/query';
import { useEffect, useState, useCallback, useContext, Fragment } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { useCandidate } from '@b2b/components/CandidateDetails';
import { CandidateContext } from '@b2b/components/CandidateDetails/CandidateDetails.connect';
import { settings } from '@b2b/settings';

import type { ApiModel } from '@nodal/api';

interface DocuSignTemplate {
  id: number;
  name: string;
}

const getStatusColor = (status: string | undefined) => {
  if (!status) return '';
  switch (status) {
    case 'signed':
      return 'bg-green-100 text-green-800';
    case 'declined':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

const DocumentsTable: FC<{
  documents: ApiModel.ParentDocuSignEnvelope[];
  title: string;
  onViewDocument: (url: string) => void;
  variant?: 'default' | 'compact';
  showAllColumns?: boolean;
  maxWidth?: string;
}> = ({
  documents,
  title,
  onViewDocument,
  variant = 'default',
  showAllColumns = true,
  maxWidth,
}) => {
  if (documents.length === 0) return null;

  const isCompact = variant === 'compact';

  return (
    <div className="space-y-2" style={maxWidth ? { maxWidth } : undefined}>
      <h3
        className={`${
          isCompact ? 'text-base' : 'text-lg'
        } font-medium text-gray-900`}
      >
        {title}
      </h3>
      <div className="overflow-x-auto">
        <table
          className={`min-w-full divide-y divide-gray-200 ${
            isCompact ? 'text-xs' : 'text-sm'
          }`}
        >
          <thead className="bg-gray-50">
            <tr>
              <th
                className={`${
                  isCompact ? 'py-1.5' : 'py-2'
                } px-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase`}
              >
                Template Name
              </th>
              <th
                className={`${
                  isCompact ? 'py-1.5' : 'py-2'
                } px-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase`}
              >
                Status
              </th>
              <th
                className={`${
                  isCompact ? 'py-1.5' : 'py-2'
                } px-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase`}
              >
                Created At
              </th>
              {showAllColumns && (
                <>
                  <th
                    className={`${
                      isCompact ? 'py-1.5' : 'py-2'
                    } px-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase`}
                  >
                    Completed At
                  </th>
                  <th
                    className={`${
                      isCompact ? 'py-1.5' : 'py-2'
                    } px-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase`}
                  >
                    Document
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody
            className={`${
              isCompact ? 'text-xs' : 'text-sm'
            } bg-white divide-y divide-gray-200`}
          >
            {documents.map((doc) => (
              <tr key={doc.id}>
                <td
                  className={`${
                    isCompact ? 'py-1.5' : 'py-2'
                  } px-4 text-gray-900 whitespace-nowrap`}
                >
                  {doc.template_name}
                </td>
                <td
                  className={`${
                    isCompact ? 'py-1.5' : 'py-2'
                  } px-4 whitespace-nowrap`}
                >
                  <span
                    className={`inline-flex rounded-full px-2 ${
                      isCompact ? 'py-0.5 text-xs' : 'py-1 text-xs'
                    } font-medium leading-4 ${getStatusColor(doc.status)}`}
                  >
                    {doc.status}
                  </span>
                </td>
                <td
                  className={`${
                    isCompact ? 'py-1.5' : 'py-2'
                  } px-4 text-gray-600 whitespace-nowrap`}
                >
                  {new Date(doc.created).toLocaleString()}
                </td>
                {showAllColumns && (
                  <>
                    <td
                      className={`${
                        isCompact ? 'py-1.5' : 'py-2'
                      } px-4 text-gray-600 whitespace-nowrap`}
                    >
                      {doc.completed_at
                        ? new Date(doc.completed_at).toLocaleString()
                        : '-'}
                    </td>
                    <td
                      className={`${
                        isCompact ? 'py-1.5' : 'py-2'
                      } px-4 whitespace-nowrap`}
                    >
                      {doc.document_url && (
                        <button
                          onClick={() => {
                            const baseUrl = settings.getApiUrl();
                            const fullUrl = doc.document_url.startsWith('http')
                              ? doc.document_url
                              : `${baseUrl}${doc.document_url}`;
                            onViewDocument(fullUrl);
                          }}
                          className={`${
                            isCompact ? 'text-xs' : 'text-sm'
                          } font-medium text-blue-600 hover:text-blue-900`}
                        >
                          View Document
                        </button>
                      )}
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const DocuSignDocuments: FC = () => {
  const [templates, setTemplates] = useState<DocuSignTemplate[]>([]);
  const [documents, setDocuments] = useState<ApiModel.ParentDocuSignEnvelope[]>(
    [],
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSending, setIsSending] = useState(false);
  const [isMarkingComplete, setIsMarkingComplete] = useState(false);

  const apiClient = useApiClient();
  const { profile, ipTasks } = useCandidate();
  const { refetchCandidate } = useContext(CandidateContext);
  const queryClient = useQueryClient();

  const contractTask = ipTasks?.find((task) => task.type === 'ip-contract');
  const isContractComplete = contractTask?.status === 'complete';

  const handleViewDocument = (url: string) => {
    window.open(url, '_blank');
  };

  const fetchDocuments = useCallback(async () => {
    if (!profile?.id) return;
    try {
      const response = await apiClient.api.DocusignApi.docusignDocumentsList({
        parentId: profile.id,
      });
      if (Array.isArray(response.data)) {
        const sortedDocuments = [...response.data].sort((a, b) => {
          // If both documents have completed_at dates, sort by completed_at
          if (a.completed_at && b.completed_at) {
            return (
              new Date(b.completed_at).getTime() -
              new Date(a.completed_at).getTime()
            );
          }
          // If only one document has completed_at, put it first
          if (a.completed_at) return -1;
          if (b.completed_at) return 1;
          // If neither has completed_at, sort by created date
          return new Date(b.created).getTime() - new Date(a.created).getTime();
        });
        setDocuments(sortedDocuments);
      }
    } catch (error) {
      console.error('Error fetching DocuSign documents:', error);
    }
  }, [apiClient, profile?.id]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setError(null);
        const response =
          await apiClient.api.DocusignApi.docusignTemplatesList();

        if (Array.isArray(response.data)) {
          setTemplates(response.data);
        } else {
          console.error('Unexpected API response format:', response.data);
          setError('Invalid response format');
        }
      } catch (error) {
        console.error('Error fetching DocuSign templates:', error);
        setError('Failed to load templates');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTemplates();
  }, [apiClient]);

  useEffect(() => {
    fetchDocuments();
  }, [apiClient, profile?.id, fetchDocuments]);

  const handleSendDocument = async () => {
    if (selectedTemplateId === -1 || !profile?.id) return;

    try {
      setIsSending(true);
      setError(null);

      const createEnvelope: ApiModel.CreateEnvelope = {
        template_id: selectedTemplateId,
        parent_id: profile.id,
      };

      await apiClient.api.DocusignApi.docusignEnvelopeCreateCreate({
        createEnvelope,
      });

      setSelectedTemplateId(-1); // Reset selection after successful send
      toast.success('Document sent successfully');

      // Refresh everything to ensure UI is up to date
      await Promise.all([
        fetchDocuments(),
        refetchCandidate(),
        queryClient.invalidateQueries(queryKeys.b2bUserTasksList),
      ]);
    } catch (error) {
      console.error('Error creating envelope:', error);
      setError('Failed to send document');
      toast.error('Failed to send document');
    } finally {
      setIsSending(false);
    }
  };

  const handleMarkAsComplete = async () => {
    try {
      if (!contractTask?.id) {
        toast.error('Contract task not found');
        return;
      }

      setIsMarkingComplete(true);
      await apiClient.api.TasksApi.tasksPartialUpdate({
        id: contractTask.id,
        patchedTask: {
          status: 'complete',
        },
      });
      await refetchCandidate();
      await queryClient.invalidateQueries([queryKeys.b2bUserTasksList]);
      toast.success('DocuSign documents marked as complete');
    } catch (err) {
      toast.error('Failed to mark DocuSign documents as complete');
      console.error('Error marking DocuSign documents as complete:', err);
    } finally {
      setIsMarkingComplete(false);
    }
  };

  const signedDocuments = documents.filter((doc) => doc.status !== 'sent');
  const pendingDocuments = documents.filter((doc) => doc.status === 'sent');

  return (
    <div className="space-y-6">
      <div className="flex gap-4 items-center">
        <div className="relative w-full">
          <Listbox value={selectedTemplateId} onChange={setSelectedTemplateId}>
            <div className="relative mt-1">
              <Listbox.Button className="relative py-2 pr-10 pl-3 w-full text-left bg-white rounded-md border border-gray-300 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-pointer sm:text-sm">
                <span className="block truncate">
                  {selectedTemplateId === -1
                    ? 'Please select a document template'
                    : templates.find((t) => t.id === selectedTemplateId)
                        ?.name || ''}
                </span>
                <span className="flex absolute inset-y-0 right-0 items-center pr-2 pointer-events-none">
                  <ChevronDownIcon
                    className="w-5 h-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="overflow-auto absolute z-10 py-1 mt-1 w-full max-h-60 text-base bg-white rounded-md focus:outline-none ring-1 ring-black/5 shadow-lg sm:text-sm">
                  <Listbox.Option
                    key="default"
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-3 pr-9 ${
                        active ? 'bg-blue-100 text-blue-900' : 'text-gray-500'
                      }`
                    }
                    value={-1}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          Please select a document template
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                  {templates.map((template) => (
                    <Listbox.Option
                      key={template.id}
                      className={({ active }) =>
                        `relative cursor-pointer select-none py-2 pl-3 pr-9 ${
                          active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'
                        }`
                      }
                      value={template.id}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block whitespace-normal ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}
                          >
                            {template.name}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
        <button
          onClick={handleSendDocument}
          disabled={selectedTemplateId === -1 || isSending}
          className="shrink-0 py-2 px-4 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:bg-blue-300 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          {isSending ? 'Sending...' : 'Send'}
        </button>
        {!isContractComplete && (
          <button
            onClick={handleMarkAsComplete}
            disabled={isMarkingComplete}
            className="shrink-0 py-2 px-4 text-sm font-medium text-white bg-green-600 hover:bg-green-700 disabled:bg-green-300 rounded-md border border-transparent focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            {isMarkingComplete ? 'Marking...' : 'Mark as Complete'}
          </button>
        )}
      </div>

      {isLoading ? (
        <div className="text-center">Loading templates...</div>
      ) : error ? (
        <div className="text-center text-red-600">{error}</div>
      ) : documents.length === 0 ? (
        <div className="text-center text-gray-500">
          No documents available at the moment
        </div>
      ) : (
        <div className="space-y-8">
          <DocumentsTable
            documents={signedDocuments}
            title="Signed Documents"
            onViewDocument={handleViewDocument}
            variant="compact"
            showAllColumns={true}
          />
          <DocumentsTable
            documents={pendingDocuments}
            title="Pending Documents"
            onViewDocument={handleViewDocument}
            variant="compact"
            showAllColumns={false}
            maxWidth="600px"
          />
        </div>
      )}
    </div>
  );
};
